import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { usePaymentContext } from 'features/payment';

import { ICartEntry, IServerOrder } from '@rbi-ctg/menu';
import { IRestaurant } from '@rbi-ctg/store';
import {
  GetOrderDocument,
  IDelivery,
  useLoyaltyUserTransactionsQuery,
  useUpdateOrderMutation,
} from 'generated/graphql-gateway';
import { useNavigation } from 'hooks/navigation/use-navigation';
import { useCart } from 'hooks/use-cart';
import useEffectOnUpdates from 'hooks/use-effect-on-updates';
import { useSetResetCartTimeout } from 'hooks/use-set-reset-cart-timeout';
import {
  CustomEventNames,
  EventTypes,
  logAddRecentItemToCartEvent,
  logEvent,
  logPurchase,
  logRBIEvent,
} from 'state/amplitude';
import { useAuthContext } from 'state/auth';
import { useErrorContext } from 'state/errors';
import { actions, selectors, useAppDispatch, useAppSelector } from 'state/global-state';
import { apolloClient } from 'state/graphql/client';
import { LaunchDarklyFlag, useFlag, useLDContext } from 'state/launchdarkly';
import { useLoyaltyContext } from 'state/loyalty';
import { useLoyaltyUser } from 'state/loyalty/hooks/use-loyalty-user';
import { getCmsOffersMapByCmsId } from 'state/loyalty/utils';
import { useMainMenuContext } from 'state/menu/main-menu';
import { useServiceModeContext } from 'state/service-mode';
import { ServiceMode } from 'state/service-mode/types';
import { useStoreContext } from 'state/store';
import { usePosDataQuery } from 'state/store/hooks/use-pos-data-query';
import { getUnavailableCartEntries } from 'utils/availability';
import { CartEntryType } from 'utils/cart';
import { ISOs, getCountryAndCurrencyCodes } from 'utils/form/constants';
import { IPlaceAddress } from 'utils/geolocation/types';
import LocalStorage, { StorageKeys } from 'utils/local-storage';
import logger, { addLoggingContext } from 'utils/logger';
import { useMemoAll } from 'utils/use-memo-all';

import { PRE_CURBSIDE_CONFIRM_ARRIVAL_TIMEOUT_IN_MINUTES } from './constants';
import { useHandleReorder } from './hooks/use-handle-reorder';
import { useUnavailableCartEntries } from './hooks/use-unavailable-cart-entries';
import { preloadedOrder } from './preloaded-order';
import { IOrderContext, IPendingReorder, OrderStatus } from './types';
import { isOrderTimeInInterval, validateCartEntries } from './utils';

export { OrderStatus, ServiceMode };
export const OrderContext = React.createContext<IOrderContext>({} as IOrderContext);
export const useOrderContext = () => useContext(OrderContext);

export const OrderProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const preloaded = useMemo(() => preloadedOrder(), []);

  const { isAuthenticated, refetchCurrentUser, user } = useAuthContext();
  const billingCountry = user?.details?.isoCountryCode || ISOs.USA;
  const { currencyCode } = getCountryAndCurrencyCodes(billingCountry as ISOs);
  const { getPaymentMethods } = usePaymentContext();
  const { updateUserStore } = useLDContext();
  const {
    prices,
    resetStore,
    selectStore: selectNewStore,
    store,
    resetLastTimeStoreUpdated,
    isStoreOpenAndAvailable,
  } = useStoreContext();
  const { storeMenuLoading } = useMainMenuContext();
  const { serviceMode, setServiceMode } = useServiceModeContext();
  const { navigate, linkTo } = useNavigation();
  const { setCurrentOrderId } = useErrorContext();
  const { refetchLoyaltyUser } = useLoyaltyContext();
  const { loyaltyUser } = useLoyaltyUser();
  const appliedOffers = useAppSelector(selectors.loyalty.selectAppliedOffers);
  const loyaltyCmsOffers = useAppSelector(selectors.loyalty.selectCmsOffers);
  const incentivesIds = useAppSelector(selectors.loyalty.selectIncentivesIds);
  const selectedLoyaltyOffer = useAppSelector(selectors.loyalty.selectSelectedOffer);
  const [cancelCurbsideOrderCallbackUrl, setCancelCurbsideOrderCallbackUrl] = useState<string>();

  const dispatch = useAppDispatch();

  const loyaltyUserId = loyaltyUser?.id;

  const { refetch: refetchLoyaltyUserTransaction } = useLoyaltyUserTransactionsQuery({
    skip: !loyaltyUserId,
    variables: { loyaltyId: loyaltyUserId || '' },
  });

  const [executeUpdateOrderMutation] = useUpdateOrderMutation();

  const [pendingReorder, setPendingReorder] = useState<IPendingReorder | null>(null);
  const [reordering, setReordering] = useState<boolean>(false);
  const [reorderedOrderId, setReorderedOrderId] = useState<string | null>(null);

  const {
    cartEntries,
    calculateCartTotal,
    addItemToCart,
    emptyCart,
    isCartEmpty,
    removeAllFromCart,
    removeFromCart,
    repriceCartEntries,
  } = useCart();

  const [curbsidePickupOrderId, setCurbsidePickupOrderId] = useState<string>(
    preloaded.curbsidePickupOrderId || ''
  );
  const [curbsidePickupOrderTimePlaced, setCurbsidePickupOrderTimePlaced] = useState<string>(
    preloaded.curbsidePickupOrderTimePlaced || ''
  );
  const [serverOrder, setServerOrder] = useState<IServerOrder | null>(null);
  const [quoteId, setQuoteId] = useState<string>(preloaded.quoteId || '');
  const [deliveryAddress, setDeliveryAddress] = useState<IPlaceAddress | null>(
    preloaded.deliveryAddress || {}
  );
  const [deliveryInstructions, setDeliveryInstructions] = useState<string | undefined>(
    preloaded.deliveryInstructions || ''
  );
  const { refetch: refetchPosData } = usePosDataQuery({
    lazy: true,
    storeNumber: '',
  });
  const [fetchingPosData, setFetchingPosData] = useState(false);
  const userPhoneNumber = user?.details?.phoneNumber;
  const [orderPhoneNumber, setOrderPhoneNumber] = useState<string>(
    // Initialize with:
    // 1. preloaded number from a previous order using the selected delivery address
    // 2. user's default number
    // 3. or an empty string if neither is available
    () => preloaded.orderPhoneNumber || user?.details?.phoneNumber || ''
  );

  React.useEffect(() => {
    if (orderPhoneNumber) {
      return;
    }
    if (userPhoneNumber) {
      setOrderPhoneNumber(userPhoneNumber);
    }
  }, [userPhoneNumber, orderPhoneNumber]);

  const [fireOrderIn, setFireOrderIn] = useState(0);

  const isDelivery = serviceMode === ServiceMode.DELIVERY;

  // TODO: BKPE-1956 - unavailableCartEntries
  const { unavailableCartEntries, setUnavailableCartEntries } = useUnavailableCartEntries({
    cartEntries,
    serverOrder,
  });
  const [pendingRecentItem, setPendingRecentItem] = useState<ICartEntry | null>(null);
  const [
    unavailablePendingRecentItem,
    setUnavailablePendingRecentItem,
  ] = useState<ICartEntry | null>(null);

  const isPreConfirmCurbside = (): boolean =>
    !!curbsidePickupOrderId &&
    isOrderTimeInInterval(
      curbsidePickupOrderTimePlaced,
      PRE_CURBSIDE_CONFIRM_ARRIVAL_TIMEOUT_IN_MINUTES
    );

  const updateShouldSaveDeliveryAddress = useCallback((shouldSaveDeliveryAddress: boolean) => {
    setDeliveryAddress(previousAddress => {
      if (!previousAddress || previousAddress.shouldSave === shouldSaveDeliveryAddress) {
        return previousAddress;
      }
      return {
        ...previousAddress,
        shouldSave: shouldSaveDeliveryAddress,
      };
    });
  }, []);

  useSetResetCartTimeout({
    storageKey: StorageKeys.ORDER_LAST_UPDATE,
    cart: cartEntries,
    resetCartCallback: emptyCart,
  });

  useEffectOnUpdates(() => {
    if (!isAuthenticated) {
      if (serviceMode === ServiceMode.CURBSIDE) {
        setCurbsidePickupOrderId('');
        setCurbsidePickupOrderTimePlaced('');
      }
      emptyCart();
    }
  }, [emptyCart, isAuthenticated, serviceMode]);

  useEffect(() => {
    setCurrentOrderId(serverOrder?.rbiOrderId || '');
  }, [serverOrder, setCurrentOrderId]);

  useEffectOnUpdates(() => {
    // Creating a map out of all valid offers in the CMS
    const cmsOffersMap = getCmsOffersMapByCmsId(loyaltyCmsOffers);
    appliedOffers.forEach(({ cartId, cmsId }) => {
      // If the applied offer is not in the cms offers map, should remove it
      const shouldRemoveCartEntry = !cmsId || !cmsOffersMap[cmsId];
      if (shouldRemoveCartEntry && cartId) {
        // Removing offer from cart will also remove it from applied offers
        removeFromCart({ cartId });
      }
    });
  }, [loyaltyCmsOffers]);

  const selectServiceMode = useCallback(
    (newMode: ServiceMode | null) => {
      logRBIEvent({
        name: CustomEventNames.SELECT_SERVICE_MODE,
        type: EventTypes.Other,
        attributes: null,
      });
      setFireOrderIn(0);
      setServiceMode(newMode);
      resetLastTimeStoreUpdated();
      return Promise.resolve(true);
    },
    [setServiceMode, resetLastTimeStoreUpdated]
  );

  useEffect(() => {
    if (incentivesIds.size > 0) {
      validateCartEntries(cartEntries, appliedOffers, incentivesIds, removeFromCart);
    }
  }, [appliedOffers, cartEntries, incentivesIds, removeFromCart]);

  const shouldEmptyCart = useCallback(
    ({ cartId }: { cartId: string }) => {
      const remainingCartEntries = cartEntries.filter(entry => entry.cartId !== cartId);
      const entryIsDonationOrExtra = (entry: ICartEntry) => entry.isDonation || entry.isExtra;

      return remainingCartEntries.every(entryIsDonationOrExtra);
    },
    [cartEntries]
  );

  const logCartStoreAndTimeout = useCallback(
    (resetStoreTimeout: number, timeSinceLastVisit: number) => {
      const storeDetails = {
        cartEntriesTotal: cartEntries.length,
        storeId: store._id,
        itemNames: cartEntries.map((entry: any) => entry.name),
      };

      const { cartEntriesTotal, storeId, itemNames } = storeDetails;

      logEvent(CustomEventNames.SESSION_RESET_FROM_INACTIVITY, EventTypes.Other, {
        storeDetails: `cartEntriesTotal: ${cartEntriesTotal}, storeId: ${storeId}, itemNames: ${JSON.stringify(
          itemNames
        )} `,
        resetStoreTimeoutSeconds: resetStoreTimeout,
        hoursSinceLastVisit: parseInt((timeSinceLastVisit / (1000 * 60 * 60)).toFixed(1)),
      });
    },
    [cartEntries, store._id]
  );

  const clearCartStoreServiceModeTimeout = useCallback(() => {
    selectServiceMode(null);
    setUnavailableCartEntries([]);
    emptyCart();
    resetStore();
    LocalStorage.removeItem(StorageKeys.LAST_TIME_STORE_UPDATED);
  }, [emptyCart, resetStore, selectServiceMode, setUnavailableCartEntries]);

  const checkoutDeliveryPriceMinimum = useFlag(LaunchDarklyFlag.OVERRIDE_CHECKOUT_DELIVERY_MINIMUM);

  const alertOrderDeliveryMinimum = useCallback(() => {
    const cartTotal = calculateCartTotal();
    if (cartEntries.length > 0 && isDelivery && cartTotal < checkoutDeliveryPriceMinimum) {
      logEvent(CustomEventNames.CHECKOUT_DELIVERY_MINIMUM_NOT_REACHED, EventTypes.Other, {
        total: String(cartTotal),
      });

      logRBIEvent(
        {
          name: CustomEventNames.DELIVERY_MINIMUM_NOT_REACHED,
          type: EventTypes.Other,
          attributes: {
            subtotal: cartTotal,
            deliveryMinimum: checkoutDeliveryPriceMinimum,
          },
        },
        { skipLoggingToBraze: true }
      );
    }
  }, [calculateCartTotal, cartEntries.length, checkoutDeliveryPriceMinimum, isDelivery]);

  const queryOrder = useCallback(async (rbiOrderId: string): Promise<IServerOrder | null> => {
    const { data, errors } = await apolloClient.query<{ order: IServerOrder }>({
      fetchPolicy: 'network-only',
      query: GetOrderDocument,
      variables: {
        id: rbiOrderId,
      },
    });

    if (errors) {
      // eslint-disable-next-line no-undef
      logger.error({ errors, message: 'Error querying order' });
      throw errors;
    }

    return data?.order ?? null;
  }, []);

  // commit was being fired twice, which was firing onCommitSuccess twice
  // the lastPurchaseOrderRId checks if the same order is being committed twice
  // where we set .current = rbiOrderID is on the first run, so the second run will return early
  const lastPurchaseOrderId = useRef<string>();
  const onCommitSuccess = useCallback(
    async (remoteOrder: IServerOrder) => {
      if (lastPurchaseOrderId.current === remoteOrder.rbiOrderId) {
        return;
      }
      if (remoteOrder.cart?.serviceMode === ServiceMode.CURBSIDE) {
        setCurbsidePickupOrderId('');
        setCurbsidePickupOrderTimePlaced('');
      }
      lastPurchaseOrderId.current = remoteOrder.rbiOrderId;
      emptyCart();

      // TODO: improve IServerOrder and IDelivery types to consume directly from generated types
      const remoteOrderDelivery: IDelivery | null = remoteOrder.delivery as IDelivery;
      const quotedFeeCents = remoteOrderDelivery?.quotedFeeCents || 0;
      const deliveryFeeCents = remoteOrderDelivery?.feeCents || 0;
      const deliveryFeeDiscountCents = remoteOrderDelivery?.feeDiscountCents || 0;
      const deliveryGeographicalFeeCents = remoteOrderDelivery?.geographicalFeeCents || 0;
      const deliveryServiceFeeCents = remoteOrderDelivery?.serviceFeeCents || 0;
      const deliverySmallCartFeeCents = remoteOrderDelivery?.smallCartFeeCents || 0;
      const baseDeliveryFeeCents = remoteOrderDelivery?.baseDeliveryFeeCents || 0;
      const deliverySurchargeFeeCents = remoteOrderDelivery?.deliverySurchargeFeeCents || 0;
      logPurchase(cartEntries, store, serviceMode as ServiceMode, remoteOrder, {
        currencyCode,
        quotedFeeCents,
        baseDeliveryFeeCents,
        totalDeliveryOrderFeesCents:
          baseDeliveryFeeCents +
          deliverySurchargeFeeCents +
          deliveryServiceFeeCents +
          deliverySmallCartFeeCents +
          deliveryGeographicalFeeCents -
          deliveryFeeDiscountCents,
        deliveryFeeCents:
          deliveryFeeCents -
          deliveryFeeDiscountCents -
          deliveryGeographicalFeeCents -
          deliveryServiceFeeCents -
          deliverySmallCartFeeCents,
        deliverySurchargeFeeCents,
        deliveryFeeDiscountCents,
        deliveryGeographicalFeeCents,
        deliveryServiceFeeCents,
        deliverySmallCartFeeCents,
        fireOrderInMinutes: Math.round(fireOrderIn / 60),
      });
      setServerOrder(remoteOrder);

      try {
        await Promise.all([
          // refresh the user's payment methods stored
          // in state, including gift cards
          getPaymentMethods(),
          // refresh loyalty user points and recent transactions
          loyaltyUserId ? refetchLoyaltyUser() : Promise.resolve(),
          loyaltyUserId ? refetchLoyaltyUserTransaction() : Promise.resolve(),
          // refresh unique purchases
          refetchCurrentUser(),
          // refetch loyalty rewards
          dispatch(actions.loyalty.setShouldRefetchRewards(true)),
        ]);
      } catch (error) {
        logger.error({ error, message: 'Error after commit success' });
      }
    },
    [
      emptyCart,
      cartEntries,
      store,
      serviceMode,
      currencyCode,
      fireOrderIn,
      getPaymentMethods,
      loyaltyUserId,
      refetchLoyaltyUser,
      refetchLoyaltyUserTransaction,
      refetchCurrentUser,
      dispatch,
    ]
  );

  const getAndRefreshServerOrder = useCallback(
    async (id: string): Promise<IServerOrder | null> => {
      try {
        const order = await queryOrder(id);
        setServerOrder(order);
        return order;
      } catch (error) {
        logger.error({ error, message: 'Error refreshing order' });
      }
      return null;
    },
    [queryOrder]
  );

  const fireOrderInXSeconds = useCallback<IOrderContext['fireOrderInXSeconds']>(
    async ({ rbiOrderId, timeInSeconds }) => {
      const { data, errors } = await executeUpdateOrderMutation({
        variables: {
          input: {
            fireOrderIn: timeInSeconds,
            rbiOrderId,
          },
        },
      });

      if (errors) {
        logger.error({ errors, message: 'Error updating order.' });
        throw errors;
      }

      return getAndRefreshServerOrder(data?.updateOrder?.rbiOrderId || rbiOrderId);
    },
    [executeUpdateOrderMutation, getAndRefreshServerOrder]
  );

  const clearServerOrder = useCallback(() => {
    setServerOrder(null);
  }, []);

  // make sure we persist everything!
  useEffect(() => {
    LocalStorage.setItem(StorageKeys.ORDER, {
      deliveryAddress,
      deliveryInstructions,
      quoteId,
      orderPhoneNumber,
      curbsidePickupOrderId,
      curbsidePickupOrderTimePlaced,
    });
  }, [
    serviceMode,
    deliveryInstructions,
    orderPhoneNumber,
    deliveryAddress,
    curbsidePickupOrderId,
    curbsidePickupOrderTimePlaced,
    quoteId,
  ]);

  // Configure the logger to hold some information
  const serverOrderRbiOrderId = serverOrder?.rbiOrderId;
  useEffect(() => {
    const storePosVendor = store?.pos?.vendor?.toLowerCase();

    const extras = {
      serviceMode,
      storePosVendor,
    };

    addLoggingContext({
      ...extras,
      rbiOrderId: serverOrderRbiOrderId,
    });
  }, [cartEntries, serverOrderRbiOrderId, serviceMode, store]);

  const repriceCartEntriesRef = useRef<typeof repriceCartEntries>();
  repriceCartEntriesRef.current = repriceCartEntries;

  // recursively reprice cartEntries when prices change
  // Currently only used to reflect a price change on Web Desktop, on the mini cart
  // The checkout flow has is own implementation of the repricing
  useEffect(() => {
    if (storeMenuLoading || !prices || !cartEntries?.length || !repriceCartEntriesRef.current) {
      return;
    }

    const repricedCartEntries = repriceCartEntriesRef.current(cartEntries);
    // Reprice cart entries return the same memory object if there is no difference
    // So using !== is valid in this context
    if (repricedCartEntries !== cartEntries) {
      dispatch(actions.ordering.repriceCartEntries(repricedCartEntries));
    }
  }, [cartEntries, dispatch, prices, storeMenuLoading]);

  const handleReorder = useHandleReorder({
    addToCart: addItemToCart,
    navigate,
    linkTo,
    setPendingReorder,
    setReordering,
    storeHasSelection: isStoreOpenAndAvailable,
    setUnavailableCartEntries,
    setReorderedOrderId,
  });

  const resetPendingReorder = useCallback(() => {
    setPendingReorder(null);
    setReordering(false);
    setReorderedOrderId(null);
  }, []);

  const selectStore = useCallback(
    async (
      newStore: IRestaurant,
      callback: () => void,
      requestedServiceMode: ServiceMode,
      skipRedirection?: boolean,
      skipChangeStoreMessage?: boolean
    ) => {
      setFetchingPosData(true);

      await updateUserStore(newStore);

      const selectedStorePrices = await refetchPosData({
        storeNumber: newStore.number || null,
        isDelivery: requestedServiceMode === ServiceMode.DELIVERY,
      });

      // CartEntries corresponding to Offers can't be checked using its original type
      const mappedCartEntries = (cartEntries || []).map(cartEntry => ({
        ...cartEntry,
        type:
          cartEntry.type === CartEntryType.offerCombo
            ? CartEntryType.combo
            : cartEntry.type === CartEntryType.offerItem
            ? CartEntryType.item
            : cartEntry.type,
      }));

      const unavailableItems = getUnavailableCartEntries(
        mappedCartEntries,
        newStore,
        selectedStorePrices?.posData || prices,
        requestedServiceMode
      );

      const unavailablePendingItems = pendingRecentItem
        ? getUnavailableCartEntries(
            [pendingRecentItem],
            newStore,
            selectedStorePrices?.posData || prices,
            requestedServiceMode
          )
        : [];

      await selectNewStore({
        skipRedirection,
        skipChangeStoreMessage,
        sanityStore: newStore,
        hasCartItems: !isCartEmpty,
        unavailableCartEntries: [...unavailableItems, ...unavailablePendingItems],
        callback: () => {
          if (unavailableItems.length) {
            removeAllFromCart(unavailableItems);
          }

          //On the Offers Details screen the user can choose an Item Offer and don't apply it in the Wizard
          //this creates an inconsistency in where we have an Offer selected but not applied
          if (appliedOffers.length === 0 && selectedLoyaltyOffer) {
            dispatch(actions.loyalty.setSelectedOffer(null));
          }

          if (pendingRecentItem) {
            if (unavailablePendingItems.length) {
              setUnavailablePendingRecentItem(pendingRecentItem);
            } else {
              addItemToCart(pendingRecentItem);
              logAddRecentItemToCartEvent(pendingRecentItem?.name ?? '', newStore);
            }

            // Remove any pending item regardless if we add it or not.
            setPendingRecentItem(null);
          }

          callback();
        },
        requestedServiceMode,
        // TODO: remove this parameter from selectNewStore
        selectedOffer: null,
      });

      setFetchingPosData(false);
    },
    [
      addItemToCart,
      appliedOffers.length,
      cartEntries,
      dispatch,
      refetchPosData,
      isCartEmpty,
      pendingRecentItem,
      prices,
      removeAllFromCart,
      selectNewStore,
      selectedLoyaltyOffer,
      updateUserStore,
    ]
  );

  const shouldHandleReorderRef = useRef(false);
  const setShouldHandleReorder = useCallback((bool: boolean) => {
    shouldHandleReorderRef.current = bool;
  }, []);

  useEffect(() => {
    if (!shouldHandleReorderRef.current) {
      return;
    }
    // we have to wait for pricing to be complete from selecting a new store before we redirect
    // we also only want to reorder if we haven't already, pendingReorder gets set to null when we are done reordering
    if (isStoreOpenAndAvailable && prices && pendingReorder) {
      setShouldHandleReorder(false);
      handleReorder(pendingReorder);
    }
  }, [handleReorder, isStoreOpenAndAvailable, pendingReorder, prices, setShouldHandleReorder]);

  const reorder = useMemoAll({
    handleReorder,
    resetPendingReorder,
    reordering,
    pendingReorder,
    setReordering,
    setPendingReorder,
    reorderedOrderId,
    setShouldHandleReorder,
  });

  const recent = useMemoAll({
    pendingRecentItem,
    setPendingRecentItem,
    unavailablePendingRecentItem,
    setUnavailablePendingRecentItem,
  });

  const value = useMemoAll<IOrderContext>({
    unavailableCartEntries,
    setUnavailableCartEntries,
    serviceMode,
    serverOrder,
    alertOrderDeliveryMinimum,
    shouldEmptyCart,
    selectServiceMode,
    clearServerOrder,
    selectStore,
    fetchingPosData,
    clearCartStoreServiceModeTimeout,
    logCartStoreAndTimeout,
    deliveryAddress,
    setDeliveryAddress,
    deliveryInstructions,
    setDeliveryInstructions,
    orderPhoneNumber,
    setOrderPhoneNumber,
    isDelivery,
    fireOrderIn,
    setFireOrderIn,
    onCommitSuccess,
    curbsidePickupOrderId,
    updateShouldSaveDeliveryAddress,
    setCurbsidePickupOrderId,
    setCurbsidePickupOrderTimePlaced,
    setQuoteId,
    quoteId,
    fireOrderInXSeconds,
    reorder,
    recent,
    isPreConfirmCurbside,
    cancelCurbsideOrderCallbackUrl,
    setCancelCurbsideOrderCallbackUrl,
  });

  return <OrderContext.Provider value={value}>{children}</OrderContext.Provider>;
};

export default OrderContext.Consumer;
