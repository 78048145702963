import React, { useCallback, useMemo, useRef, useState } from 'react';

import { ApolloError } from '@apollo/client';
import {
  Box,
  FormikCheckbox,
  FormikFormControl,
  FormikInput,
  Header,
  Text,
  View,
} from '@rbilabs/universal-components';
import { Formik, FormikErrors, FormikHelpers, FormikProps } from 'formik';
import { useIntl } from 'react-intl';

import { FormikBirthdayInput } from 'components/formik/birthday-input';
import { FormikCountrySelect } from 'components/formik/country-select';
import { VisuallyHidden } from 'components/ucl/visually-hidden';
import { PromotionalEmailsLabel } from 'components/user-info-form/checkbox-labels';
import { OptInLabel, TermsLink } from 'components/user-info-form/styled';
import { ProviderType, useSignUpCompleteQuery } from 'generated/graphql-gateway';
import useAuthRedirects from 'hooks/auth/use-auth-redirects';
import { useLoyaltyContent } from 'hooks/loyalty/use-loyalty-content';
import { useNavigation } from 'hooks/navigation/use-navigation';
import { useRoute } from 'hooks/navigation/use-route';
import { useRumPageView } from 'hooks/rum/use-rum-page-view';
import useEffectOnce from 'hooks/use-effect-once';
import useErrorModal from 'hooks/use-error-modal';
import { useFeatureAccountForm } from 'hooks/use-feature-account-form';
import { useFeatureDisclaimers } from 'hooks/use-feature-disclaimers';
import { useFieldIsRequired } from 'hooks/use-field-is-required';
import AuthActions from 'pages/authentication/components/auth-actions';
import { DialogStaticPage } from 'pages/authentication/components/dialog-static-page';
import { CampaignRouteParams } from 'pages/campaigns/types';
import { useGetExternalCampaignBySlug } from 'pages/campaigns/use-get-external-campaign-by-slug';
import { autoSignInEvent, logOTPSignUpError, signUpEvent } from 'state/amplitude';
import {
  AuthenticationMethod,
  AuthenticationPhases,
  CustomEventNames,
} from 'state/amplitude/constants';
import { useAuthContext } from 'state/auth';
import { SIGN_IN_FAIL } from 'state/auth/constants';
import { useLocale } from 'state/intl';
import { PROD_DEFAULT_REGION as DEFAULT_REGION } from 'state/intl/constants';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import {
  SignUpFieldsVariations,
  defaultSignUpFieldsVariation,
} from 'state/launchdarkly/variations';
import { useLocationContext } from 'state/location';
import { useUIContext } from 'state/ui';
import { isWeb } from 'utils/environment';
import { Region } from 'utils/environment/types';
import { GraphQLErrorCodes, graphQLErrorsIncludeExtensionCode } from 'utils/errors';
import { dobStringToObj, formatDateObject, sanitizePhoneNumber } from 'utils/form';
import { ISOs } from 'utils/form/constants';
import { ISignupFormData } from 'utils/form/types';
import { validationConfig } from 'utils/form/user-profile-validation-config/validation';
import LocalStorage, { StorageKeys } from 'utils/local-storage';
import logger from 'utils/logger';
import { routes } from 'utils/routing';
import SessionStorage, { SessionStorageKeys } from 'utils/session-storage';

import { SignupAccordion } from '../components/signup-accordion/signup-accordion';

import AutoSignin from './auto-signin';
import ExistingEmailDialog from './existing-email-dialog';
import FavoriteLocationField from './favorite-location-field';
import initialFormState from './initial-form-state';
import PhoneNumberField from './phone-number-field';
import {
  ButtonWrapper,
  Disclaimer,
  ErrorMessage,
  FieldContainer,
  MarketDisclaimer,
  SignUpContainer,
} from './signup.styled';
import { IOverrides, ISignup, OverridableFields } from './types';
import UpdateSessionStorage from './update-session-storage';
import { generateDisplayedErrors, validateSignupFormError } from './utils';

const LEGACY_USER_CREATE_CONFLICT = 'LEGACY_USER_CREATE_CONFLICT';
const USERNAME_EXISTS_EXCEPTION = 'UsernameExistsException';
const ZERO_BOUNCE = 'zerobounce';
const POLLING_INTERVAL = 2000;
const SIGNUP_COMPLETE_TIMEOUT = 1000 * 30;

/**
 * LaunchDarkly has a flag that decides if we return an error
 * via GraphQL or as a network error.
 *
 * @param error The error returned from graphql
 *
 * @returns {boolean}
 */
const hasZeroBounceError = (error: ApolloError | Error): boolean => {
  const zeroBounceGraphqlValidationFailed = graphQLErrorsIncludeExtensionCode(
    error instanceof ApolloError ? error.graphQLErrors : [],
    GraphQLErrorCodes.ZERO_BOUNCE_VALIDATION_FAILED
  );

  const zeroBounceNetworkError = error.message.toLowerCase().includes(ZERO_BOUNCE);

  return zeroBounceGraphqlValidationFailed || zeroBounceNetworkError;
};

const SignUp = ({ skipRedirect, authCampaignExtraFields, initialEmail = '' }: ISignup) => {
  useRumPageView('sign-up', 'Sign Up');
  useAuthRedirects({ skip: skipRedirect! });
  const {
    signUp,
    openSignInModal,
    originLocation,
    modalAuthIsOpen,
    modalAuthState: {
      user: modalAuthStateUser,
      loginForVendorIsLoading,
      providerType,
      shouldWaitLoginForVendor,
    },
    isAuthenticated,
    loading,
  } = useAuthContext();
  const { setSignUpEmail } = useLocationContext();
  const { params } = useRoute<
    CampaignRouteParams & {
      email?: string;
      name?: string;
      authenticationMethod?: string;
      socialAuthenticationType?: ProviderType;
    }
  >();
  const { campaign, isLoading: isCampaignLoading } = useGetExternalCampaignBySlug({
    slug: params?.campaignSlug ?? '',
  });

  const { navigate } = useNavigation();

  const { formatMessage } = useIntl();

  const { pathname } = useRoute();

  const [errorMessageToAnnounce, setErrorMessageToAnnounce] = useState('');
  const [formSuccessfullySubmitted, setFormSuccessfullySubmitted] = useState(false);
  const [isSignUpLoading, setSignUpIsLoading] = useState(false);
  const [email, setEmail] = useState(initialEmail);
  const [signUpJwt, setSignUpJwt] = useState('');
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const { region } = useLocale();
  const enableCustomBirthdayInput = useFlag(LaunchDarklyFlag.ENABLE_CUSTOM_BIRTHDAY_INPUT);
  const enableAutoSignInOnSignUp = useFlag(LaunchDarklyFlag.ENABLE_AUTO_SIGN_IN_ON_SIGN_UP);
  const enableZeroBounceErrorModal = useFlag(LaunchDarklyFlag.ENABLE_ZERO_BOUNCE_ERROR_MODAL);
  const enableSignupAfterCart = useFlag(LaunchDarklyFlag.ENABLE_MOVE_SIGNUP_AFTER_CART);
  const signUpFieldsVariations =
    useFlag<SignUpFieldsVariations>(LaunchDarklyFlag.SIGN_UP_FIELDS_VARIATIONS) ||
    defaultSignUpFieldsVariation;
  const requiredOverrides = signUpFieldsVariations.phone
    ? undefined
    : { [OverridableFields.PHONE]: { required: false } };

  const [ErrorDialog, openErrorDialog] = useErrorModal({
    modalAppearanceEventMessage: 'Error: Sign In Failure',
  });

  const zipCodeIsRequired = useFieldIsRequired(region, validationConfig, 'zipcode');
  const birthdayIsRequired = useFieldIsRequired(region, validationConfig, 'dob');
  const phoneNumberIsRequired = useFieldIsRequired(region, validationConfig, 'phoneNumber');

  const [showSignInWithExistingEmailDialog, setShowSignInWithExistingEmailDialog] = useState(false);
  const [ZeroBounceErrorDialog, openZeroBounceErrorDialog] = useErrorModal({
    onConfirm: () => navigate(routes.support, { popCurrent: true }),
    modalAppearanceEventMessage: 'Error: Sign In Failure [Zero Bounce]',
  });
  const [ForterSignUpErrorDialog, openForterSignUpErrorDialog] = useErrorModal({
    onConfirm: () => navigate(routes.support, { popCurrent: true }),
    modalAppearanceEventMessage: 'Error: Sign Up Failure [Forter]',
  });

  const { featureDisclaimers } = useFeatureDisclaimers();
  const { birthdayLegalText } = useLoyaltyContent();
  const { data: signUpCompleteData, startPolling, stopPolling } = useSignUpCompleteQuery({
    variables: {
      jwt: signUpJwt,
    },
    skip: !signUpJwt,
    onError: error => {
      autoSignInEvent({
        message: error.message,
        phase: AuthenticationPhases.ERROR,
      });
    },
  });

  const timeoutId = useRef<NodeJS.Timeout>();

  const startSignUpCompleteTimeout = useCallback(() => {
    clearTimeout(timeoutId.current);
    timeoutId.current = setTimeout(() => {
      if (!signUpCompleteData?.signUpComplete) {
        stopPolling();
        setSignUpJwt('');
        setSignUpIsLoading(false);
        openErrorDialog({ message: formatMessage({ id: 'authRetryError' }) });
      }
    }, SIGNUP_COMPLETE_TIMEOUT);
  }, [signUpCompleteData]);

  const clearSignUpCompleteTimeout = useCallback(() => {
    clearTimeout(timeoutId.current);
  }, []);

  const { featureAccountForm } = useFeatureAccountForm();
  const isSignUpCompleteLoading =
    enableAutoSignInOnSignUp &&
    !!signUpJwt &&
    !signUpCompleteData?.signUpComplete &&
    !isAuthenticated;

  const { country, signUpDisclaimers, signUpMarketDisclaimers, phoneNumber } =
    featureDisclaimers || {};
  const birthdayDisclaimer = birthdayLegalText ?? '';
  const postalZipCodeDisclaimer = featureAccountForm?.postalOrZipDisclaimer?.locale ?? '';
  const signInLink = useRef<HTMLAnchorElement>(null);

  const defaultSignupValues =
    SessionStorage.getItem(SessionStorageKeys.USER_FORM) ||
    initialFormState({
      enableCustomBirthdayInput,
      regionIso: ISOs[region],
    });

  const initialUserFormState: ISignupFormData = {
    ...defaultSignupValues,
    name: modalAuthStateUser?.name || params.name || defaultSignupValues.name,
    email:
      initialEmail ||
      modalAuthStateUser?.email ||
      params.email ||
      LocalStorage.getItem(StorageKeys.LOGIN) ||
      defaultSignupValues.email,
    dob:
      typeof defaultSignupValues.dob === 'string'
        ? dobStringToObj(defaultSignupValues.dob)
        : defaultSignupValues.dob,
  };

  // this is used for the sign up experiment to redirect any redirect to sign up to the sign in that is the entry point for both
  useEffectOnce(() => {
    // If the auth modal is open we need to bypass it redirect
    if (modalAuthIsOpen) {
      return;
    }
    if (!params.email) {
      navigate(routes.signIn, { replace: true });
    }
  });

  useEffectOnce(() => {
    // when landing on sign up page focus should be on sign in link for accessibility
    signInLink.current?.focus();

    // Clear signup complete timeout on component unmount
    return () => {
      clearSignUpCompleteTimeout();
    };
  });

  const handleAccordionInteraction = (isActive: boolean) => {
    setIsAccordionOpen(isActive);
  };

  const handleSubmitError = useCallback(
    (error: any, values: ISignupFormData, actions: FormikHelpers<ISignupFormData>) => {
      logger.warn(error);
      setSignUpIsLoading(false);

      // Clear signup complete timeout
      clearSignUpCompleteTimeout();

      // Determine error type
      const forterSignUpDecline = graphQLErrorsIncludeExtensionCode(
        error.graphQLErrors,
        GraphQLErrorCodes.SIGNUP_VALIDATION_ERROR
      );
      const emailAlreadyExists = graphQLErrorsIncludeExtensionCode(
        error.graphQLErrors,
        GraphQLErrorCodes.AUTH_EMAIL_ALREADY_EXIST
      );
      const legacyErrorRegexp = new RegExp(LEGACY_USER_CREATE_CONFLICT, 'g');
      const userAlreadyExists =
        error.code === USERNAME_EXISTS_EXCEPTION ||
        error.message.match(legacyErrorRegexp) ||
        emailAlreadyExists;
      const signInFailure = error.code === SIGN_IN_FAIL;
      const zeroBounceFailure = enableZeroBounceErrorModal && hasZeroBounceError(error);

      // Perform next actions based on which error has been received
      if (forterSignUpDecline) {
        openForterSignUpErrorDialog({
          error,
          message: formatMessage({ id: 'forterSignUpError' }),
          title: formatMessage({ id: 'duplicateAccounts' }),
        });
        setSignUpEmail(values.email);
        actions.setFieldError('email', formatMessage({ id: 'accountWithEmailAlreadyExists' }));
        logOTPSignUpError(formatMessage({ id: 'forterSignUpError' }));
        return;
      }
      if (userAlreadyExists) {
        const errorMessage = formatMessage({ id: 'accountWithEmailAlreadyExists' });
        setShowSignInWithExistingEmailDialog(true);
        setSignUpEmail(values.email);
        actions.setFieldError('email', errorMessage);
        logOTPSignUpError(errorMessage);
        return;
      }
      if (signInFailure) {
        // TODO:  bypass it for the challenger campaign auth flow
        const errorMessage = formatMessage({ id: 'signUpSuccessSignInFail' });
        setSignUpEmail(values.email);

        if (modalAuthIsOpen) {
          openSignInModal({ errorMessage });
          logOTPSignUpError(errorMessage);
          return;
        }

        navigate(routes.signUp, {
          state: {
            errorMessage,
            activeRouteIsSignIn: true,
          },
        });
      }
      if (zeroBounceFailure) {
        openZeroBounceErrorDialog({
          error,
          message: formatMessage({ id: 'theEmailAddressIsInvalid' }),
          title: formatMessage({ id: 'invalidEmail' }),
        });
        return;
      }
      openErrorDialog({
        error,
        message: formatMessage({ id: 'signUpError' }),
      });
    },
    [
      modalAuthIsOpen,
      enableZeroBounceErrorModal,
      formatMessage,
      navigate,
      openErrorDialog,
      openForterSignUpErrorDialog,
      openZeroBounceErrorDialog,
      setSignUpEmail,
      clearSignUpCompleteTimeout,
      openSignInModal,
    ]
  );

  const onSubmit = useCallback(
    async (values: ISignupFormData, actions: FormikHelpers<ISignupFormData>) => {
      setSignUpIsLoading(true);

      LocalStorage.setItem(StorageKeys.LOGIN, values.email);
      // need to do this so that TH uses the correct country in a
      // case where user switches locales in the middle of signing up
      let signupCountry = values.country;
      // only if we aren't giving the user the specific country option
      if (!signUpFieldsVariations.country) {
        signupCountry = ISOs[region] || ISOs[DEFAULT_REGION];
      }

      const favoriteStoresInfo = signUpFieldsVariations.favoriteStoreSelector && {
        favoriteStores: [
          {
            storeId: values.favoriteLocation?.storeId,
            storeNumber: values.favoriteLocation?.storeNumber,
          },
        ],
      };

      const isWalmartLandingPage = pathname === routes.walmart;
      const source = campaign?.slug ?? (isWalmartLandingPage ? 'walmart' : undefined);

      // We can only send a string for DOB
      const dob = formatDateObject(values.dob);
      const signupValues = {
        ...values,
        ...favoriteStoresInfo,
        phoneNumber: await sanitizePhoneNumber(values.phoneNumber, signupCountry),
        dob,
        country: signupCountry,
        email: values.email.trim(),
        zipcode: values.zipcode?.toUpperCase(),
        ...(source && { crmAttributes: { source } }),
      };

      try {
        const { jwt } = await signUp(
          signupValues,
          enableAutoSignInOnSignUp ? () => Promise.resolve() : undefined,
          params.authenticationMethod,
          params.socialAuthenticationType || providerType
        );

        const socialAuthenticationType = params.socialAuthenticationType;
        const authenticationMethod = socialAuthenticationType
          ? AuthenticationMethod.SOCIAL
          : AuthenticationMethod.OTP;

        signUpEvent({
          phase: AuthenticationPhases.COMPLETE,
          authenticationMethod,
          socialAuthenticationType,
        });

        // The state shouldn't be changed to false if a login for a vendor is expected.
        // This is to avoid a race condition among the functions completing the signup flow in a campaign.
        // The sequence involves user registration, auto-login, JWT validation, and finally,
        // the loginForVendor mutation within the campaign framework
        // If the state is prematurely changed to false, it can intermittent re-enable the submit button,
        // Campagin Framework: 'pages/campaigns/campaigns.tsx'
        if (!shouldWaitLoginForVendor) {
          setSignUpIsLoading(false);
        }

        setEmail(values.email);
        setFormSuccessfullySubmitted(true);

        if (jwt && enableAutoSignInOnSignUp) {
          autoSignInEvent({ phase: AuthenticationPhases.START });
          setSignUpJwt(jwt);
          startPolling(POLLING_INTERVAL);
          setSignUpIsLoading(true);
        }
      } catch (error) {
        handleSubmitError(error, values, actions);
      }
    },
    [
      autoSignInEvent,
      campaign,
      enableAutoSignInOnSignUp,
      handleSubmitError,
      isCampaignLoading,
      params,
      region,
      signUp,
      signUpFieldsVariations.country,
      shouldWaitLoginForVendor,
    ]
  );

  const TermsOfServiceLabel = () => {
    const { formatMessage } = useIntl();
    const { setDialogStaticPageState } = useUIContext();

    const openDialogStaticPage = (page: string) => setDialogStaticPageState({ isOpen: true, page });

    const privacyPolicyLink = (
      <TermsLink onPress={() => openDialogStaticPage(routes.privacyPolicy)}>
        {formatMessage({ id: 'privacyPolicy' })}
      </TermsLink>
    );

    const rewardsPolicyLink = (
      <TermsLink onPress={() => openDialogStaticPage(routes.termsConditionsRewards)}>
        {formatMessage({ id: 'rewardsPolicy' })}
      </TermsLink>
    );

    const termsOfServiceLink = (
      <TermsLink onPress={() => openDialogStaticPage(routes.termsOfService)}>
        {formatMessage({ id: 'termsOfService' })}
      </TermsLink>
    );

    return (
      <OptInLabel>
        {formatMessage(
          { id: 'acceptTermsAndPrivacyIntro' },
          { termsOfServiceLink, privacyPolicyLink, rewardsPolicyLink }
        )}
      </OptInLabel>
    );
  };

  const shouldDisplayMarketDisclaimer = useMemo(() => {
    return (
      enableSignupAfterCart && signUpMarketDisclaimers?.locale && originLocation === routes.cart
    );
  }, [enableSignupAfterCart, originLocation, signUpMarketDisclaimers]);

  const regionValue = Region[region];

  const generateErrorMessageForAccessibility = (errors: FormikErrors<ISignupFormData>) => {
    const errorEntries = Object.values(errors);
    if (errorEntries.length > 0) {
      let errorsToAnnounce = formatMessage(
        { id: 'createAnAccountErrors' },
        { count: errorEntries.length }
      );
      errorsToAnnounce = `${errorsToAnnounce} ${errorEntries
        .map((error, index) => {
          let errorMessage = '';
          if (errorEntries.length > 1) {
            errorMessage = `${formatMessage({ id: 'error' })} ${index + 1}: `;
          }
          return `${errorMessage} ${error} `;
        })
        .join('')}`;

      setErrorMessageToAnnounce(errorsToAnnounce);
    } else {
      setErrorMessageToAnnounce('');
    }
  };

  return (
    <>
      {formSuccessfullySubmitted && (
        <VisuallyHidden
          accessibilityLabel={formatMessage({ id: 'youHaveSuccessfullySubmittedTheForm' })}
        >
          <Box accessibilityRole="alert">{formatMessage({ id: 'signupEmailSent' }, { email })}</Box>
        </VisuallyHidden>
      )}
      <Formik
        initialValues={initialUserFormState}
        onSubmit={onSubmit}
        validate={validateSignupFormError({
          formatMessage,
          region: regionValue,
          overrides: requiredOverrides as IOverrides,
          signUpFieldsVariations,
          simpleValidation: false,
        })}
      >
        {({
          handleSubmit,
          errors,
          setFieldValue,
          touched,
          setTouched,
          validateForm,
          values,
          setFieldTouched,
        }: FormikProps<ISignupFormData>) => {
          const isDobInvalid = Boolean(touched.dob && errors.dob);
          const isFavoriteLocationInvalid = Boolean(
            touched.favoriteLocation && errors.favoriteLocation
          );

          const accessibilityLabel = formatMessage(
            /* @ts-expect-error TS(2322) FIXME: */
            generateDisplayedErrors(errors, touched).length === 1
              ? { id: 'thereIsAnErrorInThisForm' }
              : { id: 'thereAreErrorsInThisForm' },
            {
              /* @ts-expect-error TS(2322) FIXME: */
              numberOfErrors: generateDisplayedErrors(errors, touched).length,
            }
          );

          const isLoading =
            isSignUpLoading ||
            isSignUpCompleteLoading ||
            loading ||
            (shouldWaitLoginForVendor && loginForVendorIsLoading);

          return (
            <SignUpContainer>
              <Header accessibilityLevel={2} variant={'headerThree'}>
                {formatMessage({ id: 'createAnAccount' })}
              </Header>
              <Text mb="$4" ref={signInLink} focusable>
                {formatMessage({ id: 'createAnAccountSubheader' }, { email: values.email })}
              </Text>

              {/* @ts-expect-error TS(2322) FIXME: */}
              {generateDisplayedErrors(errors, touched).length ? (
                <VisuallyHidden role="alert" accessibilityLabel={accessibilityLabel}>
                  <Text marginBottom="$2" variant="copyOne">
                    {accessibilityLabel}
                  </Text>
                </VisuallyHidden>
              ) : null}

              {shouldDisplayMarketDisclaimer && (
                <MarketDisclaimer testID="signup-market-disclaimer">
                  {signUpMarketDisclaimers?.locale}
                </MarketDisclaimer>
              )}
              {signUpFieldsVariations.country && (
                <FieldContainer>
                  <FormikCountrySelect
                    name="country"
                    testID="signup-country-input"
                    label={formatMessage({ id: 'country' })}
                    disclaimer={country?.locale || ''}
                    onChange={value => {
                      setFieldValue('country', value.toUpperCase());
                    }}
                    value={values.country}
                  />
                  <ErrorMessage>{errors.country}</ErrorMessage>
                </FieldContainer>
              )}

              <FieldContainer>
                <FormikFormControl
                  label={formatMessage({ id: 'firstName' })}
                  name="name"
                  isRequired
                  isDisabled={isLoading}
                >
                  <FormikInput
                    inputProps={{
                      accessibilityLabel: formatMessage({ id: 'name' }),
                      placeholder: formatMessage({ id: 'firstName' }),
                      testID: 'signup-name-input',
                      placeholderTextColor: '#979797',
                      autoComplete: 'name-given',
                    }}
                  />
                </FormikFormControl>
              </FieldContainer>

              {signUpFieldsVariations.phone && (
                <>
                  <PhoneNumberField
                    isRequired={phoneNumberIsRequired}
                    hintMessage={phoneNumber?.locale}
                  />
                  {!!phoneNumber?.locale && <Disclaimer>{phoneNumber?.locale}</Disclaimer>}
                </>
              )}
              <SignupAccordion
                title={formatMessage({ id: 'optionalInformation' })}
                onInteract={handleAccordionInteraction}
                isOpen={isDobInvalid || isAccordionOpen}
                disabled={isDobInvalid}
                id={'optional-information'}
              >
                {signUpFieldsVariations.zip && (
                  <FieldContainer>
                    <FormikFormControl
                      label={formatMessage({
                        id: values.country === ISOs.USA ? 'zipCode' : 'postalCode',
                      })}
                      name="zipcode"
                      isRequired={zipCodeIsRequired}
                      isDisabled={isLoading}
                    >
                      <FormikInput
                        inputProps={{
                          placeholder: formatMessage({
                            id: values.country === ISOs.USA ? 'zipCode' : 'postalCode',
                          }),
                          testID: 'signup-zipcode-input',
                          placeholderTextColor: '#979797',
                          maxLength: 10,
                          autoComplete: 'postal-code',
                        }}
                      />
                    </FormikFormControl>
                  </FieldContainer>
                )}
                {!!authCampaignExtraFields && authCampaignExtraFields}
                {!!postalZipCodeDisclaimer && <Disclaimer>{postalZipCodeDisclaimer}</Disclaimer>}
                {signUpFieldsVariations.dob && (
                  <FieldContainer>
                    <FormikFormControl name="dob">
                      <FormikBirthdayInput
                        disabled={isLoading}
                        day={values.dob.day}
                        month={values.dob.month}
                        year={values.dob.year}
                        isRequired={birthdayIsRequired}
                        onBlur={inputName => setFieldTouched(`dob.${inputName}`, true)}
                        onChange={(inputName, inputText) =>
                          setFieldValue(`dob.${inputName}`, inputText)
                        }
                        testID="signup-dob"
                        dobErrors={errors.dob}
                        dobTouched={touched.dob}
                        hintMessage={birthdayDisclaimer}
                      />
                    </FormikFormControl>
                  </FieldContainer>
                )}
                {signUpFieldsVariations.favoriteStoreSelector && (
                  <FavoriteLocationField
                    setFieldValue={setFieldValue}
                    isInvalid={isFavoriteLocationInvalid}
                    disabled={isLoading}
                  />
                )}
                {signUpDisclaimers?.locale && <Disclaimer>{signUpDisclaimers.locale}</Disclaimer>}
              </SignupAccordion>

              {signUpFieldsVariations.wantsPromotionalEmails && (
                <FieldContainer>
                  <FormikCheckbox
                    name="wantsPromotionalEmails"
                    checkboxProps={{
                      alignItems: 'flex-start',
                      value: 'true',
                      testID: 'signup-promotional-emails',
                      accessibilityLabel: formatMessage({ id: 'signUpForPromotionalEmails' }),
                      isDisabled: isLoading,
                    }}
                  >
                    <PromotionalEmailsLabel />
                  </FormikCheckbox>
                </FieldContainer>
              )}

              {signUpFieldsVariations.agreesToTermsOfService && (
                <FieldContainer>
                  <FormikFormControl
                    name="agreesToTermsOfService"
                    isRequired
                    isDisabled={isLoading}
                  >
                    <FormikCheckbox
                      checkboxProps={{
                        onChange: isSelected => setFieldValue('agreesToTermsOfService', isSelected),
                        alignItems: 'flex-start',
                        value: 'false',
                        testID: 'signup-agreeToTermsOfService',
                        accessibilityLabel: formatMessage({ id: 'agreeToTermsOfService' }),
                      }}
                    >
                      <TermsOfServiceLabel />
                    </FormikCheckbox>
                  </FormikFormControl>
                </FieldContainer>
              )}

              <ButtonWrapper>
                <AuthActions
                  onPress={async () => {
                    const error = await validateForm();
                    generateErrorMessageForAccessibility(error);

                    const touchedFields = Object.entries(error).reduce((accum, [key, value]) => {
                      if (!value) {
                        return { ...accum };
                      }
                      return {
                        [key]: true,
                        ...accum,
                      };
                    }, {});
                    setTouched(touchedFields);

                    if (Object.keys(error).length !== 0) {
                      logOTPSignUpError(Object.values(error).join(' '));
                      return;
                    }
                    if (!modalAuthIsOpen) {
                      startSignUpCompleteTimeout();
                    }

                    handleSubmit();
                  }}
                  eventName={CustomEventNames.SIGN_UP_SUBMITTED}
                  primaryActionQaId="signup-button"
                  primaryActionLabel={formatMessage({ id: 'createAnAccount' })}
                  primaryActionIsLoading={isLoading}
                  primaryActionDisabled={isDobInvalid || isLoading}
                />
              </ButtonWrapper>
              {/* TODO: RN - Migrate FocusOnerror */}
              {/* <FocusOnError
                  setHasJustSubmitted={setHasJustSubmitted}
                  hasJustSubmitted={hasJustSubmitted}
                /> */}
              <AutoSignin
                signUpJwt={signUpJwt}
                stopPolling={stopPolling}
                signUpCompleteData={signUpCompleteData}
              />
              <UpdateSessionStorage />

              <View
                accessible={false}
                accessibilityLabel={errorMessageToAnnounce}
                accessibilityLiveRegion="polite"
                {...(isWeb && { 'aria-live': 'polite' })}
                style={{
                  width: 0,
                  height: 0,
                  opacity: 0,
                }}
              />
            </SignUpContainer>
          );
        }}
      </Formik>
      <ErrorDialog />
      <ExistingEmailDialog
        showDialog={showSignInWithExistingEmailDialog}
        dismiss={() => setShowSignInWithExistingEmailDialog(false)}
      />
      <ForterSignUpErrorDialog
        testID="forter-signup-error-modal"
        buttonLabel={formatMessage({ id: 'contactSupport' })}
        showCloseButton
      />
      <ZeroBounceErrorDialog
        testID="zero-bounce-error-modal"
        buttonLabel={formatMessage({ id: 'contactSupport' })}
        showCloseButton
      />
      <DialogStaticPage />
    </>
  );
};

export default SignUp;
