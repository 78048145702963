import { useBreakpointValue, useMediaQuery } from 'native-base';
import { Platform } from 'react-native';

export const useIsMobileBreakpoint = (): boolean => {
  return useBreakpointValue({
    base: true,
    md: false,
  });
};

export const useIsExtraSmallBreakpoint = (): boolean => {
  const [isExtraSmall] = useMediaQuery({
    maxWidth: 350,
  });

  return Boolean(isExtraSmall);
};

/**
 * This is a hook to check if the screen height is less than 640px.
 * This is a workaround to fix the issue with the map view not being visible on some devices.
 * @returns boolean
 */
export const useIsLowHeightDevice = (): boolean => {
  const [isSmallHeight] = useMediaQuery({
    maxHeight: 700,
  });

  return Boolean(isSmallHeight);
};

/** note: this will be TRUE for tablets in the APP in some cases.
 * If used incorrectly with pixel values like `margin: 20px` directly to components
 * it will cause native android crashes. Consider useIsDesktopWebBreakpoint to guarantee web.*/
export const useIsDesktopBreakpoint = (): boolean => {
  return useBreakpointValue({
    base: false,
    md: true,
  });
};

export const useIsDesktopWebBreakpoint = (): boolean => {
  return (
    useBreakpointValue({
      base: false,
      md: true,
    }) && Platform.OS === 'web'
  );
};

export const useIsDesktopLargeBreakpoint = (): boolean => {
  return useBreakpointValue({
    base: false,
    lg: true,
  });
};

export const getIsMobileWeb = (): boolean => {
  if (Platform.OS !== 'web') {
    return false;
  }

  return !!(
    typeof navigator !== 'undefined' &&
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
  );
};
