import { isNil } from 'lodash-es';
import { RouteProps } from 'react-router';

import { LazyRoute, lazyWithFallback } from 'components/layout/util';
import { routes as globalRoutes } from 'navigation/routing';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';

const SignOut: LazyRoute = lazyWithFallback(() => import('pages/authentication/sign-out'));
const SignupHome: LazyRoute = lazyWithFallback(() => import('pages/authentication/sign-up-home'));
const Account: LazyRoute = lazyWithFallback(() => import('pages/account'));
const AuthChallengeScreenJwt: LazyRoute = lazyWithFallback(() =>
  import('pages/authentication/auth-email-jwt')
);
const ConfirmJwt: LazyRoute = lazyWithFallback(() => import('pages/authentication/confirm-jwt'));

export function useAuthRoutes() {
  const enableSignUpFlag = useFlag(LaunchDarklyFlag.ENABLE_SIGN_UP);
  const enableSignUp = isNil(enableSignUpFlag) || enableSignUpFlag;
  const routes: Pick<RouteProps, 'element' | 'path'>[] = [];

  if (enableSignUp) {
    routes.push({ path: globalRoutes.signUp, element: <SignupHome /> });
    routes.push({ path: globalRoutes.signIn, element: <SignupHome /> });
    routes.push({ path: globalRoutes.account, element: <Account /> });
  }

  routes.push({
    path: globalRoutes.confirmJwt,
    element: <ConfirmJwt />,
  });
  routes.push({
    path: globalRoutes.authChallengeJwt,
    element: <AuthChallengeScreenJwt />,
  });

  routes.push({
    path: globalRoutes.signOut,
    element: <SignOut />,
  });

  return routes;
}
