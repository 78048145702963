import { getWeekDay } from 'state/menu/main-menu/hooks/use-active-day-parts';

import { IMarketingCardRules } from './types';

export const hasRules = ({ daysOfWeek, timeRanges }: IMarketingCardRules) =>
  !!daysOfWeek?.length || !!timeRanges?.length;

export const tileShouldBeDisplayed = ({ daysOfWeek, timeRanges }: IMarketingCardRules) => {
  const now = new Date();
  const currentDayOfWeek = getWeekDay(now);

  const getTimeParts = (time: string) => {
    const [hours, minutes, seconds] = time.split(':');
    const hoursInt = Number.parseInt(hours, 10);
    const minutesInt = Number.parseInt(minutes, 10);
    const secondsInt = Number.parseInt(seconds, 10);
    return [hoursInt, minutesInt, secondsInt];
  };

  const timeRangeAvailability = timeRanges?.map(range => {
    if (range && range.startTime && range && range && range.endTime) {
      const [startHour, startMinutes] = getTimeParts(range.startTime);
      const [endHour, endMinutes] = getTimeParts(range.endTime);
      const startTimeMs = new Date().setHours(startHour, startMinutes);
      const endTimeMs = new Date().setHours(endHour, endMinutes);
      const nowMs = now.getTime();
      return startTimeMs <= nowMs && nowMs <= endTimeMs;
    }

    return false;
  });

  const isActiveForDayOfWeek = daysOfWeek ? daysOfWeek.includes(currentDayOfWeek) : false;

  const isActiveForTimeRangeAvailability = timeRangeAvailability
    ? timeRangeAvailability?.some(range => range === true)
    : true; // If there is no timeRanges it is available all day.

  return isActiveForDayOfWeek && isActiveForTimeRangeAvailability;
};
