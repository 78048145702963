import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  IPlusDataV2Query,
  PlusDataV2Document,
  PosDataServiceMode,
} from 'generated/graphql-gateway';
import { apolloClient } from 'state/graphql/client';
import { simpleCacheQuery } from 'state/graphql/with-simple-cache';
import { useServiceModeContext } from 'state/service-mode';

type PosDataResults = {
  posData: Record<string, number> | null;
};

export type FetchPosDataFn = (args?: {
  storeNumber: string | null;
  isDelivery?: boolean;
}) => Promise<PosDataResults | null>;

interface IUsePosData {
  data: PosDataResults;
  isLoading: boolean;
  refetch: FetchPosDataFn;
}

// create PLU object to keep same logic
const transformToPluObject = (plus: IPlusDataV2Query['plusV2']): PosDataResults => {
  const initialPlus: Record<string, number> = {};
  return {
    posData: (plus || []).reduce((acc, plu) => {
      // We aliased the properties to reduce size.
      // We were hitting a hard limit on MenuService lambdas.
      acc[plu.k] = plu.v;
      return acc;
    }, initialPlus),
  };
};

export const usePosDataQuery = ({
  storeNumber,
  lazy = false,
}: {
  storeNumber: string | null;
  lazy?: boolean;
}): IUsePosData => {
  const { isDelivery } = useServiceModeContext();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<PosDataResults>({
    posData: null,
  });

  const fetchPosData: FetchPosDataFn = useCallback(
    async args => {
      let transformedResponse: PosDataResults = { posData: null };

      const storeId = args?.storeNumber ?? storeNumber;
      if (!storeId) {
        return transformedResponse;
      }

      setIsLoading(true);
      const data = await simpleCacheQuery(apolloClient, {
        query: PlusDataV2Document,
        variables: {
          storeId,
          serviceMode: isDelivery ? PosDataServiceMode.DELIVERY : PosDataServiceMode.PICKUP,
        },
      });

      transformedResponse = transformToPluObject(data?.plusV2);

      setData(transformedResponse);
      setIsLoading(false);

      return transformedResponse;
    },
    [isDelivery, storeNumber]
  );
  useEffect(() => {
    if (!lazy) {
      fetchPosData({
        storeNumber,
      });
    }
  }, [lazy, storeNumber, isDelivery, fetchPosData]);

  return useMemo(
    () => ({
      data,
      isLoading,
      refetch: fetchPosData,
    }),
    [data, isLoading, fetchPosData]
  );
};
