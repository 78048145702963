import { useMemo } from 'react';

import { convertQueryStringToObject } from 'navigation/utils';

import { useLocation, useParams } from '../routing/router';
import { IRouteHook } from '../types';

// TODO: GST The return type T is currently not guaranteed to match the params extracted from the query string / state
export const useRoute = <T extends object>(): IRouteHook<T> => {
  const reactRouterLocation = useLocation();
  const reactRouterParams = useParams();

  const params = useMemo<T>(() => {
    const stateParams = reactRouterLocation.state || {};
    const queryString = reactRouterLocation.search;

    const queryStringParams = queryString ? convertQueryStringToObject<T>(queryString) : {};
    return { ...queryStringParams, ...reactRouterParams, ...stateParams } as T;
    // reactRouterParams out of control changes obj ref despite nothing actually changing. use JSON.stringify to observe "actual" changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reactRouterLocation.search, reactRouterLocation.state, JSON.stringify(reactRouterParams)]);

  return {
    params,
    path: undefined, // Path is not supported in react router
    pathname: reactRouterLocation.pathname,
  };
};
