import { useEffect } from 'react';

import { useNavigation } from './use-navigation';

export const useScreenHeaderTitle = (title: string) => {
  const { setScreenHeaderTitle } = useNavigation();
  useEffect(() => {
    setScreenHeaderTitle(title);
  }, [setScreenHeaderTitle, title]);
};
