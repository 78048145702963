import { useMemo } from 'react';

import {
  LoyaltyServiceMode,
  useLoyaltyUnauthenticatedRewardsQuery,
  useLoyaltyUserRewardsQuery,
} from 'generated/graphql-gateway';
import { useAuthContext } from 'state/auth';
import { withSimpleCache } from 'state/graphql/with-simple-cache';
import { useIsLoyaltyEnabled } from 'state/loyalty/hooks/use-is-loyalty-enabled';
import { useLoyaltyUserId } from 'state/loyalty/hooks/use-loyalty-user';
import { useServiceModeContext } from 'state/service-mode';
import { useStoreContext } from 'state/store';

import { parseEngineRewards } from './utils';

const useCachedLoyaltyUserRewardsQuery = withSimpleCache(useLoyaltyUserRewardsQuery);
const useCachedLoyaltyUnauthenticatedRewardsQuery = withSimpleCache(
  useLoyaltyUnauthenticatedRewardsQuery
);

export const useLoyaltyEngineRewards = () => {
  const { isAuthenticated } = useAuthContext();
  const authenticatedData = useLoyaltyAuthenticatedEngineRewards();
  const unauthenticatedData = useLoyaltyUnauthenticatedEngineRewards();
  return isAuthenticated ? authenticatedData : unauthenticatedData;
};

const useLoyaltyAuthenticatedEngineRewards = () => {
  const { serviceMode: ctxServiceMode } = useServiceModeContext();
  const serviceMode = LoyaltyServiceMode[ctxServiceMode || ''];
  const { store } = useStoreContext();

  const { loyaltyUserId, loading } = useLoyaltyUserId();
  const loyaltyEnabled = useIsLoyaltyEnabled();

  const {
    data: engineResponse,
    loading: engineRewardsLoading,
    refetch: refetchEngineRewards,
  } = useCachedLoyaltyUserRewardsQuery({
    skip: !loyaltyEnabled || loading || !loyaltyUserId,
    variables: {
      loyaltyId: loyaltyUserId || '',
      where: {
        ignorePointBalance: true,
        serviceMode: serviceMode || undefined,
        storeId: store?.number,
      },
    },
  });

  const {
    engineRewardIds,
    engineRewardsMap,
    personalizedRewardIds,
    personalizedRewardMap,
  } = useMemo(() => parseEngineRewards(engineResponse?.loyaltyUserV2?.rewards), [engineResponse]);

  return {
    engineResponse,
    engineRewardsLoading,
    refetchEngineRewards,
    engineRewardIds,
    engineRewardsMap,
    personalizedRewardIds,
    personalizedRewardMap,
  };
};

const useLoyaltyUnauthenticatedEngineRewards = () => {
  const { serviceMode: ctxServiceMode } = useServiceModeContext();
  const serviceMode = LoyaltyServiceMode[ctxServiceMode || ''];
  const { store } = useStoreContext();

  const loyaltyEnabled = useIsLoyaltyEnabled();
  const { loyaltyUserId, loading } = useLoyaltyUserId();

  const {
    data: engineResponse,
    loading: engineRewardsLoading,
    refetch: refetchEngineRewards,
  } = useCachedLoyaltyUnauthenticatedRewardsQuery({
    skip: !loyaltyEnabled || loading || !!loyaltyUserId,
    variables: {
      where: {
        ignorePointBalance: true,
        serviceMode: serviceMode || undefined,
        storeId: store?.number,
      },
    },
  });

  const {
    engineRewardIds,
    engineRewardsMap,
    personalizedRewardIds,
    personalizedRewardMap,
  } = useMemo(() => parseEngineRewards(engineResponse?.loyaltyRewardsV2), [engineResponse]);

  return {
    engineResponse,
    engineRewardsLoading,
    refetchEngineRewards,
    engineRewardIds,
    engineRewardsMap,
    personalizedRewardIds,
    personalizedRewardMap,
  };
};
