import { createContext, useContext, useMemo } from 'react';

import { IRestaurant } from '@rbi-ctg/store';
import { ServiceMode, useOneStoreMenuQuery } from 'generated/graphql-gateway';
import { useEnableUnifiedMenu } from 'hooks/use-enable-unified-menu';
import { withSimpleCache } from 'state/graphql/with-simple-cache';
import { useServiceModeContext } from 'state/service-mode';
import { useStoreContext } from 'state/store';
import { getDateInTimezone } from 'utils/dateTime';
import { useMemoAll } from 'utils/use-memo-all';

import { findActiveDayPartIds } from './dayparts';
import { ILocalizedMenu, IMainMenuNode, IndexedMenu } from './types';
import { createIndexedMenu, createMainMenuNode, getPosServiceMode } from './utils';

export interface IUnifiedMenuContext {
  indexedMenu: IndexedMenu;
  mainMenu: IMainMenuNode[];
  isLoading: boolean;
}

export const UnifiedMenuContext = createContext<IUnifiedMenuContext>({
  indexedMenu: IndexedMenu.EMPTY,
  mainMenu: [],
  isLoading: false,
});

UnifiedMenuContext.displayName = 'UnifiedMenuContext';

export const useUnifiedMenuContext = (): IUnifiedMenuContext => {
  const context = useContext(UnifiedMenuContext);
  if (!context) {
    throw new Error('useUnifiedMenuContext must be used within a UnifiedMenuProvider');
  }
  return context;
};

const useCachedOneStoreMenuQuery = withSimpleCache(useOneStoreMenuQuery);

export const UnifiedMenuProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const isEnabled = useEnableUnifiedMenu();
  const { serviceMode } = useServiceModeContext();
  const { store } = useStoreContext();
  const { data, loading: isLoading } = useCachedOneStoreMenuQuery({
    variables: store?.number ? { storeId: store.number } : undefined,
    skip: !isEnabled || !store?.number,
  });

  const menu: ILocalizedMenu | undefined = data?.OneStoreMenu ?? undefined;

  const indexedMenu = useMemo(() => (menu ? createIndexedMenu(menu) : IndexedMenu.EMPTY), [menu]);
  const mainMenu = useMemo(() => maybeCreateMainMenu(menu, indexedMenu, serviceMode, store), [
    indexedMenu,
    menu,
    serviceMode,
    store,
  ]);

  const value: IUnifiedMenuContext = useMemoAll({
    mainMenu: mainMenu?.children ?? [],
    indexedMenu,
    isLoading,
  });

  if (!isEnabled) {
    // if it is not enabled, don't bother with the provider
    return children;
  }
  return <UnifiedMenuContext.Provider value={value}>{children}</UnifiedMenuContext.Provider>;
};

function maybeCreateMainMenu(
  menu: ILocalizedMenu | undefined,
  indexedMenu: IndexedMenu | undefined,
  serviceMode: ServiceMode | null,
  store: IRestaurant | undefined
): IMainMenuNode | undefined {
  if (indexedMenu && menu && serviceMode && store) {
    const now = store.timezone ? getDateInTimezone(store.timezone) : new Date();
    const activeDayPartIds = findActiveDayPartIds(menu.dayParts, now);
    return createMainMenuNode(indexedMenu, menu.rootMenuEntryId, {
      posServiceMode: getPosServiceMode(serviceMode),
      dayPartIds: activeDayPartIds,
    });
  }
  return undefined;
}
