import { useCallback, useEffect, useRef, useState } from 'react';

import { Box, Pressable, Text, makeUclComponent } from '@rbilabs/universal-components';

import { useUIContext } from '.';

/**
 * Hook that provides functionality to manage accessible main content region.
 * with this, an accessible user can skip to the main content of the page
 * */
export const useAccessibleMainContent = () => {
  const [hasAccessibleMainContent, setHasAccessibleMainContent] = useState(false);
  const accessibilityMainContentRef = useRef<any>(null);

  const handleSkipToMain = useCallback(() => {
    const element = accessibilityMainContentRef?.current;

    if (element) {
      element.setAttribute('tabIndex', '-1');
      element.setAttribute('focusable', 'true');

      const firstFocusableChild = element.querySelector(
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
      );

      if (firstFocusableChild) {
        (firstFocusableChild as HTMLElement).focus();
      } else if (typeof element.focus === 'function') {
        element.focus();
      }

      setTimeout(() => {
        element.removeAttribute('focusable');
        element.removeAttribute('tabIndex');
      }, 100);
    }
  }, []);

  return {
    accessibilityMainContentRef,
    handleSkipToMain,
    hasAccessibleMainContent,
    setHasAccessibleMainContent,
  };
};

/**
 * A wrapper component that makes its children accessible as the main content region.
 * Provides a focusable target for skip navigation and screen readers.
 * Used in conjunction with the useAccessibleMainContent
 */
export const AccessibleMainContent = ({ children }: { children: React.ReactNode }) => {
  const { accessibilityMainContentRef, setHasAccessibleMainContent } = useUIContext();

  useEffect(() => {
    setHasAccessibleMainContent(!!accessibilityMainContentRef.current);
  }, [accessibilityMainContentRef, setHasAccessibleMainContent]);

  return (
    <Box ref={accessibilityMainContentRef} focusable accessible tabIndex={0}>
      {children}
    </Box>
  );
};

// Let's keep this here for now to put all the accessibility logic in one place
const PressableCustom = makeUclComponent(Pressable).withConfig({
  position: 'absolute',
  left: -9999,
  zIndex: 999,
  tabIndex: 0,
  focusable: true,
  accessible: true,
  accessibilityRole: 'button',
  accessibilityLabel: 'Skip to main content',
});

export const SkipToMainContentButton = () => {
  const { handleSkipToMain, hasAccessibleMainContent } = useUIContext();

  return hasAccessibleMainContent ? (
    <PressableCustom onPress={handleSkipToMain}>
      <Text>Skip to main content</Text>
    </PressableCustom>
  ) : null;
};
