import { signInEvent, signUpEvent } from 'state/amplitude';
import { AuthenticationMethod, AuthenticationPhases } from 'state/amplitude/constants';
import { ModalAuthScreen } from 'state/auth/types';

export const authLogCancel = (message: string, page: ModalAuthScreen) => {
  const phase = AuthenticationPhases.CANCEL;

  if (page === ModalAuthScreen.SIGN_UP) {
    signUpEvent({
      phase,
      message,
    });
  }

  if (page === ModalAuthScreen.OTP) {
    signInEvent({
      phase,
      message,
      authenticationMethod: AuthenticationMethod.OTP,
    });
  }
};
