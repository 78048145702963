import { useRef } from 'react';

import { useApiKey } from 'hooks/configs/use-api-key';
import useReadyQueue from 'hooks/use-ready-queue';
import logger from 'utils/logger';
import noop from 'utils/noop';

interface IBranchEventAttributes {
  transaction_id?: string;
  currency?: 'USD' | 'CAD';
  revenue?: number;
  tax?: number;
  /** Name of Coupon */
  coupon?: string;
}

declare global {
  interface Window {
    branch?: {
      logEvent: (eventName: string, customAttributes?: IBranchEventAttributes) => void;
      disableTracking: (optOutOfBranch: boolean) => void;
      setIdentity: (id: string) => void;
      logout: () => void;
    };
  }
}

export interface IBranch {
  initBranch: () => void;
}

const useBranch = () => {
  const branchKey = useApiKey({ key: 'branch' });
  const { drainQueue } = useReadyQueue();

  const initBranchRef = useRef(noop);
  const initWasCalled = useRef(false);

  initBranchRef.current = () => {
    import('../init')
      .then(({ default: init }) => {
        if (initWasCalled.current) {
          return;
        }
        initWasCalled.current = true;
        init(branchKey);
        drainQueue(); // Branch has a native queue once init has been called, so we don't need to pass this as a callback to init
      })
      .catch(error => {
        logger.error({ error, message: 'Branch: import error' });
      });
  };

  return { initBranch: initBranchRef.current };
};

export default useBranch;
