import { RouteProps } from 'react-router';

import { LazyRoute, lazyWithFallback } from 'components/layout/util';
import { routes as globalRoutes } from 'navigation/routing';

const CommPreferences: LazyRoute = lazyWithFallback(() => import('./comm-preferences-v2'));
const AccountInfo: LazyRoute = lazyWithFallback(() => import('./account-info'));
const PaymentMethods: LazyRoute = lazyWithFallback(() => import('./payment-methods'));
const AccountOrders: LazyRoute = lazyWithFallback(() => import('./account-orders'));

export function useAccountRoutes() {
  const routes: Pick<RouteProps, 'element' | 'path'>[] = [
    {
      path: globalRoutes.accountInfo,
      element: <AccountInfo />,
    },
    {
      path: globalRoutes.communication,
      element: <CommPreferences />,
    },
    {
      path: globalRoutes.orders,
      element: <AccountOrders />,
    },
    {
      path: `${globalRoutes.orders}/:rbiOrderId`,
      element: <AccountOrders />,
    },
    {
      path: `${globalRoutes.payment}/*`,
      element: <PaymentMethods />,
    },
  ];

  return routes;
}
