import { useMemo } from 'react';

import { useIntl } from 'react-intl';

import {
  DeliveryStatus,
  IPhysicalAddress,
  Maybe,
  RbiOrderStatus,
  useGetUserOrdersQuery,
} from 'generated/graphql-gateway';
import { useAuthContext } from 'state/auth';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useMatch } from 'state/location';
import { ServiceMode, useOrderContext } from 'state/order';
import { useStoreContext } from 'state/store';
import { IPlaceAddress } from 'utils/geolocation';
import { routes } from 'utils/routing';
import { getHeadingForServiceMode } from 'utils/service-mode';

import { ITopServiceModeDetail, TopServiceVariants } from '../types';

const composeDeliveryAddress = (deliveryAddress: IPlaceAddress | null, isInCart: boolean) => {
  if (!deliveryAddress) {
    return '';
  }

  const { addressLine1 = '', city = '', state = '', zip = '' } = deliveryAddress;
  return isInCart ? `${addressLine1}` : `${addressLine1} ${city}, ${state} ${zip}`;
};

const composePhysicalAddress = (
  physicalAddress: Maybe<IPhysicalAddress> | undefined,
  isInCart: boolean
) => {
  if (!physicalAddress) {
    return '';
  }

  const { address1 = '', city = '', stateProvince = '', postalCode = '' } = physicalAddress;

  return isInCart ? `${address1} ${city}, ${stateProvince} ${postalCode}` : address1;
};

export const useTopServiceModeDetails = (): ITopServiceModeDetail => {
  const { serviceMode, deliveryAddress } = useOrderContext();
  const cartMatch = useMatch(`${routes.cart}/*`);
  const isInCart = !!cartMatch;
  const { store } = useStoreContext();
  const enableOrdering = useFlag(LaunchDarklyFlag.ENABLE_ORDERING);
  const isFastLaneEnabled = useFlag(LaunchDarklyFlag.ENABLE_FAST_LANE_DRIVE_THRU);
  const isMobileOrderForDriveThruLaneEnabled = useFlag(
    LaunchDarklyFlag.ENABLE_MOBILE_ORDER_DRIVE_THRU_LANE
  );
  const isOrderBayEnabled = useFlag(LaunchDarklyFlag.ENABLE_ORDER_BAY);
  const { formatMessage } = useIntl();

  const { isAuthenticated } = useAuthContext();

  const { data, refetch } = useGetUserOrdersQuery({
    variables: {
      limit: 1,
      orderStatuses: [
        RbiOrderStatus.INSERT_SUCCESSFUL,
        RbiOrderStatus.UPDATE_SUCCESSFUL,
        RbiOrderStatus.REFUND_SUCCESSFUL,
      ],
    },
    skip: !isAuthenticated,
  });

  const rbiOrderId = useMemo(() => {
    const deliveryOrder = data?.userOrders?.orders?.[0];
    const orderStatus = deliveryOrder?.delivery?.status;
    const failedOrderStatus = [
      DeliveryStatus.ORDER_CANCELLED,
      DeliveryStatus.ORDER_ABANDONED,
      DeliveryStatus.ORDER_DROPPED_OFF,
    ];

    if (!deliveryOrder || !orderStatus || failedOrderStatus.includes(orderStatus)) {
      return null;
    }
    return deliveryOrder.rbiOrderId;
  }, [data]);

  const serviceModeHeadingConfig = getHeadingForServiceMode(
    formatMessage,
    isFastLaneEnabled,
    isMobileOrderForDriveThruLaneEnabled,
    serviceMode,
    isOrderBayEnabled
  );

  const resultBase: ITopServiceModeDetail = {
    heading: formatMessage({ id: 'forItemAvailability' }),
    details: null,
    icon: 'bkOrder',
    rbiOrderId,
    variant: TopServiceVariants.DEFAULT,
    refetchUserOrder: refetch,
    ...serviceModeHeadingConfig,
  };

  if (store._id) {
    const address =
      serviceMode === ServiceMode.DELIVERY
        ? composeDeliveryAddress(deliveryAddress, isInCart)
        : composePhysicalAddress(store?.physicalAddress, isInCart);

    if ((!store.hasMobileOrdering || !enableOrdering) && store.physicalAddress?.address1) {
      return {
        ...resultBase,
        heading: formatMessage({ id: 'yourSelectedStore' }),
        details: address,
        icon: 'restaurant',
      };
    }

    switch (serviceMode) {
      case ServiceMode.DELIVERY:
        if (deliveryAddress) {
          return {
            ...resultBase,
            details: address,
          };
        }
        break;
      case ServiceMode.CURBSIDE:
      case ServiceMode.DRIVE_THRU:
      case ServiceMode.MOBILE_ORDER_DRIVE_THRU:
      case ServiceMode.TAKEOUT:
      case ServiceMode.EAT_IN:
        if (store.physicalAddress?.address1) {
          return {
            ...resultBase,
            details: address,
          };
        }
        break;
      default:
    }
  }
  return {
    ...resultBase,
    variant: TopServiceVariants.NO_STORE,
  };
};

export const TSM_GET_USER_ORDER_STATUSES = [
  RbiOrderStatus.INSERT_SUCCESSFUL,
  RbiOrderStatus.UPDATE_SUCCESSFUL,
  RbiOrderStatus.REFUND_SUCCESSFUL,
];
