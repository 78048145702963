import {
  Box,
  HStack,
  Header,
  Icon,
  ScrollView,
  addWithConfig,
} from '@rbilabs/universal-components';

import ActionButton, { ActionButtonVariants } from 'components/action-button';
import LoyaltyPointsIcon from 'components/icons/loyalty-points-icon';
import { IncentiveEvaluationMessages } from 'components/incentive-evaluation-messages/incentive-evaluation-messages';
import { DisclaimerContainer } from 'components/offer-disclaimer/offer-disclaimer';
import Picture from 'components/picture';
import { ProgressMeter } from 'components/progress-meter';
import SanityBlockRenderer from 'components/sanity-block-renderer';
import { theme as globalTheme } from 'styles/configure-theme';
import { theme } from 'styles/configure-theme';
import { primitive } from 'styles/constants/primitives';
import { hiddenAccessibilityPlatformProps } from 'utils/accessibility';

import { HIGHLIGHT_COLOR } from '../constants';
import { RewardPointCost } from '../reward-point-cost';

export const Wrapper = ScrollView.withConfig({
  paddingTop: '$1',
  paddingX: '$5',
  backgroundColor: Styles.color.white,
});

export const ImageWrapper = Box.withConfig({
  alignItems: 'center',
  flexShrink: 0,
});

export const StyledRewardImage = Picture.withConfig({
  width: '80%',
  marginTop: '$4',
  zIndex: Styles.zIndex.normal,
  height: '$32',
});

export const BackgroundImage = Picture.withConfig({
  position: 'absolute',
  left: 0,
  top: 0,
  width: 'full',
  height: '100%',
});

export const RewardName = Header.withConfig({
  textAlign: 'center',
  fontSize: '2xl',
  lineHeight: 'md',
  marginTop: '$4',
  maxWidth: '960px',
});

export const RewardDescription = SanityBlockRenderer.withConfig({
  color: Styles.color.grey.one,
  _text: {
    textAlign: 'center',
    fontSize: 'sm',
  },
  textProps: { fontSize: 'md', textAlign: 'center' },
});

export const RewardExpiration = Box.withConfig({
  color: Styles.color.grey.one,
  marginTop: '$3',
});

export const ButtonContainer = Box.withConfig({
  flexShrink: 0,
  width: 'full',
  maxWidth: '100%',
});

export const RewardDetailsContainer = Box.withConfig({
  alignItems: 'center',
  paddingX: '$0',
  marginBottom: '$4',
});

export const DisclaimerText = SanityBlockRenderer.withConfig({
  maxWidth: '512px',
  marginY: '$8',
  marginX: 'auto',
  textProps: {
    color: Styles.color.grey.one,
    textAlign: 'center',
    fontSize: 'xs',
  },
});

export const StyledProgressMeter = ProgressMeter.withConfig({
  borderWidth: 0,
  marginY: '$4',
  width: '240px',
  bgColor: primitive.$white,
});

export const StyledRewardLock = Box.withConfig({});

export const LockContainer = Box.withConfig({
  right: '45px',
});

export const PointsContainer = Box.withConfig({
  alignItems: 'center',
  marginTop: '$6',
});

export const PointsTextContainer = HStack.withConfig({
  alignItems: 'center',
});

export const StyledRewardPointCost = RewardPointCost.withConfig({
  fontSize: '2xl',
  lineHeight: 'md',
  fontWeight: 'bold',
  alignItems: 'center',
});

export const StyledPointsIcon = LoyaltyPointsIcon.withConfig({
  width: '32px',
  height: '32px',
  marginRight: '8px',
  fill: '#FFAA00',
});

export const RewardLabel = Box.withConfig({
  backgroundColor: HIGHLIGHT_COLOR,
  paddingTop: '$1.5',
  paddingBottom: '$2',
  paddingX: '7px',
  borderRadius: '8px',
  color: Styles.color.white,
  marginTop: '$1',
  marginBottom: '0',
  marginX: '$1.5',
  fontSize: 'sm',
});

export const StyledRewardLockIcon = Icon.withConfig({
  variant: 'locked',
  size: 7,
  color: globalTheme.token('icon-default'),
});

export const StyledIncentiveEvaluationMessages = IncentiveEvaluationMessages.withConfig({
  textAlign: 'center',
});

export const AddToCartButton = addWithConfig(ActionButton).withConfig<{ isOffer?: boolean }>(
  props => ({
    fontSize: 'lg',
    justifyContent: 'center',
    paddingX: '$12',
    height: '48px',
    width: 'full',
    textDecorationColor: primitive.bk.$bbqBrown,

    ...(!props.isOffer && {
      backgroundColor: primitive.bk.$meltyYellow,
      _text: { color: primitive.bk.$bbqBrown },
      _hover: { backgroundColor: primitive.bk.$meltyYellowDark45 },
      _pressed: { backgroundColor: primitive.bk.$meltyYellowDark40 },
      color: primitive.bk.$bbqBrown,
    }),
  })
);

export const RedeemInRestaurantButton = addWithConfig(ActionButton).withConfig<{
  isOffer?: boolean;
}>(props => ({
  fontSize: 'lg',
  justifyContent: 'center',
  paddingX: '$12',
  height: '48px',
  width: 'full',
  textDecorationColor: primitive.bk.$bbqBrown,

  ...(!props.isOffer && {
    borderColor: primitive.bk.$bbqBrown,
    _text: { color: primitive.bk.$bbqBrown },
    _hover: {
      backgroundColor: primitive.bk.$bbqBrownOpacity15,
      borderColor: primitive.bk.$bbqBrown,
    },
    _pressed: {
      backgroundColor: primitive.bk.$bbqBrownOpacity15,
      borderColor: primitive.bk.$bbqBrown,
    },
  }),
}));

export const RemoveFromCartButton = addWithConfig(ActionButton).withConfig({
  fontSize: 'lg',
  justifyContent: 'center',
  paddingX: '$12',
  height: '48px',
  variant: ActionButtonVariants.OUTLINE,
});

export const ButtonsWrapper = Box.withConfig<{ $errorMessage?: boolean }>(props => ({
  marginTop: props.$errorMessage ? '0' : '$4',
  marginY: '$4',
  alignItems: 'center',
  zIndex: Styles.zIndex.top,
  paddingTop: {
    base: '$4',
    md: '$8',
  },
  paddingX: '$4',
  paddingBottom: '$4',
  ...props,
}));

export const StyledDisclaimerContainer = DisclaimerContainer.withConfig({
  marginTop: '$4',
});

export const StyledBackIcon = Icon.withConfig({
  color: theme.token('icon-header-contrast'),
  size: '5',
  ...hiddenAccessibilityPlatformProps,
});

export const StyledModalHeader = Header.withConfig({
  variant: 'headerTwo',
  textAlign: 'center',
  paddingTop: '$3',
});
