import { Linking } from 'react-native';

import { useNavigation } from 'navigation/hooks/use-navigation';

export const useContentCardNavigation = () => {
  const { navigate: navigateURL } = useNavigation();

  const navigate = ({ url }: { url: string }) => {
    if (!url) {
      return;
    }
    if (url.startsWith('http://') || url.startsWith('https://')) {
      // Open absolute URLs in the default browser
      Linking.openURL(url);
    } else {
      // Navigate within the app for relative URLs
      navigateURL(url);
    }
  };

  return {
    navigate,
  };
};
