import { DEFAULT_BILLING_COUNTRY } from 'features/payment/constants';
import { mapDeliveryToCCFormAddress } from 'features/payment/utils';

import { CartPaymentCardType } from 'generated/graphql-gateway';
import {
  PaymentFieldVariations,
  defaultPaymentFieldVariation,
} from 'state/launchdarkly/variations';
import { ISOs } from 'utils/form/constants';
import { IPlaceAddress } from 'utils/geolocation/types';

import { IPaymentErrors, IPaymentState } from './types';

export const initialPaymentState = ({
  isDelivery = false,
  deliveryAddress = null,
  billingCountry = DEFAULT_BILLING_COUNTRY,
  userDetailsName = '',
}: {
  isDelivery?: boolean;
  billingCountry?: ISOs;
  deliveryAddress?: IPlaceAddress | null;
  userDetailsName?: string;
} = {}): IPaymentState => ({
  nameOnCard: userDetailsName ?? '',
  cardNumber: '',
  isCardNumberValid: false,
  isExpiryValid: false,
  cardType: null,
  cvv: '',
  expiry: '',
  ...mapDeliveryToCCFormAddress(isDelivery ? deliveryAddress : null),
  billingAddressSameAsDelivery: isDelivery,
  saveCard: true,
  billingCountry,
});

export const testCardPaymentState = ({
  isDelivery = false,
  paymentFieldVariations = defaultPaymentFieldVariation,
}: {
  isDelivery?: boolean;
  paymentFieldVariations?: PaymentFieldVariations;
  paymentProcessor?: string | null;
} = {}): IPaymentState => {
  return {
    nameOnCard: 'John Smith',
    cardNumber: '4111111111111111',
    isCardNumberValid: true,
    isExpiryValid: true,
    ...mapDeliveryToCCFormAddress({
      addressLine1: '100 Universal City Plaza',
      addressLine2: '',
      city: 'Hollywood',
      state: 'CA',
      zip: '11747',
    }),
    cardType: CartPaymentCardType.VISA,
    cvv: paymentFieldVariations.cvv ? '123' : '',
    expiry: paymentFieldVariations.expiration ? '12/25' : '',
    billingCountry: paymentFieldVariations.country ? ISOs.US : null,
    billingAddressSameAsDelivery: isDelivery,
    saveCard: true,
  };
};

export const initialErrorState = (): IPaymentErrors => ({
  nameOnCard: '',
  cardNumber: '',
  cardType: '',
  cvv: '',
  expiry: '',
  billingStreetAddress: '',
  billingCity: '',
  billingState: '',
  billingZip: '',
  billingCountry: '',
  billingApt: '',
  giftCardNumber: '',
  didAttemptSubmit: false,
});
