import { Dispatch, SetStateAction } from 'react';

import { Box } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import { useAuthContext } from 'state/auth';
import { ModalAuthScreen, ModalAuthTrigger } from 'state/auth/types';
import { portalToFooter } from 'state/mobile-footer';
import { primitive } from 'styles/constants/primitives';

import { StyledActionButton } from './styled';

interface IWalmartFooter {
  isLoading?: boolean;
  disabled?: boolean;
  isAlreadyLinked?: boolean;
  authzCode?: string | string[] | null;
  eligibility?: any;
  linkCalled?: boolean;
  handleShouldRedirect?: Dispatch<SetStateAction<boolean>>;
  handleOpenWelcomeBackDrawer?: Dispatch<SetStateAction<boolean>>;
  handleShouldLink?: Dispatch<SetStateAction<boolean>>;
  handleRedirectToWalmartPlus?: () => void;
  handleRedeemOffers?: () => void;
}

export const WalmartFooter = portalToFooter(
  ({
    isLoading = false,
    disabled = false,
    isAlreadyLinked = false,
    authzCode,
    eligibility,
    linkCalled = false,
    handleShouldRedirect,
    handleOpenWelcomeBackDrawer,
    handleShouldLink,
    handleRedirectToWalmartPlus,
    handleRedeemOffers,
  }: IWalmartFooter) => {
    const { formatMessage } = useIntl();
    const { isAuthenticated, setModalAuthState } = useAuthContext();

    if (disabled) {
      return null;
    }

    if (isLoading) {
      return (
        <Box padding="$4" bgColor={primitive.$white}>
          <StyledActionButton isLoading={true}></StyledActionButton>
        </Box>
      );
    }

    if (isAuthenticated && !authzCode) {
      return (
        <Box paddingBottom="$6" paddingX="$6" _web={{ padding: '$4' }} bgColor={primitive.$white}>
          <StyledActionButton
            onPress={isAlreadyLinked ? handleRedeemOffers : handleRedirectToWalmartPlus}
          >
            {formatMessage({ id: isAlreadyLinked ? 'redeemOffers' : 'walmartLinkAccounts' })}
          </StyledActionButton>
        </Box>
      );
    }

    // If no code provided, can assume user is coming from BK;
    // If not authenticated, prompt user to log in
    // At this point we know user is not linked
    if (!authzCode) {
      return (
        <Box padding="$4" bgColor={primitive.$white}>
          <StyledActionButton
            onPress={() => {
              if (isAuthenticated) {
                return handleRedirectToWalmartPlus?.();
              }
              return setModalAuthState({
                screen: ModalAuthScreen.SIGN_IN,
                trigger: ModalAuthTrigger.Global,
                onSignInSuccess: () => {
                  handleShouldRedirect?.(true);
                },
              });
            }}
          >
            {!isAuthenticated && !eligibility?.isExistingUser
              ? formatMessage({ id: 'walmartSignUpSignIn' })
              : formatMessage({ id: 'walmartLinkAccounts' })}
          </StyledActionButton>
        </Box>
      );
    }

    // If user eligible
    // If user not authenticated and not recognized BK user, then show sign up drawer
    // Otherwise show welcome back
    return eligibility?.eligible && !linkCalled ? (
      <Box padding="$4" bgColor={primitive.$white}>
        <StyledActionButton
          onPress={() => {
            if (!isAuthenticated && !eligibility.isExistingUser) {
              return setModalAuthState({
                screen: ModalAuthScreen.SIGN_IN,
                trigger: ModalAuthTrigger.Global,
                user: {
                  name: eligibility?.firstName || '',
                },
                onSignInSuccess: () => {
                  handleShouldLink?.(true);
                },
              });
            }
            return handleOpenWelcomeBackDrawer?.(true);
          }}
        >
          {!isAuthenticated && !eligibility.isExistingUser
            ? formatMessage({ id: 'walmartSignUpSignIn' })
            : formatMessage({ id: 'walmartLinkAccounts' })}
        </StyledActionButton>
      </Box>
    ) : null;
  }
);
