import React from 'react';

import { TextProps, makeUclComponent } from '@rbilabs/universal-components';

import ActionButton, { ActionButtonVariants } from 'components/action-button';
import SanityBlockRenderer from 'components/sanity-block-renderer';
import { routes } from 'navigation/routing';
import { primitive } from 'styles/constants/primitives';

import { StyledLink } from './loyalty-claim-points.styled';
import { useLoyaltyClaimPoints } from './use-loyalty-claim-points';

interface ILoyaltyClaimPointsLinkProps {
  className?: string;
  renderAsButton?: boolean;
  _textProps?: TextProps;
}

const pointsLinkTextStyles: TextProps = {
  variant: 'headerTwo',
  fontSize: 'md',
  margin: 0,
  color: primitive.bk.$bbqBrown,
};

const LoyaltyClaimPointsLink: React.FC<React.PropsWithChildren<ILoyaltyClaimPointsLinkProps>> = ({
  renderAsButton,
  _textProps,
  ...props
}) => {
  const { data } = useLoyaltyClaimPoints();

  const content = data?.claimOnHistory;
  const Wrapper = renderAsButton ? ActionButton : StyledLink;

  return content?.linkText ? (
    <Wrapper
      {...props}
      to={routes.claimPointsBase}
      variant={renderAsButton ? ActionButtonVariants.OUTLINE : undefined}
    >
      <SanityBlockRenderer
        content={content.linkText.locale}
        textProps={{ ...pointsLinkTextStyles, ..._textProps }}
      />
    </Wrapper>
  ) : null;
};

export default makeUclComponent(LoyaltyClaimPointsLink);
