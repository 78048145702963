import { Text } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';
import { Image, StyleSheet, View } from 'react-native';

import { IFeatureWalmartUiQuery } from 'generated/sanity-graphql';
import { primitive } from 'styles/constants/primitives';

import { WalmartPlusWeekOffer } from './offer';

const walmartPlusWeekOffer1Image = require('./images/free-single-croissan-wich.png');
const walmartPlusWeekOffer2Image = require('./images/free-whopper-jr.png');
const walmartPlusWeekLogo = require('./images/walmart-week-logo.png');

interface IWalmartWeekOffers {
  data: IFeatureWalmartUiQuery['Walmart'];
}

export const WalmartWeekOffers = ({ data }: IWalmartWeekOffers) => {
  const { formatMessage } = useIntl();

  const walmartPlusWeekOffer1 = formatMessage({ id: 'walmartPlusWeekOffer1' });
  const walmartPlusWeekOffer2 = formatMessage({ id: 'walmartPlusWeekOffer2' });
  const dailyWithPurchase = formatMessage({ id: 'walmartPlusWeekOfferText' });
  const walmartPlusWeekBottomText = formatMessage({ id: 'walmartPlusWeekBottomText' });

  const badge = data?.badge?.locale;

  return (
    <View style={styles.container}>
      <View>
        <Image resizeMode="contain" style={styles.logo} source={walmartPlusWeekLogo} />
      </View>
      <View style={styles.offers}>
        <WalmartPlusWeekOffer
          title={walmartPlusWeekOffer1}
          text={dailyWithPurchase}
          image={walmartPlusWeekOffer1Image}
          badge={badge}
        />
        <WalmartPlusWeekOffer
          title={walmartPlusWeekOffer2}
          text={dailyWithPurchase}
          image={walmartPlusWeekOffer2Image}
          badge={badge}
        />
      </View>
      <View>
        <Text variant="copyOne" color={primitive.$white}>
          {walmartPlusWeekBottomText}
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginLeft: 16,
    marginRight: 16,
    backgroundColor: primitive.bk.$blueWalmart,
    borderRadius: 10,
    marginVertical: 16,
    padding: 16,
  },
  offers: {
    gap: 16,
    marginVertical: 16,
  },
  offer: {
    flexDirection: 'row',
    borderRadius: 10,
    backgroundColor: primitive.$white,
    padding: 16,
  },
  offerImage: {
    width: 96,
    height: 96,
  },
  offerContent: {
    flex: 1,
    justifyContent: 'center',
    marginLeft: 16,
  },
  offerTitle: {
    fontFamily: primitive.bk.$headerOne,
  },
  logo: {
    width: 120,
    height: 50,
  },
});
