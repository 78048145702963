import { useEffect, useMemo, useState } from 'react';

import { useIntl } from 'react-intl';

import {
  IOffersFragment,
  LoyaltyPromotionType,
  LoyaltyServiceMode,
  useGetLoyaltyOffersLazyQuery,
  useLoyaltyUserOffersLazyQuery,
} from 'generated/graphql-gateway';
import { useLoyaltyOfferGroupsByIdsLazyQuery } from 'generated/sanity-graphql';
import { useCart } from 'hooks/use-cart';
import { useLoyaltyIncentivesAvailabilityUtils } from 'pages/loyalty/loyalty-incentives-components/use-loyalty-incentives-availability';
import { useServiceModeContext } from 'state/service-mode';
import { useStoreContext } from 'state/store';
import { blockContentToPlainText } from 'utils/sanity';

import { LoyaltyOffer } from '../types';

import {
  IIncentiveEvaluationResult,
  IncentiveEvaluationErrorCodes,
  LoyaltyOfferWithInlineAlertMessage,
} from './types';
import { useLoyaltyUserId } from './use-loyalty-user';
import {
  createFeedbackMaps,
  getEvaluationResultMessage,
  pushUnavailableOffersToBottom,
} from './utils/helpers';

const OFFER_NOT_AVAILABLE_EVALUATION_RESULT: IIncentiveEvaluationResult = {
  code: IncentiveEvaluationErrorCodes.OFFER_NOT_AVAILABLE,
  currentValue: null,
  message: '',
  ruleId: '',
  targetValue: null,
};

function useGetCmsOffersByGroupId(groupId = '') {
  const [getOffers, { data, loading }] = useLoyaltyOfferGroupsByIdsLazyQuery({
    nextFetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (!groupId || data || loading) {
      return;
    }
    getOffers({
      variables: {
        groupIds: groupId,
      },
    });
  }, [groupId, getOffers, data, loading]);

  const offers = data?.allOfferGroups?.[0]?.offers as LoyaltyOffer[];
  const groupName = blockContentToPlainText(data?.allOfferGroups?.[0]?.name?.localeRaw);
  return { offers, groupName, loading };
}

/**
 * Fetches loyalty offers for a given set of CMS offers.
 *
 * This hook first attempts to fetch offers for a logged-in user using their loyalty ID.
 * If the user is not logged in, it fetches offers anonymously.
 * The fetched offers are filtered based on the service mode and store ID.
 *
 * @param cmsOffers - An array of CMS offers to fetch loyalty offers for.
 * @returns An object containing the fetched offers and a loading state.
 */
function useGetLoyaltyOffers(cmsOffers: LoyaltyOffer[] | undefined) {
  const { loyaltyUserId } = useLoyaltyUserId();

  const { serviceMode: ctxServiceMode } = useServiceModeContext();
  const serviceMode = LoyaltyServiceMode[ctxServiceMode || ''];

  const { store } = useStoreContext();
  const storeId = store?.number;

  const [
    getUserOffers,
    { data: userOffers, loading: loadingUserOffers },
  ] = useLoyaltyUserOffersLazyQuery();
  const [
    getAnonOffers,
    { data: anonOffers, loading: loadingAnonOffers },
  ] = useGetLoyaltyOffersLazyQuery();

  useEffect(() => {
    if (!cmsOffers?.length || !loyaltyUserId || userOffers || loadingUserOffers) {
      return;
    }

    const cmsOffersEngineIds = cmsOffers?.map(o => o.loyaltyEngineId);
    getUserOffers({
      fetchPolicy: 'network-only',
      variables: {
        loyaltyId: loyaltyUserId,
        where: {
          ids: cmsOffersEngineIds,
          serviceMode: serviceMode || undefined,
          storeId,
          omitInvalids: false,
        },
      },
    });
  }, [
    getUserOffers,
    cmsOffers,
    loyaltyUserId,
    serviceMode,
    storeId,
    userOffers,
    loadingUserOffers,
  ]);

  useEffect(() => {
    if (!cmsOffers?.length || anonOffers || loadingAnonOffers) {
      return;
    }
    const cmsOffersEngineIds = cmsOffers?.map(o => o.loyaltyEngineId);
    getAnonOffers({
      fetchPolicy: 'network-only',
      variables: {
        where: {
          ids: cmsOffersEngineIds,
          serviceMode: serviceMode || undefined,
          storeId,
          omitInvalids: false,
        },
      },
    });
  }, [getAnonOffers, cmsOffers, serviceMode, storeId, anonOffers, loadingAnonOffers]);

  const offers = userOffers?.loyaltyUserV2?.offers || anonOffers?.loyaltyOffersV2;
  return { offers, loading: loadingUserOffers || loadingAnonOffers };
}

function useAddUnavailabilityMessages(cmsOffers: LoyaltyOffer[] | undefined) {
  const { getIncentiveUnavailableMessage, ready } = useLoyaltyIncentivesAvailabilityUtils();
  const [offers, setOffers] = useState<LoyaltyOfferWithInlineAlertMessage[] | undefined>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!cmsOffers?.length || !ready || loading) {
      return;
    }
    setLoading(true);
    // Add unavailability messages to each offer
    const nextOffers = cmsOffers.map(offer => {
      const inlineAlertMessage = getIncentiveUnavailableMessage({ incentive: offer });
      return { ...offer, inlineAlertMessage };
    });

    setOffers(nextOffers);
    setLoading(false);
  }, [cmsOffers, getIncentiveUnavailableMessage, ready, loading]);

  return { offers, loading };
}

function useOfferEvaluation(
  cmsOffers: LoyaltyOfferWithInlineAlertMessage[] | undefined,
  loyaltyOffers: readonly IOffersFragment[] | null | undefined
) {
  const formatters = useIntl();
  const { formatMessage } = formatters;

  const [loading, setLoading] = useState(false);
  const { cartEntries } = useCart();

  const offers: LoyaltyOfferWithInlineAlertMessage[] = useMemo(() => {
    if (!cmsOffers || !loyaltyOffers || loading) {
      return [];
    }
    setLoading(true);
    const cmsOffersEngineIds = cmsOffers?.map(o => o.loyaltyEngineId);
    const { offerFeedbackMap, offersMapById } = createFeedbackMaps(loyaltyOffers);

    // mapping no longer existing offers
    // if the offer didn't come back on the response then it doesn't exist
    cmsOffersEngineIds.forEach(id => {
      if (id && !offersMapById[id]) {
        offerFeedbackMap[id] = [OFFER_NOT_AVAILABLE_EVALUATION_RESULT];
      }
    });

    const filteredOffers = [];
    for (const loyaltyOffer of loyaltyOffers) {
      const cmsOffer = cmsOffers.find(o => o.loyaltyEngineId === loyaltyOffer.id);
      if (!cmsOffer) {
        continue;
      }
      const evaluationResult = offerFeedbackMap[cmsOffer.loyaltyEngineId || ''];
      const evaluationMessages = evaluationResult?.map(evaluationResult => {
        const { code, targetValue } = evaluationResult;
        if (code === IncentiveEvaluationErrorCodes.OUT_OF_DAY_PART) {
          const { incentive } = targetValue;
          const dayPart = incentive.incentives[0]?.operationalItem?.daypart[0];
          return `${dayPart} ${formatMessage({ id: 'only' })}`;
        } else if (code === IncentiveEvaluationErrorCodes.NOT_AVAILABLE_IN_STORE) {
          return formatMessage({ id: 'rewardUnavailable' });
        }
        // Fallback for other messaging
        const message = getEvaluationResultMessage({
          evaluationResult,
          formatters,
          incentiveType: LoyaltyPromotionType.OFFER,
          cartEntries,
        });
        return message as string;
      });

      // Prioritize the error message that is coming from the LoyaltyEngine
      const inlineAlertMessage = evaluationMessages
        ? evaluationMessages[0]
        : cmsOffer.inlineAlertMessage;

      filteredOffers.push({ ...cmsOffer, inlineAlertMessage, endDate: loyaltyOffer.endDate });
    }
    setLoading(false);
    return filteredOffers;
  }, [cartEntries, cmsOffers, formatMessage, formatters, loading, loyaltyOffers]);

  return { offers, loading };
}

export const useLoyaltyOfferGroupsById = (groupId = '') => {
  // Fetch the Offers from Sanity belonging to this group
  const { offers: cmsOffers, loading: cmsLoading, groupName } = useGetCmsOffersByGroupId(groupId);

  // After receiving the Offers from Sanity, fetch Offers from LoyaltyEngine
  // using Sanity's loyaltyEngineId
  const { offers: loyaltyOffers, loading: loyaltyLoading } = useGetLoyaltyOffers(cmsOffers);

  // After receiving the Offers from Sanity, add unavailability reasons to each
  // of them
  const {
    offers: offerWithUnavailability,
    loading: unavailabilityLoading,
  } = useAddUnavailabilityMessages(cmsOffers);

  // As a final step, filter out Sanity Offers that where not returned by
  // LoyaltyEngine and add unavailability messages coming from LoyaltyEngine
  const { offers: evaluatedOffers, loading: evaluationLoading } = useOfferEvaluation(
    offerWithUnavailability,
    loyaltyOffers
  );

  // Move unavailable Offers to the bottom of the list
  const sortedOffers = useMemo(() => {
    if (Array.isArray(evaluatedOffers)) {
      return evaluatedOffers.sort(pushUnavailableOffersToBottom);
    }
    return [];
  }, [evaluatedOffers]);

  return {
    loading: cmsLoading || loyaltyLoading || unavailabilityLoading || evaluationLoading,
    offers: sortedOffers,
    groupName,
  };
};
