import React from 'react';

import { FieldProps } from 'formik';
import { useIntl } from 'react-intl';
import { scrollIntoView } from 'seamless-scroll-polyfill';

import { StyledInput } from 'components/otp-input/styled';
import { isIOS, isWeb } from 'utils/environment';

const NUMBER_REGEXP = new RegExp(/^[0-9]{0,}$/);

// Additional Y amount for show email input and submit button
const ADDITIONAL_SCROLL_Y = 50;

interface IFormikOTPInput {
  field: FieldProps['field'];
  form: FieldProps['form'];
  otpCodeLength: number;
  setFieldValue(name: string, value: string): void;
  submitOnFill?: boolean;
}

export const FormikOTPInput = ({
  field,
  form,
  setFieldValue,
  submitOnFill,
  otpCodeLength,
  ...props
}: IFormikOTPInput) => {
  const { formatMessage } = useIntl();
  const handleChange = (value: string) => {
    if (value.length > otpCodeLength) {
      return;
    }

    if (!NUMBER_REGEXP.test(value)) {
      return;
    }

    setFieldValue(field.name, value);

    if (submitOnFill && !form.isSubmitting && value.length === otpCodeLength) {
      form.handleSubmit();
    }
  };

  // Do not include input field focus as it's clashing with page transition

  return (
    <StyledInput
      {...field}
      {...props}
      // show the big number-only keyboard on iOS < 12.2 (type="number" isn't enough)
      // TODO: RN - pattern="[0-9]*" check if this is needed
      // show the big number-only keyboard on Android 66+ (type="number" works too) and iOS >= 12.2 (type="number" shows a different keyboard)
      keyboardType="numeric"
      onChangeText={handleChange}
      label={formatMessage({ id: 'validationCode' })}
      accessibilityLabel={`${formatMessage({ id: 'validationCode' })}. ${formatMessage({
        id: 'authEmailSentEmail',
      })}`}
      required
      testID="OTP-code-input"
      onLayout={ev => {
        if (isWeb) {
          // This is a hack to avoid overlapping on mobile web
          const element = document.querySelector('[data-testid="OTP-code-input"]');
          const otpInput = document.querySelector('[data-testid="OTP-code-input"]');
          if (element && !isIOS()) {
            element.scrollTop = element.scrollHeight + ADDITIONAL_SCROLL_Y;
          } else if (otpInput) {
            scrollIntoView(otpInput);
          }
        }
      }}
    />
  );
};
