// eslint-disable-next-line no-restricted-imports
import { mergeWith } from 'lodash-es';

import type { DeepPartial } from '../types/utility-types';

import type { MergedThemeTypes, TBrand, UnionedThemeTypes } from './types';

export { spacing } from './shared/primitives';

const internalCache = {} as Record<TBrand, MergedThemeTypes>;
export const registeredThemes = internalCache as Readonly<typeof internalCache>;

export function registerTheme(theme: UnionedThemeTypes) {
  internalCache[theme.key] = theme as MergedThemeTypes;
}

export function getTheme(brand?: TBrand) {
  const theme = (brand && registeredThemes[brand]) || Object.values(registeredThemes)[0];
  if (!theme) {
    throw new Error(
      `Cannot find theme for brand key "${brand}". You may need to import the brand theme module.`
    );
  }
  return theme;
}

function isFunction(value: unknown): value is Function {
  return typeof value === 'function';
}

type ThemeUtil = DeepPartial<MergedThemeTypes> | (Record<string, any> & {});

export function extendTheme<T extends ThemeUtil>(
  defaultTheme: T,
  overrides: T,
  ...restOverrides: T[]
) {
  function customizer(source: unknown, override: unknown) {
    if (isFunction(source)) {
      return (...args: unknown[]) => {
        const sourceValue = source(...args);
        const overrideValue = isFunction(override) ? override(...args) : override;
        return mergeWith({}, sourceValue, overrideValue, customizer);
      };
    }
    return undefined;
  }

  const finalOverrides = [overrides, ...restOverrides].reduce(
    (prevValue, currentValue) => mergeWith({}, prevValue, currentValue, customizer),
    defaultTheme
  );

  return finalOverrides as T & MergedThemeTypes;
}
