import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useToast } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';
import { Platform } from 'react-native';
import { ToastProps } from 'ucl/components/toast/types';
import { IFormatCurrencyProps, formatCurrency } from 'utils';

import { IBaseProps } from '@rbi-ctg/frontend';
import { useNavigation } from 'hooks/navigation/use-navigation';
import { useRoute } from 'hooks/navigation/use-route';
import useEffectOnce from 'hooks/use-effect-once';
import { useAuthContext } from 'state/auth';
import { useLocale } from 'state/intl';
import { PROD_DEFAULT_REGION } from 'state/intl/constants';
import { LANGUAGES, LOCALES } from 'state/intl/types';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
// TODO: RN - locale switching -- shouldn't this pull from config?
import { Region } from 'utils/environment/types';
import { ISOs, ISOsToRegions, getCountryAndCurrencyCodes } from 'utils/form/constants';
import { setIsWebViewVisible } from 'utils/ui';

import {
  FormatCurrencyForType,
  GetFormatCurrenctForLocaleOptions,
  GetFormatCurrenctForUserOptions,
  IDialogStaticPageState,
  IUIContext,
} from './types';
import { SkipToMainContentButton, useAccessibleMainContent } from './use-accessible-main-content';

export const getFormatCurrencyForUser = ({
  userISOCountryCode,
  locale,
  region,
}: GetFormatCurrenctForUserOptions) => (amount: number) => {
  const { currencyCode, countryCode } = getCountryAndCurrencyCodes(ISOs[userISOCountryCode]);

  const formatOptions: IFormatCurrencyProps = {
    language: locale,
    currency: currencyCode,
    // React native does not currently suport the `narrowSymbol` option
    // with their Hermes engine.
    currencyDisplay:
      Platform.OS === 'web' ? (countryCode === region ? 'narrowSymbol' : 'symbol') : 'symbol',
    amount,
  };

  return formatCurrency(formatOptions);
};

export const getFormatCurrencyForLocale = ({
  userISOCountryCode,
  language,
  region,
}: GetFormatCurrenctForLocaleOptions) => (amount: number) => {
  const userRegion = ((userISOCountryCode && ISOsToRegions[userISOCountryCode]) ||
    PROD_DEFAULT_REGION) as Region;

  const userLocale = `${language}-${userRegion}` as LOCALES;

  const { currencyCode } = getCountryAndCurrencyCodes(ISOs[region]);

  const formatOptions: IFormatCurrencyProps = {
    language: userLocale,
    currency: currencyCode,
    // React native does not currently suport the `narrowSymbol` option
    // with their Hermes engine.
    currencyDisplay:
      Platform.OS === 'web' ? (userRegion === region ? 'narrowSymbol' : 'symbol') : 'symbol',
    amount,
  };

  return formatCurrency(formatOptions);
};

export const UIContext = createContext<IUIContext>({} as IUIContext);
export const useUIContext = () => useContext(UIContext);

export const UIProvider = ({ children }: IBaseProps) => {
  const { user } = useAuthContext();
  const { language, region, locale } = useLocale();
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [shouldShowPreConfirmCurbsideModal, setShouldShowPreConfirmCurbsideModal] = useState(false);
  const [confirmStoreOnCheckout, setConfirmStoreOnCheckout] = useState(true);
  const [isShowingStoreInfoDialog, setIsShowingStoreInfoDialog] = useState<boolean>(false);
  const [userEmail, setUserEmail] = useState<string | null>(null);
  const enableStoreConfirmationModal = useFlag(LaunchDarklyFlag.ENABLE_STORE_CONFIRMATION_MODAL);
  const [userISOCountryCode, setUserISOCountryCode] = useState('USA');
  const promoMessageHeightRef = useRef(0);
  const {
    handleSkipToMain,
    accessibilityMainContentRef,
    hasAccessibleMainContent,
    setHasAccessibleMainContent,
  } = useAccessibleMainContent();
  const [dialogStaticPageState, setDialogStaticPageState] = useState<IDialogStaticPageState>({
    page: '',
    isOpen: false,
  });

  useEffect(() => {
    if (user?.details?.isoCountryCode) {
      setUserISOCountryCode(user.details.isoCountryCode);
    }
  }, [user]);

  useEffectOnce(() => {
    // Just in case there was an issue re-showing webview
    // Set isVisible on mount
    setIsWebViewVisible(true);
  });

  const showStoreInfoDialog = useCallback(() => {
    setIsShowingStoreInfoDialog(true);
  }, [setIsShowingStoreInfoDialog]);

  const hideStoreInfoDialog = useCallback(() => {
    setIsShowingStoreInfoDialog(false);
  }, [setIsShowingStoreInfoDialog]);

  const shouldConfirmStoreOnCheckout = useCallback(
    (flag: boolean) => {
      if (enableStoreConfirmationModal) {
        setConfirmStoreOnCheckout(flag);
      }
    },
    [setConfirmStoreOnCheckout, enableStoreConfirmationModal]
  );

  /**
   * Use the user profile's isoCountryCode to determine the locale.
   * Use the site's locale to determine currency.
   * Useful for product prices which are displayed in the currency of the site (e.g. show a US user '$CA' on the fr-CA and en-CA sites)
   */
  const formatCurrencyForLocale: FormatCurrencyForType = useCallback(
    getFormatCurrencyForLocale({ userISOCountryCode, region, language }),
    [userISOCountryCode, region, language]
  );

  /**
   * Use the site's locale as the formatted number's locale
   * Use the user profile's isoCountryCode to determine the currency,
   * Useful for showing user an amount that they are going to pay in their account's currency (e.g. reloading a pre-paid)
   */
  const formatCurrencyForUser: FormatCurrencyForType = useCallback(
    getFormatCurrencyForUser({ userISOCountryCode, locale, region }),
    [userISOCountryCode, locale, region]
  );

  const currencySymbol = useMemo(() => {
    return formatCurrencyForLocale(0)
      .replace(/\d+(,|\.)\d+/, '')
      .trim();
  }, [formatCurrencyForLocale]);

  const currencyIsSuffix = useMemo(() => {
    return language.toString() === LANGUAGES.fr.toString();
  }, [language]);

  const toast = useToast();
  const { params } = useRoute();
  const { setParams } = useNavigation();
  const { formatMessage } = useIntl();

  const handleShowToast = useCallback(
    (args: ToastProps): void => {
      return toast.show(args);
    },
    [toast]
  );

  useEffect(() => {
    if (params && 'toast' in params) {
      const toast = params.toast as ToastProps;

      if (toast) {
        handleShowToast(toast);
        setParams({ ...params, toast: null });
      }
    }
  }, [params, handleShowToast, formatMessage, setParams]);

  const value = useMemo(
    () => ({
      userEmail,
      isShowingStoreInfoDialog,
      setUserEmail,
      hideStoreInfoDialog,
      showStoreInfoDialog,
      confirmStoreOnCheckout: enableStoreConfirmationModal && confirmStoreOnCheckout,
      shouldConfirmStoreOnCheckout,
      formatCurrencyForLocale,
      formatCurrencyForUser,
      currencySymbol,
      currencyIsSuffix,
      setIsWebViewVisible,
      handleShowToast,
      dialogStaticPageState,
      setDialogStaticPageState,
      isUpdateModalOpen,
      setIsUpdateModalOpen,
      shouldShowPreConfirmCurbsideModal,
      setShouldShowPreConfirmCurbsideModal,
      promoMessageHeightRef,
      accessibilityMainContentRef,
      handleSkipToMain,
      hasAccessibleMainContent,
      setHasAccessibleMainContent,
    }),
    [
      confirmStoreOnCheckout,
      currencyIsSuffix,
      currencySymbol,
      enableStoreConfirmationModal,
      formatCurrencyForLocale,
      formatCurrencyForUser,
      hideStoreInfoDialog,
      isShowingStoreInfoDialog,
      shouldConfirmStoreOnCheckout,
      showStoreInfoDialog,
      userEmail,
      handleShowToast,
      dialogStaticPageState,
      setDialogStaticPageState,
      isUpdateModalOpen,
      setIsUpdateModalOpen,
      shouldShowPreConfirmCurbsideModal,
      setShouldShowPreConfirmCurbsideModal,
      accessibilityMainContentRef,
      handleSkipToMain,
      hasAccessibleMainContent,
      setHasAccessibleMainContent,
    ]
  );

  return (
    <UIContext.Provider value={value}>
      <SkipToMainContentButton />
      {children}
    </UIContext.Provider>
  );
};
