import { RouteProps } from 'react-router';

import { LazyRoute, lazyWithFallback } from 'components/layout/util';
import { QuestDetailsPage } from 'components/quests/quest-details-page';
import { QuestLibrary } from 'components/quests/quests-library';
import { routes as globalRoutes } from 'navigation/routing';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useLoyaltyContext } from 'state/loyalty';
import { useIsLoyaltyEnabled } from 'state/loyalty/hooks/use-is-loyalty-enabled';

import { LoyaltyOfferDetail } from './loyalty-offers/loyalty-offer-detail';
import { OffersGroupsDetails } from './loyalty-offers/loyalty-offers-groups-details';
import { WalmartLandingPage } from './loyalty-partners';
import { WalmartLocation } from './loyalty-partners/walmart/walmart-location';
import { WalmartOffer } from './loyalty-partners/walmart/walmart-offer';
import { WalmartRedirect } from './loyalty-partners/walmart/walmart-redirect';
const LoyaltyOffers: LazyRoute = lazyWithFallback(() => import('pages/loyalty/loyalty-offers'));
const LoyaltyRewards: LazyRoute = lazyWithFallback(() => import('pages/loyalty/loyalty-rewards'));
const LoyaltyDashboard: LazyRoute = lazyWithFallback(() =>
  import('pages/loyalty/loyalty-dashboard')
);
const LoyaltyInRestaurantRedemption: LazyRoute = lazyWithFallback(() =>
  import('pages/loyalty/loyalty-in-restaurant-redemption')
);
const LoyaltyClaimPoints: LazyRoute = lazyWithFallback(() =>
  import('pages/loyalty/loyalty-claim-points')
);
const Offers: LazyRoute = lazyWithFallback(() => import('pages/offers'));

export const useLoyaltyRoutes = () => {
  const loyaltyEnabled = useIsLoyaltyEnabled();
  const { claimPointsEnabled } = useLoyaltyContext();
  const enableRewardPageStandAlone = useFlag(LaunchDarklyFlag.ENABLE_REWARD_PAGE_STAND_ALONE);
  const enableRewardsStaticPage = useFlag(LaunchDarklyFlag.ENABLE_REWARDS_STATIC_PAGE);
  const enableOffersStaticPage = useFlag(LaunchDarklyFlag.ENABLE_OFFERS_STATIC_PAGE);
  const enableQuests = useFlag(LaunchDarklyFlag.ENABLE_QUEST_TILES);
  const enableWalmartPlusIntegration = useFlag(LaunchDarklyFlag.ENABLE_WALMART_PLUS_INTEGRATION);
  const isOffersV2Enabled = useFlag(LaunchDarklyFlag.ENABLE_LOYALTY_OFFERS_V2_FE);

  const routes: Pick<RouteProps, 'element' | 'path'>[] = [];

  if (loyaltyEnabled) {
    routes.push({
      path: globalRoutes.loyaltyDashboard,
      element: <LoyaltyDashboard />,
    });
  }

  routes.push({
    path: globalRoutes.redeem,
    element: <LoyaltyInRestaurantRedemption />,
  });

  if (claimPointsEnabled) {
    routes.push({
      path: `${globalRoutes.claimPointsBase}/*`,
      element: <LoyaltyClaimPoints />,
    });
  }

  if (!enableOffersStaticPage) {
    routes.push({ path: `${globalRoutes.offers}/*`, element: <Offers /> });
  }

  if (enableRewardPageStandAlone) {
    routes.push({
      path: globalRoutes.loyaltyHome,
      element: <LoyaltyDashboard />,
    });
  }

  if (!enableRewardsStaticPage) {
    routes.push({
      path: globalRoutes.loyaltyOfferList,
      element: <LoyaltyOffers />,
    });
    routes.push({ path: globalRoutes.loyaltyChallengeDetails, element: <QuestDetailsPage /> });
    routes.push({
      path: globalRoutes.loyaltyActivateQuest,
      element: <LoyaltyRewards />,
    });
    routes.push({
      path: `${globalRoutes.loyaltyOfferList}/:offerEngineId`,
      element: isOffersV2Enabled ? <LoyaltyOfferDetail /> : <LoyaltyOffers />,
    });
    routes.push({
      path: `${globalRoutes.loyaltyOfferGroup}/:offerGroupId`,
      element: <OffersGroupsDetails />,
    });
    routes.push({
      path: globalRoutes.loyaltyRewardsList,
      element: <LoyaltyRewards />,
    });
    routes.push({
      path: `${globalRoutes.loyaltyRewardsList}/:rewardId`,
      element: <LoyaltyRewards />,
    });
    routes.push({ path: `${globalRoutes.rewards}/*`, element: <LoyaltyRewards /> });
  }

  if (enableWalmartPlusIntegration) {
    routes.push({ path: globalRoutes.partnerships, element: <WalmartOffer /> });
    routes.push({ path: globalRoutes.walmart, element: <WalmartLandingPage /> });
    routes.push({ path: globalRoutes.walmartLocation, element: <WalmartLocation /> });
    routes.push({ path: globalRoutes.walmartRedirect, element: <WalmartRedirect /> });
  }

  if (enableQuests) {
    routes.push({ path: globalRoutes.questLibrary, element: <QuestLibrary /> });
  }

  return routes;
};
