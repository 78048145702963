import React, { Suspense } from 'react';

import { View } from '@rbilabs/universal-components';
import { Outlet } from 'react-router-dom';

import AppHeader from 'components/app-header';
import { LayoutFabButton } from 'components/layout/layout-fab-button';
import MobileNavigation from 'components/mobile-web-navigation-bar';
import { TopServiceModeNotification } from 'components/top-service-mode-notification';
import { useEffectOnUrlChange } from 'hooks/use-effect-on-url-change';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useScrollContext } from 'state/scroll';

import LayoutBottomContainer from './layout-bottom-container';
import { ScrollContainer } from './scroll-container';

type LayoutProps = {
  CustomHeader?: React.FC<{
    children?: React.ReactNode;
  }>;
  CustomBottomNavigation?: React.FC;
};
export const DefaultLayout = ({
  CustomHeader = AppHeader,
  CustomBottomNavigation = () => (
    <LayoutBottomContainer>
      <MobileNavigation />
    </LayoutBottomContainer>
  ),
}: LayoutProps) => {
  const { scrollTo, scrollRef } = useScrollContext();

  const enableTopOrderStatusBar = useFlag(LaunchDarklyFlag.ENABLE_TOP_ORDER_STATUS_BAR);

  useEffectOnUrlChange(() => {
    scrollTo({ animated: false });
  });

  return (
    <>
      {CustomHeader && <CustomHeader />}
      <View testID="app-layout" flex="1" overflowX="hidden">
        <Suspense>
          <ScrollContainer scrollRef={scrollRef}>
            <Outlet />
          </ScrollContainer>
        </Suspense>
        {!enableTopOrderStatusBar && (
          <View>
            <TopServiceModeNotification />
          </View>
        )}
        <LayoutFabButton />
      </View>
      {CustomBottomNavigation && <CustomBottomNavigation />}
    </>
  );
};
