import { Box, Icon, Text } from '@rbilabs/universal-components';

import { theme } from 'styles/configure-theme';
import { primitive } from 'styles/constants/primitives';
import { hiddenAccessibilityPlatformProps } from 'utils/accessibility';

export const StyledBackIcon = Icon.withConfig({
  color: theme.token('icon-header-contrast'),
  size: '5',
  ...hiddenAccessibilityPlatformProps,
});

export const TopContainer = Box.withConfig({
  width: 'full',
  padding: '$2',
  flexDirection: 'row',
});

export const BottomContainer = Box.withConfig({
  borderTopWidth: 1,
  borderTopColor: primitive.$blackOpacity10,
  padding: '$4',
  width: 'full',
});

export const LeftContainer = Box.withConfig({
  width: { base: '$24', md: '$32' },
  justifyContent: 'center',
  alignItems: 'center',
});

export const ContentContainer = Box.withConfig({
  flex: 1,
  padding: '$2',
});

export const OffersDescription = Box.withConfig({
  justifyContent: 'space-between',
  gap: '$6',
});

export const OfferText = Text.withConfig({
  fontSize: 12,
  lineHeight: 16,
});
