import { useCallback } from 'react';

import { ICartEntry, ICombo } from '@rbi-ctg/menu';
import { getModifierMultiplierPrice } from 'pages/menu/components/product-detail/modifier/utils';
import { useMenuContext } from 'state/menu';
import { ServiceMode, useServiceModeContext } from 'state/service-mode';
import { useUnifiedMenuContext } from 'state/unified-menu';
import { IndexedMenu, PosServiceMode } from 'state/unified-menu/types';
import { checkIsBogoOfferItem } from 'utils/cart/helper';
import { CartEntryType } from 'utils/cart/types';

import usePosVendor from './menu/use-pos-vendor';
import { useEnableUnifiedMenu } from './use-enable-unified-menu';

export const useRepriceCartEntries = () => {
  // Legacy code that prices menuObjects and cartEntries using vendorConfigs
  const { vendor } = usePosVendor();
  const {
    pricingFunction,
    priceForItemInComboSlotSelection,
    priceForItemOptionModifier,
  } = useMenuContext();

  const legacyMethod = useCallback(
    (
      entries: ICartEntry[],
      parentEntry?: ICartEntry,
      mainCombo?: ICartEntry | ICombo
    ): ICartEntry[] =>
      entries.map((entry: ICartEntry) => {
        const hasPrice = entry.price !== undefined;
        const isItem = entry.type === CartEntryType.item;
        const isCombo = entry.type === CartEntryType.combo;
        const isComboSlot = parentEntry?.type === CartEntryType.comboSlot;

        if (isItem) {
          // reprices an item and its grandchild itemOptionModifiers in one go.

          let itemPrice = 0;
          if (isComboSlot) {
            itemPrice = entry.enableUpsellPricing
              ? priceForItemInComboSlotSelection({
                  combo: mainCombo,
                  comboSlot: parentEntry,
                  selectedItem: entry,
                })
              : 0;
          } else {
            itemPrice = pricingFunction({ item: entry, quantity: entry.quantity });
          }

          return {
            ...entry,
            // prices are not defined on main items in combos, we need to preserve this
            ...(hasPrice && { price: itemPrice }),
            children: (entry.children || []).map(
              (itemOption: any): ICartEntry => ({
                ...itemOption,
                // reprice the item modifiers
                children: (itemOption.children || []).map((modifier: any) => {
                  const modifierPrice = priceForItemOptionModifier({
                    item: entry,
                    itemOption,
                    modifier,
                  });
                  return {
                    ...modifier,
                    price: modifierPrice,
                  };
                }),
              })
            ),
          };
        }
        // Checking for BOGO Offers price
        const isBogoOffer = checkIsBogoOfferItem(entry, vendor);

        const price = pricingFunction({
          item: entry,
          quantity: entry.quantity,
          vendor,
          isBogoOffer,
        });
        return {
          ...entry,
          ...((isCombo || isBogoOffer) && hasPrice && { price }),
          children: legacyMethod(entry.children, entry, isCombo ? entry : mainCombo),
        };
      }),
    [vendor, pricingFunction, priceForItemInComboSlotSelection, priceForItemOptionModifier]
  );

  // Newer code that prices cartEntries only using oneMenu
  const { indexedMenu, isLoading } = useUnifiedMenuContext();
  const { serviceMode } = useServiceModeContext();
  const posServiceMode = serviceMode === ServiceMode.DELIVERY ? 'delivery' : 'pickup';

  const oneMenuMethod = useCallback(
    (entries: ICartEntry[]): ICartEntry[] =>
      isLoading ? entries : repriceCartEntriesWithOneMenu(entries, indexedMenu, posServiceMode),
    [indexedMenu, isLoading, posServiceMode]
  );

  const isOneMenuEnabled = useEnableUnifiedMenu();
  return isOneMenuEnabled ? oneMenuMethod : legacyMethod;
};

const repriceModifiersWithOneMenu = (
  indexedMenu: IndexedMenu,
  itemCartEntry: ICartEntry,
  posServiceMode: PosServiceMode
): ICartEntry[] => {
  return (itemCartEntry.children || []).map(modifierCartEntry => {
    const modifierId = `${itemCartEntry.sanityId}$${modifierCartEntry.sanityId}`;
    const modifierMenuEntry = indexedMenu.get(modifierId);

    const updatedMultipliers = (modifierCartEntry.children || []).map(multiplierCartEntry => {
      return {
        ...multiplierCartEntry,
        price:
          getModifierMultiplierPrice(
            modifierMenuEntry,
            multiplierCartEntry.sanityId,
            posServiceMode
          ) * multiplierCartEntry.quantity,
      };
    });

    return {
      ...modifierCartEntry,
      children: updatedMultipliers,
    };
  });
};

const repriceCartEntriesWithOneMenu = (
  entries: ICartEntry[],
  indexedMenu: IndexedMenu,
  posServiceMode: PosServiceMode,
  parentId?: string,
  parentCombo?: ICartEntry | undefined
): ICartEntry[] => {
  return entries.map((entry: ICartEntry) => {
    // Determine contextual price based on parent type
    const comboSlotId = parentId === parentCombo?._id ? 'main' : parentId;
    const comboSlot = indexedMenu.find(`${parentCombo?._id}$${comboSlotId}`);
    const comboSlotOption = comboSlot?.options?.entries?.find(x => x.entryId === entry.sanityId);
    const contextualPrice = comboSlotOption?.price;

    // Find the menu entry and compute the price
    const menuEntry = indexedMenu.find(entry.sanityId);
    let menuPrice = contextualPrice?.[posServiceMode] ?? menuEntry?.price?.[posServiceMode] ?? 0;

    // Temporary approach until we have a better way to handle OfferCombo pricing
    // We are disallowing OfferCombo slots to have upcharges
    if (parentCombo?.type === CartEntryType.offerCombo) {
      menuPrice = 0;
    }

    // Process children based on entry type
    const processedChildren =
      entry.type === CartEntryType.item
        ? repriceModifiersWithOneMenu(indexedMenu, entry, posServiceMode)
        : repriceCartEntriesWithOneMenu(
            entry.children,
            indexedMenu,
            posServiceMode,
            entry.sanityId,
            entry.type === CartEntryType.combo || entry.type === CartEntryType.offerCombo
              ? entry
              : parentCombo
          );

    // Return the updated entry
    return {
      ...entry,
      price: menuPrice * entry.quantity,
      children: processedChildren,
    };
  });
};
