import React, { useCallback, useEffect, useState } from 'react';

import { useApolloClient } from '@apollo/client';
import { Box, Pressable, Text } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import { LogUserErrorSeverity, logUserErrorMessage } from 'hooks/log-user-error-message';
import { useNavigation } from 'hooks/navigation/use-navigation';
import { useRoute } from 'hooks/navigation/use-route';
import useEffectOnce from 'hooks/use-effect-once';
import { HttpErrorCodes } from 'remote/constants';
import { useAuthContext } from 'state/auth';
import {
  getStoredOtpCredentials,
  storeOtpCredentials,
} from 'state/auth/hooks/use-account-authentication';
import { ModalAuthScreen } from 'state/auth/types';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import noop from 'utils/noop';
import { decodeBase64String } from 'utils/parse-string';
import { routes } from 'utils/routing';

import { OtpForm } from './otp-form';
import {
  CodeSentText,
  CodeText,
  ErrorMessage,
  NotReceived,
  PressableTextWrapper,
  SentEmailText,
  TransformedHeadline,
} from './otp.styled';
import { useResendOtp } from './use-resend-otp.hook';
import { useSendNewOtp } from './use-send-new-otp.hook';

const OTP_CODE_URL_PARAM = 'code';
const OTP_CODE_LENGTH = 6;
const EMAIL_URL_PARAM = 'user';

interface GraphQLError {
  graphQLErrors?: Array<{
    extensions?: {
      statusCode?: number;
    };
  }>;
}

export const ConfirmOtp = () => {
  const {
    isAuthenticated,
    originLocation,
    setOriginLocation,
    validateLoginOtp,
    setOtpAuthError,
    modalAuthIsOpen,
    setModalAuthState,
    onSignInSuccess,
  } = useAuthContext();

  const client = useApolloClient();
  const { formatMessage } = useIntl();
  const { navigate } = useNavigation();
  const { params } = useRoute<{ [OTP_CODE_URL_PARAM]: string; [EMAIL_URL_PARAM]: string }>();
  const { email, phoneNumber, sessionId } = getStoredOtpCredentials() || {};
  const [isValidatingOtp, setIsValidatingOtp] = useState(false);
  const [showSendNewOtpForm, setShowSendNewOtpForm] = useState(false);

  const sendSameOtpCodeOnce = useFlag(LaunchDarklyFlag.ENABLE_SEND_SAME_OTP_CODE_ONCE);

  // Prevent using OTP code from URL params if invalid length to avoid conflicts
  // with integrations that use the same parameter name (i.e. Walmart)
  const urlOtpCode =
    params[OTP_CODE_URL_PARAM]?.trim()?.length === OTP_CODE_LENGTH
      ? params[OTP_CODE_URL_PARAM]?.trim()
      : '';
  const emailFromUrl = params[EMAIL_URL_PARAM];

  const [otpValidationError, setOtpValidationError] = useState<string | null>(null);
  const [resendOrSendNewOtpError, setResendOrSendNewOtpError] = useState<string | null>(null);
  const [showHasResentOtpMessage, setShowHasSentResentOtpMessage] = useState(false);
  const [showHasSentNewOtpMessage, setShowHasSentNewOtpMessage] = useState(false);

  const hasEmail = Boolean(email?.length);
  const hasPhoneNumber = Boolean(phoneNumber?.length);

  const { sendNewOtp, isLoading: isRequestingNewCode } = useSendNewOtp({
    setOtpValidationError,
    setOtpAuthError,
    setResendOrSendNewOtpError,
    setShowHasSentNewOtpMessage,
    setShowSendNewOtpForm,
  });

  const { resendOtp, isLoading: isResendingOtp } = useResendOtp({
    setOtpValidationError,
    setResendOrSendNewOtpError,
    setShowHasSentResentOtpMessage,
    setShowSendNewOtpForm,
    sendSameOtpCodeOnce,
  });

  // Either if we have a login, or once we validate OTP and get an update for the user being loaded
  // we can then handle the navigate.
  useEffect(() => {
    // If the confirm OTP is rendered in the ModalAuth component, we don't want to navigate away from the modal.
    if (modalAuthIsOpen) {
      return;
    }

    if (isAuthenticated) {
      const path = originLocation || routes.base;
      setOriginLocation(null);
      navigate(path, {
        // This allows us to notify the screen reader to announce a successful sign in only when signing in via OTP.
        state: {
          triggerSignInAccessibility: true,
        },
        replace: true,
        popCurrent: true,
      });
    }
  }, [
    isAuthenticated,
    originLocation,
    modalAuthIsOpen,
    navigate,
    setOriginLocation,
    formatMessage,
  ]);

  const handleValidateLogin = useCallback(
    async (otpCode: string) => {
      setIsValidatingOtp(true);
      setShowHasSentNewOtpMessage(false);
      setOtpValidationError(null);
      setOtpAuthError(null);
      setResendOrSendNewOtpError(null);

      try {
        await validateLoginOtp({ otpCode });
        // refetch offers when user navigates to /offers page
        client.reFetchObservableQueries();
        onSignInSuccess();
      } catch (error) {
        let errorMessage;
        setIsValidatingOtp(false);

        if (!sessionId) {
          errorMessage = logUserErrorMessage({
            message: formatMessage({ id: 'differentDeviceError' }),
            severity: LogUserErrorSeverity.Recoverable,
          });

          setShowSendNewOtpForm(true);
          setOtpValidationError(errorMessage);
          setOtpAuthError(errorMessage);
          throw error;
        }

        const graphQLError = error as GraphQLError;
        switch (graphQLError?.graphQLErrors?.[0]?.extensions?.statusCode) {
          case HttpErrorCodes.InternalServerError:
            errorMessage = logUserErrorMessage({
              message: formatMessage({ id: 'looksLikeWereExperiencingIssues' }),
              severity: LogUserErrorSeverity.Irrecoverable,
            });
            setShowSendNewOtpForm(false);
            break;
          case HttpErrorCodes.TooManyRequests:
            errorMessage = logUserErrorMessage({
              message: formatMessage({ id: 'maxAttemptsReachedRequestNewCode' }),
              severity: LogUserErrorSeverity.Recoverable,
            });
            setShowSendNewOtpForm(true);
            break;
          case HttpErrorCodes.Unauthorized:
          default:
            errorMessage = logUserErrorMessage({
              message: formatMessage({ id: 'invalidOtpProvided' }),
              severity: LogUserErrorSeverity.Recoverable,
            });
            setShowSendNewOtpForm(sendSameOtpCodeOnce ?? false);
            break;
        }
        setOtpValidationError(errorMessage);
        setOtpAuthError(errorMessage);
        throw error;
      }
    },
    [
      setOtpAuthError,
      validateLoginOtp,
      client,
      onSignInSuccess,
      sessionId,
      formatMessage,
      sendSameOtpCodeOnce,
    ]
  );

  /**
   * Ensure that user's email or phone number exists in local storage or url param
   * If the email and phone number doesn't exist, navigate the user to sign in
   */
  useEffectOnce(() => {
    let eitherEmail = email;
    const eitherPhone = phoneNumber;
    if (emailFromUrl) {
      eitherEmail = decodeBase64String({ value: emailFromUrl }) || email || phoneNumber;
    }

    if (eitherEmail || eitherPhone) {
      storeOtpCredentials({ sessionId, email: eitherEmail, phoneNumber: eitherPhone });
    } else {
      // modalAuthIsOpen is set to true when the user start the authentication process it let us know that the user is in the modal auth flow.
      // If the confirm OTP is rendered in the ModalAuth component, we don't want to navigate away from the modal.
      if (modalAuthIsOpen) {
        setModalAuthState({
          screen: ModalAuthScreen.SIGN_IN,
        });
        return;
      }

      navigate(routes.signUp, {
        state: { activeRouteIsSignIn: true },
      });
    }
  });

  /**
   * Only try submission on mount or when the otpCode in the url changes
   */
  useEffect(() => {
    if (urlOtpCode) {
      handleValidateLogin(urlOtpCode).catch(noop);
    }
  }, [urlOtpCode]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box safeAreaBottom>
      <TransformedHeadline>{formatMessage({ id: 'authVerifyWithCode' })}</TransformedHeadline>
      {hasEmail && (
        <SentEmailText aria-label={formatMessage({ id: 'visuallyHiddenSignInCodeSentToEmail' })}>
          {formatMessage({ id: 'authEmailSentEmail' })}
          &nbsp;
          <Text testID="otp-email" data-private>
            {email}
          </Text>
        </SentEmailText>
      )}
      {hasPhoneNumber && (
        <SentEmailText aria-label={formatMessage({ id: 'visuallyHiddenSignInCodeSentToPhone' })}>
          {formatMessage({ id: 'authPhoneSentSms' })}
          &nbsp;
          <Text testID="otp-email" data-private>
            {phoneNumber}
          </Text>
        </SentEmailText>
      )}
      <OtpForm
        errorMessage={otpValidationError}
        initialCode={urlOtpCode}
        loading={isValidatingOtp}
        onSubmit={handleValidateLogin}
      />

      {showSendNewOtpForm && (
        <Pressable
          alignSelf="center"
          disabled={isRequestingNewCode || isValidatingOtp}
          onPress={sendNewOtp}
          testID="otp-send-new-form"
          mt="$4"
        >
          <PressableTextWrapper>{formatMessage({ id: 'sendNewCode' })}</PressableTextWrapper>
        </Pressable>
      )}

      {showHasSentNewOtpMessage && hasEmail && (
        <CodeSentText aria-live="polite">{formatMessage({ id: 'newCodeSent' })}</CodeSentText>
      )}

      {showHasSentNewOtpMessage && hasPhoneNumber && (
        <CodeSentText aria-live="polite">{formatMessage({ id: 'newCodeSentPhone' })}</CodeSentText>
      )}

      {/** resend OTP & send new OTP forms are never visible at the same time, they alternate */}
      {!showSendNewOtpForm && (
        <>
          <NotReceived>
            <CodeText>{formatMessage({ id: 'didNotReceiveCodeHeading' })}</CodeText>
            <Pressable
              disabled={isValidatingOtp || isResendingOtp}
              onPress={resendOtp}
              testID="otp-resend-form"
            >
              <PressableTextWrapper>{formatMessage({ id: 'resendCode' })}</PressableTextWrapper>
            </Pressable>
          </NotReceived>
          {!showHasSentNewOtpMessage && showHasResentOtpMessage && hasEmail && (
            <CodeSentText testID="otp-resend-success" aria-live="polite">
              {formatMessage({ id: 'signInCodeSentToEmailAgain' })}
            </CodeSentText>
          )}
          {!showHasSentNewOtpMessage && showHasResentOtpMessage && hasPhoneNumber && (
            <CodeSentText testID="otp-resend-success" aria-live="polite">
              {formatMessage({ id: 'signInCodeSentToPhoneAgain' })}
            </CodeSentText>
          )}
        </>
      )}

      {resendOrSendNewOtpError && (
        <ErrorMessage testID="otp-resend-or-send-new-error">{resendOrSendNewOtpError}</ErrorMessage>
      )}
    </Box>
  );
};

export default ConfirmOtp;
