import { ImageUrlBuilderOptionsWithAliases } from '@sanity/image-url/lib/types/types';
import { PixelRatio, Platform } from 'react-native';

import { ISanityImage } from '@rbi-ctg/menu';
import { ISanityImage as GWISanityImage } from 'generated/graphql-gateway';
import { IImageFragment } from 'generated/sanity-graphql';
import { buildImageUrl, parseAssetID } from 'remote/build-image-url';
import logger from 'utils/logger';

export type PictureBoundaryDimensions =
  | {
      width?: string | number | undefined;
      height?: string | number | undefined;
    }
  | {
      height: null;
      width: null;
    };

export type PictureMeasuredDimensions = { width: number; height: number };

/**
 * Image container width ranges and fixed image widths for each range.
 * These are used to generate the src uri for the picture component.
 * These widths are based on devices with a pixel ratio of 1.
 */
const breakpoints = [
  { range: [1, 50], width: 50 },
  { range: [51, 150], width: 150 },
  { range: [151, 250], width: 250 },
  { range: [251, 350], width: 350 },
  { range: [351, 450], width: 450 },
  { range: [451, 500], width: 550 },
  { range: [501, 600], width: 650 },
  { range: [600, Infinity], width: 900 },
];

export interface IGenerateSrcUri {
  image: ISanityImage | IImageFragment | GWISanityImage;
  boundaryDimensions: PictureBoundaryDimensions;
  measuredDimensions: PictureMeasuredDimensions;
  screenWidth: number;
  imageFormat: ImageFormat;
  quality?: number;
  resolutionMultiplier?: number;
}

export type ImageFormat = 'auto' | 'webp' | 'avif';

// with webp we can achieve ~90% reduction of image file size without noticeable quality reduction, use it if we can!
export function getImageFormat(
  image: ISanityImage | IImageFragment | GWISanityImage | undefined | null
): ImageFormat {
  try {
    if (Platform.OS === 'web') {
      return 'auto';
    }

    // null or not defined yet
    if (!image || !image.asset?._id) {
      return 'auto';
    }

    // when image is a gif it might be an animated gif so return autoFormat
    // because sanity won't return an animated webp (if source was an animated gif)
    // NOTE: gif is very uncommon, usually images are png that we want to webp optimize
    const { format } = parseAssetID(image.asset?._id || undefined);
    if (format === 'gif') {
      return 'auto';
    }

    // android SDK 31+ supports avif images (smaller / better than png/webp)
    if (Platform.OS === 'android' && Platform.Version >= 31) {
      return 'avif';
    }

    // android SDK 18+ supports webp  w/ transparency
    // https://developer.android.com/studio/write/convert-webp
    if (Platform.OS === 'android' && Platform.Version >= 18) {
      return 'webp';
    }

    // iOS 16+ supports avif images (smaller / better than png/webp)
    // version can have major and minor ex 17.0.1 hence parseInt. as recommended here:
    // https://reactnative.dev/docs/platform-specific-code#detecting-the-ios-version
    if (Platform.OS === 'ios' && parseInt(Platform.Version, 10) >= 16) {
      return 'avif';
    }

    // iOS 14+ supports webp
    // https://developer.apple.com/documentation/uniformtypeidentifiers/uttype/3551599-webp
    if (Platform.OS === 'ios' && parseInt(Platform.Version, 10) >= 14) {
      return 'webp';
    }
  } catch (error) {
    logger.error({
      message: 'Error detecting webp support, defaulting to sanity auto format',
      error,
    });
  }

  return 'auto';
}

function getSanityFormatArgs(imageFormat: ImageFormat): Partial<ImageUrlBuilderOptionsWithAliases> {
  if (imageFormat === 'auto') {
    // per docs automatically select best supported image format - doesn't work on react-native but *DOES* work on web
    // https://www.sanity.io/docs/image-urls#auto-777d41f23d56
    return { auto: 'format' };
  }

  if (imageFormat === 'webp') {
    return { format: 'webp' };
  }

  if (imageFormat === 'avif') {
    // in conjunction with AVIF headers avif will be returned
    // https://www.sanity.io/docs/image-urls#e6444759a5a5
    return { auto: 'format' };
  }

  return { fm: imageFormat };
}

export function getDefaultQuality(imageFormat: ImageFormat) {
  if (imageFormat === 'avif' || Platform.OS === 'web') {
    // 75 on an avif looks like an 80 on a webp. web pretty much always uses avif now.
    return 75;
  }

  return 80;
}

/**
 * Given a sanity image, this function generates the CDN src uri based
 * on the device's pixel ratio and the width of the image container.
 *
 * @param {ISanityImage} image - Sanity image to generate uri for.
 * @param {PictureBoundaryDimensions} boundaryDimensions - Calculated dimensions of Picture boundary.
 * @param {PictureMeasuredDimensions} measuredDimensions - Dimensions from onLayout callback.
 * @param {number} screenWidth - Width dimension of the device screen.
 * @param {number} [quality] - number between 1-100 dictating the generated image quality.
 * @param {number} [resolutionMultiplier] - a multiplier to be used to adjust the image size when requesting the image
 *
 * @returns A uri string to be used to fetch an image from the CDN.
 */
export function generateSrcUri({
  image,
  boundaryDimensions,
  measuredDimensions,
  screenWidth,
  quality,
  imageFormat,
  resolutionMultiplier = 1,
}: IGenerateSrcUri) {
  // boundaryDimensions.width can be a string, number, null or undefined.
  // Make a string version
  const boundaryWidthString = String(boundaryDimensions.width || 0);
  // parseInt to get the number value
  const boundaryWidthInt = parseInt(boundaryWidthString, 10);
  // check for percentage width
  const widthIsPercent = boundaryWidthString.includes('%');
  // If we have a boundary width calculate percentage width if percent value, or use the number value
  // Otherwise fallback to the measured width
  const containerWidth = boundaryDimensions.width
    ? widthIsPercent
      ? (boundaryWidthInt / 100) * screenWidth // TODO: should this be percent of screen width? How would we get parent width?
      : boundaryWidthInt
    : measuredDimensions.width;
  // Find the breakpoint the image container width falls into.
  // If we can't find a matching breakpoint, use a middle of the road breakpoint
  // to try to get an image width that should work for most use cases.
  const defaultBreakpoint = breakpoints[3];
  const breakpoint =
    breakpoints.find(
      breakpoint => containerWidth >= breakpoint.range[0] && containerWidth <= breakpoint.range[1]
    ) || defaultBreakpoint;
  // Get the current device's pixel ratio
  const pixelRatio = Math.max(1, PixelRatio.get());
  // Calculate the final width to request from the CDN, without decimals
  const finalWidth = Math.floor(breakpoint.width * pixelRatio * resolutionMultiplier);

  const format = getSanityFormatArgs(imageFormat);

  const uri = buildImageUrl(image, {
    fit: 'max',
    quality,
    width: finalWidth,
    ...format,
  });

  return uri;
}
