import { useEffect, useRef } from 'react';

import { useToast } from '@rbilabs/universal-components';
import { useFormikContext } from 'formik';
import { useIntl } from 'react-intl';

import { ISignUpCompleteQuery, useSignInJwtMutation } from 'generated/graphql-gateway';
import { useNavigation } from 'hooks/navigation/use-navigation';
import { CustomEventNames, EventTypes, autoSignInEvent, logRBIEvent } from 'state/amplitude';
import { AuthenticationPhases } from 'state/amplitude/constants';
import { useAuthContext } from 'state/auth';
import { useConfirmJwt } from 'state/auth/hooks/use-confirm-jwt';
import { platform, welcomeEmailDomain } from 'utils/environment';
import { ISignupForm } from 'utils/form/types';
import { encodeBase64String } from 'utils/parse-string';
import { routes } from 'utils/routing';

interface IAutoSignin {
  signUpJwt: string;
  signUpCompleteData?: ISignUpCompleteQuery;
  stopPolling: () => void;
}

const AutoSignin: React.FC<React.PropsWithChildren<IAutoSignin>> = ({
  signUpJwt,
  signUpCompleteData,
  stopPolling,
}) => {
  const { handleConfirmJwt } = useConfirmJwt();
  const { values } = useFormikContext<ISignupForm>();
  const { navigate } = useNavigation();
  const [signInJwtMutation] = useSignInJwtMutation();
  const { formatMessage } = useIntl();
  const {
    isAuthenticated,
    modalAuthIsOpen,
    onSignInSuccess,
    modalAuthState,
    setModalAuthState,
  } = useAuthContext();
  const toast = useToast();
  const hasMutatedRef = useRef(false);

  const { email } = values;

  useEffect(() => {
    if (
      !hasMutatedRef.current &&
      !isAuthenticated &&
      signUpJwt &&
      signUpCompleteData?.signUpComplete
    ) {
      // TODO: handle a loading state for the sign up form in the campaign auth flow
      stopPolling();
      autoSignInEvent({ phase: AuthenticationPhases.VALIDATION });
      signInJwtMutation({
        variables: {
          input: {
            email,
            stage: welcomeEmailDomain(),
            platform: platform(),
          },
        },
      })
        .then(async () => {
          hasMutatedRef.current = true;
          const user = encodeBase64String({ value: email });
          logRBIEvent({
            name: CustomEventNames.AUTO_SIGN_IN,
            type: EventTypes.Other,
            attributes: {
              signUpType: email ? 'Email' : 'Phone Number',
            },
          });
          autoSignInEvent({ phase: AuthenticationPhases.COMPLETE });
          // If the user comes from the cart, the modal auth is not implemented yet. That could happens if the enable-move-signup-after-cart is on.
          // But if the user signup from the modal auth we should show the toast instead
          if (modalAuthIsOpen && user) {
            await handleConfirmJwt(signUpJwt, user);
            toast.show({ text: formatMessage({ id: 'autoSignInSuccess' }), variant: 'positive' });
            onSignInSuccess();
            return;
          }

          navigate(routes.confirmJwt, {
            state: {
              token: signUpJwt,
              user,
              showToast: true,
            },
          });
        })
        .catch(err => {
          throw err;
        });
    }
  }, [
    handleConfirmJwt,
    navigate,
    stopPolling,
    signUpJwt,
    signUpCompleteData,
    email,
    signInJwtMutation,
    formatMessage,
    modalAuthIsOpen,
    isAuthenticated,
    onSignInSuccess,
    toast,
    modalAuthState,
    setModalAuthState,
  ]);
  return null;
};

export default AutoSignin;
