import { EntryTypeEnum, IQuantity } from 'generated/graphql-gateway';
import { ClickEventComponentNames, logButtonClick } from 'state/amplitude';
import { ILocalizedMenuEntry, IndexedMenu, PosServiceMode } from 'state/unified-menu/types';
import {
  hasOptionEntries,
  mapAvailableOptions,
  stringifySanityImage,
} from 'state/unified-menu/utils';

import { MultipliersSelections } from '../../product-wizard/types';
import { QUANTITY_DEFAULTS } from '../utils';

import { IModifier, IModifierMultiplier, ModifierType } from './types';

export function mapModifier(
  menu: IndexedMenu,
  id: string,
  modifiersCount: number,
  posServiceMode: PosServiceMode,
  quantity?: IQuantity | null
): IModifier {
  const modifierEntry = menu.get(id, { type: EntryTypeEnum.MODIFIER_GROUP });
  if (!hasOptionEntries(menu, posServiceMode, modifierEntry)) {
    throw new Error(`Modifier group "${id}" has no options`);
  }

  const { min, max } = setQuantityDefaults(quantity);
  const name = modifierEntry.name?.locale ?? modifierEntry.id;
  const multipliers = mapAvailableOptions(
    menu,
    posServiceMode,
    modifierEntry.options.entries,
    option => mapModifierMultiplier(menu, modifierEntry, option.entryId, name, posServiceMode)
  );

  const modifier: IModifier = {
    id: modifierEntry.id,
    name,
    quantity: { min, max },
    multipliers,
    defaultMultipliers: modifierEntry.options.defaults ?? [],
    singleChoiceOnly: modifiersCount === 1 && multipliers.length > 2,
    displayModifierMultiplierName: true,
    type: ModifierType.CHECKBOX, //temp will be reassigned right after.
    image: stringifySanityImage(modifierEntry.image),
  };

  modifier.type = getModifierType(modifier);

  if (modifier.defaultMultipliers.length === 0) {
    modifier.defaultMultipliers = [modifier.multipliers[0].id];
  }

  return modifier;
}

export function getModifierMultiplierPrice(
  modifier: ILocalizedMenuEntry,
  multiplierId: string,
  posServiceMode: PosServiceMode
): number {
  const multiplierRef = modifier.options?.entries?.find(entry => entry.entryId === multiplierId);
  const multiplierRefPrice = multiplierRef?.price?.[posServiceMode] ?? 0;
  const modifierPrice = modifier.price?.[posServiceMode] ?? 0;
  return modifierPrice + multiplierRefPrice;
}

function mapModifierMultiplier(
  menu: IndexedMenu,
  modifierEntry: ILocalizedMenuEntry,
  id: string,
  modifierName: string,
  posServiceMode: PosServiceMode
): IModifierMultiplier {
  const multiplier = menu.get(id, { type: EntryTypeEnum.MODIFIER });

  const oName = multiplier.name?.locale;
  const name = oName ?? multiplier.id;
  const nutrition = multiplier.nutrition ?? undefined;

  // TODO: figure out if prefix will arrive in API
  const prefix = guessMultiplierPrefix(oName ?? '', modifierName);

  return {
    id: multiplier.id,
    name,
    prefix,
    multiplier: guessMultiplier(multiplier.id, name), //TODO: fix once multipliers arrive in API
    nutrition,
    price: getModifierMultiplierPrice(modifierEntry, id, posServiceMode),
    image: stringifySanityImage(multiplier.image),
  };
}

/**
 * Removes modifiers that cannot be modified.
 * Example: modifiers with only one option available where the user must select one.
 */
export function getValidModifiersForSelection(modifiers: IModifier[]): IModifier[] {
  return modifiers.filter(modifier => {
    const requiresUserSelection =
      modifier.type === ModifierType.MULTIPLE_CHOICE ||
      modifier.type === ModifierType.SINGLE_CHOICE;
    if (requiresUserSelection && modifier.multipliers.length <= 1) {
      // nothing for the user to select if there is 0 or 1 option
      return false;
    }

    return true;
  });
}

export function getMultiplierLabel(
  allOptionsHavePrefix: boolean,
  selectedMultiplier: IModifierMultiplier
): string {
  return allOptionsHavePrefix && selectedMultiplier.prefix
    ? selectedMultiplier.prefix
    : selectedMultiplier.multiplier.toString();
}

/**
 * Gets the selected multiplier for a modifier.
 * It will return the first selected multiplier if there are multiple selected.
 * If there are none selected, it will return the default multiplier.
 */
export function getSelectedMultiplier(
  modifier: IModifier,
  selections: MultipliersSelections
): IModifierMultiplier {
  const selectedMultiplierId = Object.keys(selections)[0] ?? modifier.defaultMultipliers[0];
  return modifier.multipliers.find(multiplier => multiplier.id === selectedMultiplierId)!;
}

/**
 * Identifies if a multiplier is visually selected.
 * While a multiplier can be selected, it might not be visually selected in the UI.
 * For example, a default of No Cheese will not be visually selected.
 * @param modifier
 * @param multiplier
 * @returns
 */
export function isMultiplierVisuallySelected(multiplier: IModifierMultiplier) {
  const isZeroMultiplier = multiplier.multiplier === 0;
  return !isZeroMultiplier;
}

export const logProductModifierCustomization = (
  text: string,
  headerText: string,
  componentKey: string
) => {
  logButtonClick(
    {
      attributes: {
        component: ClickEventComponentNames.PRODUCT_MODIFIER_CUSTOMIZATION,
        componentId: componentKey,
        headerText,
        name: text,
      },
    },
    { logDuplicateClickEvent: true }
  );
};

function getModifierType(modifier: IModifier): ModifierType {
  if (isMultiChoiceModifier(modifier)) {
    return ModifierType.MULTIPLE_CHOICE;
  } else if (isCheckboxModifier(modifier)) {
    return ModifierType.CHECKBOX;
  } else if (isNonNumericModifier(modifier)) {
    return ModifierType.NON_NUMERIC;
  } else if (isNumericModifier(modifier)) {
    return ModifierType.NUMERIC;
  } else if (isSingleChoiceModifier(modifier)) {
    return ModifierType.SINGLE_CHOICE;
  }
  return ModifierType.NON_NUMERIC;
}

function isCheckboxModifier(modifier: IModifier): boolean {
  if (modifier.singleChoiceOnly || modifier.multipliers.length !== 2) {
    return false;
  }
  return modifier.multipliers.every(n => n.multiplier === 0 || n.multiplier === 1);
}

function isNonNumericModifier(modifier: IModifier): boolean {
  return !modifier.singleChoiceOnly && modifier.displayModifierMultiplierName;
}

function isNumericModifier(modifier: IModifier): boolean {
  return !modifier.singleChoiceOnly && !modifier.displayModifierMultiplierName;
}

const isSingleChoiceModifier = (modifier: IModifier): boolean => {
  return modifier.singleChoiceOnly && modifier.quantity.max === 1;
};

function isMultiChoiceModifier(modifier: IModifier): boolean {
  return modifier.quantity.max > 1;
}

function guessMultiplier(id: string, name: string) {
  if (id.includes('_0-00')) {
    return 0;
  }
  if (id.includes('_0-50')) {
    return 0.5;
  }
  if (id.includes('_1-00')) {
    return 1;
  }
  if (id.includes('_1-50')) {
    return 1.5;
  }
  if (id.includes('_2-00')) {
    return 2;
  }

  name = name.toLowerCase();
  if (name.includes('light')) {
    return 0.5;
  }
  if (name.includes('regular')) {
    return 1;
  }
  if (name.includes('extra')) {
    return 2; // could be 1.5 or 2. Just guessing
  }
  if (name.includes('dipping sauce')) {
    return 1;
  }
  return 0;
}

function guessMultiplierPrefix(multiplierName: string, modifierName: string): string {
  const [firstWord] = multiplierName.split(' ');
  if (['No', 'Light', 'Regular', 'Extra'].includes(firstWord)) {
    return firstWord;
  }

  const regex = new RegExp(modifierName, 'i');
  return multiplierName.replace(regex, '').trim();
}

function setQuantityDefaults(quantity: IQuantity | undefined | null): IModifier['quantity'] {
  return {
    min: quantity?.min ?? QUANTITY_DEFAULTS.min,
    max: quantity?.max ?? QUANTITY_DEFAULTS.max,
  };
}
