import { FC, PropsWithChildren, useCallback } from 'react';

import { ContentCard } from '@braze/react-native-sdk';
import { Pressable } from '@rbilabs/universal-components';

import useBrazeContext from 'state/braze/hooks/use-braze-context';
import { ContentCardTypes } from 'state/braze/types';
import { actions, useAppDispatch } from 'state/global-state';

import { CaptionedCardContainer, ContentCardContainer, ImageOnlyCardImage } from './styled';
import { useContentCardNavigation } from './use-content-card-navigation';

type ImageOnlyContentCardProps = {
  card: ContentCard & {
    type: typeof ContentCardTypes.IMAGE_ONLY;
  };
};

export const ImageOnlyContentCard: FC<PropsWithChildren<ImageOnlyContentCardProps>> = ({
  card,
}) => {
  const { logContentCardClicked } = useBrazeContext();
  const dispatch = useAppDispatch();
  const { navigate } = useContentCardNavigation();

  const handleClickCta = useCallback(() => {
    logContentCardClicked(card);
    !!card.url && navigate({ url: card.url });
    dispatch(actions.loyalty.closeInbox());
  }, [card, dispatch, logContentCardClicked, navigate]);

  return (
    <Pressable onPress={handleClickCta}>
      <ContentCardContainer>
        <CaptionedCardContainer>
          <ImageOnlyCardImage
            alt={card.type}
            source={{ uri: card.image }}
            style={{ aspectRatio: card.imageAspectRatio }}
          />
        </CaptionedCardContainer>
      </ContentCardContainer>
    </Pressable>
  );
};
