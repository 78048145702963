import Version from './version';

export enum Keys {
  AMPLITUDE_VARIANTS = 'RBI::AMPLITUDE_VARIANTS',
  APPLIED_LOYALTY_OFFERS = 'RBI::APPLIED_LOYALTY_OFFERS',
  APPLIED_LOYALTY_REWARDS = 'RBI::APPLIED_LOYALTY_REWARDS',
  AUTH_REDIRECT = 'RBI::AUTH_REDIRECT',
  BRAND = 'RBI::BRAND',
  CART = 'RBI::CART',
  MINIMAL_CART = 'RBI::MINIMAL_CART',
  IN_RESTAURANT_REDEMPTION = 'RBI::LOYALTY_IN_RESTAURANT_REDEMPTION',
  IN_RESTAURANT_REDEMPTION_LAST_MOD = 'RBI::IN_RESTAURANT_REDEMPTION_LAST_MOD',
  IN_RESTAURANT_REDEMPTION_SHORT_CODE = 'RBI::LOYALTY_IN_RESTAURANT_REDEMPTION_SHORT_CODE',
  LANGUAGE = 'RBI::LANGUAGE',
  LAST_COOKIE_VERSION = 'RBI::COOKIE_VERSION',
  LAST_TIME_COOKIES_ACCEPTED = 'RBI::LAST_TIME_COOKIES_ACCEPTED',
  LAST_TIME_COOKIES_IGNORED = 'RBI::LAST_TIME_COOKIES_IGNORED',
  LAST_TIME_STORE_UPDATED = 'RBI::LAST_TIME_STORE_UPDATED',
  LAUNCH_DARKLY_USER_ATTRIBUTES = 'RBI::LAUNCH_DARKLY_USER_ATTRIBUTES',
  LAUNCH_DARKLY_FLAGS = 'RBI::LAUNCH_DARKLY_FLAGS',
  LOGIN = 'RBI::LOGIN',
  LOYALTY_OFFERS_APPLIED_FILTERS = 'RBI::LOYALTY_OFFERS_APPLIED_FILTERS',
  OFFER = 'RBI::OFFER',
  ONETRUST_COOKIE_CONSENT_GROUPS = 'RBI::ONETRUST_COOKIE_CONSENT_GROUPS',
  ORDER = 'RBI::ORDER',
  ORDER_LAST_UPDATE = 'RBI::ORDER_LAST_UPDATE',
  ORDER_ID = 'RBI::ORDER_ID',
  IN_RESTAURANT_CART_LAST_UPDATE = 'RBI::IN_RESTAURANT_CART_LAST_UPDATE',
  OTP = 'RBI::OTP',
  PAYMENT = 'RBI::PAYMENT',
  REGION = 'RBI::REGION',
  SELECTED_LOYALTY_OFFER = 'RBI::SELECTED_LOYALTY_OFFER',
  SERVICE_MODE = 'RBI::SERVICE_MODE',
  DELIVERY_SURCHARGE_FEE = 'RBI::DELIVERY_SURCHARGE_FEE',
  STORAGE_VERSION = 'RBI::STORAGE_VERSION',
  STORE = 'RBI::STORE',
  TABBED_MENU_PREVIOUS_ACTIVE_TAB = 'RBI::TABBED_MENU_PREVIOUS_ACTIVE_TAB',
  USER = 'RBI::USER',
  USER_AUTH_TOKEN = 'RBI::USER_AUTH_TOKEN',
  USER_SIGNED_IN_SUCCESSFULLY = 'RBI::USER_SIGNED_IN_SUCCESSFULLY',
  VEHICLE_DESCRIPTION = 'RBI::VEHICLE_DESCRIPTION',
  REDEEM_LAST_CODE_STATE = 'RBI::REDEEM_LAST_CODE_STATE',
  REDEEM_SHOULD_REFRESH = 'RBI::REDEEM_SHOULD_REFRESH',
  TEMP_FUTURE_ORDERING_TIME = 'RBI::TEMP_FUTURE_ORDERING_TIME',
  LAST_ERROR_BOUNDARY_CRASH_TIME = 'RBI::ERROR_BOUNDARY_CRASHES',
  LAST_CRASH_KEY_CLEAR_TIME = 'RBI::LAST_CRASH_KEY_CLEAR_TIME',

  LAST_VERSION_INFO = 'RBI::LAST_VERSION_INFO',

  // debug local storage keys
  DEBUG_OPERATION_NAME = 'RBI::DEBUG_OPERATION_NAME',
  DEBUG_CHAOS_MODE = 'RBI::DEBUG_CHAOS_MODE',
  INTERNAL_BUILD_ONLY_ENV_PICKER_CONFIG = 'RBI::INTERNAL_BUILD_ONLY_ENV_PICKER_CONFIG',

  // Social login user email
  SOCIAL_LOGIN_USER_EMAIL = 'RBI::SOCIAL_LOGIN_USER_EMAIL',
  SOCIAL_LOGIN_USER_NAME = 'RBI::SOCIAL_LOGIN_USER_NAME',
  LOCATION_CAN_ASK_AGAIN = 'RBI::LOCATION_CAN_ASK_AGAIN',

  // Order Status Card
  ORDER_STATUS_CARD_CLOSED = 'RBI::ORDER_STATUS_CARD_CLOSED',

  // Subscription to Marketing Communication Channels
  MARKETING_SMS_SUBSCRIPTION_REQUESTED = 'RBI::MARKETING_SMS_SUBSCRIPTION_REQUESTED',
  MARKETING_EMAIL_SUBSCRIPTION_REQUESTED = 'RBI::MARKETING_EMAIL_SUBSCRIPTION_REQUESTED',
  MARKETING_PUSH_SUBSCRIPTION_REQUESTED = 'RBI::MARKETING_PUSH_SUBSCRIPTION_REQUESTED',

  LOCK_SCREEN_LAST_ORDER_STATUS = 'RBI::LOCK_SCREEN_LAST_ORDER_STATUS',

  // ONBOARDING
  ONBOARDING_STATE = 'RBI::ONBOARDING_STATE',

  // User is on an Order Bay
  ORDER_BAY = 'RBI::ORDER_BAY',

  // CAMPAIGNS
  IS_ORIENTATION_DEFAULT = 'RBI::IS_ORIENTATION_DEFAULT',

  // Skip auto select store prompt
  SKIP_AUTO_SELECT_PROMPT = 'RBI::SKIP_AUTO_SELECT_PROMPT',

  // Set auto select store prompt timestamp
  AUTO_SELECT_TIMESTAMP = 'RBI::AUTO_SELECT_TIMESTAMP',

  // Content cards
  READ_CONTENT_CARDS = 'RBI::READ_CONTENT_CARDS',

  // Campaigns local storage
  CAMPAIGNS_LOCAL_STORAGE = 'RBI::CAMPAIGNS_LOCAL_STORAGE',
}

export const LOCAL_STORAGE_VERSION = Version.localStorage;
export const COOKIE_VERSION = Version.cookie;
