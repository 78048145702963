import { Button, Icon } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

interface IGetMyDiscountButton {
  onPress: () => void;
}

export const GetMyDiscountButton = ({ onPress }: IGetMyDiscountButton) => {
  const { formatMessage } = useIntl();

  return (
    <Button
      variant="outline"
      onPress={onPress}
      rightIcon={
        <Icon
          name="mi:navigate-next"
          marginTop={{ base: '$1', md: '$0.5' }}
          color={Styles.color.tertiary}
        />
      }
    >
      {formatMessage({ id: 'getMyDisccountButton' })}
    </Button>
  );
};
