import React, { createContext, useContext, useEffect, useState } from 'react';

import { IBaseProps, SetState } from '@rbi-ctg/frontend';
import { IQuest } from 'components/quests/loyalty-quest/types';
import { useLoyaltyQuestQuery } from 'components/quests/loyalty-quest/use-loyalty-quest-query';
import useEffectOnUpdates from 'hooks/use-effect-on-updates';
import useEffectOnce from 'hooks/use-effect-once';
import { usePrevious } from 'hooks/use-previous';
import { actions, selectors, useAppDispatch, useAppSelector } from 'state/global-state';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useMemoAll } from 'utils/use-memo-all';

import { IUseLoyaltyOffers, IUseLoyaltyRewards } from './hooks/types';
import { useGetOffersIncentivesIds } from './hooks/use-get-offers-incentives';
import { useIsLoyaltyEnabled } from './hooks/use-is-loyalty-enabled';
import { useLoyaltyOffers } from './hooks/use-loyalty-offers';
import { useLoyaltyRewards } from './hooks/use-loyalty-rewards';
import { useLoyaltyUserState } from './hooks/use-loyalty-user-state';
import { InRestaurantRedemptionProvider } from './in-restaurant-redemption';

// Leaving as an interface to be extended in future PR
export interface ILoyaltyContext extends IUseLoyaltyRewards, IUseLoyaltyOffers {
  claimPointsEnabled: boolean;
  refetchLoyaltyUser: () => void;
  addOffersCallbackUrl: string;
  setAddOffersCallbackUrl: SetState<string>;
  loadingQuests: boolean;
  userQuest: IQuest | null;
  refetchQuests: () => void;
  setShouldQuestsModalBeVisible: SetState<boolean>;
  shouldQuestsModalBeVisible: boolean;
}

export const LoyaltyContext = createContext({} as ILoyaltyContext);
export const useLoyaltyContext = () => useContext(LoyaltyContext);

export const LoyaltyProvider = (props: IBaseProps) => {
  const claimPointsEnabled = Boolean(useFlag(LaunchDarklyFlag.ENABLE_CLAIM_LOYALTY_POINTS));
  const [addOffersCallbackUrl, setAddOffersCallbackUrl] = useState<string>('');
  const [shouldQuestsModalBeVisible, setShouldQuestsModalBeVisible] = useState<boolean>(false);
  const loyaltyEnabled = useIsLoyaltyEnabled();
  const {
    loading: loadingLoyaltyUser,
    loyaltyUser,
    refetch: refetchLoyaltyUser,
  } = useLoyaltyUserState({ refreshOnMount: false });
  const loyaltyUserId = loyaltyUser?.id;
  const prevLoyaltyUserId = usePrevious(loyaltyUserId);
  const loyaltyUserPoints = loyaltyUser?.points ?? 0;

  const loyaltyRewards = useLoyaltyRewards(loyaltyUser);
  const loyaltyOffers = useLoyaltyOffers();
  const { fetchIncentiveIds } = useGetOffersIncentivesIds();
  const dispatch = useAppDispatch();
  const shouldRefetch = useAppSelector(selectors.loyalty.selectShouldRefetchRewards);
  // Fetch the Quest stored in our Loyalty service for the current User
  const { loadingQuests, quest: userQuest, refetchQuests } = useLoyaltyQuestQuery({
    loyaltyId: loyaltyUserId,
  });

  useEffectOnce(() => {
    if (loyaltyEnabled) {
      fetchIncentiveIds().then(ids => dispatch(actions.loyalty.setIncentivesIds(ids || [])));
    }
  });

  useEffectOnUpdates(() => {
    if (!loyaltyEnabled) {
      dispatch(actions.loyalty.resetLoyaltyRewardsState({ points: loyaltyUserPoints }));
    }
  }, [dispatch, loyaltyEnabled, loyaltyUserPoints]);

  const { refetchRewards, engineRewardsMap } = loyaltyRewards;
  // Reset rewards on loyalty user change
  useEffect(() => {
    if (prevLoyaltyUserId && !loadingLoyaltyUser && prevLoyaltyUserId !== loyaltyUserId) {
      dispatch(actions.loyalty.resetLoyaltyRewardsState({ points: loyaltyUserPoints }));
    }
  }, [
    dispatch,
    loadingLoyaltyUser,
    loyaltyUserId,
    loyaltyUserPoints,
    prevLoyaltyUserId,
    shouldRefetch,
  ]);

  useEffect(() => {
    if (shouldRefetch && loyaltyUserId) {
      refetchRewards(loyaltyUserId);
      dispatch(actions.loyalty.setShouldRefetchRewards(false));
    }
  }, [dispatch, loyaltyUserId, refetchRewards, shouldRefetch]);

  useEffect(() => {
    const engineRewardsList = Object.values(engineRewardsMap);
    if (engineRewardsList?.length) {
      dispatch(actions.loyalty.createAvailableLoyaltyRewardsMap(engineRewardsList));
    }
  }, [dispatch, engineRewardsMap]);

  const value = useMemoAll({
    addOffersCallbackUrl,
    claimPointsEnabled,
    refetchLoyaltyUser,
    setAddOffersCallbackUrl,
    loadingQuests,
    userQuest,
    refetchQuests,
    setShouldQuestsModalBeVisible,
    shouldQuestsModalBeVisible,
    // other loyalty hooks
    ...loyaltyOffers,
    ...loyaltyRewards,
  });

  return (
    <LoyaltyContext.Provider value={value}>
      <InRestaurantRedemptionProvider>{props.children}</InRestaurantRedemptionProvider>
    </LoyaltyContext.Provider>
  );
};
