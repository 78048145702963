import { Box, Text } from '@rbilabs/universal-components';

export const ContentContainer = Box.withConfig({
  paddingBottom: '8',
});

export const PrimaryText = Text.withConfig({
  marginY: '8',
  marginX: 'auto',
  textAlign: 'center',
});

export const SecondaryText = Text.withConfig({
  textAlign: 'center',
});
