import * as React from 'react';

import { VStack, View } from '@rbilabs/universal-components';
import { useWindowDimensions } from 'react-native';

import { BackArrowHistory } from 'components/navigation/back-arrow';
import AuthRequired from 'pages/authentication/components/auth-required';

import { QuestSection } from '../quest-section';

const Wrapper = VStack.withConfig<{
  $windowHeight: number;
}>(({ $windowHeight }) => ({
  height: '100%',
  maxHeight: $windowHeight,
  paddingY: '$2',
  paddingX: '$4',
}));

export const QuestLibrary = () => {
  const { height: $windowHeight } = useWindowDimensions();

  return (
    <View flex={1}>
      <AuthRequired>
        <BackArrowHistory />
        <Wrapper $windowHeight={$windowHeight}>
          <QuestSection />
        </Wrapper>
      </AuthRequired>
    </View>
  );
};
