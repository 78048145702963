import { forwardRef } from 'react';

import { Text as NbText } from 'native-base';

import { addWithConfig } from '../../utils';

import type { TextProps } from './types';

export const Text = forwardRef((props: TextProps, ref) => (
  <NbText ref={ref} variant="copyOne" {...props} />
));

export default addWithConfig(Text);
