import { PropsWithChildren } from 'react';

import ActionButton, { IActionButtonProps } from 'components/action-button';
import { ModalContent as UCModalContent } from 'components/modal';
import { isMobile } from 'utils/environment';

export const GiftCardModalContent = UCModalContent.withConfig({
  alignSelf: 'center',
  alignItems: 'center',
  width: 'full',
  mt: '$24',
  maxWidth: {
    md: '50%',
    lg: '30%',
  },
});

export const SubmitButton = ({ children, ...props }: PropsWithChildren<IActionButtonProps>) => (
  <ActionButton
    fullWidth
    _web={{
      width: isMobile() ? 'full' : '80%',
      marginTop: '$5',
    }}
    marginTop="$2"
    {...props}
  >
    {children}
  </ActionButton>
);
