import { ReactNode } from 'react';

import { Box, Button, Header, Icon, Pressable, Text } from '@rbilabs/universal-components';
import { GestureResponderEvent } from 'react-native';

import { primitive } from 'styles/constants/primitives';

import {
  BottomContainer,
  ContentContainer,
  LeftContainer,
  OfferText,
  OffersDescription,
  TopContainer,
} from './styled';

type IWalmartOfferItem = {
  name: string;
  description: string;
  descriptionL2?: string;
  image: ReactNode;
  activateButtonLabel: string;
  onPartnershipActivate: ((event: GestureResponderEvent) => void) | null | undefined;
  isLoadingUser: boolean;
  isWalmartUser?: boolean;
  showUnlinkButton: boolean;
  unlinkButtonLabel: string;
  onUnlinkUser: () => void;
  isUnlinkingUser: boolean;
  onhandleClickWalmartOfferItemCard: () => void;
};

export const WalmartOfferItem = ({
  name,
  description,
  descriptionL2,
  image,
  activateButtonLabel,
  onPartnershipActivate,
  onhandleClickWalmartOfferItemCard,
  isLoadingUser,
  isWalmartUser,
  showUnlinkButton,
  unlinkButtonLabel,
  onUnlinkUser,
  isUnlinkingUser,
}: IWalmartOfferItem) => (
  <Box
    backgroundColor={primitive.$white}
    rounded={10}
    borderWidth={0}
    borderColor={'transparent'}
    flexDirection={'column'}
  >
    <Pressable onPress={isWalmartUser ? onhandleClickWalmartOfferItemCard : undefined}>
      <TopContainer>
        <LeftContainer>{image}</LeftContainer>
        <ContentContainer>
          <Header
            variant="headerThree"
            color={'token.text-default'}
            ellipsizeMode="tail"
            numberOfLines={2}
            mb="$1"
          >
            {name}
          </Header>
          <OffersDescription>
            {!!description && (
              <Box>
                <OfferText>{description}</OfferText>
              </Box>
            )}
            {!!descriptionL2 && (
              <Box>
                <OfferText>{descriptionL2}</OfferText>
              </Box>
            )}
          </OffersDescription>

          {!isLoadingUser && !isWalmartUser && (
            <Button
              variant="outline"
              size="sm"
              width="$24"
              onPress={onPartnershipActivate}
              marginTop={{ base: '$2', md: '$4' }}
            >
              {activateButtonLabel}
            </Button>
          )}
        </ContentContainer>

        {isWalmartUser && (
          <Box width={'$6'} justifyContent={'center'} alignItems={'center'}>
            <Icon variant="next" />
          </Box>
        )}
      </TopContainer>
    </Pressable>

    {!isLoadingUser && isWalmartUser && showUnlinkButton && (
      <BottomContainer>
        <Pressable onPress={onUnlinkUser} width="$24" disabled={isUnlinkingUser}>
          <Text variant="copyOne" bold>
            {unlinkButtonLabel}
          </Text>
        </Pressable>
      </BottomContainer>
    )}
  </Box>
);
