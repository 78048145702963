import { primitive } from 'styles/constants/primitives';
export const driverMarkerSvg = `
<svg width="48" height="60" viewBox="0 0 48 60" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M31.0326 46.9532C40.856 43.9471 48 34.8078 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 34.3472 6.54807 43.1651 15.7264 46.5358L23.5 60L31.0326 46.9532Z" fill="${primitive.bk.$fieryRed}"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M32.75 16.75C32.75 15.375 31.625 14.25 30.25 14.25H26.5V16.75H30.25V20.0625L25.9 25.5H21.5V19.25H16.5C13.7375 19.25 11.5 21.4875 11.5 24.25V28H14C14 30.075 15.675 31.75 17.75 31.75C19.825 31.75 21.5 30.075 21.5 28H27.1L32.75 20.9375V16.75ZM17.75 29.25C17.0625 29.25 16.5 28.6875 16.5 28H19C19 28.6875 18.4375 29.25 17.75 29.25ZM21.5 15.5H15.25V18H21.5V15.5ZM29 28C29 25.925 30.675 24.25 32.75 24.25C34.825 24.25 36.5 25.925 36.5 28C36.5 30.075 34.825 31.75 32.75 31.75C30.675 31.75 29 30.075 29 28ZM31.5 28C31.5 28.6875 32.0625 29.25 32.75 29.25C33.4375 29.25 34 28.6875 34 28C34 27.3125 33.4375 26.75 32.75 26.75C32.0625 26.75 31.5 27.3125 31.5 28Z" fill="${primitive.bk.$mayoEggWhite}"/>
</svg>
`;

// eslint-disable-next-line import/no-default-export
export default driverMarkerSvg;
