import { Text } from '@rbilabs/universal-components';
import { Image, ImageSourcePropType, StyleSheet, View } from 'react-native';

import { ISanityImage } from '@rbi-ctg/menu';
import Picture from 'components/picture';
import { IImageFragment } from 'generated/sanity-graphql';
import { primitive } from 'styles/constants/primitives';

interface IWalmartPlusWeekOffer {
  title: string;
  text: string;
  image: ImageSourcePropType | undefined;
  badge: ISanityImage | IImageFragment | ISanityImage | null | undefined;
}

export const WalmartPlusWeekOffer = ({ title, text, image, badge }: IWalmartPlusWeekOffer) => (
  <View style={styles.offer}>
    <View style={styles.offerImage}>
      <Image style={styles.offerImage} source={image} />
    </View>
    <View style={styles.offerContent}>
      <Text variant="headerTwo" margin={0}>
        {title}
      </Text>
      <Text variant="copyOne" marginTop={1} marginBottom={1}>
        {text}
      </Text>
      <Picture image={badge} alt={'Walmart'} objectFitContain lazy={false} height="24px" />
    </View>
  </View>
);

const styles = StyleSheet.create({
  offer: {
    flexDirection: 'row',
    borderRadius: 10,
    backgroundColor: primitive.$white,
    padding: 16,
  },
  offerImage: {
    width: 96,
    height: 96,
  },
  offerContent: {
    flex: 1,
    justifyContent: 'center',
    marginLeft: 16,
  },
});
