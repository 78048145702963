import { RouteProps } from 'react-router';
import { Navigate } from 'react-router-dom';

import { LazyRoute, lazyWithFallback } from 'components/layout/util';
import { routes as globalRoutes } from 'navigation/routing';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';

const Pypestream: LazyRoute = lazyWithFallback(() => import('pages/pypestream'));

export function usePypestreamRoutes() {
  const enablePypestream = useFlag(LaunchDarklyFlag.ENABLE_PYPESTREAM);
  const routes: Pick<RouteProps, 'element' | 'path'>[] = [];

  if (enablePypestream) {
    // show pypestream and redirect all other support links to pypestream
    routes.push({ path: globalRoutes.support, element: <Pypestream /> });
    routes.push({
      path: `${globalRoutes.support}/*`,
      element: <Navigate replace to={globalRoutes.support} />,
    });
  }

  return routes;
}
