import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { ToastProps, useToast } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import { useNavigation } from 'hooks/navigation/use-navigation';
import useErrorModal from 'hooks/use-error-modal';
import { getCurrentSession } from 'remote/auth';
import { CustomEventNames, EventTypes, logRBIEvent } from 'state/amplitude';
import {
  AuthInterface,
  ModalAuthScreen,
  ModalAuthState,
  ModalAuthTrigger,
  OpenSignInModalProps,
} from 'state/auth/types';
import LocalStorage, { StorageKeys } from 'utils/local-storage';
import { routes } from 'utils/routing';
import { useMemoAll } from 'utils/use-memo-all';

import { useAccountAuthentication } from './hooks/use-account-authentication';
import { UserDetails, useCurrentUser } from './hooks/use-current-user';

// Initial state for the modal authentication hook.
// This defines the default values for all modal properties when the component mounts or when the modal is explicitly closed.
const initialModalAuthState: ModalAuthState = {
  screen: ModalAuthScreen.CLOSED,
  shouldDisplaySignInSuccessMessage: true,
  shouldDisableHeadlineLinks: false,
  shouldSkipDefaultSignInCallback: false,
  shouldWaitLoginForVendor: false,
  disableEmailInput: false,
  loginForVendorIsLoading: false,
  trigger: ModalAuthTrigger.Global,
  onClose: undefined,
  onPreSignIn: undefined,
  onSignInSuccess: undefined,
  user: undefined,
  providerType: undefined,
  errorMessage: '',
  heading: '',
};

export const AuthContext = createContext<AuthInterface>(({} as any) as AuthInterface);
export const useAuthContext = () => useContext(AuthContext);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const toast = useToast();
  const { formatMessage } = useIntl();
  const { navigate } = useNavigation();
  const [modalAuthState, setModalAuthState] = useState<ModalAuthState>(initialModalAuthState);
  const [isReAuthenticating, setIsReAuthenticating] = useState(false);
  const [otpAuthError, setOtpAuthError] = useState(null);
  const modalAuthIsOpen = modalAuthState.screen !== ModalAuthScreen.CLOSED;
  const [accountCallbackUrl, setAccountCallbackUrl] = useState<string>('');
  const [alertMessage, setAlertMessage] = useState<string>('');

  /**
   * A handler function to update the modal authentication state.
   * It merges the new state with the previous state, ensuring that no data is lost
   * and only specified properties are updated.
   *
   * @param {Partial<ModalAuthState>} newState - The new state to be merged with the previous state.
   */
  const handleSetModalAuthState = useCallback((newState: Partial<ModalAuthState>) => {
    setModalAuthState(prevModalAuthState => {
      const mergedState = { ...prevModalAuthState, ...newState } as ModalAuthState;

      const prevUser = prevModalAuthState.user || {};
      const newUser = newState.user || {};
      mergedState.user = {
        name: newUser.name ?? prevUser.name,
        email: newUser.email ?? prevUser.email,
      };

      if (JSON.stringify(prevModalAuthState) === JSON.stringify(mergedState)) {
        return prevModalAuthState;
      }

      if (newState.screen && prevModalAuthState.screen !== newState.screen) {
        logRBIEvent({
          name: CustomEventNames.AUTH_DRAWER_VIEW,
          type: EventTypes.Other,
          attributes: {
            screen: newState.screen,
          },
        });
      }
      return mergedState;
    });
  }, []);
  const onConfirmErrorDialog = useCallback(() => {
    // If the user is in the modal auth flow, we want to bypass the redirection to the sign up page and instead show the sign up form in the auth modal
    if (modalAuthIsOpen) {
      handleSetModalAuthState({
        screen: ModalAuthScreen.SIGN_IN,
      });
      return;
    }
    navigate(routes.signUp, {
      state: { activeRouteIsSignIn: true },
      replace: true,
    });
  }, [modalAuthIsOpen, navigate, handleSetModalAuthState]);

  const [ErrorDialog, openErrorDialog] = useErrorModal({
    onConfirm: onConfirmErrorDialog,
    modalAppearanceEventMessage: 'Error: Auth Error',
  });

  const {
    refetchCurrentUser,
    refreshCurrentUser,
    refreshCurrentUserWithNewSession,
    setCurrentUser,
    updateUserCommPrefs,
    updateUserFavStores,
    updateUserInfo,
    user,
    userLoading,
    useUpdateMeMutationLoading,
    isAuthenticated,
  } = useCurrentUser({
    openErrorDialog,
  });

  const {
    authLoading,
    originLocation,
    setOriginLoc,
    signIn,
    signOut,
    signUp,
    socialLogin,
    validateLogin,
    validateLoginOtp,
  } = useAccountAuthentication({
    refreshCurrentUser,
    openErrorDialog,
    setCurrentUser,
    setModalAuthState: handleSetModalAuthState,
    modalAuthIsOpen,
  });

  useEffect(() => {
    // save redirect url so user can get redirected correctly after login
    LocalStorage.setItem(StorageKeys.AUTH_REDIRECT, { callbackUrl: originLocation });
  }, [originLocation]);

  const loading = authLoading || userLoading;

  const defaultCloseAuthModal = useCallback(() => {
    handleSetModalAuthState(initialModalAuthState);
  }, [handleSetModalAuthState]);

  const defaultOnSignInSuccess = useCallback(() => {
    if (modalAuthState.shouldSkipDefaultSignInCallback) {
      return;
    }
    const shouldShowToast = !!modalAuthState.shouldDisplaySignInSuccessMessage;
    const successToast: ToastProps = {
      text: formatMessage({ id: 'signInSuccess' }),
      variant: 'positive',
    };

    if (!modalAuthState.navigateOnSuccessRoute && shouldShowToast) {
      toast.show(successToast);
    }

    if (modalAuthState.navigateOnSuccessRoute?.length) {
      navigate(modalAuthState.navigateOnSuccessRoute, {
        state: {
          toast: shouldShowToast ? successToast : '',
        },
      });
    }
    defaultCloseAuthModal();
  }, [defaultCloseAuthModal, formatMessage, modalAuthState, navigate, toast]);

  const openSignInModal = useCallback(
    (params?: OpenSignInModalProps) => {
      const {
        errorMessage = '',
        shouldDisableHeadlineLinks = false,
        shouldWaitLoginForVendor = false,
        navigateOnSuccessRoute,
        trigger = ModalAuthTrigger.Global,
      } = params || {};

      handleSetModalAuthState({
        screen: ModalAuthScreen.SIGN_IN,
        shouldDisplaySignInSuccessMessage: true,
        navigateOnSuccessRoute,
        errorMessage,
        shouldDisableHeadlineLinks,
        shouldWaitLoginForVendor,
        trigger,
      });
    },
    [handleSetModalAuthState]
  );

  // use cognito to determind if user is authed or not. Prevent showing un-auth view to auth user.
  const authContext = useMemoAll<AuthInterface>({
    // Computed values
    loading,
    useUpdateMeMutationLoading,
    originLocation,
    otpAuthError,
    user: user as UserDetails,
    // Functions
    getCurrentSession,
    refetchCurrentUser,
    refreshCurrentUser,
    refreshCurrentUserWithNewSession,
    isAuthenticated,
    signIn,
    signOut,
    signUp,
    socialLogin,
    updateUserInfo,
    updateUserCommPrefs,
    updateUserFavStores,
    validateLogin,
    validateLoginOtp,
    setOriginLocation: setOriginLoc as (args: null | string) => void,
    setOtpAuthError: setOtpAuthError as (args: null | string) => void,
    setModalAuthState: handleSetModalAuthState,
    modalAuthIsOpen,
    modalAuthState,
    isReAuthenticating,
    setIsReAuthenticating,
    openSignInModal,
    onSignInSuccess: useCallback(() => {
      defaultOnSignInSuccess();
      modalAuthState?.onSignInSuccess && modalAuthState.onSignInSuccess();
    }, [defaultOnSignInSuccess, modalAuthState]),
    closeModalAuth: useCallback(() => {
      defaultCloseAuthModal();
      modalAuthState?.onClose && modalAuthState.onClose();
    }, [defaultCloseAuthModal, modalAuthState?.onClose]),
    accountCallbackUrl,
    setAccountCallbackUrl,
    alertMessage,
    setAlertMessage,
  });

  return (
    <AuthContext.Provider value={authContext}>
      {children}
      <ErrorDialog />
    </AuthContext.Provider>
  );
};

export default AuthContext.Consumer;
