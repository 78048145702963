import { ISanityImage } from '@rbi-ctg/menu';
import { EntryTypeEnum, IOneStoreMenuQuery, IPriceDto } from 'generated/graphql-gateway';

export type ILocalizedMenu = NonNullable<IOneStoreMenuQuery['OneStoreMenu']>;

export type ILocalizedMenuEntry = ILocalizedMenu['entries'][number];

export type ILocalizedText = ILocalizedMenuEntry['name'];

/**
 * Represents an entity (item, combo, picker, etc.) that can be ordered. Not a section.
 */
export interface IMainMenuProduct {
  price: number;
  calories: number;
}

export type MenuItemType =
  | EntryTypeEnum.COMBO
  | EntryTypeEnum.ITEM
  | EntryTypeEnum.MENU
  | EntryTypeEnum.PICKER
  | EntryTypeEnum.SECTION;

export type PosServiceMode = Exclude<keyof IPriceDto, '__typename'>;

/**
 * Represents a node in the main menu hierarchy
 */
export interface IMainMenuNode {
  id: string;
  name: string;
  image: {
    resource: ISanityImage;
    description: string;
  };
  children: IMainMenuNode[];
  product: IMainMenuProduct | undefined;
  type: MenuItemType;
}

export interface IndexedMenuLookupOptions {
  /**
   * The matched entry must have this type
   */
  type?: EntryTypeEnum;
}

/**
 * Fast lookup for menu entries by id
 */
export class IndexedMenu {
  public static readonly EMPTY = new IndexedMenu(new Map());

  constructor(private readonly map: Map<string, ILocalizedMenuEntry>) {}

  /**
   * Returns the menu entry with the given id or throws an error if it does not exist
   * @param id The id of the entry to look up
   * @param options Options to filter the lookup
   */
  public get(id: string, options?: IndexedMenuLookupOptions): ILocalizedMenuEntry {
    const entry = this.map.get(id);
    if (!entry) {
      throw new Error(`Entry not found: ${id}`);
    }

    if (options?.type && entry.type !== options.type) {
      throw new Error(`Entry type mismatch: ${entry.type} !== ${options.type}`);
    }

    return entry;
  }

  /**
   * Returns the menu entry with the given id or undefined if it does not exist
   * @param id The id of the entry to look up
   * @param options Options to filter the lookup
   */
  public find(id: string, options?: IndexedMenuLookupOptions): ILocalizedMenuEntry | undefined {
    const entry = this.map.get(id);
    if (!entry) {
      return undefined;
    }

    if (options?.type && entry.type !== options.type) {
      return undefined;
    }

    return entry;
  }
}
