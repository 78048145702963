import React from 'react';

import { useIntl } from 'react-intl';

import { routes } from 'navigation/routing';
import { useIsLoyaltyEnabled } from 'state/loyalty/hooks/use-is-loyalty-enabled';

import { TermsLink } from '../styled';

export const RewardsPolicyTermsLink = () => {
  const loyaltyEnabled = useIsLoyaltyEnabled();
  const { formatMessage } = useIntl();
  if (!loyaltyEnabled) {
    return null;
  }
  return (
    <TermsLink to={routes.termsConditionsRewards}>
      {formatMessage({ id: 'rewardsPolicy' })}
    </TermsLink>
  );
};
