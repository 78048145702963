import React, { createContext, useEffect } from 'react';

import { IBaseProps } from '@rbi-ctg/frontend';
import Braze from 'utils/braze';
import { isNative } from 'utils/environment';

import { BrazeCssBugOverride } from './braze-css-bug-override';
import useBrazeContext from './hooks/use-braze-context';
import { IBrazeContext } from './types';

export const BrazeContext = createContext<IBrazeContext>({} as IBrazeContext);

export const BrazeProvider = ({ children }: IBaseProps) => {
  const ctxValue = useBrazeContext();
  const { setIsInAppMessageOpen } = ctxValue;

  useEffect(() => {
    if (isNative) {
      Braze.subscribeToInAppMessage(true, event => {
        setIsInAppMessageOpen(true);
      });
    }
  }, [setIsInAppMessageOpen]);

  useEffect(() => {
    Braze.requestContentCardsRefresh();
  }, []);

  return (
    <BrazeContext.Provider value={ctxValue}>
      <BrazeCssBugOverride />
      {children}
    </BrazeContext.Provider>
  );
};
