import { Box, Pressable } from '@rbilabs/universal-components';

import { LinkNoDecoration, MobileHeaderContainer } from 'components/app-header/styled';
import { LayoutContainer } from 'components/layout/styled';
import { Logo } from 'components/logo';
import { useNavigation } from 'navigation/hooks';
import { useLoyaltyUser } from 'state/loyalty/hooks/use-loyalty-user';
import { portalToReplaceHeader } from 'state/mobile-header-nav';
import { routes } from 'utils/routing';

import { BackButtonContainer, HeaderContainer, StyledBackIcon, StyledLogo } from './styled';

interface IWalmartHeader {
  authzCode?: string | string[] | null;
  disabled?: boolean;
}

export const WalmartHeader = portalToReplaceHeader(
  ({ authzCode, disabled = false }: IWalmartHeader) => {
    const { loyaltyUser } = useLoyaltyUser();
    const { navigate } = useNavigation();

    if (disabled) {
      return null;
    }

    return (
      <MobileHeaderContainer>
        <LayoutContainer>
          <HeaderContainer>
            {loyaltyUser && !authzCode && (
              <BackButtonContainer>
                <Pressable onPress={() => navigate(routes.partnerships)}>
                  <StyledBackIcon variant="back" />
                </Pressable>
              </BackButtonContainer>
            )}
            <Box marginLeft={'$8'}>
              <LinkNoDecoration to={routes.base}>
                <StyledLogo>
                  <Logo />
                </StyledLogo>
              </LinkNoDecoration>
            </Box>
          </HeaderContainer>
        </LayoutContainer>
      </MobileHeaderContainer>
    );
  }
);
