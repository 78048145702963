import { useIntl } from 'react-intl';

import ActionButton, { ActionButtonVariants } from 'components/action-button';
import { ActionsheetContent, ActionsheetWrapper } from 'components/action-sheet';
import Picture from 'components/picture';
import { useFeatureLoyaltyUi } from 'hooks/use-feature-loyalty-ui';

import { useLoyaltyWalmart } from '../use-loyalty-walmart';

import { ContentHeader, ContentText, ContentWrapper } from './styled';
interface IDialogActionSheetProps {
  isAlreadyLinked: boolean;
  isOpen?: boolean;
  handleOnClose?: () => void;
  accountEmail?: string;
  handleLinkAccount: () => Promise<void>;
  handleSwitchAccount: () => Promise<void>;
  handleSignIn: () => Promise<void> | void;
}

export const WalmartWelcomeBack = ({
  isAlreadyLinked = false,
  isOpen = false,
  handleOnClose,
  accountEmail,
  handleLinkAccount,
  handleSwitchAccount,
  handleSignIn,
}: IDialogActionSheetProps) => {
  const { featureLoyaltyUi } = useFeatureLoyaltyUi();
  const { formatMessage } = useIntl();
  const logo = featureLoyaltyUi?.rewardsLogo?.locale;
  const { data, loading } = useLoyaltyWalmart();

  const onConfirm = () => {
    handleLinkAccount();
  };
  const onDismiss = () => {
    handleSwitchAccount();
  };

  if (loading || !isOpen) {
    return null;
  }

  return (
    <ActionsheetWrapper
      isOpen={isOpen}
      onOpenEventMessage="Walmart Welcome Back modal"
      onClose={handleOnClose}
    >
      <ActionsheetContent>
        <ContentWrapper>
          <Picture
            image={logo}
            alt={formatMessage({ id: 'royalPerks' })}
            objectFitContain
            lazy={false}
            lqOff
            width="$48"
            height="$24"
          />
          <ContentHeader>{data?.welcomeBack?.title}</ContentHeader>
          {!isAlreadyLinked ? (
            <>
              <ContentText>
                {data?.welcomeBack?.description?.replace('{accountEmail}', accountEmail || '')}
              </ContentText>
              <ActionButton fullWidth onPress={onConfirm} mb="$4">
                {data?.welcomeBack?.linkButton?.buttonText?.locale}
              </ActionButton>
              <ActionButton
                fullWidth
                onPress={onDismiss}
                mb="$4"
                variant={ActionButtonVariants.TEXT_ONLY}
              >
                {data?.welcomeBack?.notLinkButton?.buttonText?.locale}
              </ActionButton>
            </>
          ) : (
            <>
              <ContentText>
                {formatMessage({ id: 'walmartPartnerUserAlreadyLinked' }, { email: accountEmail })}
              </ContentText>
              <ActionButton fullWidth onPress={() => handleSignIn()} mb="$4">
                {formatMessage({ id: 'signIn' })}
              </ActionButton>
            </>
          )}
        </ContentWrapper>
      </ActionsheetContent>
    </ActionsheetWrapper>
  );
};
