import { UUID_REGEX_STRING } from 'utils/constants';

import { LinkToType } from '../types';

import { relativeRoutes, routes } from './routes';

export const isSectionRoute = (pathname: string) => pathname.startsWith(`${routes.menu}/section-`);

export const isRecentItemsRoute = (pathname: string) =>
  pathname.startsWith(`${routes.menu}/${relativeRoutes.recentItems}`);

export const isFavoritesRoute = (pathname: string) => pathname.startsWith(routes.favorites);

export const isOfferItemRoute = (pathname: string) =>
  new RegExp(`^${routes.loyaltyOfferList}/${UUID_REGEX_STRING}`).test(pathname);

export const isItemPickerOrComboRoute = (pathname: string) =>
  new RegExp(`^${routes.menu}/(item|picker|combo)-`).test(pathname);

export function editFavorite({
  linkTo,
  favoriteId,
  popCurrent = false,
}: {
  linkTo: LinkToType;
  favoriteId?: string | null;
  popCurrent?: boolean;
}) {
  linkTo(`${routes.favorites}/${favoriteId}/edit`, { popCurrent });
}
