/// <reference types="@types/googlepay" />
import { useCallback, useEffect, useMemo, useState } from 'react';

import { IGooglePayDetails } from 'features/payment/types';

import { useConfigValue } from 'hooks/configs/use-config-value';
import { sanitizeAlphanumeric } from 'utils/form';
import logger from 'utils/logger';
import { buildFormattedBillingAddress } from 'utils/native-actions';
import { parseStringifiedJSON } from 'utils/parse-string';

import { IRequestGooglePayPayment, IUseGooglePay } from '../types';

import { googlePayCardDetails } from './google-pay-payment-details';
import { useCanUseGooglePay } from './use-can-use-google-pay.web';
import { loadScript } from './utils';

const GOOGLE_PAY_SCRIPT_URL = 'https://pay.google.com/gp/p/js/pay.js';

interface IGooglePayResult {
  result?: {
    paymentMethodData?: google.payments.api.PaymentMethodData;
  };
  error?: any;
}

const baseRequest = {
  apiVersion: 2,
  apiVersionMinor: 0,
};

const getGooglePaymentDataRequest = ({
  cardPaymentMethod,
  countryCode,
  currencyCode,
  total,
  merchantName,
  merchantId,
}: {
  cardPaymentMethod: google.payments.api.PaymentMethodSpecification;
  countryCode: string;
  currencyCode: string;
  total: string;
  merchantName: string;
  merchantId: string;
}) => {
  const paymentDataRequest: google.payments.api.PaymentDataRequest = {
    ...baseRequest,
    allowedPaymentMethods: [cardPaymentMethod],
    transactionInfo: {
      countryCode,
      currencyCode,
      totalPriceStatus: 'FINAL',
      totalPrice: total,
    },
    merchantInfo: {
      merchantId,
      merchantName,
    },
  };
  return paymentDataRequest;
};

const makePaymentRequest = async ({
  tokenizationSpecification,
  countryCode,
  currencyCode,
  total,
  environment,
  networks,
  merchantId,
  merchantName,
}: {
  environment: string;
  networks: google.payments.api.CardNetwork[];
  tokenizationSpecification: google.payments.api.PaymentMethodTokenizationSpecification;
  gateway: string;
  countryCode: string;
  currencyCode: string;
  total: string;
  merchantId: string;
  merchantName: string;
}): Promise<IGooglePayResult> => {
  let paymentClient: google.payments.api.PaymentOptions = {};
  if (environment !== 'test') {
    paymentClient = {
      environment: 'PRODUCTION',
      merchantInfo: {
        merchantName,
        merchantId,
      },
    };
  }

  const paymentsClient = new google.payments.api.PaymentsClient(paymentClient);

  const baseCardPaymentMethod: google.payments.api.IsReadyToPayPaymentMethodSpecification = {
    type: 'CARD',
    parameters: {
      allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
      allowedCardNetworks: networks,
      assuranceDetailsRequired: true,
    },
  };
  const cardPaymentMethod: google.payments.api.PaymentMethodSpecification = {
    ...baseCardPaymentMethod,
    tokenizationSpecification,
  };
  const isReadyToPayRequest = {
    ...baseRequest,
    allowedPaymentMethods: [baseCardPaymentMethod],
    existingPaymentMethodRequired: false,
  };
  try {
    const isReadyToPay = await paymentsClient.isReadyToPay(isReadyToPayRequest);
    if (isReadyToPay.result) {
      const paymentDataRequest = getGooglePaymentDataRequest({
        cardPaymentMethod,
        countryCode,
        currencyCode,
        total,
        merchantId,
        merchantName,
      });
      const paymentData = await paymentsClient.loadPaymentData(paymentDataRequest);
      return {
        result: {
          paymentMethodData: paymentData.paymentMethodData,
        },
      };
    }
  } catch (error) {
    return { error: `Error processing google pay: ${error}` };
  }
  return { error: 'Failed processing google pay' };
};

export function useGooglePay(args: IUseGooglePay = {}) {
  const [requestingPayment, setRequestingPayment] = useState(false);
  const { canUseGooglePay } = useCanUseGooglePay();

  const googleConfig = useConfigValue({ key: 'google', defaultValue: {} });
  const googleConfigNetworks = googleConfig.paymentsNetworks;
  const networks = useMemo(() => {
    return googleConfigNetworks?.filter((i: unknown) => !!i) ?? [];
  }, [googleConfigNetworks]);
  const environment = googleConfig.paymentsEnvironment;

  const [scriptHasMounted, setScriptHasMounted] = useState(false);
  useEffect(() => {
    if (canUseGooglePay && !scriptHasMounted) {
      loadScript(GOOGLE_PAY_SCRIPT_URL).then(() => setScriptHasMounted(true));
    }
  }, [canUseGooglePay, scriptHasMounted]);

  const requestGooglePayPayment = useCallback(
    async ({
      countryCode,
      currencyCode,
      total,
      gateway,
      gatewayMerchantId,
    }: IRequestGooglePayPayment): Promise<IGooglePayDetails | void> => {
      setRequestingPayment(true);

      try {
        const tokenizationSpecification: google.payments.api.PaymentMethodTokenizationSpecification = {
          type: 'PAYMENT_GATEWAY',
          parameters: {
            gateway,
            gatewayMerchantId,
          },
        };

        const response = await makePaymentRequest({
          networks,
          environment,
          tokenizationSpecification,
          gateway,
          countryCode,
          currencyCode,
          total: (total / 100).toString(),
          merchantName: gateway,
          merchantId: gatewayMerchantId,
        });

        const { result, error } = response;

        if (error) {
          throw error;
        }

        setRequestingPayment(false);

        if (!result || !result.paymentMethodData) {
          if (args.onCompleted) {
            args.onCompleted();
          }
          return;
        }

        const {
          tokenizationData: { token },
          info: cardInfo,
          description: displayName = '',
        } = result.paymentMethodData;

        if (!cardInfo) {
          throw new Error('Failed to get card info');
        }

        const parsedToken = parseStringifiedJSON({ value: token });
        const { signature, protocolVersion, signedMessage: data } = parsedToken;

        const {
          billingAddress: billingInformation = {
            locality: '',
            postalCode: '',
            administrativeArea: '',
            address1: '',
            countryCode: '',
          },
          cardNetwork: paymentType,
        } = cardInfo;

        const {
          locality,
          postalCode,
          administrativeArea: region,
          address1: streetAddress,
          countryCode: country,
        } = billingInformation;

        const formattedBillingAddress = buildFormattedBillingAddress({
          street: String(streetAddress),
          city: String(locality),
          state: String(region),
          postalCode: String(postalCode),
          country: String(country),
        });

        const billingAddress = {
          locality,
          postalCode: sanitizeAlphanumeric(postalCode),
          region,
          streetAddress,
        };

        const decoratedPaymentData: IGooglePayDetails = {
          signature,
          country: countryCode,
          data,
          formatted: formattedBillingAddress,
          primary: true,
          type: 'work',
          version: protocolVersion,
          billingAddress,
          paymentData: token,
          PaymentMethodData: {
            paymentType,
            displayName,
          },
        };

        if (args.onCompleted) {
          args.onCompleted(decoratedPaymentData);
        }
        return decoratedPaymentData;
      } catch (error) {
        setRequestingPayment(false);
        if (args.onError) {
          args.onError(error as string);
        }
        logger.error({
          message: 'Device Google Payment Failed',
          error: (error as Error)?.message,
        });
        return Promise.reject(error);
      }
    },
    [args, environment, networks]
  );

  return {
    googlePayCardDetails,
    requestingPayment,
    requestGooglePayPayment,
  };
}
