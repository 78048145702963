import { RouteProps } from 'react-router';

import { LazyRoute, lazyWithFallback } from 'components/layout/util';
import { routes as globalRoutes } from 'navigation/routing';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';

const SupportV2CategoriesPage: LazyRoute = lazyWithFallback(() =>
  import('pages/support-v2/support-v2-categories-page')
);
const SupportV2CategoryPage: LazyRoute = lazyWithFallback(() =>
  import('pages/support-v2/support-v2-category-page')
);
const SupportV2FormPage: LazyRoute = lazyWithFallback(() =>
  import('pages/support-v2/support-v2-form-page')
);
const SupportV2OrdersListPage: LazyRoute = lazyWithFallback(() =>
  import('pages/support-v2/support-v2-orders-list-page')
);
export function useSupportV2Routes() {
  const enablePypestream = useFlag(LaunchDarklyFlag.ENABLE_PYPESTREAM);
  const routes: Pick<RouteProps, 'element' | 'path'>[] = [];

  if (!enablePypestream) {
    // general support requests
    routes.push({ path: globalRoutes.supportForm, element: <SupportV2FormPage /> });
    routes.push({ path: globalRoutes.supportOrders, element: <SupportV2OrdersListPage /> });
    routes.push({ path: globalRoutes.supportCategories, element: <SupportV2CategoriesPage /> });
    routes.push({ path: globalRoutes.supportCategory, element: <SupportV2CategoryPage /> });
    routes.push({ path: globalRoutes.support, element: <SupportV2CategoriesPage /> });
  }

  return routes;
}
