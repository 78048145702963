import { FC, PropsWithChildren, useCallback, useMemo, useState } from 'react';

import { ContentCard } from '@braze/react-native-sdk';
import { ScrollView } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import Dialog from 'components/dialog';
import { termsToSanityBlockContent } from 'components/features/components/braze-marketing-card/utils';
import useTerms from 'components/features/components/terms/terms.hook';
import SanityBlockRenderer from 'components/sanity-block-renderer';
import useBrazeContext from 'state/braze/hooks/use-braze-context';
import { CONTENT_CARD_SETTING, ContentCardTypes } from 'state/braze/types';
import { actions, useAppDispatch } from 'state/global-state';

import {
  ButtonContainer,
  CaptionedCardContainer,
  CaptionedCardContent,
  CaptionedCardImage,
  CardButton,
  CardDescription,
  CardTitle,
  ContentCardContainer,
  StyledLink,
} from './styled';
import { useContentCardNavigation } from './use-content-card-navigation';

type CaptionedContentCardProps = {
  card: ContentCard & {
    type: typeof ContentCardTypes.CAPTIONED;
  };
};

export const CaptionedContentCard: FC<PropsWithChildren<CaptionedContentCardProps>> = ({
  card,
}) => {
  const { logContentCardClicked } = useBrazeContext();
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const { navigate } = useContentCardNavigation();
  const [showTermsModal, setShowTermsModal] = useState(false);

  const { extras = {} } = card;

  const isChallenge = useMemo(() => extras[CONTENT_CARD_SETTING.campaign] === 'true', [extras]);
  const cardTerms = useMemo(() => extras[CONTENT_CARD_SETTING.terms] || '', [extras]);

  // Fallback to "terms and conditions" localized
  const termsAccessibilityLabel = formatMessage(
    { id: 'termsAccessibilityLabel' },
    { cardTitle: card.title }
  );
  const termsButtonText =
    extras[CONTENT_CARD_SETTING.termsButtonActionText] ?? formatMessage({ id: 'termsConditions' });
  const termsButtonUrl = extras[CONTENT_CARD_SETTING.termsButtonActionUrl] || '';
  const hasTerms = !!cardTerms || !!termsButtonUrl;

  const handleClickCta = useCallback(() => {
    logContentCardClicked(card);
    !!card.url && navigate({ url: card.url });
    dispatch(actions.loyalty.closeInbox());
  }, [card, dispatch, logContentCardClicked, navigate]);

  const onPressTermsLink = useTerms({
    onShowTermsModal: () => setShowTermsModal(true),
    termsButtonText,
    termsButtonUrl,
    termsText: termsToSanityBlockContent(cardTerms),
  });

  const handlePressTermsLink = () => {
    if (!termsButtonUrl) {
      onPressTermsLink();
    } else {
      dispatch(actions.loyalty.closeInbox());
    }
  };

  return (
    <ContentCardContainer>
      <CaptionedCardContainer>
        <CaptionedCardImage
          alt={card.title}
          source={{ uri: card.image }}
          style={{ aspectRatio: card.imageAspectRatio }}
        />
        <CaptionedCardContent>
          <CardTitle>{card.title}</CardTitle>
          <CardDescription>{card.cardDescription}</CardDescription>
          <ButtonContainer>
            {!!card.url && (
              <CardButton onPress={handleClickCta} isChallenge={isChallenge}>
                {card.domain}
              </CardButton>
            )}
            {hasTerms && (
              <StyledLink
                accessibilityRole="link"
                accessibilityLabel={termsAccessibilityLabel}
                linkPath={termsButtonUrl}
                onPress={handlePressTermsLink}
              >
                {termsButtonText}
              </StyledLink>
            )}
          </ButtonContainer>
        </CaptionedCardContent>
      </CaptionedCardContainer>
      <Dialog
        heading={termsButtonText}
        bodyComponent={
          <ScrollView showsVerticalScrollIndicator={false}>
            <SanityBlockRenderer content={cardTerms ? termsToSanityBlockContent(cardTerms) : []} />
          </ScrollView>
        }
        showDialog={showTermsModal}
        showCloseButton
        onDismiss={() => setShowTermsModal(false)}
        modalAppearanceEventMessage="Terms and Conditions"
      />
    </ContentCardContainer>
  );
};
