import { FC, useEffect } from 'react';

import { LayoutContainer } from 'components/layout/styled';
import { Logo } from 'components/logo';
import { useNavigation } from 'hooks/navigation/use-navigation';
import { useAuthContext } from 'state/auth';
import { routes } from 'utils/routing';

import { useLoyaltyUserState } from '../../../../../state/loyalty/hooks/use-loyalty-user-state';
import { isActiveWalmartUser } from '../../../../../state/loyalty/hooks/utils/walmart';
import { StyledLoadingAnimation } from '../../../loyalty-history/loyalty-history.styled';
import { WalmartFooter } from '../walmart-footer';
import { WalmartHeader } from '../walmart-header';

import { StyledBox, StyledLogo } from './styled';

export const WalmartRedirect: FC<React.PropsWithChildren<{}>> = () => {
  const { isAuthenticated, loading } = useAuthContext();
  const { navigate } = useNavigation();
  const { loyaltyUser, loading: loyaltyLoading } = useLoyaltyUserState({ refreshOnMount: true });

  useEffect(() => {
    const isAlreadyLinked = isActiveWalmartUser(loyaltyUser);
    if (!loading && !loyaltyLoading) {
      if (!isAuthenticated || !isAlreadyLinked) {
        navigate(`${routes.walmart}?type=distro`);
      } else {
        navigate(routes.loyaltyOfferList);
      }
    }
  }, [isAuthenticated, loading, loyaltyLoading, loyaltyUser, navigate]);

  return (
    <LayoutContainer isFullContainer={true}>
      <WalmartHeader disabled />
      <StyledBox>
        <StyledLogo>
          <Logo />
        </StyledLogo>
        <StyledLoadingAnimation />
      </StyledBox>
      <WalmartFooter disabled />
    </LayoutContainer>
  );
};
