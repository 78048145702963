import { ISanityImage } from '@rbi-ctg/menu';
import {
  EntryTypeEnum,
  IEntryReferenceDto,
  IMenuEntryDto,
  IPriceDto,
  ServiceMode,
} from 'generated/graphql-gateway';

import {
  ILocalizedMenu,
  ILocalizedMenuEntry,
  IMainMenuNode,
  IndexedMenu,
  MenuItemType,
  PosServiceMode,
} from './types';

export function createIndexedMenu(menu: ILocalizedMenu): IndexedMenu {
  const map = new Map<string, IMenuEntryDto>();

  menu.entries.forEach(entry => map.set(entry.id, entry));

  Object.entries(mapLoyaltyEntitiesToMenuEntryDto(menu.systemWideOffers)).forEach(([id, value]) =>
    map.set(id, value)
  );
  Object.entries(mapLoyaltyEntitiesToMenuEntryDto(menu.configOffers)).forEach(([id, value]) =>
    map.set(id, value)
  );
  Object.entries(mapLoyaltyEntitiesToMenuEntryDto(menu.rewards)).forEach(([id, value]) =>
    map.set(id, value)
  );

  const indexedMenu = new IndexedMenu(map);
  return indexedMenu;
}

export interface IMenuQueryOptions {
  posServiceMode: PosServiceMode;
}

export interface ICreateMainMenuOptions extends IMenuQueryOptions {
  dayPartIds: string[];
}

/**
 * Creates the main menu hierarchy of sections and products
 * Menu
 *  ├── Meals                     (Section)
 *  │   └── Whopper Meals         (Product)
 *  ├── Flame Grilled Burgers     (Section)
 *  │   └── Double Whopper        (Product)
 *  ├── Chicken & Fish            (Section)
 *  │   └── Royal Crispy Chicken   (Product)
 * @param menu The menu to create the hierarchy from
 * @param rootId The id of the root menu entry
 * @param options Options for creating the main menu
 * @returns The main menu hierarchy
 */
export function createMainMenuNode(
  menu: IndexedMenu,
  rootId: string,
  options: ICreateMainMenuOptions
): IMainMenuNode | undefined {
  const entry = menu.get(rootId);
  if (!entry) {
    return undefined;
  }

  if (!isEntryAvailable(entry, options.posServiceMode, options.dayPartIds)) {
    return undefined;
  }

  const node: IMainMenuNode = {
    id: entry.id,
    type: getMainMenuNodeType(entry.type),
    name: entry.name?.locale ?? entry.id,
    image: {
      resource: mapImageResourceToSanityImage(entry.image?.resource ?? ''),
      description: entry.image?.altText?.locale ?? '',
    },
    children: [],
    product: entry.price
      ? {
          price: getEntryPriceOrZero(entry, options.posServiceMode),
          calories: entry.nutrition?.calories?.def ?? entry.nutrition?.calories?.min ?? 0,
        }
      : undefined,
  };

  if (entry.type === EntryTypeEnum.SECTION || entry.type === EntryTypeEnum.MENU) {
    entry.options?.entries?.forEach(option => {
      const child = createMainMenuNode(menu, option.entryId, options);
      if (child) {
        node.children.push(child);
      }
    });

    // If there are no entities in the section, don't include it
    if (node.children.length === 0) {
      return undefined;
    }
  }

  return node;
}

export function getPosServiceMode(serviceMode: ServiceMode): PosServiceMode {
  return serviceMode === ServiceMode.DELIVERY ? 'delivery' : 'pickup';
}

export function getAvailableOptions(
  entryReferences: IEntryReferenceDto[] | undefined | null | Readonly<IEntryReferenceDto[]>,
  posServiceMode: PosServiceMode,
  dayPartIds: string[] | undefined
): IEntryReferenceDto[] {
  return entryReferences?.filter(ref => isEntryAvailable(ref, posServiceMode, dayPartIds)) ?? [];
}

/**
 * Returns the available options for a menu entry, optionally mapping and filtering them to a different type.
 * The map function can return undefined to exclude items.
 * @param menu Indexed menu to look up related entries
 * @param posServiceMode POS service mode to check availability
 * @param entryReferences References to entries to map
 * @param mapFn Function to map entry references to a different type
 * @returns Available options mapped and filtered by the map function
 */
export function mapAvailableOptions<T>(
  menu: IndexedMenu,
  posServiceMode: PosServiceMode,
  entryReferences: IEntryReferenceDto[] | undefined | null | Readonly<IEntryReferenceDto[]>,
  mapFn: (entryReference: IEntryReferenceDto) => T | undefined
): T[] {
  if (!entryReferences) {
    return [];
  }

  const result: T[] = [];
  for (const ref of entryReferences) {
    if (!isEntryAvailable(ref, posServiceMode, undefined)) {
      continue;
    }

    const entry = menu.get(ref.entryId);
    if (!isEntryAvailable(entry, posServiceMode, undefined)) {
      continue;
    }

    const mapped = mapFn(ref);
    if (mapped) {
      result.push(mapped);
    }
  }
  return result;
}

export function isEntryAvailable(
  entry: ILocalizedMenuEntry | IEntryReferenceDto,
  posServiceMode: PosServiceMode,
  dayPartIds: string[] | undefined
): boolean {
  // Day part check. Assumes no dayparts means it's available all day
  if (
    'dayParts' in entry &&
    entry.dayParts &&
    dayPartIds &&
    !dayPartIds.some(n => entry.dayParts!.includes(n))
  ) {
    return false;
  }

  // Entry availability check. Assumes lack of availability means it's available
  if (entry.availability && entry.availability[posServiceMode] === false) {
    return false;
  }

  return true;
}

/**
 * Gets the product associated with the picker selections or defaults
 * @param menu Indexed menu to look up related entries
 * @param picker to traverse
 * @param selections to use in picker
 * @returns The product associated with the picker selections
 */
export function getPickerProduct(
  menu: IndexedMenu,
  picker: ILocalizedMenuEntry,
  selections: Record<string, string>
) {
  if (picker.type !== EntryTypeEnum.PICKER) {
    throw new Error('Entry is not a picker');
  }

  let aspectId = picker.options!.entries![0].entryId;
  while (true) {
    // This loop traverses picker aspects and aspect options based on selections
    // until a product (combo or item) is found.

    const aspect = menu.get(aspectId, { type: EntryTypeEnum.PICKER_ASPECT });

    if (!aspect.options?.entries?.length) {
      throw new Error(`Aspect picker "${aspectId}" has no aspect options`);
    }

    // Assign aspect option default or first option, then reassign if it's in selections
    let aspectOptionId = aspect.options?.defaults?.[0] ?? aspect.options.entries[0].entryId;

    const selectedAspectOptionId = selections[aspectId];
    if (
      selectedAspectOptionId &&
      aspect.options.entries.find(option => option.entryId === selectedAspectOptionId)
    ) {
      aspectOptionId = selectedAspectOptionId;
    }

    const aspectOption = menu.get(aspectOptionId, { type: EntryTypeEnum.ASPECT_OPTION });

    if (!aspectOption.options?.entries?.[0].entryId) {
      throw new Error(`Aspect option "${aspectOptionId}" has no options`);
    }

    const aspectOrProductId = aspectOption.options.entries[0].entryId;
    const aspectOrProduct = menu.get(aspectOrProductId);

    if (isItem(aspectOrProduct) || isCombo(aspectOrProduct)) {
      // we have found our product
      return aspectOrProduct;
    }

    // continue to the next aspect
    aspectId = aspectOrProductId;
  }
}

export function isCombo(entry: ILocalizedMenuEntry | string): boolean {
  return (
    normalizeType(typeof entry === 'string' ? entry : entry.type) ===
    normalizeType(EntryTypeEnum.COMBO)
  );
}

export function isComboSlot(entry: ILocalizedMenuEntry | string): boolean {
  return (
    normalizeType(typeof entry === 'string' ? entry : entry.type) ===
    normalizeType(EntryTypeEnum.COMBO_SLOT)
  );
}

export function isSlotOption(entry: ILocalizedMenuEntry): boolean {
  return normalizeType(entry.type) === normalizeType(EntryTypeEnum.SLOT_OPTION);
}

export function isItem(entry: ILocalizedMenuEntry): boolean {
  return entry.type === EntryTypeEnum.ITEM;
}

export function isModifier(entry: ILocalizedMenuEntry): boolean {
  return entry.type === EntryTypeEnum.MODIFIER;
}

export function isPicker(entry: ILocalizedMenuEntry): boolean {
  return entry.type === EntryTypeEnum.PICKER;
}

export function isPickerAspect(entry: ILocalizedMenuEntry): boolean {
  return entry.type === EntryTypeEnum.PICKER_ASPECT;
}

export function isAspectOption(entry: ILocalizedMenuEntry): boolean {
  return entry.type === EntryTypeEnum.ASPECT_OPTION;
}

export const normalizeType = (type?: string) => type?.replace(/[\W_]+/g, '').toUpperCase();

export function hasOptionEntries(
  menu: IndexedMenu,
  posServiceMode: PosServiceMode,
  entry: ILocalizedMenuEntry
): entry is ILocalizedMenuEntry & { options: { entries: IEntryReferenceDto[] } } {
  if (!entry.options?.entries?.length) {
    return false;
  }

  for (const option of entry.options.entries) {
    if (isEntryAvailable(option, posServiceMode, undefined)) {
      return true;
    }
  }

  return false;
}

/**
 * Returns value from possible null chain or throws
 * @example
 * const value = unwrap(obj?.prop?.value, 'Value is required');
 */
export function unwrap<T>(value: T | undefined | null, error: string): T {
  if (value !== undefined && value !== null) {
    return value;
  }
  throw new Error(error);
}

export function mapImageResourceToSanityImage(resource: string, altText?: string): ISanityImage {
  return {
    locale: altText && {
      imageDescription: altText,
    },
    asset: {
      _id: resource,
    },
  } as ISanityImage;
}

export function stringifySanityImage(
  image: ILocalizedMenuEntry['image'] | undefined
): string | undefined {
  return image?.resource
    ? JSON.stringify(mapImageResourceToSanityImage(image.resource))
    : undefined;
}

export const getMainMenuNodeType = (type: EntryTypeEnum): MenuItemType => {
  switch (type) {
    case EntryTypeEnum.PICKER:
      return EntryTypeEnum.PICKER;
    case EntryTypeEnum.SECTION:
      return EntryTypeEnum.SECTION;
    case EntryTypeEnum.COMBO:
      return EntryTypeEnum.COMBO;
    default:
      return EntryTypeEnum.ITEM;
  }
};

/**
 * Gets the first default id for a menu entry
 */
export function getFirstDefaultId(
  entry: ILocalizedMenuEntry,
  fallbackToFirstOption: boolean = true
): string {
  let defaultId = entry.options?.defaults?.[0];

  if (!defaultId && fallbackToFirstOption) {
    defaultId = entry.options?.entries?.[0]?.entryId;
  }

  if (!defaultId) {
    throw new Error(`No default found for ${entry.id}`);
  }

  return defaultId;
}

/**
 * Gets the first option id for a menu entry or throws if none found
 */
export function getFirstOption(entry: ILocalizedMenuEntry): IEntryReferenceDto {
  const firstOption = entry.options?.entries?.[0];
  if (!firstOption) {
    throw new Error(`No options found for ${entry.id}`);
  }
  return firstOption;
}

/**
 * Returns the price for a menu entry in a given service mode and context or zero if no price is found
 * @param entry - entry to price
 * @param posServiceMode - service mode to get the price for
 * @param contextualPrice - contextual price to use if available. Ex: combo price.
 * @returns price for the entry
 */
export function getEntryPriceOrZero(
  entry: ILocalizedMenuEntry,
  posServiceMode: PosServiceMode,
  contextualPrice?: IPriceDto
): number {
  return contextualPrice?.[posServiceMode] ?? entry.price?.[posServiceMode] ?? 0;
}

/**
 * Returns the price for a menu entry in a given service mode or undefined if no price is found
 * @param entry - entry to price
 * @param posServiceMode - service mode to get the price for
 * @param contextualPrice - contextual price to use if available. Ex: combo price.
 * @returns price for the entry
 */
export function getEntryPrice(
  entry: ILocalizedMenuEntry,
  posServiceMode: PosServiceMode,
  contextualPrice?: IPriceDto
): number | undefined {
  return contextualPrice?.[posServiceMode] ?? entry.price?.[posServiceMode] ?? undefined;
}

/**
 * Returns the slot option for a given item id in a combo slot.
 * @param indexedMenu - indexed menu to look up related entries
 * @param comboSlotId - combo slot id to look up
 * @param itemId - item id to look up
 * @returns slot option for the item in the combo slot
 */
export function getSlotOptionByItemId(
  indexedMenu: IndexedMenu,
  comboSlotId: string,
  itemId: string
): ILocalizedMenuEntry {
  const comboSlot = indexedMenu.get(comboSlotId, { type: EntryTypeEnum.COMBO_SLOT });
  for (const slotOptionRef of comboSlot.options?.entries ?? []) {
    const slotOption = indexedMenu.get(slotOptionRef.entryId, { type: EntryTypeEnum.SLOT_OPTION });
    // a SLOT_OPTION has a single option which is an ITEM
    const itemRef = getFirstOption(slotOption);
    if (itemRef.entryId === itemId) {
      return slotOption;
    }
  }

  throw new Error(`Slot option for item ${itemId} not found in combo slot ${comboSlotId}`);
}

/**
 * Returns the price for an item in a combo slot taking
 * into account contextual pricing.
 * @param indexedMenu - indexed menu to look up related entries
 * @param comboSlotId - combo slot id to look up
 * @param itemId - item id to look up otherwise combo slot default is used
 * @returns slot option for the item id or default in the combo slot
 */
export function getComboSlotItemPrice(
  indexedMenu: IndexedMenu,
  comboSlotId: string,
  itemId: string | undefined
): IPriceDto | undefined {
  const comboSlot = indexedMenu.get(comboSlotId, { type: EntryTypeEnum.COMBO_SLOT });

  let itemRef = itemId
    ? comboSlot.options?.entries?.find(n => n.entryId === itemId)
    : getFirstOption(comboSlot);

  itemRef = itemRef ?? getFirstOption(comboSlot);

  if (itemRef.price) {
    return itemRef.price;
  }

  const item = indexedMenu.get(itemRef.entryId);
  return item.price ?? undefined;
}

export type Typenameless<T> = Omit<T, '__typename'>;

export function getKeysWithoutTypename<T extends {}>(object: T): (keyof Typenameless<T>)[] {
  var keys = Object.keys(object).filter(key => key !== '__typename');
  return keys as any;
}

function emptyEntryReference(entryId: string): IEntryReferenceDto {
  return {
    entryId,
    price: {
      __typename: 'PriceDto',
      pickup: 0,
      delivery: 0,
    },
    __typename: 'EntryReferenceDto',
  };
}

function mapLoyaltyEntitiesToMenuEntryDto(
  loyaltyEntities:
    | ILocalizedMenu['systemWideOffers']
    | ILocalizedMenu['rewards']
    | ILocalizedMenu['configOffers']
): Record<string, IMenuEntryDto> {
  const mappedEntities: Record<string, IMenuEntryDto> = {};

  const incentivesMap = new Map<string, string>(
    (loyaltyEntities ?? [])
      .filter(entity => entity.loyaltyEngineId)
      .flatMap(offer => offer.options?.map(incentive => [incentive.entryId, offer.id]) ?? [])
  );

  (loyaltyEntities ?? [])
    .filter(entity => !entity.loyaltyEngineId)
    .forEach(loyaltyEntity => {
      const parentLoyaltyEntityId = incentivesMap.get(loyaltyEntity.id);
      if (parentLoyaltyEntityId) {
        const comboSlotIds: string[] = [];

        (loyaltyEntity.options ?? []).forEach((option, index) => {
          if (option.type === 'ENTRY' && option.isMainItem) {
            const manuallyCreatedComboSlotId = `${loyaltyEntity.id}_comboSlot_${index}`;
            mappedEntities[manuallyCreatedComboSlotId] = {
              id: manuallyCreatedComboSlotId,
              options: {
                entries: [emptyEntryReference(option.entryId)],
              },
              type: EntryTypeEnum.COMBO_SLOT,
              __typename: 'MenuEntryDto',
            } as IMenuEntryDto;
            comboSlotIds.push(manuallyCreatedComboSlotId);
          } else {
            comboSlotIds.push(option.entryId);
          }
        });

        const manuallyCreatedMenuEntry: IMenuEntryDto = {
          ...loyaltyEntity,
          id: loyaltyEntity.id,
          options: {
            entries: comboSlotIds.map(emptyEntryReference),
          },
          type: EntryTypeEnum.COMBO,
          __typename: 'MenuEntryDto',
        };

        mappedEntities[parentLoyaltyEntityId] = manuallyCreatedMenuEntry;
        mappedEntities[loyaltyEntity.id] = {
          ...manuallyCreatedMenuEntry,
          __parentLoyaltyEntityId: parentLoyaltyEntityId,
        } as IMenuEntryDto;
      } else {
        mappedEntities[loyaltyEntity.id] = {
          ...loyaltyEntity,
          id: loyaltyEntity.id,
          options: {
            entries: (loyaltyEntity.options ?? []).map(({ entryId }) =>
              emptyEntryReference(entryId)
            ),
          },
          type: EntryTypeEnum.COMBO_SLOT,
          __typename: 'MenuEntryDto',
        } as IMenuEntryDto;
      }
    });

  return mappedEntities;
}
