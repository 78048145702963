import { useIntl } from 'react-intl';

import { useRoute } from 'navigation/hooks';
import { routes } from 'utils/routing';

// TODO: It'd be great to center each page title similarly to this. Remove all the helmet implementation and move
// This approach helps document the need to centralize and link a route path with a title.
// That way, we can ensure every future route path has a more descriptive title.
export const usePageTitle = () => {
  const { formatMessage } = useIntl();
  const { pathname } = useRoute();

  const pageTitles = {
    [routes.loyaltyOfferList]: formatMessage({ id: 'offers' }),
    [routes.rewards]: formatMessage({ id: 'rewards' }),
    [routes.storeLocator]: formatMessage({ id: 'storeLocator' }),
    [routes.base]: formatMessage({ id: 'home' }),
  } as const;

  if (!pathname || !(pathname in pageTitles)) {
    return null;
  }

  return pageTitles[pathname];
};
