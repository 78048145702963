import { useRef } from 'react';

import { useRoute } from 'hooks/navigation/use-route';
import { useIsDesktopBreakpoint } from 'hooks/use-media-query';
import { relativeRoutes } from 'navigation/routing';
import { isSectionRoute } from 'navigation/routing/utils';
import { routes } from 'utils/routing';

const routesForTopServiceModeDisplay = [
  { route: routes.base, exact: true },
  { route: `${routes.menu}/${relativeRoutes.recentItems}`, exact: true },
  { route: routes.favorites, exact: true },
  { route: routes.menu, exact: false },
  { route: routes.orders, exact: false },
  { route: routes.offers, exact: false },
  { route: routes.redeem, exact: false },
  { route: routes.loyaltyRewardsList, exact: false },
  { route: routes.loyaltyOfferList, exact: false },
  { route: routes.loyaltyRewardsDashboard, exact: false },
];

export const useRoutesDisplayBottomServiceNotification = (): boolean => {
  const resolutionMap = useRef(new Map());

  const isDesktop = useIsDesktopBreakpoint();
  const { pathname } = useRoute();

  const routeKey = `${pathname}-${isDesktop}`;

  if (!resolutionMap.current.has(routeKey)) {
    const shouldShowFromRoutesForServiceMode = routesForTopServiceModeDisplay.some(
      ({ route, exact }) => {
        if (!isDesktop && route === routes.menu) {
          // when not on desktop, only show TSM on base menu or section routes, not item / combo / picker
          return pathname === routes.menu || isSectionRoute(pathname);
        }

        return exact ? pathname === route : pathname.startsWith(route);
      }
    );
    resolutionMap.current.set(routeKey, shouldShowFromRoutesForServiceMode);
  }

  return resolutionMap.current.get(routeKey);
};
