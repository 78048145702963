import React, { useContext, useState } from 'react';

// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'react... Remove this comment to see the full error message
import createDOMProps from 'react-native-web/dist/modules/createDOMProps';

import { IBaseProps } from '@rbi-ctg/frontend';
import { usePriorValue } from 'hooks/use-prior-value';
import { useMemoAll } from 'utils/use-memo-all';

import { Location, Link as RRLink, useLocation } from '../routing/router';
import { ILocation } from '../types';

/**
 * Useful for preventing re-renders for analytics/loggers etc. that care about routes but not real-time
 * DO NOT USE FOR RENDER-TIME LOCATION INFO as this will not trigger re-renders
 */
export const staticLocation: { current: Location } = {
  // should be set very quick at app start but just in case...
  current: { hash: '', key: '', pathname: '', search: '', state: '' },
};

export const staticReferrerLocation: { current: string | undefined } = {
  // should be set very quick at app start but just in case...
  current: '/',
};

export const getStaticLocationString = () => {
  const loc = staticLocation.current;
  return `${loc.pathname}${loc.search || ''}${loc.hash || ''}`;
};

export { useMatch, useParams, useSearchParams, Routes, Route } from '../routing/router';

export const Link = (props: any) => {
  const domProps = createDOMProps('a', props);

  return <RRLink {...domProps} />;
};

export const LocationContext = React.createContext<ILocation>({} as ILocation);
export const useLocationContext = () => useContext(LocationContext);

export function LocationProvider(props: IBaseProps) {
  const [storeLocatorCallbackUrl, setStoreLocatorCallbackUrl] = useState('');
  const [addPaymentCallbackUrl, setAddPaymentCallbackUrl] = useState('');

  const [signUpEmail, setSignUpEmail] = useState('');
  const location = useLocation();
  staticLocation.current = location;
  const referrerLocation = usePriorValue(location.pathname + location.search, undefined);
  staticReferrerLocation.current = referrerLocation;

  const value: ILocation = useMemoAll({
    signUpEmail,
    referrerLocation,
    setSignUpEmail,
    setStoreLocatorCallbackUrl,
    storeLocatorCallbackUrl,
    addPaymentCallbackUrl,
    setAddPaymentCallbackUrl,
  });

  return <LocationContext.Provider value={value}>{props.children}</LocationContext.Provider>;
}

export const LocationConsumer = LocationContext.Consumer;
