import React, { ElementRef, FC, createContext, useContext, useEffect, useRef } from 'react';

import { FlatList, View, findNodeHandle } from 'react-native';

import noop from 'utils/noop';

export type AnchorScrollContainerRefType = ElementRef<typeof FlatList>;
export type AnchorComponentRefType = View;

interface AnchorContextType {
  registerContainerRef: (container: AnchorScrollContainerRefType) => void;
  registerTarget: (name: string, ref: AnchorComponentRefType) => AnchorComponentRefType;
  scrollToTarget: (name: string, animated?: boolean) => void;
  formatLocaleAnchorName: (anchorName: string) => string;
}

export const AnchorContext = createContext<AnchorContextType>(null!);

export const AnchorContextProvider: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const scrollContainer = useRef<AnchorScrollContainerRefType>();
  const targets = useRef<Record<string, AnchorComponentRefType>>({});
  const registerContainerRef = (container: AnchorScrollContainerRefType) => {
    scrollContainer.current = container;
  };

  const registerTarget = (anchorName: string, ref: AnchorComponentRefType) => {
    targets.current = {
      ...targets.current,
      [anchorName]: ref,
    };
    return ref;
  };

  const formatLocaleAnchorName = (anchorName: string) => anchorName.replace(/\s/g, '');

  const scrollTo = (target: AnchorComponentRefType, animated: boolean = false) => {
    if (scrollContainer.current) {
      const refHandle = findNodeHandle(scrollContainer.current);
      if (refHandle) {
        target.measureLayout(
          refHandle,
          (_, y) => {
            scrollContainer.current!.scrollToOffset({ offset: y, animated });
          },
          noop // fail silently
        );
      }
    }
  };

  const timerId = React.useRef<ReturnType<typeof requestAnimationFrame>>();
  const scrollToOffscreenElement = async (anchorName: string, animated: boolean) => {
    const waitForAnchor = async (): Promise<AnchorComponentRefType | void> => {
      if (timerId.current) {
        cancelAnimationFrame(timerId.current);
      }
      const element = Object.entries(targets.current).find(([key]) => key === anchorName);
      if (element) {
        // element is now mounted
        scrollTo(element[1], animated);
        return element[1];
      }
      scrollContainer.current?.scrollToEnd({ animated });
      timerId.current = requestAnimationFrame(waitForAnchor);
      // @TODO case when the anchor is never found causing an infinite loop.
      // The timer will still be cleared on unMount but undefined behaviour or performance degradation
      // may arise in this case
    };
    return waitForAnchor();
  };
  useEffect(() => {
    // cleanup scroll anchor timer incase anchor is never found or user navigates causing an unmount
    return () => {
      if (timerId.current) {
        cancelAnimationFrame(timerId.current);
      }
    };
  }, []);

  const scrollToTarget = async (anchorName: string, animated: boolean = false) => {
    const formattedAnchorName = formatLocaleAnchorName(anchorName);
    const target = targets.current[formattedAnchorName];
    if (!target) {
      // no target found in view, scroll down more to find it so those anchor refs will be mounted
      await scrollToOffscreenElement(formattedAnchorName, animated);
      return;
    }
    scrollTo(target);
  };

  return (
    <AnchorContext.Provider
      value={{
        registerContainerRef,
        registerTarget,
        scrollToTarget,
        formatLocaleAnchorName,
      }}
    >
      {children}
    </AnchorContext.Provider>
  );
};

export const useAnchorContext = () => useContext(AnchorContext);
