import { useAuthContext } from 'state/auth';
import { selectors, useAppSelector } from 'state/global-state';

import type { LoyaltyUser } from './types';

/** if you just need the ID use useLoyaltyUserId instead */
export const useLoyaltyUser = (): { loyaltyUser: LoyaltyUser | null; loading: boolean } =>
  useAppSelector(selectors.loyalty.selectLoyaltyUser);

/** faster way to get just loyaltyUserId */
export const useLoyaltyUserId = () => {
  const { user, loading } = useAuthContext();
  return { loyaltyUserId: user?.loyaltyId, loading };
};
