module.exports = {
  // versions have a max of 99

  APP_VERSION_MAJOR: 7,

  APP_VERSION_MINOR: 45,

  // NORMALLY ONLY FOR OTA USE - should only be >0 for OTAs - increment this version for each OTA
  // (but see WARNING / SPECIAL CIRCUMSTANCES below)
  APP_VERSION_PATCH: 0,

  // 0 is NORMAL HERE DO NOT CHANGE WITHOUT READING BELOW SPECIAL CIRCUMSTANCES
  APP_NATIVE_RUNTIME_PATCH: 0,

  // SPECIAL CIRCUMSTANCES - if we need to do a binary runtime (app store) release for example as 7.27.1 (that isn't an OTA)
  //  set the APP_NATIVE_RUNTIME_PATCH to 1
  //  this will ensure that any OTAs on the 7.27.1 ex 7.27.2... line will use the correct native runtime of 7.27.1
  //
  // WARNING - when using OTAs make sure no native runtime binary changes have been made on OTAs that could cause crashes
  //  see https://docs.expo.dev/eas-update/runtime-versions/#avoiding-crashes-with-incompatible-updates
  //  FOR EXAMPLE:
  //    Given a binary runtime (app store) release of 7.27.0...
  //    OTAs can be released at 7.27.1, 7.27.2 ... BUT no runtime binary changes should be made without
  //    creating a new binary runtime app store release of example 7.28.0 or using APP_NATIVE_RUNTIME_PATCH
  //    to correctly target the minimum required native runtime for changes
};
