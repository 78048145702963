import { IBaseItem, ICartEntry } from '@rbi-ctg/menu';
import { IProductHierarchy } from '@rbi-ctg/menu';
import { IRestaurant } from '@rbi-ctg/store';
import { ICmsQuestCard } from 'components/quests/cms-hooks/types';
import { IQuest } from 'components/quests/loyalty-quest/types';
import {
  ICartEntryFragment,
  LoyaltyPromotionType,
  LoyaltyQuestIncentiveType,
} from 'generated/graphql-gateway';
import { ProviderType } from 'generated/graphql-gateway';
import { LoyaltyOffer, LoyaltyReward } from 'state/loyalty/types';
import { OTPAuthDeliveryMethod } from 'utils/otp';

import { AllowedEvent } from './allowed-events';
import {
  AuthenticationMethod,
  AuthenticationPhases,
  ClickEventComponentNames,
  CustomEventNames,
} from './constants';
import { IClickEvent } from './events';

export interface ILogEvent {
  (
    eventName: CustomEventNames | string,
    eventType: EventTypes,
    attributes?: object,
    customFlags?: object
  ): void;
}

export interface ILogRBIEvent {
  (event: AllowedEvent, logOptions?: { skipLoggingToBraze?: boolean }): void;
}

export type AllowedProducstEventNames =
  | CustomEventNames.UPSELL_DISPLAYED
  | CustomEventNames.E_COMMERCE_PURCHASE
  | CustomEventNames.E_COMMERCE_ADD_TO_CART
  | CustomEventNames.E_COMMERCE_REMOVE_FROM_CART
  | CustomEventNames.EDIT_CART
  | CustomEventNames.PRODUCT_FAVORITE_BUTTON_CLICKED;

export interface ILogRBIProductsEvent {
  name: AllowedProducstEventNames;
  products: ICartEntry[] | IBaseItem[] | ICartEntryLoggable[];
  price?: number;
  attributes?: Record<string, string | number | boolean | IAmplitudeProduct[] | Products>;
  logOptions?: { skipLoggingToBraze: boolean };
}

export interface IAuthenticationEventOptions {
  phase: AuthenticationPhases;
  message?: string;
  authenticationMethod?: AuthenticationMethod;
  socialAuthenticationType?: ProviderType;
  authenticationPath?: AuthenticationPath;
  otpMethod?: OTPAuthDeliveryMethod; // TODO: remove this once. Probably not needed.
}

export type AllowedLoyaltyEvent =
  | CustomEventNames.REWARD_SELECTED
  | CustomEventNames.OFFER_SELECTED
  | CustomEventNames.REWARD_DETAILS_VIEWED
  | CustomEventNames.OFFER_DETAILS_VIEWED
  | CustomEventNames.PROMO_CODE_APPLIED
  | CustomEventNames.OFFERS_DISPLAYED;

export interface ILogLoyaltyEvent {
  name: AllowedLoyaltyEvent;
  incentives: (LoyaltyOffer | LoyaltyReward)[];
  attributes?: {
    [key: string]: string | number | undefined | boolean | any[] | Record<string, any>;
  };
}

export type AllowedQuestEvent = CustomEventNames.QUEST_DETAILS_VIEW;

export interface ILogQuestEvent {
  name: AllowedQuestEvent;
  quest: IQuest;
  cmsQuestCard: ICmsQuestCard;
  attributes?: {
    [key: string]: string | number | undefined | boolean | any[] | Record<string, any>;
  };
}

export interface ILogButtonClickEvent {
  type?: IClickEvent['type'];
  attributes: Partial<IClickEvent['attributes']> & {
    component: ClickEventComponentNames;
    name: string;
    [key: string]: string | number | undefined | boolean | any[] | Record<string, any>;
  };
}

// booleans need to be stringified for amplitude
export interface IAmplitudeAttributes extends IUtmParamsAttributes {
  $FirstName?: string;
  $LastName?: string;
  $Zip?: string;
  $Age?: number;
  $Gender?: string;
  $City?: string;
  $State?: string;
  $Mobile?: string;
  brand?: string;
  region?: string;
  env?: string;
  'Date of Birth'?: string;
  'Email Opt In'?: string;
  favoriteStores?: string;
  'Join Date'?: string;
  language?: string;
  Locale?: string;
  'Legacy User'?: string;
  'Location Services'?: string;
  marketingEmail?: string;
  marketingPush?: string;
  orderStatus?: string;
  'RBI Cognito ID'?: string;
  rewardsEmail?: string;
  rewardsPush?: string;
  Timezone?: string;
  'Type Preference'?: string;
  'Snack Preference'?: string;
  'Size Preference'?: string;
  'Time Preference'?: string;
  'IOS Location Permissions'?: string;
  'Android Location Permissions'?: string;
  locationPermission?: string;
}

export enum ProductItemType {
  Parent = 'Parent',
  Child = 'Child',
}

export interface IAmplitudeUniversalAttributes extends IUtmParamsAttributes {
  'Service Mode': string;
  'Pickup Mode': string;
  'Source Page': string;
  isSmallScreen: boolean;
  isLoyaltyUser: boolean;
  currentBuild: string;
}

// Track the modification of item, combo slot and picker aspect.
// These values are initially false and is set to true if the user
// changes these values away from the initial state.
export interface IAddToCartSelectionAttributes {
  pickerAspectSelection: boolean;
  comboSlotSelection: boolean;
  itemModified: boolean;
}

export interface IUtmParams {
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_term?: string;
  utm_content?: string;
}

export interface IUtmParamsAttributes {
  'UTM Source'?: string;
  'UTM Medium'?: string;
  'UTM Campaign'?: string;
  'UTM Term'?: string;
  'UTM Content'?: string;
  utmSource?: string;
  utmMedium?: string;
  utmCampaign?: string;
  utmTerm?: string;
  utmContent?: string;
}

// booleans need to be stringified for amplitude
export interface IAmplitudeAttributes extends IUtmParamsAttributes {
  'Android Location Permissions'?: string;
  'App Tracking Status'?: string;
  'Date of Birth'?: string;
  'Email Opt In'?: string;
  'IOS Location Permissions'?: string;
  'Join Date'?: string;
  'Legacy User'?: string;
  'Location Services'?: string;
  'RBI Cognito ID'?: string;
  'Size Preference'?: string;
  'Snack Preference'?: string;
  'Time Preference'?: string;
  'Type Preference'?: string;
  $Age?: number;
  $City?: string;
  $Mobile?: string;
  $State?: string;
  $Zip?: string;
  Age?: number;
  brand?: string;
  env?: string;
  favoriteStores?: string;
  language?: string;
  Locale?: string;
  locationPermission?: string;
  marketingEmail?: string;
  marketingPush?: string;
  orderStatus?: string;
  region?: string;
  rewardsEmail?: string;
  rewardsPush?: string;
  Timezone?: string;
}

export type ExcludesNull = <T>(x: T | null) => x is T;

export interface IAmplitudeProduct {
  'Item Level': ProductItemType;
  cartId: string;
  comboChild: string;
  custom_attributes: { [key: string]: string | number | boolean };
  id: string;
  isDonation: string;
  isExtra: string;
  rewardItem?: string;
  L1?: string;
  L2?: string;
  L3?: string;
  L4?: string;
  L5?: string;
  L6?: string;
  name: string;
  price: number;
  quantity: number;
  sublevelItems: string;
  total_product_amount: number;
}

export interface IAmplitudeProductDetails {
  name?: string;
  sanityId: string;
  quantity?: number;
  price?: number;
  bkpn?: string | null;
  productType: string;
  parentName?: string;
  parentSanityId?: string;
  foodType?: string;
  category?: string;
  segment?: string;
  platform?: string;
  menuItemName?: string;
  isExtra?: boolean | string;
}

export interface ICartEntryLoggable extends ICartEntryFragment {
  children?: ICartEntryLoggable[];
  productHierarchy?: IProductHierarchy | null;
  productNumber?: string;
  cartId?: string;
}
export interface IAmplitudePurchaseEventAttributes {
  brand: string;
  env: string;
  region: string;
  'Card Type': string;
  'Cart Data': string;
  'CC Token': string | null;
  'Coupon Applied': string;
  'Coupon ID': string;
  'Device Time': string;
  'Has Upsell': boolean;
  'Is Kiosk': boolean;
  'Is Loyalty': boolean;
  'Payment Type': string;
  'Pickup Mode': string;
  'Product Count': number;
  'Restaurant Address': string | null;
  'Restaurant City': string | null;
  'Restaurant Country': string | null;
  'Restaurant Drink Station Type': string | null;
  'Restaurant Drive Thru Lane Type': string | null;
  'Restaurant Franchise Group Id': number | null;
  'Restaurant Franchise Group Name': string | null;
  'Restaurant Front Counter Closed': boolean | null;
  'Restaurant Has Breakfast': boolean | null;
  'Restaurant Has Burgers For Breakfast': boolean | null;
  'Restaurant Has Curbside': boolean | null;
  'Restaurant Has Dine In': boolean | null;
  'Restaurant Has Drive Thru': boolean | null;
  'Restaurant Has Mobile Order Drive Thru': boolean | null;
  'Restaurant Has Front Counter Closed': boolean | null;
  'Restaurant Has Home Delivery': boolean | null;
  'Restaurant Has Mobile Ordering': boolean | null;
  'Restaurant Has Parking': boolean | null;
  'Restaurant Has Playground': boolean | null;
  'Restaurant Has Take Out': boolean | null;
  'Restaurant Has Wifi': boolean | null;
  'Restaurant ID': string | null;
  'Restaurant Latitude': number | null;
  'Restaurant Longitude': number | null;
  'Restaurant Name': string | null;
  'Restaurant Number Drive Thru Windows': number;
  'Restaurant Number': string | null;
  'Restaurant Parking Type': string | null;
  'Restaurant Playground Type': string | null;
  'Restaurant POS': string | null;
  'Restaurant Postal Code': string | null;
  'Restaurant State/Province Name': string | null;
  'Restaurant Status': string | null;
  'Service Mode': string;
  'Source Page': string;
  'Tax Amount': number;
  'Timed Fire Minutes': string;
  'Total Amount': number;
  'Transaction Order Number ID': string;
  'Transaction POS': string;
  'Transaction RBI Cloud Order ID': string;
  'Upsell Total': string;
  'Value Threshold 10 Met'?: boolean;
  'Value Threshold 15 Met'?: boolean;
  'Value Threshold 20 Met'?: boolean;
  'Value Threshold 5 Met'?: boolean;
  baseDeliveryFeeAmount?: number;
  branch_service_mode: string;
  Currency: string;
  customer_event_alias: string;
  deliveryDiscountAmount?: number;
  deliveryFeeAmount?: number;
  deliveryGeographicalFeeAmount?: number;
  deliveryServiceFeeAmount?: number;
  deliverySmallCartFeeAmount?: number;
  deliverySurchargeFeeAmount?: number;
  quotedFeeAmount?: number;
  Rewards: string | null;
  roundUpAmount: number;
  totalDeliveryOrderFeeAmount?: number;
}

export interface IAmplitudeSublevelItem {
  id: string;
  quantity: number;
}

// Track the modification of item, combo slot and picker aspect.
// These values are initially false and is set to true if the user
// changes these values away from the initial state.
export interface IAddToCartSelectionAttributes {
  pickerAspectSelection: boolean;
  comboSlotSelection: boolean;
  itemModified: boolean;
}

export interface IAmplitudeLoyaltyDocument {
  name: string;
  sanityId: string;
  loyaltyEngineId: string;
  type: string;
  incentiveType: LoyaltyPromotionType | LoyaltyQuestIncentiveType;
  dayParts?: string[];
  engineId?: string; // same as loyaltyEngineId - kept for backwards compatibility
  incentiveSanityId?: string; // same as sanityId - kept for backwards compatibility
  questStartDate?: string;
  questEndDate?: string;
}

export enum EventTypes {
  Navigation = 1,
  Location = 2,
  Search = 3,
  Transaction = 4,
  UserContent = 5,
  UserPreference = 6,
  Social = 7,
  Other = 8,
}

export interface IGlobalAttributes {
  /** Current Screen the user is at (ie /cart/payment) */
  currentScreen: string;
  deviceTime: string;
  brand?: string;
  region?: string;
  env?: string;
  serviceMode?: string;
  pickupMode?: string;
  appBuild?: string;
  browserType?: string;
  browserVersion?: string;
  isMobileWeb?: string;
}

export interface IEventBase {
  name: string;
  type: EventTypes;
  globalAttributes?: IGlobalAttributes;
  attributes?: {
    [key: string]: string | number | undefined | boolean | any[] | Record<string, any>;
  } | null;
  customFlags?: { [key: string]: string | boolean | number };
}

export type Restaurant = {
  restaurantId: string;
  restaurantAddress: string;
  restaurantZip: string;
  restaurantCity: string;
  restaurantState: string;
  restaurantCountry: string;
  storeId?: string;
};

export type GoogleAnalyticsFlags = {
  'Google.Page': string;
  'Google.DocumentReferrer': string;
};

export type PageViewExtraAttributes = {
  pathname: string;
  sanityId: string;
  referrer: string;
};

export type Products = {
  products?: any[];
  productDetails?: IAmplitudeProductDetails[];
};

export interface ILogPageView {
  pathname: string;
  store: IRestaurant;
}

export enum AuthenticationPath {
  CONTEXTUAL = 'Contextual',
  CONVENTIONAL = 'Conventional',
}
