import { IDayPartDto } from 'generated/graphql-gateway';

const DAY_START = [
  'sunOpen',
  'monOpen',
  'tueOpen',
  'wedOpen',
  'thrOpen',
  'friOpen',
  'satOpen',
] as const;
const DAY_END = [
  'sunClose',
  'monClose',
  'tueClose',
  'wedClose',
  'thrClose',
  'friClose',
  'satClose',
] as const;

export function findActiveDayPartIds(
  dayParts: ReadonlyArray<IDayPartDto>,
  currentTime: Date
): string[] {
  const currDayOfWeek = currentTime.getDay();
  const currHour = currentTime.getHours();
  const currMinute = currentTime.getMinutes();

  const result: string[] = [];
  for (const dayPart of dayParts) {
    const open = dayPart.schedule[DAY_START[currDayOfWeek]];
    const close = dayPart.schedule[DAY_END[currDayOfWeek]];
    if (!open || !close) {
      continue;
    }

    const [openHour, openMinute] = parseTime(open);
    const [closeHour, closeMinute] = parseTime(close);
    if (isInRange(openHour, openMinute, closeHour, closeMinute, currHour, currMinute)) {
      result.push(dayPart.id);
      continue;
    }
  }

  return result;
}

function parseTime(open: string): [number, number] {
  const openHour = parseInt(open.substring(0, 2), 10);
  const openMinute = parseInt(open.substring(3, 5), 10);
  return [openHour, openMinute];
}

/**
 * Checks if the current time is within the given time range
 */
function isInRange(
  startHour: number,
  startMinute: number,
  endHour: number,
  endMinute: number,
  currentHour: number,
  currentMinute: number
): boolean {
  // Convert the start, end, and current times to minutes since midnight for easy comparison
  const startTime = startHour * 60 + startMinute;
  const endTime = endHour * 60 + endMinute;
  const currentTime = currentHour * 60 + currentMinute;

  // Handle cases where the range spans midnight
  if (startTime <= endTime) {
    // Normal range (e.g., 08:00 to 18:00)
    return currentTime >= startTime && currentTime <= endTime;
  }
  // Range spans midnight (e.g., 22:00 to 06:00)
  return currentTime >= startTime || currentTime <= endTime;
}
