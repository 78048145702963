import { useContext, useEffect } from 'react';

import { useGetLoginRoute, useNavigation, useRoute } from 'navigation/hooks';
import { AuthContext } from 'state/auth';
import useAuthFlow from 'state/auth/hooks/use-auth-flow';

interface IAuthRedirectsProps {
  skip?: boolean;
  keepParams?: boolean;
  searchParamsOverride?: Record<string, string>;
}

export const authRedirectsDefaultOpts: IAuthRedirectsProps = {
  skip: false,
  keepParams: false,
  searchParamsOverride: undefined,
};

export default function useAuthRedirects(opts = authRedirectsDefaultOpts) {
  const { loading, isAuthenticated, originLocation, setOriginLocation } = useContext(AuthContext);
  const { navigate } = useNavigation();
  const { pathname: originRouteName, params } = useRoute<Record<string, any>>();
  const { isInAuthFlow } = useAuthFlow();
  const loginRoute = useGetLoginRoute();

  useEffect(() => {
    if (opts.skip) {
      return;
    }

    if (loading) {
      return;
    }

    if (isAuthenticated && originLocation) {
      const nextLocation = originLocation;
      setOriginLocation(null);
      navigate(nextLocation, { replace: true, popCurrent: true });
    } else if (!isAuthenticated && !isInAuthFlow) {
      const originLocationURLParams = new URLSearchParams(params).toString();
      const originLocationWithParams = `${originRouteName}${
        originLocationURLParams ? `?${originLocationURLParams}` : ''
      }`;
      setOriginLocation(originLocationWithParams);

      const searchParams = opts.searchParamsOverride ?? params;
      const nextSearchParams = opts.keepParams ? searchParams : {};
      const next = loginRoute + convertObjectToSearchParameters(nextSearchParams);
      navigate(next, { state: { ...nextSearchParams }, replace: true, popCurrent: true });
    }
  }, [
    isAuthenticated,
    loading,
    originLocation,
    setOriginLocation,
    navigate,
    isInAuthFlow,
    originRouteName,
    params,
    opts.skip,
    opts.searchParamsOverride,
    opts.keepParams,
    loginRoute,
    opts,
  ]);
}

function convertObjectToSearchParameters(obj: object) {
  const params = Object.keys(obj)
    .map(key => `${key}=${obj[key]}`)
    .join('&');

  if (params.length) {
    return `?${params}`;
  }

  return '';
}
