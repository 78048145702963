import React, { useCallback, useMemo } from 'react';

import { Radio, Text, VStack } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import ActionButton from 'components/action-button';
import { SupportedReadableLanguages, SupportedReadableRegions, useLocale } from 'state/intl';
import { Region } from 'utils/environment/types';
import { formatCurrencyCodeForRegion } from 'utils/intl/region/format-currency-code-for-region';

import {
  ActionsWrapper,
  Disclaimer,
  LanguageSelectionCell,
  LanguageSelectionContainer,
  ModalAdditionalContentWrapper,
} from './styled';

interface ILanguageAndCountryLabelProps {
  showLanguageBeforeCountry: boolean;
  showSingleRegionLanguageSelector: boolean;
  readableLanguages: SupportedReadableLanguages;
  readableRegions: SupportedReadableRegions;
  mappedLanguage: string;
  mappedRegion: string;
}

const LabelContent: React.FC<React.PropsWithChildren<ILanguageAndCountryLabelProps>> = ({
  showLanguageBeforeCountry,
  showSingleRegionLanguageSelector,
  readableLanguages,
  readableRegions,
  mappedLanguage,
  mappedRegion,
}) => {
  const languageLabel = (
    <Text
      variant={showLanguageBeforeCountry ? 'headerThree' : 'copyOne'}
      mb={0}
      //TODO: RN - set lang attr on web
      data-lang={mappedLanguage.toLowerCase()}
    >
      {readableLanguages[mappedLanguage]}
    </Text>
  );

  const regionLabel = !showSingleRegionLanguageSelector ? (
    <Text
      variant={!showLanguageBeforeCountry ? 'headerThree' : 'copyOne'}
      mb={0}
      testID={`dialog-code-${mappedLanguage.toLowerCase()}-${mappedRegion.toLowerCase()}`}
    >
      {formatCurrencyCodeForRegion(readableRegions[mappedRegion])}
    </Text>
  ) : null;

  return (
    <VStack ml={2} gap={1}>
      {showLanguageBeforeCountry ? languageLabel : regionLabel}
      {showLanguageBeforeCountry ? regionLabel : languageLabel}
    </VStack>
  );
};

interface ILanguageModalContentsProps {
  regionFilter: (region: string) => boolean;
  disclaimer?: string;
  applyButtonText: string;
  setLanguage: () => void;
  handleSelection: (selectedLanguage: string, mappedRegion: string, isOneStep: boolean) => void;
  regionKey: string;
  langKey: string;
  /**
   * Pass the id of the heading that describes the radio group.
   */
  ariaRadioGroupLabeledBy: string;
}

const LanguageSelectorModalContents: React.FC<React.PropsWithChildren<
  ILanguageModalContentsProps
>> = ({
  regionFilter,
  regionKey,
  langKey,
  disclaimer,
  applyButtonText,
  setLanguage,
  handleSelection,
  ariaRadioGroupLabeledBy = '',
}) => {
  const { formatMessage } = useIntl();
  const { readableRegions, readableLanguages, supportedLocales, region } = useLocale();
  const showSingleRegionLanguageSelector = region === Region.CA;

  const locales = useMemo(
    () =>
      showSingleRegionLanguageSelector
        ? supportedLocales.filter(l => l.region === region)
        : supportedLocales,
    [region, showSingleRegionLanguageSelector, supportedLocales]
  );

  const regionLanguageSelections = locales
    .filter(({ region: filteredRegion }) => regionFilter(filteredRegion))
    .map(({ region: mappedRegion, language: mappedLanguage }) => {
      const commonLabelProps = {
        showLanguageBeforeCountry: true,
        showSingleRegionLanguageSelector,
        readableLanguages,
        readableRegions,
        mappedLanguage,
        mappedRegion,
      };
      return (
        <LanguageSelectionCell key={`dialog-button-${mappedLanguage}-${mappedRegion}`}>
          <Radio
            value={`${mappedLanguage}-${mappedRegion}`}
            testID={`dialog-button-${mappedLanguage.toLowerCase()}-${mappedRegion.toLowerCase()}`}
            accessibilityLabel={`${formatMessage({ id: 'select' })} ${
              readableLanguages[mappedLanguage]
            } / ${mappedRegion}`}
          >
            <LabelContent {...commonLabelProps} />
          </Radio>
        </LanguageSelectionCell>
      );
    });

  const handleRadioChange = useCallback(
    (value: string) => {
      const [mappedLanguage, mappedRegion] = value.split('-');
      handleSelection(mappedLanguage, mappedRegion, false);
    },
    [handleSelection]
  );

  return (
    <>
      <LanguageSelectionContainer
        name={ariaRadioGroupLabeledBy}
        value={`${langKey}-${regionKey}`}
        onChange={handleRadioChange}
        accessibilityRole="radiogroup"
        aria-labelledby={ariaRadioGroupLabeledBy}
      >
        {regionLanguageSelections}
      </LanguageSelectionContainer>
      {disclaimer && (
        <ModalAdditionalContentWrapper>
          <Disclaimer>{disclaimer}</Disclaimer>
        </ModalAdditionalContentWrapper>
      )}
      <ActionsWrapper>
        <ActionButton onPress={setLanguage} testID="action-button-apply-language-selection">
          {applyButtonText}
        </ActionButton>
      </ActionsWrapper>
    </>
  );
};

export default LanguageSelectorModalContents;
