import React, {
  PropsWithChildren,
  PureComponent,
  createContext,
  useCallback,
  useContext,
  useRef,
} from 'react';

import { IBaseProps } from '@rbi-ctg/frontend';
import ErrorBoundary from 'components/error-boundary-static';
import { staticLocation } from 'navigation/location/location-provider';
import crashlytics from 'utils/crashlytics';
import logger, { LogLevel } from 'utils/logger';
import { useMemoAll } from 'utils/use-memo-all';

interface IErrorBoundaryProps extends IBaseProps {
  onError: (e: Error, meta: object) => void;
}

export interface IErrorCtx {
  handleError: (e: Error, meta?: object) => void;
  setCurrentOrderId: (id: string) => void;
  // This ref is used to provide the current sanity data that was fetched when the app crashed for datadog logging purposes.
  setSanityDataRef: (data: any) => void;
}

type SanityData = Record<string, any> | Array<Record<string, any>> | undefined;

export const ErrorContext = createContext<IErrorCtx>(({} as any) as IErrorCtx);
export const useErrorContext = () => useContext(ErrorContext);

/**
 * `componentDidCatch` is only available
 * on Class components :(
 **/
class TopLevelErrorBoundary extends PureComponent<IErrorBoundaryProps> {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: any) {
    const { onError } = this.props;
    onError(error, { info, level: LogLevel.fatal });
  }

  render() {
    if (this.state.hasError) {
      return <ErrorBoundary />;
    }
    return this.props.children;
  }
}

export function ErrorsProvider({ children }: PropsWithChildren) {
  const orderId = useRef<string>('');
  const sanityDataRef = useRef<SanityData>();
  const setCurrentOrderId = useCallback((serverOrderId: string) => {
    orderId.current = serverOrderId;
  }, []);

  const setSanityDataRef = useCallback((data: any) => {
    sanityDataRef.current = data;
  }, []);

  const sanityData = sanityDataRef.current;

  const handleError = useCallback(
    (error: Error, meta: object = {}) => {
      error.message = `Error Boundary: ${error.message}`;
      const errorMeta = {
        ...meta,
        'Transaction RBI Cloud Order ID': orderId.current,
        'Source Page': staticLocation.current.pathname,
        errorBoundary: true,
        containsSanityData: !!sanityData,
        // Only apply the sanity data key if sanity data is available
        ...(sanityData && { sanityData }),
      };

      logger.fatal({ error, context: { ...errorMeta } });
      crashlytics().recordError(error);
      crashlytics().sendUnsentReports();
      // If sanity data was present, clear the data after logging.
      if (sanityDataRef.current) {
        setSanityDataRef(undefined);
      }
    },
    [setSanityDataRef, sanityData]
  );

  const value = useMemoAll({ handleError, setCurrentOrderId, setSanityDataRef });

  return (
    <ErrorContext.Provider value={value}>
      <TopLevelErrorBoundary onError={handleError}>{children}</TopLevelErrorBoundary>
    </ErrorContext.Provider>
  );
}
