import jwtDecode from 'jwt-decode';
import { useIntl } from 'react-intl';
import { IResolveParams, LoginSocialGoogle } from 'reactjs-social-login';

import ActionButton, { ActionButtonVariants } from 'components/action-button';
import { Helmet } from 'components/helmet';
import useDebouncedEffect from 'hooks/use-debounce-effect';
import AuthButton from 'pages/authentication/components/social-auth-buttons/auth-button';
import GoogleIcon from 'pages/authentication/components/social-auth-buttons/google/icon';
import {
  SocialLoginParams,
  UserAuth,
} from 'pages/authentication/components/social-auth-buttons/types';
import { googleServiceWebClientIds } from 'utils/environment';

const GoogleAuthButton = ({
  isLoading,
  isDisabled,
  onSuccess,
  onError,
  onStart,
}: SocialLoginParams) => {
  const { formatMessage } = useIntl();
  const authButton = (
    <AuthButton
      label={formatMessage({ id: 'logInWithGoogle' })}
      icon={<GoogleIcon />}
      loading={isLoading}
      disabled={isLoading || isDisabled}
    />
  );

  /* This is a Hack, we have an issue that is on the Google lib,
  they don't give us a way to check if the popup is closed
  before the user logs in it supposed that error_callback inside the lib catch this case,
  but is not working we don't receive any code.
  So to solve this, we have to check the cookie to see if the popup was closed or not
  and as we don't know how much time the user can take to select an account.
  I have set to 6s to check if the cookie has that flag, I didn't realize another way to do this */
  useDebouncedEffect(
    () => {
      if (isLoading && !document.cookie.includes('"i_l":0')) {
        onError({ code: 'COOKIE_CLOSE_POPUP' });
        // clear g_state cookie if the pop-up has already been closed
        document.cookie = `g_state=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT`;
      }
    },
    6000,
    [document.cookie]
  );

  return (
    <>
      <Helmet>
        <meta name="referrer" content="strict-origin-when-cross-origin" />
      </Helmet>
      <ActionButton
        fullWidth
        borderColor={Styles.color.black}
        marginY="$2"
        variant={ActionButtonVariants.OUTLINE}
        disabled={isLoading || isDisabled}
      >
        {/*On IOS mobile web, the LoginSocial component doesn't take care about disabling the child button, and
          it fires the click event, to solve this we just return the disabled button without the LoginSocial component*/}
        {isDisabled ? (
          authButton
        ) : (
          <LoginSocialGoogle
            client_id={googleServiceWebClientIds}
            scope={
              'https://www.googleapis.com/auth/userinfo.profile, https://www.googleapis.com/auth/userinfo.email'
            }
            typeResponse="idToken"
            onResolve={({ data }: IResolveParams) => {
              const tokenDecoded: { email?: string } = jwtDecode(data?.credential);
              const userInfo: UserAuth = {
                name: data?.name,
                email: tokenDecoded?.email,
                token: data?.credential,
              };
              onSuccess(userInfo);
            }}
            onReject={error => {
              onError(error);
            }}
            onLoginStart={() => {
              window.google.accounts.id.prompt();
              onStart();
            }}
          >
            {authButton}
          </LoginSocialGoogle>
        )}
      </ActionButton>
    </>
  );
};

export default GoogleAuthButton;

declare global {
  interface Window {
    google?: {
      accounts: {
        id: {
          prompt: () => void;
        };
      };
    };
  }
}
