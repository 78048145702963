import { PayloadAction } from '@reduxjs/toolkit';
import { isEqual, isNumber } from 'lodash-es';

import { ICartEntry } from '@rbi-ctg/menu';
import { SYNC_WITH_STORAGE_ACTION } from 'state/global-state/global-actions';
import { createAppSlice } from 'state/global-state/utils';
import LocalStorage, { StorageKeys } from 'utils/local-storage';

import { ICartState, IMinimalCartEntry } from './cart.types';

const getCartEntriesFromStorage = () => {
  const cartEntries = LocalStorage.getItem(StorageKeys.CART);
  return (cartEntries || []) as ICartEntry[];
};

const setCartEntriesInStorage = (cartEntries: ICartEntry[]) => {
  LocalStorage.setItem(StorageKeys.CART, cartEntries);
};

const getMinimalCartEntriesFromStorage = () => {
  const minimalCartEntries = LocalStorage.getItem(StorageKeys.MINIMAL_CART);
  return (minimalCartEntries || []) as IMinimalCartEntry[];
};

const setMinimalCartEntriesInStorage = (minimalCartEntries: IMinimalCartEntry[]) => {
  LocalStorage.setItem(StorageKeys.MINIMAL_CART, minimalCartEntries);
};

export const initialState: ICartState = {
  cartEntries: [],
  minimalCartEntries: [],
};

export const cartSlice = createAppSlice({
  name: 'cart',
  initialState,
  reducers: {
    addCartEntry: (state, { payload }: PayloadAction<ICartEntry>) => {
      state.cartEntries.push(payload);
      setCartEntriesInStorage(state.cartEntries);
    },
    addMinimalCartEntry: (state, { payload }: PayloadAction<IMinimalCartEntry>) => {
      state.minimalCartEntries.push(payload);
      setMinimalCartEntriesInStorage(state.minimalCartEntries);
    },
    // used to remove single and multiple entries
    removeCartEntries: (state, { payload: idsToRemove }: PayloadAction<Set<string>>) => {
      state.cartEntries = state.cartEntries.filter(entry => !idsToRemove.has(entry.cartId));
      state.minimalCartEntries = state.minimalCartEntries.filter(
        entry => !idsToRemove.has(entry.id)
      );

      // Reset cart if only `invalid` entries remains
      // TODO: check for `isDiscountOffer(state.loyalty.appliedOffers[cartId])`
      if (state.cartEntries.every(entry => entry.isExtra || entry.isDonation)) {
        state.cartEntries = [];
      }
      setCartEntriesInStorage(state.cartEntries);
      setMinimalCartEntriesInStorage(state.minimalCartEntries);
    },
    // FIXME: replace this action because is unsafe to delegate the responsibility of mutating the state to the consumers.
    repriceCartEntries: (state, { payload }: PayloadAction<ICartEntry[]>) => {
      // Safety measure in case we are overriding the cartEntries with stale data
      if (payload?.length !== state.cartEntries.length) {
        return;
      }
      state.cartEntries = payload;
      setCartEntriesInStorage(payload);
    },
    resetCart: state => {
      state.cartEntries = [];
      setCartEntriesInStorage([]);
    },
    resetMinimalCart: state => {
      state.minimalCartEntries = [];
      setMinimalCartEntriesInStorage([]);
    },
    updateCartEntry: (
      state,
      { payload }: PayloadAction<{ cartEntry: ICartEntry; cartId: string }>
    ) => {
      const { cartEntry, cartId } = payload;
      const index = state.cartEntries.findIndex(entry => entry.cartId === cartId);
      state.cartEntries =
        index !== -1
          ? [...state.cartEntries.slice(0, index), cartEntry, ...state.cartEntries.slice(index + 1)]
          : [...state.cartEntries, cartEntry];
      setCartEntriesInStorage(state.cartEntries);
    },
    updateQuantityAndPrice: (
      state,
      { payload }: PayloadAction<{ cartId: string; quantity: number; price?: number }>
    ) => {
      const { cartId, quantity, price } = payload;
      state.cartEntries = state.cartEntries.map(entry => {
        if (entry.cartId === cartId) {
          entry.quantity = quantity;
          if (isNumber(price)) {
            entry.price = price;
          }
        }
        return entry;
      });
      setCartEntriesInStorage(state.cartEntries);
    },
  },
  extraReducers: {
    [SYNC_WITH_STORAGE_ACTION]: (state: ICartState) => {
      const cartEntriesFromStorage = getCartEntriesFromStorage();
      if (!isEqual(state.cartEntries, cartEntriesFromStorage)) {
        state.cartEntries = cartEntriesFromStorage;
      }
      const minimalCartEntriesFromStorage = getMinimalCartEntriesFromStorage();
      if (!isEqual(state.minimalCartEntries, minimalCartEntriesFromStorage)) {
        state.minimalCartEntries = minimalCartEntriesFromStorage;
      }
    },
  },
});
