import React from 'react';

import MaterialIcons from '@expo/vector-icons/MaterialIcons';
import { useIntl } from 'react-intl';

import { ActionButtonSizes } from 'components/action-button';
import { LoyaltyPointsButton } from 'components/loyalty-points-button';
import { useRoute } from 'hooks/navigation/use-route';
import { useAuthContext } from 'state/auth';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { primitive } from 'styles/constants/primitives';
import { routes } from 'utils/routing';

import { IconButton } from '../../../../ucl/components/icon-button/IconButton';

export const MobileHeaderCta = () => {
  const { pathname } = useRoute();
  const { formatMessage } = useIntl();
  const enableSignUp = useFlag(LaunchDarklyFlag.ENABLE_SIGN_UP);
  const { openSignInModal, isAuthenticated } = useAuthContext();
  const onPress = () => openSignInModal();

  if (isAuthenticated) {
    return (
      <>
        <LoyaltyPointsButton />
      </>
    );
  }

  if (!enableSignUp || (!isAuthenticated && pathname === routes.account)) {
    return null;
  }

  return (
    <IconButton
      icon={<MaterialIcons name="person" size={24} color={primitive.bk.$bbqBrown} />}
      size={ActionButtonSizes.SMALL}
      onPress={onPress}
      testID="mobile-nav-signup-link"
      alignSelf="center"
      accessible
      accessibilityRole="button"
      accessibilityLabel={formatMessage({ id: 'signUpOrSignIn' })}
    />
  );
};
