import { ReactNode } from 'react';

import {
  Badge,
  Box,
  Button,
  HStack,
  Header,
  Pressable,
  Text,
  VStack,
} from '@rbilabs/universal-components';
import { GestureResponderEvent } from 'react-native';

import { OfferFeedbackMessage } from 'components/cart-item-experiment/styled';
import { useOfferExpiration } from 'hooks/loyalty/use-offer-expiration';

type IOfferGroupItem = {
  name: string;
  groupId?: string;
  description?: string;
  image: ReactNode;
  onPress?: ((event?: GestureResponderEvent) => any) | null | undefined;
  isPartnershipOffer?: boolean;
  activateButtonLabel?: string;
  onPartnershipActivate?: ((event: GestureResponderEvent) => void) | null | undefined;
  isWalmartUser?: boolean;
  linkedButtonLabel?: string;
  endDate?: string;
};

export const OfferGroupItem = ({
  name,
  description,
  groupId,
  image,
  onPress,
  isPartnershipOffer,
  activateButtonLabel,
  onPartnershipActivate,
  isWalmartUser,
  linkedButtonLabel,
  endDate,
  ...buttonProps
}: IOfferGroupItem) => {
  const expiresAt = useOfferExpiration(endDate, false);
  return (
    <Pressable
      accessibilityRole="button"
      dd-action-name={name}
      onPress={onPress}
      mb="$4"
      width="full"
      {...buttonProps}
    >
      {({ isHovered, isPressed, isFocused }) => {
        const padding = isPartnershipOffer ? ['$0', '$0'] : ['$3', '$4'];
        const pressed = isPressed && onPress;
        return (
          <Box
            bg="token.background-pattern"
            rounded={10}
            borderWidth={0}
            borderColor={'transparent'}
          >
            <VStack
              p={padding}
              pt={'$3'}
              borderTopRadius={10}
              borderBottomRadius={10}
              bg={pressed || isFocused ? 'token.background-focus' : 'transparent'}
            >
              <HStack mr="$2">
                {image}
                <VStack flexShrink={1} ml="$3" alignItems="flex-start" justifyContent="center">
                  <Header
                    variant="headerThree"
                    color={
                      isHovered && !isPartnershipOffer ? 'token.text-active' : 'token.text-default'
                    }
                    ellipsizeMode="tail"
                    numberOfLines={2}
                    mb={isPartnershipOffer ? '$1' : '$2'}
                  >
                    {name}
                  </Header>
                  {description ? (
                    <Text fontSize={12} lineHeight={16} numberOfLines={2} ellipsizeMode="tail">
                      {description}
                    </Text>
                  ) : null}
                  {expiresAt && <OfferFeedbackMessage>{expiresAt}</OfferFeedbackMessage>}
                  {isPartnershipOffer && !isWalmartUser ? (
                    <Button
                      size="sm"
                      mt="$2"
                      mb="$2"
                      pb="$1"
                      pt="$1"
                      onPress={onPartnershipActivate}
                      variant="outline"
                    >
                      {activateButtonLabel}
                    </Button>
                  ) : null}
                  {isPartnershipOffer && isWalmartUser ? (
                    <Badge
                      mt="$2"
                      variant="default-subtle"
                      backgroundColor="#D1FAE5"
                      iconName="checkFill"
                    >
                      {linkedButtonLabel || 'Active'}
                    </Badge>
                  ) : null}
                </VStack>
              </HStack>
            </VStack>
          </Box>
        );
      }}
    </Pressable>
  );
};
