import { useIntl } from 'react-intl';

import { IRestaurant } from '@rbi-ctg/store';
import { IOperatingHours } from 'generated/graphql-gateway';
import { useConfigValue } from 'hooks/configs/use-config-value';
import { useServiceModeStatus } from 'hooks/use-service-mode-status';
import { ServiceMode } from 'state/service-mode';
import {
  isOpenIfNotWithinMinutesOfCloseTodayAndNot24hours,
  readableCloseHourToday,
} from 'utils/restaurant';
import { TWELVE_HOUR_TIME_PARSE_FORMAT } from 'utils/restaurant/constants';

const getOperatingHours = (restaurant: IRestaurant) => (serviceMode: ServiceMode) => {
  switch (serviceMode) {
    case ServiceMode.DRIVE_THRU:
      return restaurant.driveThruHours;
    case ServiceMode.MOBILE_ORDER_DRIVE_THRU:
      return restaurant.mobileOrderDriveThruHours;
    case ServiceMode.CURBSIDE:
      return restaurant.curbsideHours;
    default:
      return restaurant.diningRoomHours;
  }
};

const isWithinOneHourOfClose = (hours: IOperatingHours) => {
  return !isOpenIfNotWithinMinutesOfCloseTodayAndNot24hours(hours, new Date(Date.now()), 60);
};

interface IOpenClosedText {
  isOpen: boolean;
  isWithinOneHourOfClose: boolean;
  text: string;
}

export const useOpenClosedText = (restaurant: IRestaurant): IOpenClosedText => {
  const timeFormatConfig = useConfigValue({
    key: 'timeFormat',
    defaultValue: TWELVE_HOUR_TIME_PARSE_FORMAT,
  });
  const { availablePickupServiceModes } = useServiceModeStatus(restaurant);
  const { formatMessage } = useIntl();

  // Get the open hours for the first available pickup service mode.
  const [operatingHours] = availablePickupServiceModes.map(getOperatingHours(restaurant));

  if (!operatingHours) {
    // TODO: Should we consider if the store can be selected? i.e., if (!operatingHours || !restaurantCanBeSelected) {
    return { isOpen: false, isWithinOneHourOfClose: false, text: formatMessage({ id: 'closed' }) };
  }

  const closesWithinOneHour = isWithinOneHourOfClose(operatingHours);

  const text = closesWithinOneHour
    ? formatMessage(
        { id: 'closesAt' },
        { time: readableCloseHourToday(operatingHours, timeFormatConfig) }
      )
    : formatMessage({ id: 'open' });

  return {
    isOpen: true,
    isWithinOneHourOfClose: closesWithinOneHour,
    text,
  };
};
