import { Dispatch, SetStateAction } from 'react';

import { IPaymentMethod } from 'features/payment/types';

export interface IPaymentMethodProps {
  checkoutPaymentMethodNeedRevault?: boolean;
  hasBorder?: boolean;
  handleSelect: (fdAccountId?: string) => void;
  isAddCardOpen?: boolean;
  isAddGiftCardMethodSelected?: boolean;
  isOrbitalPaymentEnabled?: boolean;
  methods: IPaymentMethod[];
  openAddCardOnPress: Dispatch<SetStateAction<boolean>>;
  selectedMethodId: string;
  setIsAddGiftCardMethodSelected?: Dispatch<SetStateAction<boolean>>;
  showCollapsibleMenu?: boolean;
  isQuickPay?: boolean;
  setIsQuickPayMethodSelected?: Dispatch<SetStateAction<boolean>>;
}

export type PaymentMethodCategories = {
  selectedMethod: IPaymentMethod | undefined;
  unSelectedMethods: IPaymentMethod[];
  prepaidPaymentMethod: IPaymentMethod | undefined;
};

export enum CurrentSelectedMethodType {
  ADD_NEW_PAYMENT_METHOD = 'addNewPaymentMethod',
  ADD_NEW_GIFT_CARD = 'addNewGiftCard',
  ADD_NEW_CARD = 'addNewCard',
  SELECTED_PAYMENT_METHOD_OPTION = 'selectedPaymentMethodOption',
}
