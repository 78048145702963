import { Box, Text } from '@rbilabs/universal-components';

import { primitive } from 'styles/constants/primitives';

import theme from './theme';

export const Container = Box.withConfig({
  alignSelf: 'center',
  // TODO: BKPE-1177 Review modal container width
  width: {
    base: 'full',
    sm: '3/4',
    md: '3/5',
    lg: '2/4',
  },
});

export const ItemContainer = Box;

export const Item = Box.withConfig({
  flexDirection: 'row',
  alignItems: 'center',
  marginBottom: '$4',
  paddingBottom: '$4',
  paddingX: '$4',
  paddingY: '$4',
  paddingTop: '$2',
  backgroundColor: 'token.background-focus',
  borderRadius: 10,
});

export const TileImageWrapper = Box.withConfig({
  height: '$20',
  width: '$20',
  borderRadius: 10,
  marginRight: '$5',
});

export const ItemName = Text.withConfig({
  fontFamily: 'heading',
  fontWeight: theme.itemNameFontWeight,
  fontSize: 'md',
  lineHeight: 'md',
});

export const FooterContainer = Box.withConfig({
  safeAreaBottom: true,
  paddingTop: '$0',
  padding: '$4',
  flex: 1,
  alignItems: 'center',
  flexDirection: 'column',
  borderTopWidth: 1,
  borderTopColor: primitive.$blackOpacity10,
});

export const PointLabel = Box.withConfig({
  height: '$6',
  width: '$24',
  alignItems: 'center',
  justifyContent: 'flex-start',
  backgroundColor: Styles.color.loyaltyHighlight,
  borderRadius: 'md',
  flexDirection: 'row',
});

export const ErrorMessage = Text.withConfig({
  textAlign: 'center',
  fontSize: 'md',
  lineHeight: 'md',
  marginY: '$4',
});
