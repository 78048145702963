import { FC, PropsWithChildren } from 'react';

import SanityBlockRenderer from 'components/sanity-block-renderer';

import { useLoyaltyWalmart } from '../use-loyalty-walmart';

const linkStyles = {
  variant: 'copyTwo',
  fontSize: 'xs',
  textDecorationLine: 'underline',
  color: `${Styles.color.black}`,
};

export const WalmartTerms: FC<PropsWithChildren<{}>> = () => {
  const { data, loading } = useLoyaltyWalmart();

  if (loading) {
    return null;
  }

  const { termsAndConditionsRaw } = data?.landingPage || {};

  return (
    <SanityBlockRenderer
      textProps={{ fontFamily: 'Flame-RegularSans', fontSize: 'xs' }}
      content={termsAndConditionsRaw}
      linkProps={linkStyles}
    />
  );
};
