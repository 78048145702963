import React, { useCallback, useEffect, useState } from 'react';

import { useIntl } from 'react-intl';

import Modal from 'components/modal';
import useEffectOnce from 'hooks/use-effect-once';
import {
  CustomEventNames,
  EventTypes,
  logEvent,
  updateAmplitudeUserAttributes,
} from 'state/amplitude';
import { useLocale } from 'state/intl';
import { LANGUAGES } from 'state/intl/types';
import { theme } from 'styles/configure-theme';
import { Region } from 'utils/environment/types';
import { inferSupportedLocale } from 'utils/intl/locale';

import LanguageSelectorModalContents from './language-selector-modal-contents';
import { ModalAdditionalContentWrapper } from './language-selector-modal-contents/styled';
import { ContentContainer, PrimaryText, SecondaryText } from './language-selector-modal.styled';
import { ILanguageModalProps } from './types';
import { useChangeLocale } from './use-change-locale.hook';

const LanguageSelectorModal: React.FC<React.PropsWithChildren<ILanguageModalProps>> = ({
  onModalDismiss,
  heading,
  primaryText,
  secondaryText,
  disclaimer,
  regionFilter = (regionString: string) => !!regionString,
  redirectToCurrentLocation = false,
}) => {
  const { formatMessage } = useIntl();
  const { region, locale, language } = useLocale();
  const [{ langKey, regionKey }, setSelectedLocale] = useState({
    langKey: language,
    regionKey: region,
  });
  const { changeLocale } = useChangeLocale({ redirectToCurrentLocation, langKey, regionKey });

  const [isOneStepSelection, setIsOneStepSelection] = useState(false);

  const showSingleRegionLanguageSelector = region === Region.CA;

  const handleSelection = (
    selectedLanguage: string,
    mappedRegion: string,
    isOneStep: boolean = false
  ) => {
    setIsOneStepSelection(isOneStep);
    setSelectedLocale({
      langKey: selectedLanguage as LANGUAGES,
      regionKey: mappedRegion as Region,
    });
  };

  const isNewLocaleSelectionSameAsCurrent = useCallback(() => {
    const inferredLocaleFromArgs = inferSupportedLocale(langKey as LANGUAGES, regionKey as Region);

    return inferredLocaleFromArgs === locale;
  }, [langKey, regionKey, locale]);

  const setLanguage = useCallback(() => {
    if (isNewLocaleSelectionSameAsCurrent()) {
      onModalDismiss();
      return;
    }
    changeLocale();
    onModalDismiss();
  }, [changeLocale, isNewLocaleSelectionSameAsCurrent, onModalDismiss]);

  useEffectOnce(() => {
    logEvent(CustomEventNames.LOCALE_SELECTOR_MODAL_SHOWN, EventTypes.Navigation);
  });

  useEffect(() => {
    updateAmplitudeUserAttributes({ language });
    if (!isOneStepSelection) {
      return;
    }
    if (isNewLocaleSelectionSameAsCurrent()) {
      onModalDismiss();
    } else {
      setLanguage();
    }
  }, [
    langKey,
    regionKey,
    isNewLocaleSelectionSameAsCurrent,
    setLanguage,
    isOneStepSelection,
    onModalDismiss,
    language,
  ]);

  const applyButtonText = formatMessage({ id: 'apply' });
  const modalHeading =
    heading ||
    (showSingleRegionLanguageSelector
      ? formatMessage({ id: 'selectLanguage' })
      : formatMessage({ id: 'changeLanguage' }));

  return (
    <Modal
      testID="select-language-modal"
      onDismiss={onModalDismiss}
      amplitudeEventData={{
        modalAppearanceEventMessage: 'Language and region selector',
      }}
      header={modalHeading}
    >
      <ContentContainer paddingTop="$13.5" background={theme.token('background-dialog')}>
        {(primaryText || secondaryText) && (
          <ModalAdditionalContentWrapper>
            {primaryText && <PrimaryText variant="copyOne">{primaryText}</PrimaryText>}
            {secondaryText && <SecondaryText variant="copyOne">{secondaryText}</SecondaryText>}
          </ModalAdditionalContentWrapper>
        )}
        <LanguageSelectorModalContents
          ariaRadioGroupLabeledBy="language-select-modal-heading"
          regionFilter={regionFilter}
          regionKey={regionKey}
          langKey={langKey}
          disclaimer={disclaimer}
          applyButtonText={applyButtonText}
          setLanguage={setLanguage}
          handleSelection={handleSelection}
        />
      </ContentContainer>
    </Modal>
  );
};

export default LanguageSelectorModal;
