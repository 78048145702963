import { FC, useCallback, useState } from 'react';

import { Box, Header, Pressable } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';
import { Linking } from 'react-native';

import { InnerLayout, LayoutContainer } from 'components/layout/styled';
import { useNavigation } from 'hooks/navigation/use-navigation';
import { useAppStateChangeListener } from 'hooks/use-app-state-change-listener';
import useEffectOnce from 'hooks/use-effect-once';
import { ClickEventComponentNames, CustomEventNames, logButtonClick } from 'state/amplitude';
import { useAuthContext } from 'state/auth';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useLoyaltyUserState } from 'state/loyalty/hooks/use-loyalty-user-state';
import { isActiveWalmartUser } from 'state/loyalty/hooks/utils/walmart';
import { portalToFooter } from 'state/mobile-footer';
import { portalToReplaceHeader } from 'state/mobile-header-nav';
import { routes } from 'utils/routing';

import { useLoyaltyWalmart } from '../use-loyalty-walmart';
import { WalmartLogo } from '../walmart-logo';

import { StyledBackIcon } from './styled';
import { UnlinkWalmartUserModal } from './unlink-walmart-user-modal';
import { WalmartOfferItem } from './walmart-offer-item';

const TIME_TO_REFETCH_LOYALTY_USER_AFTER_UNLINK = 3000;

export const WalmartOffer: FC<React.PropsWithChildren<{}>> = () => {
  const { formatMessage } = useIntl();
  const { navigate } = useNavigation();

  const enableWalmartPlusTrialOffer = useFlag(LaunchDarklyFlag.ENABLE_WALMART_PLUS_TRIAL_OFFER);
  const enableWalmartUnlinkFlow = useFlag(LaunchDarklyFlag.ENABLE_UNLINK_WALMART_FLOW);

  const { closeModalAuth, setAccountCallbackUrl } = useAuthContext();

  const [showUnlinkModal, setShowUnlinkModal] = useState<boolean>(false);
  const [isVerifyingUnlink, setIsVerifyingUnlink] = useState<boolean>(false);

  const {
    loyaltyUser,
    loading: isLoadingLoyaltyUser,
    refetch: refetchLoyaltyUser,
  } = useLoyaltyUserState({ refreshOnMount: true });

  const isWalmartUser = isActiveWalmartUser(loyaltyUser);

  const { data, loading } = useLoyaltyWalmart();
  const { addAppStateChangeListeners, removeAppStateChangeListeners } = useAppStateChangeListener();
  const handleActivateAccount = () => {
    logButtonClick({
      attributes: {
        name: CustomEventNames.ACTIVATE_WALMART_PLUS_ACCOUNT,
        component: ClickEventComponentNames.BUTTON,
      },
    });
    navigate(routes.walmart);
  };

  const handleRefetchLoyaltyUserAfterUnlink = useCallback(
    async (retry: boolean = true) => {
      setIsVerifyingUnlink(true);
      await refetchLoyaltyUser();

      if (!isWalmartUser) {
        removeAppStateChangeListeners();
        setIsVerifyingUnlink(false);
      } else if (retry) {
        // If the user is still linked, we try to verify once again after a few seconds.
        setTimeout(
          () => handleRefetchLoyaltyUserAfterUnlink(false),
          TIME_TO_REFETCH_LOYALTY_USER_AFTER_UNLINK
        );
      } else {
        setIsVerifyingUnlink(false);
      }
    },
    [isWalmartUser, refetchLoyaltyUser, removeAppStateChangeListeners]
  );

  const handleRedirectToUnlink = useCallback(() => {
    const unlinkWalmartSanityUrl = data?.walmartUnlinkUrl;

    if (unlinkWalmartSanityUrl) {
      /**
       * Before redirecting the user, we add an event listener to detect when the user
       * returns to the app. This way, we can fetch the loyaltyUser again and verify that
       * the user was successfully unlinked.
       */
      addAppStateChangeListeners(handleRefetchLoyaltyUserAfterUnlink);
      Linking.openURL(unlinkWalmartSanityUrl);
    }
  }, [addAppStateChangeListeners, data?.walmartUnlinkUrl, handleRefetchLoyaltyUserAfterUnlink]);

  useEffectOnce(() => {
    return () => {
      // TODO: Consider closing the modal using state in the useAuth context on mount.
      closeModalAuth();
    };
  });

  const handleClickWalmartOfferItemCard = () => {
    if (!isWalmartUser) {
      return;
    }

    navigate(routes.walmart);
  };

  const AppHeader = portalToReplaceHeader(() => {
    return (
      <Box _ios={{ marginTop: '$12' }} _android={{ marginTop: '$3' }} marginTop={'$0'}>
        <Pressable
          onPress={() => {
            setAccountCallbackUrl(routes.base);
            navigate(routes.account);
          }}
          padding="$4"
        >
          <StyledBackIcon variant="back" />
        </Pressable>
        <Header alignSelf="center" variant="headerTwo" top="$4" position="absolute">
          {formatMessage({ id: 'partnerOffers' })}
        </Header>
      </Box>
    );
  });

  const AppFooter = portalToFooter(() => null);

  return (
    <>
      <AppHeader />
      {/* @TODO check with design: We should do a loading/skeleton screen */}
      {!loading && !isLoadingLoyaltyUser && (
        <LayoutContainer paddingTop="$2">
          <InnerLayout>
            <WalmartOfferItem
              name={formatMessage({ id: 'partnershipWalmartTitle' })}
              description={formatMessage({ id: 'partnershipWalmartDescription' })}
              descriptionL2={
                enableWalmartPlusTrialOffer && !isWalmartUser
                  ? formatMessage({ id: 'walmartPlusMembershipDiscount' })
                  : undefined
              }
              image={<WalmartLogo image={data?.logo?.locale} />}
              activateButtonLabel={formatMessage({ id: 'activate' })}
              onPartnershipActivate={handleActivateAccount}
              onhandleClickWalmartOfferItemCard={handleClickWalmartOfferItemCard}
              isLoadingUser={isLoadingLoyaltyUser}
              isWalmartUser={isWalmartUser}
              showUnlinkButton={enableWalmartUnlinkFlow}
              unlinkButtonLabel={formatMessage({ id: 'walmartDeactivateButton' })}
              onUnlinkUser={() => setShowUnlinkModal(true)}
              isUnlinkingUser={isVerifyingUnlink}
            />
          </InnerLayout>
        </LayoutContainer>
      )}
      <AppFooter />
      <UnlinkWalmartUserModal
        open={showUnlinkModal}
        onConfirm={handleRedirectToUnlink}
        onClose={() => setShowUnlinkModal(false)}
      />
    </>
  );
};
