import { CardType } from 'features/payment/types';

import { INutritionInfo } from '@rbi-ctg/menu';

export type PaymentFieldVariations = {
  zip: boolean;
  cvv: boolean;
  cardNumber: boolean;
  addressLine1: boolean;
  addressLine2: boolean;
  city: boolean;
  country: boolean;
  name: boolean;
  expiration: boolean;
  state: boolean;
};

export const defaultPaymentFieldVariation: PaymentFieldVariations = {
  zip: true,
  cvv: true,
  cardNumber: true,
  addressLine1: true,
  addressLine2: true,
  city: true,
  country: true,
  name: true,
  expiration: true,
  state: true,
};

export type SignUpFieldsVariations = {
  agreesToTermsOfService: boolean;
  country: boolean;
  email: boolean;
  name: boolean;
  wantsPromotionalEmails: boolean;
  zip: boolean;
  dob: boolean;
  phone: boolean;
  favoriteStoreSelector?: boolean;
};

export const defaultSignUpFieldsVariation: SignUpFieldsVariations = {
  agreesToTermsOfService: true,
  country: true,
  email: true,
  name: true,
  wantsPromotionalEmails: true,
  zip: true,
  dob: true,
  phone: true,
  favoriteStoreSelector: false,
};

// ENABLE_OFFER_REDEMPTION_METHODS flag
export enum OfferAvailableRedemptionMethods {
  ENABLE_ALL = 'all',
  ENABLE_MOBILE_ONLY = 'mobile',
  ENABLE_RESTAURANT_ONLY = 'restaurant',
  DISABLE_ALL = 'disable',
}

export enum OrderLocatorTab {
  NEARBY = 'nearby',
  RECENT = 'recent',
  FAVORITE = 'favorite',
  DELIVERY = 'delivery',
}
export type OrderLocatorTabVariations = {
  '0': OrderLocatorTab;
  '1': OrderLocatorTab;
  '2': OrderLocatorTab;
  '3': OrderLocatorTab;
};

export const defaultOrderLocatorTabVariation: OrderLocatorTabVariations = {
  '0': OrderLocatorTab.NEARBY,
  '1': OrderLocatorTab.RECENT,
  '2': OrderLocatorTab.FAVORITE,
  '3': OrderLocatorTab.DELIVERY,
};

// store-card-button-options flag
export enum StoreCardButtonOptions {
  INFO = 'info',
  ORDER = 'order',
  OFFERS = 'offers',
}

export type StoreCardButtonOptionsFlagVariation = {
  [key: string]: StoreCardButtonOptions;
};

export const defaultStoreCardButtonOptionsOrder: StoreCardButtonOptions[] = [
  StoreCardButtonOptions.INFO,
  StoreCardButtonOptions.ORDER,
  StoreCardButtonOptions.OFFERS,
];

export type EnablePrepaidPaymentMethodsVariation = {
  [key: string]: boolean;
};

export type EnableMenuReorderingAvailabilityOverrideVariation = string[];

export const defaultPrepaidPaymentMethodsVariation = {
  APPLE_PAY: true,
  CREDIT: true,
  EXISTING_METHOD: true,
  GOOGLE_PAY: true,
  ONE_TIME_METHOD: true,
};

// hide-items-from-nutrition-info flag
export type ItemNutritionInfoToHide = {
  [k in keyof INutritionInfo]?: boolean;
};

export const defaultItemNutritionInfoToHide = {
  calories: false,
  energyKJ: false,
  fat: false,
  saturatedFat: false,
  transFat: false,
  cholesterol: false,
  sodium: false,
  salt: false,
  carbohydrates: false,
  fiber: false,
  sugar: false,
  proteins: false,
  weight: false,
  caloriesPer100: false,
  energyKJPer100: false,
  carbohydratesPer100: false,
  sugarPer100: false,
  fatPer100: false,
  saturatedFatPer100: false,
  proteinsPer100: false,
  saltPer100: false,
};

export type TimedFireGenerateOptions = {
  /**
   * timeInterval: minutes of separation between options.
   **/
  timeInterval: number;
  /**
   * lengh: number of options to get generated.
   **/
  length: number;
  /**
   * offset: optional minutes from now of the 1st option. Defaults to 0.
   **/
  offset?: number;
  /**
   * startNow: optionally set OPTION_NOW the 1st option in the array. Defaults to false.
   **/
  startNow?: boolean;
};

export type TimedFireOptionsVariation = {
  generateOptions: null | TimedFireGenerateOptions;
};

export type SupportedCardType = CardType;

export const defaultSupportedCardTypes: SupportedCardType[] = [
  'AMEX',
  'DINERS_CLUB',
  'DISCOVER',
  'JCB',
  'MASTERCARD',
  'VISA',
];

export enum OrderInjectionFlowVariations {
  IM_HERE = 'IM_HERE',
  TIMED_FIRE = 'TIMED_FIRE',
  INJECT_NOW = 'INJECT_NOW',
  INJECT_AND_HOLD = 'INJECT_AND_HOLD',
}

export enum DeliveryFeesStripeVariations {
  DISABLED = 'disabled',
  ENABLED = 'enabled',
  ENABLED_WITH_TITLE_ONLY = 'enabled-with-title-only',
}
