// side effect imports to run first
import 'react-native-get-random-values';
import './why-did-you-render';
import 'styles';
import './polyfill';
import 'utils/accessibility/text-size';
import 'utils/keyboard/keyboard-config';

import { useEffect, useState } from 'react';

import { Asset } from 'expo-asset';
import { updateId } from 'expo-updates';
import { LogBox, Platform, StyleSheet, View } from 'react-native';

import { AppContainer } from 'components/app-container';
import { EnvPicker } from 'components/env-picker';
import ReduceProviders from 'components/reduce-providers';
import { useOtaLogger } from 'hooks/use-ota-logger';
import { Router } from 'navigation/routing/router';
import { initializeAmplitude } from 'state/amplitude/initialize';
import { theme } from 'styles/theme';
import { appVersion, binaryRuntimeAppVersionWithBuildNumber } from 'utils/app-version-info';
import { Cognito } from 'utils/cognito';
import NativeAuthStorage from 'utils/cognito/storage';
import crashlytics from 'utils/crashlytics';
import { initDatadog } from 'utils/datadog';
import { getDeviceId } from 'utils/device-id';
import { appStartId, shouldShowEnvPickerAtStart } from 'utils/environment';
import { EventName, emitEvent } from 'utils/event-hub';
import { initExpoFonts } from 'utils/expo';
import { handleFatalError, setupGlobalErrorHandler } from 'utils/fatal-error-handler';
import { WebLocalStorage } from 'utils/local-storage/web-local-storage';
import logger from 'utils/logger';
import { initializePerformanceMonitors } from 'utils/performance';
import { Measures, PerformanceMarks, logMeasurement, setMark } from 'utils/timing';

import { sanityAssets } from './sanityAssets';

// TODO fix these as we will get better RUM stats if we do
LogBox.ignoreLogs([
  'DATADOG: An action event was dropped because either the `onPress` method arguments were undefined or they were missing the target information.',
  'DATADOG: A navigation change was detected but the RUM ViewEvent was dropped as the route was undefined.',
  /SerializableStateInvariantMiddleware took.*/,
]);

// Added this to prevent crash on Android
// See https://github.com/andyearnshaw/Intl.js#regexp-cache--restore
// and https://github.com/expo/expo/issues/6536
if (Platform.OS === 'android') {
  if (typeof (Intl as any).__disableRegExpRestore === 'function') {
    (Intl as any).__disableRegExpRestore();
  }
}

const setCrashlyticsAttributes = () => {
  crashlytics().setAttribute('binaryRuntimeVersion', binaryRuntimeAppVersionWithBuildNumber);
  crashlytics().setAttribute('appVersion', appVersion);
  crashlytics().setAttribute('updateId', updateId ?? 'UNKNOWN');
  crashlytics().setAttribute('appStartId', appStartId);
};

// SETUP datadog logging
try {
  try {
    initDatadog();
  } catch (error) {
    setCrashlyticsAttributes();
    crashlytics().recordError(new Error(`Failed to init datadog logging! ${error?.toString()}`));
  }
} catch (error) {}

// SETUP crashlytics
try {
  setCrashlyticsAttributes();
  getDeviceId().then(deviceId => crashlytics().setAttribute('deviceId', deviceId));
} catch (error) {
  logger.error({ error, message: 'Failed to set crashlytics attributes on app start' });
}

// SETUP global error handler
setupGlobalErrorHandler();

// SETUP performance monitors
initializePerformanceMonitors();
setMark(PerformanceMarks.APP_RN_TSX_START);
emitEvent(EventName.JAVASCRIPT_START);

initializeAmplitude();

export const App = () => {
  const [isAppReady, setIsAppReady] = useState(false);

  useEffect(() => {
    const init = async () => {
      try {
        logger.info({ message: 'React app started', isAppStart: true });

        setMark(PerformanceMarks.APP_LOADING_START);

        // Startup congito with its configuration as early as possible.
        Cognito.configure();

        const importedSanityAssets = Object.entries(sanityAssets.current).map(
          ([, assetRequire]) => {
            return Asset.fromModule(assetRequire).downloadAsync();
          }
        );

        await Promise.all([
          NativeAuthStorage.sync(), // this is called internally by Cognito.configure() but it doesn't give us a promise!
          initExpoFonts(theme.cachedFonts),
          WebLocalStorage.sync(),
          ...importedSanityAssets,
        ] as Array<Promise<Asset | void>>);

        logMeasurement(Measures.APP_LOADING, PerformanceMarks.APP_LOADING_START);

        setIsAppReady(true);
        // eslint-disable-next-line prettier/prettier
      } catch (error: any) {
        handleFatalError('InitError', error);
      }
    };

    init();
  }, []);

  useOtaLogger();

  if (shouldShowEnvPickerAtStart()) {
    return <EnvPicker />;
  }

  if (!isAppReady) {
    return <View style={style.appContainer} />;
  }

  return (
    <View style={style.appContainer}>
      <Router>
        <ReduceProviders>
          <AppContainer />
        </ReduceProviders>
      </Router>
    </View>
  );
};

const style = StyleSheet.create({
  appContainer: { flex: 1, backgroundColor: Styles.color.background },
});
