import { useEffect, useMemo, useRef, useState } from 'react';

import { RunningExperiments, useExperiments } from 'state/experiments';
import { isNative } from 'utils/environment';

/**
 * Depending on the experiment variant, the user will see the following:
 *
 * | Variant    | Mobile                 | Web                 |
 * |------------|------------------------|---------------------|
 * | Control    | Home Page Feed         | Home Page Feed      |
 * | Treatment  | News Feed              | Home Page Feed      |
 *
 */
export enum ContentCardNotificationsExperimentVariants {
  CONTROL = 'control',
  TREATMENT = 'treatment',
}

export const useContentCardsNotificationsExperiment = () => {
  const { isLoading: isLoadingExperiment, variant } = useExperiments();
  const [experimentLoaded, setExperimentLoaded] = useState(false);
  const hasStartedLoading = useRef(false);

  const contentCardsExperiment = RunningExperiments.ContentCardsNotifications;

  const isTreatment = variant(contentCardsExperiment, 'treatment', {
    skipExposureLogging: true,
  });

  const isControl = variant(contentCardsExperiment, 'control', {
    skipExposureLogging: true,
  });

  const isNewsFeedEnabled = useMemo(() => isNative && isTreatment, [isTreatment]);

  const isHomePageFeedEnabled = useMemo(() => isControl || (isTreatment && !isNative), [
    isControl,
    isTreatment,
  ]);

  const isExperimentEnabled = isNewsFeedEnabled || isHomePageFeedEnabled;

  useEffect(() => {
    if (isLoadingExperiment) {
      hasStartedLoading.current = true;
    } else if (hasStartedLoading.current) {
      setExperimentLoaded(true);
    }
  }, [isLoadingExperiment]);

  return {
    isNewsFeedEnabled,
    isHomePageFeedEnabled,
    isExperimentEnabled: isExperimentEnabled && experimentLoaded,
    experimentToExpose: contentCardsExperiment,
  };
};
