import React, { FC } from 'react';

import { Icon } from '@rbilabs/universal-components';
import { CardType, IPaymentErrors, IPaymentState } from 'features/payment/types';
import { useIntl } from 'react-intl';

import { TextInput, TextInputMask } from 'components/ucl/text-input';
import { useValidateCCNumberInClientExperiment } from 'experiments/use-validate-cc-number-in-client';
import { PaymentFieldVariations } from 'state/launchdarkly/variations';
import { creditCardNumberMask, expiryMask } from 'utils/masks';

import {
  CardNumberWrapper,
  StyledCvvWrapper,
  StyledExpiryCVVWrapper,
  StyledExpiryWrapper,
} from './styled';

interface IFirstDataCreditCardFormInputs {
  onChange: (name: string, value: string) => void;
  errors: IPaymentErrors;
  paymentValues: IPaymentState;
  supportedCardTypes?: CardType[];
  paymentFieldVariations: PaymentFieldVariations;
  validateCreditCardForm?: () => boolean;
  didBlur: Record<string, boolean>;
  validateOnBlur: (fieldName: string) => void;
}

const CreditCardIcon: FC<React.PropsWithChildren<{ cardType: string | null }>> = ({ cardType }) =>
  (cardType &&
    {
      AMEX: <Icon variant="amex" />,
      DISCOVER: <Icon variant="discover" />,
      DINERS_CLUB: <Icon variant="diners" />,
      JCB: <Icon variant="jcb" />,
      MASTERCARD: <Icon variant="mastercard" />,
      VISA: <Icon variant="visa" />,
    }[cardType]) ||
  null;

const FirstDataCreditCardInputs: FC<React.PropsWithChildren<IFirstDataCreditCardFormInputs>> = ({
  errors,
  onChange,
  paymentValues,
  paymentFieldVariations,
  validateOnBlur,
  didBlur,
}) => {
  const { formatMessage } = useIntl();

  const validateCCExperiment = useValidateCCNumberInClientExperiment();

  return (
    <>
      {paymentFieldVariations.cardNumber && (
        <CardNumberWrapper>
          <TextInputMask
            aria-label={formatMessage({ id: 'creditCardNumber' })}
            onChangeText={value => onChange('cardNumber', value)}
            label={formatMessage({ id: 'creditCardNumber' })}
            value={paymentValues.cardNumber}
            required
            testID="cardNumber"
            onBlur={() => validateOnBlur('cardNumber')}
            errorMessage={
              validateCCExperiment && !didBlur.cardNumber && !errors.didAttemptSubmit
                ? ''
                : errors.cardType || errors.cardNumber
            }
            autoComplete="cc-number"
            keyboardType="numeric"
            // @ts-expect-error TS(2322) FIXME: Type '(cardNumber: string) => any[]' is not assign... Remove this comment to see the full error message
            mask={creditCardNumberMask}
            endIcon={
              paymentValues.isCardNumberValid ? (
                <CreditCardIcon cardType={paymentValues.cardType} />
              ) : null
            }
          />
        </CardNumberWrapper>
      )}
      <StyledExpiryCVVWrapper>
        {paymentFieldVariations.expiration && (
          <StyledExpiryWrapper>
            <TextInputMask
              autoComplete="cc-exp"
              onBlur={() => validateOnBlur('expiry')}
              errorMessage={
                validateCCExperiment && !didBlur.expiry && !errors.didAttemptSubmit
                  ? ''
                  : errors.expiry
              }
              keyboardType="numeric"
              label={formatMessage({ id: 'cardExpiration' })}
              mask={expiryMask}
              onChangeText={(value: string) => {
                onChange('expiry', value);
              }}
              required
              testID="expiry"
              value={paymentValues.expiry || ''}
            />
          </StyledExpiryWrapper>
        )}
        {paymentFieldVariations.cvv && (
          <StyledCvvWrapper>
            <TextInput
              aria-label={formatMessage({ id: 'cvv' })}
              onChangeText={value => onChange('cvv', value)}
              label={formatMessage({ id: 'cvv' })}
              value={paymentValues.cvv || ''}
              required
              testID="cvv"
              onBlur={() => validateOnBlur('cvv')}
              errorMessage={
                validateCCExperiment && !didBlur.cvv && !errors.didAttemptSubmit ? '' : errors.cvv
              }
              autoComplete="cc-csc"
              keyboardType="numeric"
              // TODO - RN input should support pattern
              // pattern="[0-9]*"
              maxLength={4}
            />
          </StyledCvvWrapper>
        )}
      </StyledExpiryCVVWrapper>
    </>
  );
};

export default FirstDataCreditCardInputs;
