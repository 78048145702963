import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { isCashApp } from 'features/payment/utils';

import { IBaseProps } from '@rbi-ctg/frontend';
import { CurrentSelectedMethodType } from 'components/payment-method/types';
import { IEncryptionResult } from 'components/payments/integrations/orbital/components/encryption/types';
import {
  IGetEncryptionDetailsMutation,
  IMergePrepaidInput,
  IMergePrepaidPayload,
  UserAccountsDocument,
  useAddCreditAccountMutation,
  useGetEncryptionDetailsMutation,
} from 'generated/graphql-gateway';
import { useRoute } from 'hooks/navigation/use-route';
import useEffectOnce from 'hooks/use-effect-once';
import useErrorModal from 'hooks/use-error-modal';
import { useAuthContext } from 'state/auth';
import { useLocale } from 'state/intl';
import { PaymentFieldVariations } from 'state/launchdarkly/variations';
import LocalStorage, { StorageKeys } from 'utils/local-storage';
import { routes } from 'utils/routing';
import { useMemoAll } from 'utils/use-memo-all';

import { getValidPaymentMethodId } from './hooks/getPaymentMethodsState/getValidPaymentMethodId';
import usePayment from './hooks/use-payment';
import { initialPaymentState } from './initial-state';
import {
  CardTypes,
  IAddPaymentMethodOptions,
  IPaymentDetails,
  IPaymentMethod,
  IPaymentPayload,
  IPaymentState,
  IReloadPrepaidCard,
} from './types';

export interface IPaymentContext {
  addPaymentMethod: (
    method: IPaymentPayload,
    options?: IAddPaymentMethodOptions,
    encryptionResult?: IEncryptionResult
  ) => Promise<string>;
  checkoutPaymentMethod: IPaymentMethod | undefined;
  checkoutPaymentMethodId: string;
  canUseApplePay: boolean;
  canUseGooglePay: boolean;
  clearPaymentDetails(): void;
  defaultPaymentMethodId: string;
  defaultReloadPaymentMethodId: string;
  deletePaymentMethod: (accountId: string) => Promise<void>;
  encryptionResult: IEncryptionResult | undefined;
  getBalanceFromPaymentMethods: (prepaidPaymentMethod: IPaymentMethod) => number;
  getCounter?: () => Promise<void>;
  getEncryptionDetails: () => Promise<
    Pick<
      IGetEncryptionDetailsMutation['encryptionDetails'],
      'fdPublicKey' | 'fdApiKey' | 'fdAccessToken' | 'fdCustomerId' | 'algorithm'
    >
  >;
  getPaymentMethods: () => Promise<void>;
  getPrepaidPaymentMethod: () => IPaymentMethod | null;
  getPrepaidCardNumber: () => string | null;
  hasGetPaymentMethodsError: boolean;
  isFirstData: boolean;
  isOrbital: boolean;
  loading: boolean;
  savingPaymentMethods: boolean;
  mergePrepaidCardBalances: (input: IMergePrepaidInput) => Promise<IMergePrepaidPayload>;
  paymentMethods: IPaymentMethod[];
  paymentDetails: IPaymentDetails;
  paymentProcessor: string | null | undefined;
  prepaidReloadPaymentMethodId: string;
  reloadPaymentValues: IPaymentState;
  reloadPrepaidCard: (input: IReloadPrepaidCard) => Promise<number | undefined>;
  setCheckoutPaymentMethodId: (accountId: string) => void;
  setEncryptionResult: (encryptionResult: IEncryptionResult | undefined) => void;
  setDefaultPaymentMethodId: (accountId: string) => void;
  setDefaultReloadPaymentMethodId: (accountId: string) => void;
  setPrepaidReloadPaymentMethodId: (accountId: string) => void;
  setLastCommitOrderErrorDate: Dispatch<SetStateAction<number | null>>;
  lastCommitOrderErrorDate: number | null;
  setSelected?: (fdAccountId: string) => void;
  setReloadPaymentValues: Dispatch<SetStateAction<IPaymentState>>;
  storePaymentDetails: (data: IPaymentDetails) => void;
  transformPaymentValues: ({
    paymentValues,
    paymentFieldVariations,
  }: {
    paymentValues: IPaymentState;
    paymentFieldVariations: PaymentFieldVariations;
  }) => IPaymentPayload;
  setLoading: (input: boolean) => void;
  isQuickPayMethodSelected: boolean;
  setIsQuickPayMethodSelected: Dispatch<SetStateAction<boolean>>;
  isAddPaymentMethodSuccess: boolean;
  setIsAddPaymentMethodSuccess: (input: boolean) => void;
  orderGrandTotal: number;
  setOrderGrandTotal: (input: number) => void;
  setPaymentMethodByNativePriority(): void;
  noPaymentMethodsAvailable: boolean;
  isCheckoutErrorsModalOpen: boolean;
  isStoreConfirmationOpen: boolean;
  processingPayment: boolean;
  showPaymentFooter: boolean;
  setIsCheckoutErrorsModalOpen: Dispatch<SetStateAction<boolean>>;
  setIsStoreConfirmationOpen: Dispatch<SetStateAction<boolean>>;
  setProcessingPayment: Dispatch<SetStateAction<boolean>>;
  setShowPaymentFooter: Dispatch<SetStateAction<boolean>>;
  isAddCreditCardModalVisible: boolean;
  setIsAddCreditCardModalVisible: (input: boolean) => void;
  accountToDelete: string;
  setAccountToDelete: (param: string) => void;
  showCashAppErrorMessage: boolean;
}

export const PaymentContext = createContext<IPaymentContext>({} as IPaymentContext);
export const usePaymentContext = () => useContext(PaymentContext);

const clearPersistedPaymentDetails = (): void => {
  LocalStorage.setItem(StorageKeys.PAYMENT, {});
};

const persistPaymentDetails = (paymentDetails: IPaymentDetails): void => {
  LocalStorage.setItem(StorageKeys.PAYMENT, paymentDetails);
};

const retrievePersistedPaymentDetails = (): IPaymentDetails | null => {
  return LocalStorage.getItem(StorageKeys.PAYMENT);
};

export function PaymentProvider(props: IBaseProps) {
  const { feCountryCode } = useLocale();
  const { updateUserInfo, user, isReAuthenticating } = useAuthContext();
  const [getEncryptionDetailsMutation] = useGetEncryptionDetailsMutation();
  const [addCreditAccountMutation] = useAddCreditAccountMutation({
    awaitRefetchQueries: true,
    refetchQueries: [{ query: UserAccountsDocument, variables: { feCountryCode } }],
  });

  const [paymentDetails, setPaymentDetails] = useState<IPaymentDetails>({});
  const [isQuickPayMethodSelected, setIsQuickPayMethodSelected] = useState(false);
  const [isAddPaymentMethodSuccess, setIsAddPaymentMethodSuccess] = useState(false);
  // The null value ensures that cashApp is not excluded from the payment methods array
  // and prevents changing the default method if cashApp was previously selected when this state updates.
  // This state can only be updated through the setOSPaymentMethod function.
  // It is used to conditionally render the cashApp button based on a minimum purchase amount of $1.
  const [orderGrandTotal, setOrderGrandTotal] = useState<number | null>(null);
  const [lastCommitOrderErrorDate, setLastCommitOrderErrorDate] = useState<number | null>(null);
  const { pathname } = useRoute();
  const isCartPayment = pathname === routes.cartPayment;
  const [isCheckoutErrorsModalOpen, setIsCheckoutErrorsModalOpen] = useState(false);
  const [isStoreConfirmationOpen, setIsStoreConfirmationOpen] = useState(false);
  const [processingPayment, setProcessingPayment] = useState<boolean>(false);
  const [showPaymentFooter, setShowPaymentFooter] = useState<boolean>(false);
  const [isAddCreditCardModalVisible, setIsAddCreditCardModalVisible] = useState(false);
  const [accountToDelete, setAccountToDelete] = useState('');
  const isCashAppAllowed = useMemo(
    () => !isCartPayment || orderGrandTotal === null || orderGrandTotal >= 100,
    [isCartPayment, orderGrandTotal]
  );
  const [ErrorDialog, openErrorDialog] = useErrorModal({
    modalAppearanceEventMessage: 'Error: Payment Error',
  });

  const [reloadPaymentValues, setReloadPaymentValues] = useState(
    initialPaymentState({ billingCountry: feCountryCode, userDetailsName: user?.details?.name })
  );

  const storePaymentDetails = useCallback((data: IPaymentDetails): void => {
    setPaymentDetails(data);
    persistPaymentDetails(data);
  }, []);

  const clearPaymentDetails = useCallback(() => {
    setPaymentDetails({});
    clearPersistedPaymentDetails();
  }, []);

  useEffectOnce(() => {
    const values = retrievePersistedPaymentDetails();
    if (values) {
      setPaymentDetails(values);
    }
  });

  const {
    addPaymentMethod,
    canUseApplePay,
    canUseGooglePay,
    checkoutPaymentMethod,
    checkoutPaymentMethodId,
    defaultPaymentMethodId,
    defaultReloadPaymentMethodId,
    deletePaymentMethod,
    encryptionResult,
    setEncryptionResult,
    getEncryptionDetails,
    getBalanceFromPaymentMethods,
    getPaymentMethods,
    getPrepaidCardNumber,
    getPrepaidPaymentMethod,
    hasGetPaymentMethodsError,
    isFirstData,
    isOrbital,
    loading,
    savingPaymentMethods,
    mergePrepaidCardBalances,
    paymentMethods,
    paymentProcessor,
    prepaidReloadPaymentMethodId,
    reloadPrepaidCard,
    setCheckoutPaymentMethodId,
    setDefaultPaymentMethodId,
    setDefaultReloadPaymentMethodId,
    setPrepaidReloadPaymentMethodId,
    transformPaymentValues,
    setLoading,
    noPaymentMethodsAvailable,
  } = usePayment({
    getEncryptionDetailsMutation,
    openErrorDialog,
    user,
    isReAuthenticating,
    updateUserInfo,
    addCreditAccountMutation,
    paymentDetails,
    isQuickPayMethodSelected,
  });

  const showCashAppErrorMessage =
    isCartPayment && !isCashAppAllowed && isCashApp(checkoutPaymentMethodId);

  const setPaymentMethodByNativePriority = useCallback(() => {
    if (canUseApplePay) {
      setCheckoutPaymentMethodId(CardTypes.APPLE_PAY);
      setDefaultPaymentMethodId(CardTypes.APPLE_PAY);
      return;
    }
    if (canUseGooglePay) {
      setCheckoutPaymentMethodId(CardTypes.GOOGLE_PAY);
      setDefaultPaymentMethodId(CardTypes.GOOGLE_PAY);
      return;
    }
    // Remove CashApp from the list
    const paymentMethodsWithoutCashApp = paymentMethods.filter(
      method => !isCashApp(method.accountIdentifier || method.fdAccountId)
    );
    const validPaymentMethodId = getValidPaymentMethodId({
      canUseApplePay,
      canUseGooglePay,
      // If gift card payment is available, it will return this method;
      // otherwise, it will select the first available one from the list.
      paymentMethodId: CardTypes.GIFT_CARD,
      paymentMethods: paymentMethodsWithoutCashApp,
      returnFirstValid: true,
    });

    if (validPaymentMethodId) {
      setCheckoutPaymentMethodId(validPaymentMethodId);
      setDefaultPaymentMethodId(validPaymentMethodId);
    }
  }, [
    canUseApplePay,
    canUseGooglePay,
    paymentMethods,
    setCheckoutPaymentMethodId,
    setDefaultPaymentMethodId,
  ]);

  // when clearing the paymentDetails, and the One Time Payment Method was selected, we need to update
  // the checkoutPaymentMethodId with the user's default payment method
  useEffect(() => {
    if (
      checkoutPaymentMethodId === CurrentSelectedMethodType.ADD_NEW_CARD &&
      !paymentDetails.state
    ) {
      setPaymentMethodByNativePriority();
    }
  }, [
    checkoutPaymentMethodId,
    defaultPaymentMethodId,
    paymentDetails.state,
    setCheckoutPaymentMethodId,
    setPaymentMethodByNativePriority,
  ]);

  const value = useMemoAll({
    addPaymentMethod,
    canUseApplePay,
    canUseGooglePay,
    checkoutPaymentMethod,
    checkoutPaymentMethodId,
    clearPaymentDetails,
    encryptionResult,
    setEncryptionResult,
    defaultPaymentMethodId,
    defaultReloadPaymentMethodId,
    deletePaymentMethod,
    getPaymentMethods,
    getBalanceFromPaymentMethods,
    getPrepaidPaymentMethod,
    getPrepaidCardNumber,
    getEncryptionDetails,
    hasGetPaymentMethodsError,
    isFirstData,
    isOrbital,
    loading,
    savingPaymentMethods,
    lastCommitOrderErrorDate,
    mergePrepaidCardBalances,
    paymentMethods,
    paymentProcessor,
    paymentDetails,
    prepaidReloadPaymentMethodId,
    reloadPaymentValues,
    reloadPrepaidCard,
    setCheckoutPaymentMethodId,
    setDefaultPaymentMethodId,
    setDefaultReloadPaymentMethodId,
    setLastCommitOrderErrorDate,
    setPrepaidReloadPaymentMethodId,
    storePaymentDetails,
    setReloadPaymentValues,
    transformPaymentValues,
    setLoading,
    isQuickPayMethodSelected,
    setIsQuickPayMethodSelected,
    isAddPaymentMethodSuccess,
    setIsAddPaymentMethodSuccess,
    orderGrandTotal: orderGrandTotal ?? 0,
    setOrderGrandTotal,
    setPaymentMethodByNativePriority,
    noPaymentMethodsAvailable,

    isCheckoutErrorsModalOpen,
    isStoreConfirmationOpen,
    processingPayment,
    showPaymentFooter,
    setIsCheckoutErrorsModalOpen,
    setIsStoreConfirmationOpen,
    setProcessingPayment,
    setShowPaymentFooter,
    isAddCreditCardModalVisible,
    setIsAddCreditCardModalVisible,
    accountToDelete,
    setAccountToDelete,
    showCashAppErrorMessage,
  });

  return (
    <PaymentContext.Provider value={value}>
      {props.children}
      <ErrorDialog />
    </PaymentContext.Provider>
  );
}

export default PaymentContext.Consumer;
