import { primitive } from 'styles/constants/primitives';

const destinationMarkerSvg = `
<svg width="48" height="60" fill="none" viewBox="0 0 48 60" xmlns="http://www.w3.org/2000/svg">
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M31.033 46.953C40.856 43.947 48 34.808 48 24 48 10.745 37.255 0 24 0S0 10.745 0 24c0 10.347 6.548 19.165 15.726 22.536L23.5 60l7.533-13.047z"
    fill="${Styles.color.red}"
  />
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M36.902 20.465h-2.187V32.24c0 .504-.337 1.009-.841 1.009h-6.56v-9.084h-6.73v9.084h-6.056c-.505 0-.841-.336-.841-.841V20.465H11.5v-1.682c0-.337.168-.841.505-1.01l11.607-6.897c.337-.168.841-.168 1.178 0l11.607 6.897c.337.169.505.673.505 1.01v1.682z"
    fill="${primitive.$white}"
  />
</svg>
`;

// eslint-disable-next-line import/no-default-export
export default destinationMarkerSvg;
