import { format } from 'date-fns';
import { useIntl } from 'react-intl';

import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';

export function useOfferExpiration(
  endDate: string | null | undefined,
  isWalmartOffer: boolean
): string | null {
  const { formatMessage } = useIntl();
  let expiresAt: string | null = null;

  const enableOfferExpirationDisplay = useFlag(
    LaunchDarklyFlag.ENABLE_LOYALTY_OFFER_EXPIRATION_DISPLAY
  );

  if (
    enableOfferExpirationDisplay &&
    !isWalmartOffer &&
    typeof endDate === 'string' &&
    endDate &&
    endDate.slice(0, 4) !== '9999'
  ) {
    expiresAt = formatMessage(
      { id: 'offerExpires' },
      { date: format(new Date(endDate), 'MM/dd/yy') }
    );
  }
  return expiresAt;
}
