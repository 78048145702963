import { Box } from '@rbilabs/universal-components';

export const StyledBox = Box.withConfig({
  padding: '$4',
});

export const BlockContainer = Box.withConfig({
  paddingLeft: '$4',
  paddingRight: '$4',
  maxWidth: '912px',
  alignSelf: 'center',
  width: '100%',
});
