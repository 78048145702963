import { SetStateAction } from 'react';

import { ICartEntry, ISanityImage } from '@rbi-ctg/menu';
import { ILocalizedMenuEntry } from 'state/unified-menu/types';

export const DEFAULT_SLOT_KEY = 'slot_0';

export type PickerSelections = {
  [pickerAspectId: string]: string;
};

export type MultipliersSelections = {
  // Multiplier key to quantity
  [multiplierKey: string]: number;
};

export type ModifiersSelections = {
  // Modifier key to multipliers
  [modifierKey: string]: MultipliersSelections;
};

export type SlotSelections = {
  itemId: string;
  modifiers?: ModifiersSelections;
};

export type SlotsSelections = {
  [slotKey: string]: SlotSelections | undefined;
};

export interface IProductWizardContext {
  /**
   * The raw menu data object passed to wizard
   */
  menuObject: ILocalizedMenuEntry;
  /**
   * The selected Combo/Item that can be customized and added to cart
   */
  selectedProduct: ILocalizedMenuEntry;
  /**
   * The ISanityImage of selected Combo/Item.
   */
  selectedProductImage: ISanityImage;
  /**
   * Update selectedProduct in context.
   */
  setSelectedProduct: React.Dispatch<SetStateAction<ILocalizedMenuEntry>>;
  /**
   * The quantity selected by the user.
   */
  productQuantity: number;
  setProductQuantity: React.Dispatch<SetStateAction<number>>;
  /**
   * Controls whether or not the close dialog should display.
   */
  showCloseDialog: boolean;
  /**
   * Displays the Close Dialog
   * Previously known handleCloseDialog
   */
  setShowCloseDialog: React.Dispatch<SetStateAction<boolean>>;
  /**
   * Each pickerAspect selection (if any)
   */
  pickerSelections: PickerSelections;
  selectPickerAspectOption: (pickerAspectKey: string, pickerAspectOptionId: string) => void;
  /**
   * Each comboSlot selected items and modifiers.
   * If only item, slot_0 is used as a key.
   */
  slotsSelections: SlotsSelections;
  selectComboSlotItem: (slotKey: string, itemId: string) => void;

  selectModifierMultiplier: (
    slotKey: string,
    itemId: string,
    modifierKey: string,
    multipliers: MultipliersSelections
  ) => void;

  /**
   * A representation of a picker Flow
   */
  flow: FlowStep[];

  /**
   * Saves the current slots selections to be restored later
   */
  saveSlotsSelectionSnapshot(): void;

  /**
   * Restores the slot selections to the last saved snapshot
   */
  restoreSlotsSelectionSnapshot(): void;

  /**
   * Clears the slot selections snapshot
   */
  clearSlotsSelectionSnapshot(): void;

  /**
   * Returns whether or not there are unsaved changes
   */
  hasUnsavedChanges(): boolean;
  /**
   * Returns whether any slot has been customized.
   */
  isAnySlotCustomized(): boolean;
}

export interface IProductWizardProvider {
  menuObject: ILocalizedMenuEntry;
  editingCartEntry?: ICartEntry;
}

export enum ProductWizardStep {
  ProductHome = 'ProductHome',
  ProductCustomization = 'ProductCustomization',
  PickerSelection = 'PickerSelection',
  PickerCustomization = 'PickerCustomization',
  PickerCustomizationSides = 'PickerCustomizationSides',
  PickerCustomizationDrinks = 'PickerCustomizationDrinks',
  PickerReview = 'PickerReview',
}

export interface FlowStep {
  step: ProductWizardStep;
  slotKey?: string;
  pickerAspectKey?: string;
}

export interface IProductWizardParams {
  id: string;
  cartIdEditing?: string;
  step?: ProductWizardStep;
  slotKey?: string;
  pickerAspectKey?: string;
  rewardBenefitId?: string;
}

export interface IProductWizardFlow {
  /**
   * Id of the cart entry being edited. Menu flow will assign empty string.
   */
  cartIdEditing: string;
  /**
   * Current step in the flow
   */
  currentFlowStep: FlowStep;
  /** The MenuObject ID */
  id: string;
  /**
   * Next step in the flow (if any)
   */
  nextFlowStep: FlowStep | undefined;
  /**
   * Reward benefit id
   */
  rewardBenefitId?: string;
  /**
   * Closes the wizard
   */
  closeWizard: () => void;
  /**
   * Navigates to the product customization screen
   */
  goToCustomization: (slotKey: string) => void;
  /**
   * Navigates backwards through the flow
   */
  goToPreviousStep: () => void;
  /**
   * Navigates forwards through the flow
   */
  goToNextStep: () => void;
}
