import { ReactNode } from 'react';

import { Box } from '@rbilabs/universal-components';

/**
 * Wrapping your modal content with a `Box` and setting the `focusable` prop to `true` is a clever way to prevent the unwanted automatic focus
 * behavior in the Chrome browser.
 *
 */
export const FocusableContainer: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  return (
    <Box justifyContent="center" flex={1} focusable backgroundColor="transparent" outlineWidth={0}>
      {children}
    </Box>
  );
};
