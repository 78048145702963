import { ApolloError, isApolloError } from '@apollo/client';
import { CardTypes } from 'features/payment/types';
import { GraphQLError as GQLError } from 'graphql';
import { IntlFormatters } from 'react-intl';

import { NonNullableObject } from '@rbi-ctg/frontend';
import {
  messageIdForPspRefusedErrors,
  messageIdMappingForDialogs,
} from 'pages/cart/payment/message-id-mappings';
import { filterMap } from 'utils/array';
import { GraphQLError } from 'utils/network';

import { GraphQLErrorCodes, IGraphqlErrorMap, paymentErrorModalMaps } from './types';

const mapErrorsWithCodes = filterMap<GQLError, NonNullableObject<GQLError>, IGQLParsedErrorCode>(
  err => !!err.extensions?.code,
  err =>
    ({
      errorCode: err.extensions.code,
      message: err.message,
      extensions: err.extensions || {},
    } as IGQLParsedErrorCode)
);

/**
 * Given a GraphQL error, parse all the GraphQL error codes.
 */
export function parseGraphQLErrorCodes(error: GraphQLError | ApolloError): IGQLParsedErrorCode[] {
  if (isApolloError(error)) {
    return mapErrorsWithCodes(error.graphQLErrors);
  }
  return mapErrorsWithCodes(error.originalError || []);
}

export const isPspRefusedError = (error: any) =>
  parseGraphQLErrorCodes(error)[0]?.extensions?.responseMessage || null;
/**
 * Given an array of graphql errors, do any errors have an extension with the given code
 *
 * @param graphQLErrors {GraphQLError[]} An array of Apollo GraphQL errors
 * @param code {GraphQLErrorCodes} The code
 */
export const graphQLErrorsIncludeExtensionCode = (
  graphQLErrors: ApolloError['graphQLErrors'] = [],
  code: GraphQLErrorCodes
) => graphQLErrors.some(graphQLError => graphQLError?.extensions?.code === code);

export interface IErrorDialog {
  title?: string;
  body?: string | React.ReactNode;
  link?: {
    key: string;
    to: string;
    text: string;
  };
  message: string;
}

export interface IGQLParsedErrorCode {
  errorCode: GraphQLErrorCodes;
  message?: string;
  extensions?: any;
}

/**
 * Given an error code from the CTG GQL api, return localized error dialog content.
 *
 * Returns `undefined` if we have no dialog defined for the given error code.
 * @param parsedError RBI CTG GraphQL error code
 * @param staticStrings Defined static strings
 */
export function getErrorDialogForGraphQLCode(
  parsedError: IGQLParsedErrorCode,
  formatMessage: IntlFormatters['formatMessage']
): IErrorDialog | undefined {
  const { errorCode, message } = parsedError;

  const paymentModalKey = paymentErrorModalMaps[errorCode];
  const errorDialog = messageIdMappingForDialogs[paymentModalKey];
  if (!errorDialog) {
    return;
  }
  const { title: titleId, body, message: messageId } = errorDialog;
  if (errorCode === GraphQLErrorCodes.GRAPHQL_VALIDATION_FAILED && message) {
    return {
      title: formatMessage({ id: titleId }),
      body: message,
      message,
    };
  }

  return {
    title: formatMessage({ id: titleId }),
    body: formatMessage({ id: body }),
    message: formatMessage({ id: messageId }),
  };
}

/**
 * Given an error code from PSP Refused errors, return the dialog information
 *
 * Returns `undefined` if we have no dialog defined for the given error code.
 * @param pspErrorCode PSP error code
 * @param formatMessage Function to get proper error message
 */
export function getPspRefusedErrorDialog(
  pspErrorCode: string,
  formatMessage: IntlFormatters['formatMessage']
): IErrorDialog | undefined {
  const errorDialog = messageIdForPspRefusedErrors[pspErrorCode];
  if (!errorDialog) {
    return;
  }
  const { title: titleId, message: messageId } = errorDialog;

  return {
    title: formatMessage({ id: titleId }),
    message: formatMessage({ id: messageId }),
  };
}

/**
 * Digital Wallet payment error modal key
 * Returns the key for the digital wallet payment error modal
 * @param fdAccountId
 * @param defaultMessage
 * @returns
 */
export const getAppleOrGooglePayError = (
  fdAccountId: string,
  defaultMessage?: string
): string | undefined => {
  // Send custom message if it's apple pay or google pay
  // Otherwise, show default message
  const messageMapping = {
    [CardTypes.APPLE_PAY]: 'orderFailureMessageForApplePay',
    [CardTypes.GOOGLE_PAY]: 'orderFailureMessageForGooglePay',
  };

  return messageMapping[fdAccountId] || defaultMessage;
};

/**
 * Creates a dictionary from an array of gql errors using code as the lookup index
 * If a code is not supplied the key will be `GQL_ERROR_$index`
 *
 * @param {GQLError[]} gqlErrors
 *
 * @returns {IGraphqlErrorMap}
 */
export const createGqlErrorMap = (gqlErrors: GQLError[]): IGraphqlErrorMap =>
  gqlErrors.reduce((acc, error, idx) => {
    const key = `${error.extensions?.code || 'GQL_ERROR'}_${idx}`;
    acc[key] = error;
    return acc;
  }, {});

export * from './types';
