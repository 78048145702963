import React from 'react';

import { StaticImage, StaticImages } from 'components/picture/static-image';

export const StoreLocatorLoader = () => {
  return (
    <StaticImage
      alt="Store locator loading"
      style={{
        width: 100,
      }}
      source={StaticImages.StoreLocatorLoader}
    />
  );
};
