import { Header as UCHeader } from '@rbilabs/universal-components';

import { ModalContent as UCModalContent } from 'components/modal';

export const ModalContent = UCModalContent.withConfig({
  alignSelf: 'center',
  width: 'full',
  mt: '$0',
  maxWidth: {
    md: '50%',
    lg: '50%',
  },
});

export const Header = UCHeader.withConfig({
  mb: '$8',
  variant: 'headerOne',
  testID: 'modal-heading',
  textAlign: 'center',
});
