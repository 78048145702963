import React, { TouchEventHandler, useCallback, useEffect, useMemo, useState } from 'react';

import { Box } from '@rbilabs/universal-components';
import { debounce, uniqBy } from 'lodash-es';

import { ILocation } from '@rbi-ctg/frontend';
import { IRestaurant } from '@rbi-ctg/store';
import useMap from 'hooks/use-map';
import { IUseMapArgs } from 'hooks/use-map/types';
import { useIsDesktopBreakpoint } from 'hooks/use-media-query';
import { ClickEventComponentNames, CustomEventNames, logButtonClick } from 'state/amplitude';
import { useStoreContext } from 'state/store';

import { useStoreMapMarkers } from '../../hooks/use-markers';
import { StoreCard } from '../store-card';

import { MapButtons } from './map-buttons';
import { MapContainer } from './map.view.styled';

import { StoreLocatorMap } from '.';

export interface IMapViewProps {
  activeCoordinates: ILocation | null;
  currentViewIsMap: boolean;
  handleResetLocation: () => void;
  onPressSearch: (location: ILocation) => void;
  storesFavs: IRestaurant[];
  storesRecent: IRestaurant[];
  storesNearby: IRestaurant[];
  isInSupportForm?: boolean;
  restaurantFocused?: IRestaurant;
  handleSelectStore?: (storeId: string) => void;
  setRestaurantFocused?: (restaurant: IRestaurant) => void;
}

const setupEvtListeners = (onDragEnd: TouchEventHandler) => ({
  dragend: debounce(onDragEnd, 200),
});

const MapViewStoreCard: React.FC<{
  restaurant: IRestaurant;
  isSelected: boolean;
  handleSelectStore?: (storeId: string) => void;
  isInSupportForm?: boolean;
}> = ({ restaurant, isSelected, handleSelectStore, isInSupportForm }) => {
  return (
    <StoreCard
      restaurant={restaurant}
      isSelected={isSelected}
      handleSelectStore={handleSelectStore}
      isInMapView
      isInSupportForm={isInSupportForm}
    />
  );
};

export const MapView: React.FC<React.PropsWithChildren<IMapViewProps>> = ({
  activeCoordinates,
  currentViewIsMap,
  handleResetLocation,
  onPressSearch,
  storesFavs,
  storesNearby,
  storesRecent,
  isInSupportForm,
  handleSelectStore,
  restaurantFocused,
}) => {
  const isDesktop = useIsDesktopBreakpoint();

  const { store: preSelectedStore } = useStoreContext();

  const [focusedStore, setFocusedStore] = useState<IRestaurant | null>(() => {
    return preSelectedStore ?? null;
  });

  const [hasPanned, setHasPanned] = useState(false);

  const eventListeners: IUseMapArgs['eventListeners'] = useMemo(() => {
    return {
      ...setupEvtListeners(() => {
        setHasPanned(true);
      }),
    };
  }, []);

  const { center, clearMarkers, createMarker, map, zoom, panTo } = useMap(
    activeCoordinates
      ? {
          eventListeners,
          position: activeCoordinates,
        }
      : { eventListeners }
  );

  useEffect(() => {
    if (restaurantFocused) {
      panTo({ lat: restaurantFocused?.latitude, lng: restaurantFocused?.longitude });
      setFocusedStore(restaurantFocused);
    }
  }, [panTo, restaurantFocused]);

  const onPressButtonSearch = useCallback(() => {
    setHasPanned(false);

    onPressSearch(center);
  }, [center, onPressSearch]);

  /**
   * TODO:
   *  - should we still include favs & recents in map view, if we have values?
   *  - added recents here bc idk why they weren't there in current implementation
   */
  const storesWithMarkers = useMemo(() => {
    clearMarkers();
    return uniqBy([...storesNearby, ...storesFavs, ...storesRecent], '_id');
  }, [clearMarkers, storesFavs, storesNearby, storesRecent]);

  const onPress = useCallback((store: IRestaurant) => {
    if (!store._id) {
      return;
    }

    setFocusedStore(prevSelectedStore => {
      if (prevSelectedStore !== store) {
        logButtonClick({
          attributes: {
            ...(store._id && { StoreId: store._id }),
            Name: CustomEventNames.BUTTON_CLICK_STORE_SELECTED,
            name: CustomEventNames.BUTTON_CLICK_STORE_SELECTED,
            component: ClickEventComponentNames.STORE_LOCATOR_MAP_MARKER,
          },
        });
      }

      return store;
    });

    // TODO: don't think we need this - just log the marker click
    // const { latitude: lat, longitude: lng } = store;

    // if (lat && lng) {
    //   return searchNearbyRestaurants({ location: { lat, lng } });
    // }
  }, []);

  useStoreMapMarkers({
    createMarker,
    storesFavs,
    storesNearby: storesWithMarkers,
    selectedStoreId: preSelectedStore?._id,
    onPress,
    panTo,
    focusedStoreId: focusedStore?.id,
  });

  const isSelected = preSelectedStore._id === focusedStore?._id;
  useEffect(() => setFocusedStore(preSelectedStore._id ? preSelectedStore : null), [
    preSelectedStore,
  ]);

  return (
    <MapContainer>
      <StoreLocatorMap map={map} currentViewIsMap={currentViewIsMap} />
      {currentViewIsMap && (
        <Box position="absolute" bottom={0} left={0} right={0}>
          <MapButtons
            handleResetLocation={handleResetLocation}
            hasPanned={hasPanned}
            isDesktop={isDesktop}
            onPressSearch={onPressButtonSearch}
            zoom={zoom}
          />
          {focusedStore?._id && (
            <MapViewStoreCard
              restaurant={focusedStore}
              isSelected={isSelected}
              handleSelectStore={handleSelectStore}
              isInSupportForm={isInSupportForm}
            />
          )}
        </Box>
      )}
    </MapContainer>
  );
};
