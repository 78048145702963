import jwtDecode from 'jwt-decode';
import { useIntl } from 'react-intl';
import { IResolveParams, LoginSocialApple } from 'reactjs-social-login';

import ActionButton, { ActionButtonVariants } from 'components/action-button';
import AppleIcon from 'pages/authentication/components/social-auth-buttons/apple/icon';
import AuthButton from 'pages/authentication/components/social-auth-buttons/auth-button';
import {
  SocialLoginParams,
  UserAuth,
} from 'pages/authentication/components/social-auth-buttons/types';
import { appleWebClientIds } from 'utils/environment';

const AppleAuthButton = ({
  isLoading,
  isDisabled,
  onSuccess,
  onError,
  onStart,
}: SocialLoginParams) => {
  const { formatMessage } = useIntl();
  const authButton = (
    <AuthButton
      icon={<AppleIcon />}
      label={formatMessage({ id: 'logInWithApple' })}
      loading={isLoading}
      disabled={isDisabled}
    />
  );

  return (
    <ActionButton
      fullWidth
      borderColor={Styles.color.black}
      marginY="$2"
      variant={ActionButtonVariants.OUTLINE}
      disabled={isLoading || isDisabled}
    >
      {/*On IOS mobile web, the LoginSocial component doesn't take care about disabling the child button, and
          it fires the click event, to solve this we just return the disabled button without the LoginSocial component*/}
      {isDisabled ? (
        authButton
      ) : (
        <LoginSocialApple
          client_id={appleWebClientIds.client_id}
          redirect_uri={appleWebClientIds.redirect_uri}
          onResolve={({ data }: IResolveParams) => {
            const tokenDecoded: { email?: string } = jwtDecode(data?.authorization?.id_token);
            const userInfo: UserAuth = {
              // TODO: I have to save the email on LS as apple just return the email on the first log in
              name: data?.user?.name?.firstName || '',
              email: tokenDecoded?.email,
              token: data?.authorization?.id_token,
            };
            onSuccess(userInfo);
          }}
          onReject={(error: any) => {
            onError(error);
          }}
          onLoginStart={() => onStart()}
        >
          {authButton}
        </LoginSocialApple>
      )}
    </ActionButton>
  );
};

export default AppleAuthButton;
