import React, { FC, createContext, useContext, useMemo } from 'react';

import noop, { asyncNoop } from 'utils/noop';

import { IFlyBuyContext } from './types';

export const FlyBuyContext = createContext<IFlyBuyContext>({} as IFlyBuyContext);

export const useFlyBuyContext = () => useContext(FlyBuyContext);

export const FlyBuyProvider: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const value = useMemo(
    () => ({
      isAvailable: false,
      isReady: false,
      isReadyWithTimedFire: false,
      init: asyncNoop,
      needPermissions: false,
      checkPermissions: async () => false,
      isCheckingPermissions: false,
      createOrder: asyncNoop,
      permissionsModalShown: false,
      setPermissionsModalShown: noop,
    }),
    []
  );

  return <FlyBuyContext.Provider value={value}>{children}</FlyBuyContext.Provider>;
};
