import { ProductWizardStep } from '../product-wizard/types';

export const isCustomization = (step: ProductWizardStep) =>
  [
    ProductWizardStep.ProductCustomization,
    ProductWizardStep.PickerCustomization,
    ProductWizardStep.PickerCustomizationSides,
    ProductWizardStep.PickerCustomizationDrinks,
  ].includes(step);

export const QUANTITY_DEFAULTS = { min: 0, max: 1 };
