import { sanitizeSanityBlockContent } from 'components/sanity-block-renderer/helpers';
import { LoyaltyOfferWithInlineAlertMessage } from 'state/loyalty/hooks/types';
import { LoyaltyReward, isReward } from 'state/loyalty/types';
import { maybeLocale, maybeLocaleImages } from 'utils/graphql';
import { blockContentToPlainText } from 'utils/sanity';

export const parseIncentiveData = (
  incentive: LoyaltyReward | LoyaltyOfferWithInlineAlertMessage
) => {
  const incentiveData = isReward(incentive)
    ? {
        description: incentive?.description?.locale,
        image: maybeLocale(incentive?.image),
        imageDescription: incentive?.imageDescription?.locale || '',
        name: maybeLocale(incentive?.name) || '',
        moreInfo: incentive?.moreInfo?.enRaw,
        isOffer: false,
        metadata: null,
      }
    : {
        description: incentive?.description?.localeRaw,
        image: maybeLocaleImages(incentive?.localizedImage),
        imageDescription: '',
        name: blockContentToPlainText(incentive?.name?.localeRaw),
        moreInfo: incentive?.moreInfo?.localeRaw,
        isOffer: true,
        offerServiceMode: incentive?.offerServiceMode || '',
        metadata: incentive?.metadata,
        inlineAlertMessage: incentive?.inlineAlertMessage,
        endDate: incentive?.endDate,
      };

  return {
    id: incentive?._id,
    backgroundImage: incentive?.backgroundImage,
    loyaltyEngineId: incentive?.loyaltyEngineId ?? '',
    ...incentiveData,
    description: incentiveData.description
      ? sanitizeSanityBlockContent(incentiveData.description)
      : undefined,
  };
};
