import * as React from 'react';

import { IRestaurant } from '@rbi-ctg/store';
import { IStoreDiagnosticData } from 'components/store-diagnostic-modal';
import { useStoreCard } from 'hooks/store-card';
import { useServiceModeStatus } from 'hooks/use-service-mode-status';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';

interface IUseDiagnosticsOptions {
  restaurant: IRestaurant;
}

export const useDiagnostics = ({ restaurant }: IUseDiagnosticsOptions) => {
  const isEnabled = useFlag(LaunchDarklyFlag.ENABLE_INTERNAL_DIAGNOSTIC_TOOLS);
  const [showModal, setShowModal] = React.useState(false);

  const onClick = React.useMemo(() => {
    return isEnabled ? () => setShowModal(true) : undefined;
  }, [isEnabled]);

  const onDismiss = React.useCallback(() => setShowModal(false), []);

  const {
    driveThruOpen,
    diningRoomOpen,
    isMobileOrderingAvailable,
    mobileOrderDriveThruOpen,
    isRestaurantPosOnline,
  } = useStoreCard({ restaurant, shouldGetRemoteAvailabilityData: false });

  const { restaurantCanBeSelected } = useServiceModeStatus(restaurant);

  const data: IStoreDiagnosticData = {
    driveThruOpen,
    diningRoomOpen,
    isRestaurantOpen: restaurantCanBeSelected,
    isMobileOrderingAvailable,
    isRestaurantPosOnline,
    mobileOrderDriveThruOpen,
    restaurantName: restaurant.name || '',
    restaurantHasMobileOrdering: Boolean(restaurant.hasMobileOrdering),
    restaurantCountry: restaurant?.physicalAddress?.country || '',
    restaurantStreetAddress: restaurant?.physicalAddress?.address1 || '',
  };

  return {
    data,
    onClick,
    onDismiss,
    showModal,
  };
};
