import { useMemo } from 'react';

import { ServiceMode, useServiceModeContext } from 'state/service-mode';

import { getPosServiceMode } from './utils';

// Not setting a default service mode to understand which parts of the app are using this hook without a service mode initialized
// Will treat this on a case by case basis
export function useMenuQueryOptions(defaultServiceMode?: ServiceMode) {
  const { serviceMode } = useServiceModeContext();

  if (!serviceMode && !defaultServiceMode) {
    throw new Error('Service mode is not set');
  }

  const value = useMemo(
    () => ({
      posServiceMode: getPosServiceMode(
        serviceMode ?? defaultServiceMode ?? ServiceMode.DRIVE_THRU
      ),
    }),
    [serviceMode, defaultServiceMode]
  );
  return value;
}
