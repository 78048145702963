import { Box, Header, Text } from '@rbilabs/universal-components';

import { WEB_APP_CTA_MAX_WIDTH } from 'utils/constants';

export const Wrapper = Box.withConfig({
  paddingX: { base: '$4', md: 0 },
  maxW: WEB_APP_CTA_MAX_WIDTH,
  alignSelf: 'center',
  justifyContent: 'center',
  flex: 1,
  _web: {
    flex: 'none',
  },
});

export const IconWrapper = Box.withConfig({
  flexDirection: 'row',
  justifyContent: 'center',
  marginBottom: '$4',
  marginTop: '$2',
});

export const HeadingText = Header.withConfig({
  color: Styles.color.black,
  fontSize: '2xl',
  textAlign: 'center',
  accessibilityLevel: 1,
});

export const BodyText = Text.withConfig({
  fontSize: 'md',
  textAlign: 'center',
  marginBottom: {
    base: '$2',
    md: '$4',
  },
});
