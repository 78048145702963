import React from 'react';

import { Box, Icon } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import { LinkNoDecoration } from 'components/app-header/styled';
import { VisuallyHidden } from 'components/ucl/visually-hidden';
import { useNavigation } from 'hooks/navigation/use-navigation';
import { portalToHeader } from 'state/mobile-header-nav';
import { theme } from 'styles/configure-theme';
import { hiddenAccessibilityPlatformProps } from 'utils/accessibility';

const StyledBackIcon = Icon.withConfig({
  color: theme.token('icon-header-contrast'),
  size: '5',
  ...hiddenAccessibilityPlatformProps,
});

interface BackArrowLinkProps {
  to: string;
  handleOnPress?: any;
}

const PortalBackArrowLink: React.VFC<BackArrowLinkProps> = portalToHeader(
  ({ to, handleOnPress }) => {
    const { formatMessage } = useIntl();

    return (
      <LinkNoDecoration to={to} onPress={handleOnPress}>
        <StyledBackIcon variant="back" />
        <VisuallyHidden accessibilityLabel={formatMessage({ id: 'back' })} />
      </LinkNoDecoration>
    );
  }
);
export const BackArrowLink = PortalBackArrowLink;

interface BackArrowHistoryProps {
  onPress?: () => void;
  icon?: React.ReactNode;
  color?: string;
  accessibilityLabel?: string;
}
export const BackArrow: React.FC<BackArrowHistoryProps> = ({
  onPress,
  color = theme.token('icon-header-contrast'),
  icon = <StyledBackIcon variant="back" color={color} />,
  accessibilityLabel,
}) => {
  const { formatMessage } = useIntl();
  const { goBack } = useNavigation();
  const onPressBack = () => goBack();
  const backButtonLabel = accessibilityLabel || formatMessage({ id: 'back' });

  return (
    <LinkNoDecoration
      testID="back-arrow-history"
      accessibilityRole="link"
      href="#"
      accessibilityLabel={backButtonLabel}
      onPress={event => {
        event?.preventDefault();
        return onPress ? onPress() : onPressBack();
      }}
    >
      <Box>
        {icon}
        <VisuallyHidden accessibilityLabel={backButtonLabel} />
      </Box>
    </LinkNoDecoration>
  );
};

export const BackArrowHistory = portalToHeader(BackArrow);
