import React, { ReactNode } from 'react';

import { Text } from '@rbilabs/universal-components';
import { differenceInYears, isExists, isFuture, isValid } from 'date-fns';
import { IntlFormatters, useIntl } from 'react-intl';

import Link from 'components/link';
import { routes } from 'navigation/routing';
import { useUIContext } from 'state/ui';
import { minimumSignupAge } from 'utils/environment';
import { Region } from 'utils/environment/types';
import { formatDateObject } from 'utils/form';
import { validationConfig } from 'utils/form/user-profile-validation-config/validation';
import formatListOfWords, { JoinType } from 'utils/intl/formatListOfWords';

const StyledText = Text.withConfig({
  variant: 'copyOne',
  fontSize: 'xs',
  color: Styles.color.error,
});

const LONGEST_RECORDED_HUMAN_LIFE_IN_YEARS = 122;

const BelowMinimumMessage = () => {
  const { formatMessage } = useIntl();
  const { setDialogStaticPageState } = useUIContext();

  const openDialogStaticPage = (page: string) => setDialogStaticPageState({ isOpen: true, page });

  return (
    <StyledText>
      {formatMessage(
        { id: 'dateOfBirthIsBelowMinimum' },
        {
          termsLink: (
            <Link isInline isUnderlined onPress={() => openDialogStaticPage(routes.termsOfService)}>
              <StyledText>{formatMessage({ id: 'termsOfService' })}</StyledText>
            </Link>
          ),
          privacyPolicyLink: (
            <Link isInline isUnderlined onPress={() => openDialogStaticPage(routes.privacyPolicy)}>
              <StyledText>{formatMessage({ id: 'privacyPolicy' })}</StyledText>
            </Link>
          ),
        }
      )}
    </StyledText>
  );
};

const checkDob = (
  dobDate: Date,
  dobRawInput: { month: string; day: string; year: string },
  formatMessage: IntlFormatters['formatMessage']
): ReactNode | null => {
  // JS Date object sucks...
  dobDate.setTime(dobDate.getTime() + dobDate.getTimezoneOffset() * 60000);
  if (
    !isValid(dobDate) ||
    !isExists(
      parseInt(dobRawInput.year),
      parseInt(dobRawInput.month) - 1,
      parseInt(dobRawInput.day)
    )
  ) {
    return formatMessage({ id: 'invalidDateOfBirthProvided' });
  }
  if (isFuture(dobDate)) {
    return formatMessage({ id: 'futureDateOfBirthProvided' });
  }
  if (differenceInYears(Date.now(), dobDate) > LONGEST_RECORDED_HUMAN_LIFE_IN_YEARS) {
    return formatMessage({ id: 'invalidDateOfBirthProvided' });
  }
  if (differenceInYears(Date.now(), dobDate) < minimumSignupAge()) {
    return <BelowMinimumMessage />;
  }

  return null;
};

export const getDobErrors = (
  dob = { month: '', day: '', year: '' },
  formatMessage: IntlFormatters['formatMessage'],
  region: Region
): ReactNode | null => {
  const isRequired = (() => {
    const req = validationConfig.dob.required;

    if (req === false) {
      return false;
    }

    return req === true || req.locales.includes(region);
  })();

  if (isRequired && !Object.values(dob).every(Boolean)) {
    return formatMessage({ id: 'dateOfBirthIsRequired' });
  }

  // Empty DOB (valid)
  if (!dob.month && !dob.day && !dob.year) {
    return null; // dob is not required
  }

  // Partial DOB
  if (!dob.month || !dob.day || !dob.year) {
    return getInvalidDateOfBirthMessage(dob, formatMessage);
  }

  return checkDob(new Date(formatDateObject(dob)), dob, formatMessage);
};

export const getLegacyDobErrors = (
  dob: string | undefined,
  formatMessage: IntlFormatters['formatMessage']
): null | ReactNode => {
  // Empty DOB (valid)
  if (!dob) {
    return null; // dob is not required
  }

  const [month, day, year] = dob.split('/');

  return checkDob(new Date(dob), { year, month, day }, formatMessage);
};

function getInvalidDateOfBirthMessage(
  dob = { month: '', day: '', year: '' },
  formatMessage: IntlFormatters['formatMessage']
) {
  const missingFieldList = [];
  if (!dob.year) {
    missingFieldList.push(formatMessage({ id: 'year' }));
  }
  if (!dob.month) {
    missingFieldList.push(formatMessage({ id: 'month' }));
  }
  if (!dob.day) {
    missingFieldList.push(formatMessage({ id: 'day' }));
  }

  const missingFields = formatListOfWords({
    formatMessage,
    list: missingFieldList,
    joinType: JoinType.CONJUCTION,
  });
  return formatMessage(
    { id: 'invalidDateMissingFields' },
    {
      count: missingFieldList.length,
      missingFields,
    }
  );
}
