import { useCallback } from 'react';

import { ApolloQueryResult, useApolloClient } from '@apollo/client';
import {
  addDays,
  differenceInMinutes,
  format,
  getHours,
  isAfter,
  isBefore,
  isValid,
  isWithinInterval,
  parse,
  subHours,
  subMinutes,
} from 'date-fns';
import { pipe } from 'lodash/fp';
import { IntlShape } from 'react-intl';

import { ILocation } from '@rbi-ctg/frontend';
import { IRestaurant } from '@rbi-ctg/store';
import {
  GetRestaurantDocument,
  IGetRestaurantQuery,
  IOperatingHours,
  OrderingStatus,
} from 'generated/graphql-gateway';
import { useConfigValue } from 'hooks/configs/use-config-value';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { ServiceMode } from 'state/service-mode/types';
import { convertMilesToFeet, convertMilesToKilometers, convertMilesToMeters } from 'utils/distance';
import { Region } from 'utils/environment/types';
import logger from 'utils/logger';
import { coordinateDistance } from 'utils/rbi-common';

import {
  ALT_PARSE_FORMAT,
  LOCALE_TIME_PARSE_FORMAT,
  MIDNIGHT,
  PARSE_FORMAT,
  TWELVE_HOUR_TIME_PARSE_FORMAT,
} from './constants';

export { getUSState } from './get-us-state';
export { default as remapStore } from './remap-store';

export enum Days {
  sun,
  mon,
  tue,
  wed,
  thr,
  fri,
  sat,
}

export enum HeartbeatOverrideStatus {
  Online = 'online',
  Offline = 'offline',
  Auto = 'auto',
}

export type IRbiRestaurant = IRestaurant | IGetRestaurantQuery['restaurant'];

type IAvailabilityProps = object & {
  available?: boolean;
  isAvailable?: boolean;
};

type ICheckAvailability = (rbiRestaurant?: IRestaurant) => boolean;

type IGetRestaurantFn = (storeId: string | null) => Promise<IRbiRestaurant | undefined>;

type QueryResult = ApolloQueryResult<{ restaurant: IRbiRestaurant } | null>;

interface IMergeRestaurantData {
  rbiRestaurant: IRestaurant;
  sanityStore: IRestaurant;
}

const isAvailable = (rbiRestaurant?: IAvailabilityProps): boolean => {
  if (!rbiRestaurant) {
    return true;
  }

  // if `available` is null | undefined, it means the query for RbiRestaurant failed
  // in this case we default to true
  if ('isAvailable' in rbiRestaurant) {
    return rbiRestaurant.isAvailable ?? true;
  }
  return rbiRestaurant.available ?? true;
};

/**
 * Utility hook for determining restaurant availability
 *
 * @returns {ICheckAvailability}
 * * Function that determines availability based on the result of the
 * * GetRestaurant query and the ENABLE_ORDERING LD flag
 * * If the query throws an errors, or the flag is off, then we default to `true`
 * * otherwise we return the `restaurant.available` boolean
 */
export const useGetRestaurantAvailabilityFn = (): ICheckAvailability => {
  const enableOrdering = useFlag(LaunchDarklyFlag.ENABLE_ORDERING);

  return useCallback(
    (rbiRestaurant: any) => {
      // if LD is offline for any reason, we default to true
      return enableOrdering ? isAvailable(rbiRestaurant) : true;
    },
    [enableOrdering]
  );
};

/**
 * Utility hook that provides a function to query an rbiRestaurant by id
 *
 * @returns {IGetRestaurantFn}
 * Function that retrieves an RbiRestaurant with the most up to date data
 * It will pass the restaurant through `ICheckAvailability` before returning
 */
export const useGetRestaurantFn = (): IGetRestaurantFn => {
  const client = useApolloClient();
  const checkAvailability = useGetRestaurantAvailabilityFn();

  const getRestaurantFn = useCallback<IGetRestaurantFn>(
    async (storeId: string | null) => {
      if (!storeId) {
        return;
      }
      const { data, errors }: QueryResult = await client.query({
        // we always want the most up to date data when calculating heartbeat freshness
        fetchPolicy: 'network-only',
        variables: { storeId },
        query: GetRestaurantDocument,
      });

      if (!data || errors?.length) {
        const message = `Failed to query 'GetRestaurant' with 'storeId: ${storeId}'`;

        if (errors?.length) {
          logger.error({
            errors,
            message,
          });
        } else {
          logger.error(message);
        }

        return;
      }

      const { restaurant } = data;

      return {
        ...restaurant,
        available: checkAvailability(restaurant as IRestaurant),
      };
    },
    [checkAvailability, client]
  );

  return getRestaurantFn;
};

/**
 * Merges the data from the GetRestaurant query and sanity query
 *
 * @param {IMergeRestaurantData} data
 * * rbiRestaurant - The restaurant returend from GetRestaurant
 * * sanityStore - the sanity document queried from groq
 *
 * @returns {IStore} merged store document
 *
 *
 * *NOTE* at the moment only the operating hours come from GetRestaurant
 */
export const mergeRestaurantData = ({
  rbiRestaurant,
  sanityStore,
}: IMergeRestaurantData): IRestaurant => {
  return {
    ...sanityStore,
    available: rbiRestaurant.available,
    curbsideHours: rbiRestaurant.curbsideHours || sanityStore.curbsideHours || null,
    deliveryHours: rbiRestaurant.deliveryHours || sanityStore.deliveryHours || null,
    diningRoomHours: rbiRestaurant.diningRoomHours || sanityStore.diningRoomHours || null,
    driveThruHours: rbiRestaurant.driveThruHours || sanityStore.driveThruHours || null,
    mobileOrderDriveThruHours:
      rbiRestaurant.mobileOrderDriveThruHours || sanityStore.mobileOrderDriveThruHours || null,
    isOpenByServiceMode: rbiRestaurant.isOpenByServiceMode,
  };
};

export function nextDay(now: Date) {
  return Days[(now.getDay() + 1) % 7];
}

export function previousDay(now: Date) {
  const dayOfWeek = now.getDay();
  // Handle Sunday as the previous Saturday
  if (dayOfWeek === 0) {
    return Days[6]; // Saturday
  }
  return Days[dayOfWeek - 1];
}

function isLocation(point: Partial<ILocation>): point is ILocation {
  return Boolean(point?.lat && point?.lng);
}

/*
 * Determines what day is the next open day and returns the open hour
 */
export function nextOpenDay(hours: IOperatingHours, now = new Date(Date.now())): string {
  return hours[nextDay(now).concat('Open')];
}

/*
 * Determines what day is the next close day and returns the close hour
 */
export function nextCloseDay(hours: IOperatingHours, now = new Date(Date.now())): string {
  return hours[nextDay(now).concat('Close')];
}

/**
 * Parses a time string from mdm
 *
 * time strings can come in three flavors, so we have to try to parse all three
 * * yyyy-MM-dd HH:mm:ss.SSSSSSS
 * * yyyy-MM-dd HH:mm:ss
 * * HH:mm:ss
 *
 */
export function parseMdmTimeString(timeString: string): Date {
  let formattedTime = parse(timeString, PARSE_FORMAT, new Date(Date.now()));

  if (!isValid(formattedTime)) {
    formattedTime = parse(timeString, ALT_PARSE_FORMAT, new Date(Date.now()));
  }

  if (!isValid(formattedTime)) {
    formattedTime = parse(timeString, LOCALE_TIME_PARSE_FORMAT, new Date(Date.now()));
  }

  return formattedTime;
}

/**
 * Displays the given time string in a human readable format, either in standard or
 * military time format based on the market configuration.
 * @param {string} timeString Time string.
 * @param {boolean | undefined} ignoreMdmTimeParse If true, will not parse the time string as mdm.
 * @returns {string} Human readable time string.
 */
interface IReadableHourProps {
  timeString: string;
  ignoreMdmTimeParse?: boolean;
  timeFormat?: string;
}
export function readableHour({
  timeString,
  ignoreMdmTimeParse,
  timeFormat = TWELVE_HOUR_TIME_PARSE_FORMAT,
}: IReadableHourProps): string {
  try {
    if (ignoreMdmTimeParse) {
      return format(new Date(timeString), timeFormat);
    }

    return format(parseMdmTimeString(timeString), timeFormat);
  } catch (error) {
    return 'hours not available';
  }
}

interface IReadableTimeInterval {
  fallbackMessage: string;
  open24HoursMessage?: string;
  timeFormat?: string;
}
export const readableTimeInterval = ({
  fallbackMessage,
  open24HoursMessage,
  timeFormat = TWELVE_HOUR_TIME_PARSE_FORMAT,
}: IReadableTimeInterval) => (fromTime: string, toTime: string) => {
  try {
    const readableFromHour = format(parseMdmTimeString(fromTime), timeFormat);
    const readableToHour = format(parseMdmTimeString(toTime), timeFormat);

    // we cannot simply check for equality because we only want to show
    // that a store is open 24 hours if the open/close times are both midnight
    // and not just any 24 hour interval.
    const is24HourInterval = readableFromHour === MIDNIGHT && readableToHour === MIDNIGHT;

    if (is24HourInterval && open24HoursMessage) {
      return open24HoursMessage;
    }

    return `${readableFromHour} - ${readableToHour}`;
  } catch (e) {
    return fallbackMessage;
  }
};

function getTodaysHoursOfOperation(
  hours: IOperatingHours,
  now: Date = new Date(Date.now())
): [string, string] {
  if (!hours) {
    return ['', ''];
  }
  const dayName = Days[now.getDay()];
  const openHours: string = hours[dayName + 'Open'];
  const closeHours: string = hours[dayName + 'Close'];
  return [openHours, closeHours];
}

export function readableOpenHourToday(
  hours?: IOperatingHours | null,
  timeFormat?: string,
  notApplicable?: string
) {
  if (!hours) {
    return notApplicable || 'N/A';
  }

  const [openHours] = getTodaysHoursOfOperation(hours);

  if (!openHours) {
    return notApplicable || 'N/A';
  }

  return readableHour({ timeString: openHours, timeFormat });
}

export function readableCloseHourToday(hours?: IOperatingHours | null, timeFormat?: string) {
  if (!hours) {
    return 'N/A';
  }

  const [, closeHours] = getTodaysHoursOfOperation(hours);

  if (!closeHours) {
    return 'N/A';
  }

  return readableHour({ timeString: closeHours, timeFormat });
}

export function readableImperialDistance(
  miles: number,
  milesLocale: string,
  feetLocale: string
): string {
  if (miles >= 10) {
    return `${miles.toFixed(0)} ${milesLocale}`;
  } else if (miles >= 0.1) {
    return `${miles.toFixed(1)} ${milesLocale}`;
  }
  return `${convertMilesToFeet(miles).toFixed(0)} ${feetLocale}`;
}

export function readableMetricDistance(
  miles: number,
  kilometersLocale: string,
  metersLocale: string
): string {
  const kilometers = convertMilesToKilometers(miles);
  if (kilometers >= 10) {
    return `${kilometers.toFixed(0)} ${kilometersLocale}`;
  } else if (kilometers >= 0.1) {
    return `${kilometers.toFixed(1)} ${kilometersLocale}`;
  }
  return `${convertMilesToMeters(miles).toFixed(0)} ${metersLocale}`;
}

export function readableDistanceFromStore(
  miles: number,
  region: Region,
  formatMessage: IntlShape['formatMessage']
): string {
  if (['US', 'GB'].includes(region)) {
    return readableImperialDistance(
      miles,
      formatMessage({ id: 'miles' }),
      formatMessage({ id: 'feet' })
    );
  }
  return readableMetricDistance(
    miles,
    formatMessage({ id: 'kilometers' }),
    formatMessage({ id: 'meters' })
  );
}

/*
 * Composable version of Date.getTime()
 */
const dateToTime = (date: Date) => date.getTime();

/*
 * Given a time and a reference date, return a Date that is set to the reference
 * date's month, day, year, and the hours, minutes, seconds from time.
 */
const getDateForTime = (time: number, referenceDate: Date = new Date(Date.now())): Date => {
  const date = new Date(time);
  date.setFullYear(referenceDate.getFullYear());
  date.setMonth(referenceDate.getMonth());
  date.setDate(referenceDate.getDate());

  return date;
};

/*
 * Given a time string of the format "yyyy-MM-dd HH:mm:ss",
 * return today's date with hours, minutes, and seconds from the time string.
 */
export const getDateForHours = pipe(parseMdmTimeString, dateToTime, getDateForTime);

export function isHoursOfOperationValid(
  hours: IOperatingHours | null,
  now: Date = new Date(Date.now())
) {
  if (!hours) {
    return false;
  }

  const [openHours, closeHours] = getTodaysHoursOfOperation(hours, now);

  return !!(closeHours && openHours);
}

/*
 * Hours is a strange shape of data. Things like
 * sunOpen: string,
 * sunClose: string,
 * monOpen: string,
 * monClose: string,
 * etc
 */
export function isRestaurantOpen(hours?: IOperatingHours | null, now: Date = new Date(Date.now())) {
  if (!hours) {
    return false;
  }

  const [openHours, closeHours] = getTodaysHoursOfOperation(hours, now);

  // if the hours are the same, the store is open 24hrs
  if (openHours && closeHours && openHours === closeHours) {
    return true;
  }

  // We send null for opening / closing when we want to close a restaurant
  // But we need to check if the restaurant closes past midnight to and ensure
  // current time is past yesterday closing hour when it happens.
  // Ex:
  // todayOpen: null
  // todayClose: null
  // currentTime: 1:00am
  // yesterdayCloseTime: 3:00am
  if (!closeHours || !openHours) {
    const yesterday = new Date(now.getTime());
    yesterday.setDate(yesterday.getDate() - 1);
    const [yesterdayOpenHours, yesterdayCloseHours] = getTodaysHoursOfOperation(hours, yesterday);

    if (!yesterdayOpenHours || !yesterdayCloseHours) {
      return false;
    }

    // getDateForHours always uses date now so there's no need to fix the day
    const yesterdayOpen = getDateForHours(yesterdayOpenHours);
    const yesterdayClose = getDateForHours(yesterdayCloseHours);

    // Check if the restaurant closes past midnight and if now is before close
    if (isBefore(yesterdayClose, yesterdayOpen) && isBefore(now, yesterdayClose)) {
      return true;
    }

    // if we got here it means that the restaurant is closed today
    return false;
  }

  let open = getDateForHours(openHours);
  const close = getDateForHours(closeHours);

  // If Closing is past midnight, some date manipulation is needed
  // before the times can be compared
  // e.g.
  // open:  06:00
  // close: 01:00
  if (isBefore(close, open)) {
    // Current Time is past midnight
    // e.g.
    // open: 06:00
    // currentTime: 01:00
    if (isBefore(now, open)) {
      const yesterdayDayName = previousDay(now);
      const yesterdayOpenHours = hours[`${yesterdayDayName}Open`];
      if (!yesterdayOpenHours) {
        return false;
      }

      open = getDateForHours(yesterdayOpenHours);
      // Past midnight, the open hours for the previous day are the relevant hours
      // Set Open to the day before for comparison
      open.setDate(open.getDate() - 1);
    } else {
      // Current Time is NOT past midnight
      // e.g.
      // open: 06:00
      // currentTime: 23:00
      // Fix close date day for comparison
      close.setDate(close.getDate() + 1);
    }
  }
  return isAfter(now, open) && isBefore(now, close);
}

// TODO: Refactor or remove
export function checkServiceModeUnavailable(serviceMode: ServiceMode, store: IRestaurant) {
  let serviceModeUnavailable = false;
  switch (serviceMode) {
    case ServiceMode.DELIVERY:
      serviceModeUnavailable = !isRestaurantOpen(store.deliveryHours);
      break;
    case ServiceMode.CURBSIDE:
      serviceModeUnavailable = !isRestaurantOpen(store.curbsideHours);
      break;
    case ServiceMode.DRIVE_THRU:
      serviceModeUnavailable = !isRestaurantOpen(store.driveThruHours);
      break;
    case ServiceMode.MOBILE_ORDER_DRIVE_THRU:
      serviceModeUnavailable = !isRestaurantOpen(store.mobileOrderDriveThruHours);
      break;
    default:
      serviceModeUnavailable = !isRestaurantOpen(store.diningRoomHours);
  }
  return serviceModeUnavailable;
}

// TODO: Use timezone
export function checkIfWithinOneHourOfCloseToday(
  hours: IOperatingHours | undefined | null,
  now: Date = new Date(Date.now())
) {
  if (!hours || !isRestaurantOpen(hours, now)) {
    return false;
  }
  const [, closeHours] = getTodaysHoursOfOperation(hours, now);
  const close = getDateForHours(closeHours);

  return isWithinInterval(now, { start: subHours(close, 1), end: close });
}

// Check given time is within 20 minutes of closing
// and ensuring there is a gap closure of the next opening day
export function isOpenIfNotWithinMinutesOfCloseTodayAndNot24hours(
  hours: IOperatingHours,
  now: Date = new Date(Date.now()),
  minutes = 20
) {
  if (!isRestaurantOpen(hours, now)) {
    return false;
  }
  const [, closeHours] = getTodaysHoursOfOperation(hours, now);
  const close = getDateForHours(closeHours);

  if (!isWithinInterval(now, { start: subMinutes(close, minutes), end: close })) {
    return true;
  }

  // 24 hour store check
  // Subtract one minute due from 00 to 59
  const FOR_24_HOURS_IN_MINUTES_BETWEEN_MONTHS = 60 * 24 - 1;
  const tomorrowDayName = nextDay(now);
  const tomorrowOpenHours = hours[`${tomorrowDayName}Open`];

  const open = getDateForHours(tomorrowOpenHours);

  // The comparison of today close and tmrw open hours
  return differenceInMinutes(close, open) === FOR_24_HOURS_IN_MINUTES_BETWEEN_MONTHS;
}

// TODO: Use timezone
export function checkIfDateIsWithinCloseTimeAndMinutes(
  hours: IOperatingHours,
  now: Date = new Date(Date.now()),
  minutes = 60
) {
  if (!isRestaurantOpen(hours, now)) {
    return false;
  }
  const [, closeHours] = getTodaysHoursOfOperation(hours, now);
  let close = getDateForHours(closeHours);

  if (getHours(close) === 0) {
    close = addDays(close, 1);
  }

  return isWithinInterval(now, { start: subMinutes(close, minutes), end: close });
}

export function milesBetweenCoordinates(point1: ILocation, point2: Partial<ILocation>): number {
  if (!isLocation(point1) || !isLocation(point2)) {
    return 0;
  }

  return coordinateDistance(
    { latitude: point1.lat, longitude: point1.lng },
    { latitude: point2.lat, longitude: point2.lng },
    true
  );
}

/*
 * isMobileOrderingAvailable compares the app environment with the
 * restaurant environment returned from Sanity
 */

export const isMobileOrderingAvailable = (
  restaurant: IRestaurant,
  validMobileOrderingEnvs: string[] = []
): boolean => {
  const { mobileOrderingStatus } = restaurant;

  if (!restaurant?.hasMobileOrdering || !mobileOrderingStatus) {
    return false;
  }
  if ([OrderingStatus.ALPHA, OrderingStatus.BETA].includes(mobileOrderingStatus)) {
    return false;
  }

  return validMobileOrderingEnvs.includes(mobileOrderingStatus);
};

// TODO: Check if can be removed
export const isMobileOrderingAvailableExperiment = (
  restaurant: Pick<IRestaurant, 'mobileOrderingStatus' | 'hasMobileOrdering'>,
  validMobileOrderingEnvs: string[] = []
): boolean => {
  const { mobileOrderingStatus, hasMobileOrdering } = restaurant;

  if (!hasMobileOrdering || !mobileOrderingStatus) {
    return false;
  }
  if ([OrderingStatus.ALPHA, OrderingStatus.BETA].includes(mobileOrderingStatus)) {
    return false;
  }

  return validMobileOrderingEnvs.includes(mobileOrderingStatus);
};

/**
 * wrap `isMobileOrderingAvailable` in a hook so that it can encapsulate checking the LD flag
 */
export const useIsMobileOrderingAvailable = (restaurant: IRestaurant): boolean => {
  const restaurantsConfig = useConfigValue({ key: 'restaurants', defaultValue: {} });
  const validMobileOrderingEnvs = restaurantsConfig.validMobileOrderingEnvs ?? [];

  return isMobileOrderingAvailable(restaurant, validMobileOrderingEnvs);
};

// Get hour in a.m or p.m.
export const formatTime = (timeString: any) => {
  const [hourString, minute] = timeString.split(':');
  const hour = +hourString % 24;
  return (hour % 12 || 12) + ':' + minute + (hour < 12 ? ' a.m.' : ' p.m.');
};
