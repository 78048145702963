import { reloadAppAsync } from 'expo';
import { Alert } from 'react-native';

import { SplashScreen } from 'components/splash-screen/splash-screen';
import { isNative, isWeb } from 'utils/environment';
import LocalStorage, { StorageKeys } from 'utils/local-storage';

import crashlytics from './crashlytics';
import logger from './logger';
import noop from './noop';

const FIVE_MINUTES = 1000 * 60 * 5;

// put keys unlikely to be the cause of the crash here
const DO_NOT_CLEAR_ON_CRASH_KEYS = [
  StorageKeys.LANGUAGE,
  StorageKeys.REGION,
  StorageKeys.USER_AUTH_TOKEN,
  StorageKeys.USER,
  StorageKeys.INTERNAL_BUILD_ONLY_ENV_PICKER_CONFIG, // in dev web this getting cleared pre-matruely due to forter.js issues causing an error boundary hit
];

// Logic:
// Hit 1: restart the JS part of the app
// Hit 2: clear most local storage (within FIVE_MINUTES)
// Hit 3: full quit the app with a crash (within FIVE_MINUTES)
export const handleFatalErrorReload = () => {
  const lastErrorBoundaryCrashTime = LocalStorage.getItem(
    StorageKeys.LAST_ERROR_BOUNDARY_CRASH_TIME
  );
  LocalStorage.setItem(StorageKeys.LAST_ERROR_BOUNDARY_CRASH_TIME, Date.now());

  // Hit 3 - native crash
  const lastKeyClearTime = LocalStorage.getItem(StorageKeys.LAST_CRASH_KEY_CLEAR_TIME);
  if (lastKeyClearTime && Date.now() - lastKeyClearTime < FIVE_MINUTES) {
    logger.error({
      message: '[FatalErrorReload] - Doing a native crash to attempt recovery',
      isFatalErrorReload: true,
    });
    setTimeout(() => crashlytics().crash(), 500); // setTimeout give logger a change to send.
    return;
  }

  // Hit 2 - clear storage
  // clear most storage (but not user) to prevent the crash from happening if it last happened within 5 minutes...
  const shouldClearStorage =
    lastErrorBoundaryCrashTime && Date.now() - lastErrorBoundaryCrashTime < FIVE_MINUTES;
  if (shouldClearStorage) {
    LocalStorage.clear({ excludeKeys: DO_NOT_CLEAR_ON_CRASH_KEYS });
    LocalStorage.setItem(StorageKeys.LAST_CRASH_KEY_CLEAR_TIME, Date.now());
  }

  // Hit 1, 2 - reload JS app
  logger.error({
    message: `[FatalErrorReload] - Reloading JS to attempt recovery, clearKeys:${
      shouldClearStorage || false
    }`,
    isFatalErrorReload: true,
    shouldClearStorage,
  });
  setTimeout(() => (isWeb ? window?.location?.reload() : reloadAppAsync()), 500); // setTimeout give logger a change to send.
};

const GLOBAL_ERROR_PREFIX = 'FatalGlobalHandlerError';

export const setupGlobalErrorHandler = () => {
  if (__DEV__) {
    // this replaces the dev mode error handler which we do not want in dev mode
    return;
  }

  if (isNative) {
    ErrorUtils.setGlobalHandler((error, isFatal) => {
      if (!isFatal) {
        // is a little nebulous as to what a non fatal error is but in practice this doesn't seem to happen much if at all in previous prod code
        logger.warn({
          message: `[NonFatalErrorHandlerError] - ${error.message}`,
          error,
          isNonFatalGlobalError: true,
        });
        return;
      }

      handleFatalError(GLOBAL_ERROR_PREFIX, error);
    });
  } else {
    window.onerror = (event, source, lineno, colno, error) => {
      if (error) {
        handleFatalError(GLOBAL_ERROR_PREFIX, error);
      }
    };
  }
};

export const handleFatalError = (prefix: string, error: any) => {
  // eliminate situation where app could error sooner than splash screen goes away
  SplashScreen.hide();

  crashlytics().recordError(error);
  crashlytics().sendUnsentReports();
  const message = `[${prefix}] - ${error.message}`;
  error.message = message;
  logger.fatal({ message, error, isFatalError: true, [`is${prefix}`]: true });

  if (isNative) {
    Alert.alert(
      'Something went wrong',
      'Please reload the app.',
      [
        {
          text: 'Reload',
          onPress: handleFatalErrorReload,
          style: 'default',
        },
      ],
      {
        cancelable: false,
        onDismiss: noop,
      }
    );
  } else {
    window.alert('Something went wrong. Press OK below to reload the site.');
    window.location.reload();
  }
};
