import { differenceInYears, parse } from 'date-fns';
import { compact, isArray, isNil, isObject, isString } from 'lodash-es';
import { Platform } from 'react-native';

import { NonNullableObject } from '@rbi-ctg/frontend';
import { IBackendCartEntries, IBaseItem, ICartEntry, IServerOrder } from '@rbi-ctg/menu';
import { ICmsQuestCard } from 'components/quests/cms-hooks/types';
import { IQuest } from 'components/quests/loyalty-quest/types';
import { LoyaltyPromotionType } from 'generated/graphql-gateway';
import {
  IAmplitudeLoyaltyDocument,
  IAmplitudeProduct,
  IAmplitudeProductDetails,
  IAmplitudeSublevelItem,
  ICartEntryLoggable,
  ProductItemType,
} from 'state/amplitude/types';
import { getIncentiveDayParts } from 'state/loyalty/hooks/utils/incentives-availability';
import { LoyaltyOffer, LoyaltyReward, isReward } from 'state/loyalty/types';
import { CartPaymentType } from 'state/order/types';
import { ServiceMode } from 'state/service-mode/types';
import { CartEntryType } from 'utils/cart/types';
import { RBIExpandedPlatform } from 'utils/environment';
import { maybeLocale } from 'utils/graphql';
import { centsToDollars } from 'utils/index';
import logger from 'utils/logger';
import { blockContentToPlainText } from 'utils/sanity';

import { CustomEventNames, FALSE, FALSE_VALS, TRUE, TRUE_VALS } from '../amplitude/constants';

import { Identify, identify } from './amplitude-package';
import { IAmplitudeAttributes } from './types';

export function sanitizeValues<M extends object = IAmplitudeAttributes>(
  attributes: M
): NonNullableObject<Partial<M>> {
  return Object.entries(attributes).reduce((memo, [key, value]) => {
    const attrIsEmptyString = typeof value === 'string' && value.length === 0;
    if (!isNil(value) && !attrIsEmptyString) {
      memo[key] = value;
    }
    return memo;
  }, {} as NonNullableObject<Partial<M>>) as NonNullableObject<Partial<M>>;
}

export const getAge = (dob: string): number | null => {
  if (dob) {
    return differenceInYears(new Date(), parse(dob, 'yyyy-MM-dd', new Date()));
  }
  return null;
};

/**
 *
 * @param userAttributes object with any values (we'll only take the ones we care about)
 * @returns
 */
export function extractAmplitudeUserAttributes(
  userAttributes: Record<string, string | any>
): IAmplitudeAttributes {
  return {
    // CRM-Cleanup there are many duplicate user attributes that we can remove.
    // We need them for now to acheive parity with what we already have on our CRM suite.
    ...(userAttributes.adsPersonalizationOptOut && {
      adsPersonalizationOptOut: userAttributes.adsPersonalizationOptOut,
    }),
    ...(userAttributes.brand && { brand: userAttributes.brand }),
    ...(userAttributes.customerid && {
      'RBI Cognito ID': userAttributes.customerid,
      userId: userAttributes.customerid,
      customerid: userAttributes.customerid,
      customerId: userAttributes.customerid,
    }),
    ...(userAttributes.env && { env: userAttributes.env }),
    ...(userAttributes.favoriteStores && { favoriteStores: userAttributes.favoriteStores }),
    ...(userAttributes.promotionalEmails && { 'Email Opt In': userAttributes.promotionalEmails }),
    ...(userAttributes.region && { region: userAttributes.region }),
    ...(userAttributes.zipcode && { $Zip: userAttributes.zipcode, Zip: userAttributes.zipcode }),
    ...(userAttributes['Join Date'] && { 'Join Date': userAttributes['Join Date'] }),
    ...(userAttributes.language && { language: userAttributes.language }),
    ...(userAttributes.Locale && { Locale: userAttributes.Locale }),
    ...(userAttributes['Legacy User'] && { 'Legacy User': userAttributes['Legacy User'] }),
    ...(userAttributes['Location Services'] && {
      'Location Services': userAttributes['Location Services'],
    }),
    ...(userAttributes.marketingEmail && { marketingEmail: userAttributes.marketingEmail }),
    ...(userAttributes.marketingPush && { marketingPush: userAttributes.marketingPush }),
    ...(userAttributes.orderStatus && { orderStatus: userAttributes.orderStatus }),
    ...(userAttributes.rewardsEmail && { rewardsEmail: userAttributes.rewardsEmail }),
    ...(userAttributes.rewardsPush && { rewardsPush: userAttributes.rewardsPush }),
    ...(userAttributes.Timezone && { Timezone: userAttributes.Timezone }),
    ...(userAttributes['Type Preference'] && {
      'Type Preference': userAttributes['Type Preference'],
    }),
    ...(userAttributes['Snack Preference'] && {
      'Snack Preference': userAttributes['Snack Preference'],
    }),
    ...(userAttributes['Size Preference'] && {
      'Size Preference': userAttributes['Size Preference'],
    }),
    ...(userAttributes['Time Preference'] && {
      'Time Preference': userAttributes['Time Preference'],
    }),
    ...(userAttributes['IOS Location Permissions'] && {
      'IOS Location Permissions': userAttributes['IOS Location Permissions'],
      locationPermission: userAttributes['IOS Location Permissions'],
    }),
    ...(userAttributes['Android Location Permissions'] && {
      'Android Location Permissions': userAttributes['Android Location Permissions'],
      locationPermission: userAttributes['Android Location Permissions'],
    }),
    ...(userAttributes['UTM Source'] && {
      'UTM Source': userAttributes['UTM Source'],
      utmSource: userAttributes['UTM Source'],
    }),
    ...(userAttributes['UTM Medium'] && {
      'UTM Medium': userAttributes['UTM Medium'],
      utmMedium: userAttributes['UTM Medium'],
    }),
    ...(userAttributes['UTM Campaign'] && {
      'UTM Campaign': userAttributes['UTM Campaign'],
      utmCampaign: userAttributes['UTM Campaign'],
    }),
    ...(userAttributes['UTM Term'] && {
      'UTM Term': userAttributes['UTM Term'],
      utmTerm: userAttributes['UTM Term'],
    }),
    ...(userAttributes['UTM Content'] && {
      'UTM Content': userAttributes['UTM Content'],
      utmContent: userAttributes['UTM Content'],
    }),
  };
}

export const setAmplitudeUserAttributes = (userAttributes: IAmplitudeAttributes) => {
  const identifyObj = new Identify();
  for (const userAttributeKey of Object.keys(userAttributes)) {
    identifyObj.set(userAttributeKey, userAttributes[userAttributeKey]);
  }
  identify(identifyObj);
};

export const booleanToString = (bool: boolean): typeof TRUE | typeof FALSE => (bool ? TRUE : FALSE);

const normalizeStringBoolean = (str: string): string => {
  if (TRUE_VALS.includes(str)) {
    return TRUE;
  }
  if (FALSE_VALS.includes(str)) {
    return FALSE;
  }
  return str;
};

/**
 * Slices string values that are too large for Amplitude (starting from the 0 index).
 * Amplitude max string length is 1024
 * https://help.amplitude.com/hc/en-us/articles/115002923888#h_84aa2818-b187-4a80-b838-4ebd11b36904
 */
export const toAttributesWithValidLengthValues = (attributes: Record<string, any>) => {
  const STRING_VALUE_LENGTH_LIMIT = 1024;

  const reduceFn = (acc: object, [key, value]: [string, any]) => {
    const validValue =
      typeof value === 'string' ? value.slice(0, STRING_VALUE_LENGTH_LIMIT) : value;
    return { ...acc, [key]: validValue };
  };

  return Object.entries(attributes).reduce(reduceFn, {});
};

/**
 * Converts any boolean values to strings
 * eg true => "True"
 */
export function normalizeBooleans<M extends object = IAmplitudeAttributes>(
  attributes: M
): NonNullableObject<Partial<M>> {
  const copy: Partial<M> = {};
  Object.keys(attributes).forEach(key => {
    const value = attributes[key];
    if (typeof value === 'boolean') {
      copy[key] = booleanToString(value);
    } else if (typeof value === 'string') {
      copy[key] = normalizeStringBoolean(value);
    } else {
      copy[key] = value;
    }
  });
  return copy as NonNullableObject<Partial<M>>;
}

/**
 * Normalize the attribute values which are objects
 * This method will call JSON.stringify on values which are objects or arrays but no strings
 * and keep remaining attributes unchanged.
 * @param attributes list of attributes for amplitude to log
 * @param logError if true, will log an error on every object that is encountered
 * @returns a new object where objects are stringified
 */
export function normalizeObjectsAndArrays(attributes: object, logError: boolean = false): object {
  const copy = {};
  Object.keys(attributes).forEach(key => {
    const value = attributes[key];
    if (!isString(value) && isObject(value)) {
      if (logError) {
        logger.info(
          `Unexpected object for key ${key}, received ${value}. Auto converting to string for amplitude`
        );
      }
      copy[key] = JSON.stringify(value);
    } else {
      copy[key] = String(value);
    }
  });

  return copy;
}

/**
 * Returns an object where all the values which are arrays in the original objects have their
 * array values converted to strings
 * @param attributes object of attributes to be normalized
 * @param logError if true, will log an error on every array that is encountered
 * @returns an object where values which are arrays only contains arrays of strings
 */
export function normalizeArray(attributes: object, logError: boolean = false) {
  const copy = {};
  Object.keys(attributes).forEach(key => {
    const value = attributes[key];
    if (isArray(value)) {
      if (logError) {
        logger.info(
          `Unexpected array for key ${key}, received ${value}. Auto converting to string for amplitude`
        );
      }

      const arrayCopy: String[] = [];
      value.forEach(arrayValue => {
        // We may want to normalize booleans or numbers here in the same way we sanitize and normalize above
        arrayCopy.push(String(arrayValue));
      });

      copy[key] = arrayCopy;
    } else {
      copy[key] = String(value);
    }
  });

  return copy;
}

export function determinePlatformFromNavigator(): RBIExpandedPlatform {
  switch (Platform.OS) {
    case 'android':
      return RBIExpandedPlatform.ANDROID;
    case 'ios':
      return RBIExpandedPlatform.IOS;
    case 'web':
      return RBIExpandedPlatform.WEB;
    default:
      throw new Error('should never reach here');
  }
}

export const getIsMobileWeb = (): 'TRUE' | 'FALSE' => {
  if (determinePlatformFromNavigator() !== RBIExpandedPlatform.WEB) {
    return 'FALSE';
  }

  return typeof navigator !== 'undefined' &&
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    ? 'TRUE'
    : 'FALSE';
};

function convertJsonAttrs(customAttributes: Record<string, any>) {
  for (var key in customAttributes) {
    if (typeof customAttributes[key] === 'string') {
      try {
        var parsed = JSON.parse(customAttributes[key]);
        if (typeof parsed === 'object') {
          customAttributes[key] = parsed;
        }
      } catch (e) {
        // if parsing fails, don't update the customAttribute object
      }
    }
  }
  return customAttributes;
}

const TOTAL_AMOUNT = 'Total Amount';
const TOTAL_PRODUCT_AMOUNT = 'Total Product Amount';
/**
 * @param eventAttributes attributes on the event we are sending.
 * @returns attributes in the shape Amplitude currently receives them in.
 */
export function createEcommerceAttributes(attributes: Record<string, any>) {
  var updatedAttributes = {};
  for (var key in attributes) {
    if (key !== TOTAL_AMOUNT && key !== TOTAL_PRODUCT_AMOUNT) {
      updatedAttributes[key] = attributes[key];
    }
  }

  return convertJsonAttrs(updatedAttributes);
}

export function flattenCartEntryItems(cartEntry: ICartEntry): ICartEntry[] {
  const children = cartEntry.children.reduce((accum: ICartEntry[], current) => {
    return [...accum, ...flattenCartEntryItems(current)];
  }, []);
  return [cartEntry, ...children];
}

const createSublevelItems = (cartEntry: ICartEntry): IAmplitudeSublevelItem[] => {
  const subItems = flattenCartEntryItems(cartEntry).filter(
    item => item._id !== cartEntry._id && item.type === CartEntryType.item
  );
  // Merge sublevel items by item id
  const mergedItemsById = subItems.reduce<{
    [id: string]: IAmplitudeSublevelItem | undefined;
  }>((acc, item) => {
    const curItem = acc[item._id];
    if (curItem) {
      curItem.quantity += item.quantity;
    } else {
      acc[item._id] = { id: item._id, quantity: item.quantity };
    }
    return acc;
  }, {});
  return compact(Object.values(mergedItemsById));
};

const createSublevelProducts = (cartEntry: ICartEntry): IAmplitudeProduct[] => {
  const subProducts: IAmplitudeProduct[] = [];
  // Get cart entry sublevel items
  const subItems = flattenCartEntryItems(cartEntry);

  // Merge sublevel items by item id
  for (const subItem of subItems) {
    if (
      subItem._id !== cartEntry._id &&
      subItem.type === CartEntryType.item &&
      subItem.productHierarchy
    ) {
      const product = createProduct(subItem);
      if (product) {
        product.custom_attributes.comboChild = booleanToString(true);
        product.comboChild = booleanToString(true);
        subProducts.push(product);
      }
    }
  }
  return subProducts;
};

export const reformatAttributesForSingularProduct = (product: IAmplitudeProduct) => {
  const newAttributes: Record<string, any> = { ...product };

  // remap product attributes
  newAttributes.Name = product.name ?? '';
  newAttributes.Id = product.id ?? '';
  newAttributes.Price = String(product.price ?? '');
  newAttributes.Quantity = String(product.quantity ?? '');
  newAttributes['Total Product Amount'] = String(product.total_product_amount ?? '');

  // remove old attributes
  delete newAttributes.id;
  delete newAttributes.name;
  delete newAttributes.quantity;
  delete newAttributes.price;
  delete newAttributes.total_product_amount;

  return newAttributes;
};

export const createProduct = (
  cartEntry: ICartEntry | IBackendCartEntries
): IAmplitudeProduct | null => {
  const _id = '_id' in cartEntry ? cartEntry._id : cartEntry.sanityId;
  if (!_id) {
    return null;
  }
  const cartId = 'lineId' in cartEntry ? cartEntry.lineId : cartEntry.cartId;
  const { name = '', price, quantity, isDonation = false, isExtra = false } = cartEntry;
  const basePrice = price ? centsToDollars(price / quantity) : 0;
  const productSublevelItems = createSublevelItems(cartEntry as ICartEntry);
  const itemLevel =
    productSublevelItems.length === 0 ? ProductItemType.Child : ProductItemType.Parent;

  const productAttrs = {
    cartId: cartId || _id,
    comboChild: booleanToString(false),
    isDonation: booleanToString(isDonation),
    isExtra: booleanToString(isExtra),
    'Item Level': itemLevel,
    sublevelItems: JSON.stringify(productSublevelItems),
  };
  const hierarchyData = {
    L1: cartEntry.productHierarchy?.L1 || '',
    L2: cartEntry.productHierarchy?.L2 || '',
    L3: cartEntry.productHierarchy?.L3 || '',
    L4: cartEntry.productHierarchy?.L4 || '',
    L5: cartEntry.productHierarchy?.L5 || '',
  };
  const product: IAmplitudeProduct = {
    name,
    id: _id,
    quantity,
    price: basePrice,
    total_product_amount: basePrice * quantity,
    custom_attributes: {
      ...productAttrs,
      ...(itemLevel === ProductItemType.Child && { ...hierarchyData }),
      sublevelItems: JSON.stringify(productSublevelItems),
    },
    ...(itemLevel === ProductItemType.Child && { ...hierarchyData }),
    ...productAttrs,
  };

  return product;
};

/**
 * @param serverOrder IServerOrder
 * @param cartEntries ICartEntry[]
 * @returns {IAmplitudeProduct[]} IAmplitudeProduct[] CRM products array
 */
export function createCRMProducts({
  serverOrder,
  cartEntries,
}: {
  serverOrder: IServerOrder;
  cartEntries: ICartEntry[];
}) {
  const products: IAmplitudeProduct[] = cartEntries.reduce(
    (accumulator: IAmplitudeProduct[], cartEntry) => {
      const eCommerceProduct = createProduct(cartEntry);
      if (!eCommerceProduct) {
        return accumulator;
      }

      const sublevelProducts = createSublevelProducts(cartEntry);
      const rewardApplied = serverOrder.cart.rewardsApplied?.find(
        reward => reward?.cartId === eCommerceProduct.custom_attributes?.cartId
      );

      eCommerceProduct.rewardItem = booleanToString(!!rewardApplied);
      eCommerceProduct.custom_attributes = {
        ...eCommerceProduct.custom_attributes,
        rewardItem: booleanToString(!!rewardApplied),
      };

      return [...accumulator, eCommerceProduct, ...sublevelProducts];
    },
    []
  );
  return products;
}

interface FlattenedObject {
  [key: string]: any;
}

function flattenObject(obj: object, prefix = 'products'): FlattenedObject {
  const result: object = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const propName = prefix ? `${prefix}.${key}` : key;
      const value = obj[key];
      if (typeof value === 'object' && value !== null) {
        Object.assign(result, flattenObject(value, propName));
      } else {
        result[propName] = value;
      }
    }
  }
  return result;
}

/**
 * In order to query event properties on eCommerce events,
 * we must flatten nested product information.
 * For example: if we have a cart with two products: `product: { name: "chicken" }` and `product: { name: "pie" }`
 * We must transform this to:
 * `product.name: ["chicken", "pie"]`
 * This allows digital teams to write queries like "get me all purchases that included product.name = 'chicken'""
 */
export function expandProductAttributes(products: IAmplitudeProduct[]) {
  const expandedObjects: Record<string, any[]> = {};
  for (const obj of products) {
    const flattenedObj = flattenObject(obj, 'products');
    const expandedObj: object = {};

    for (const key in flattenedObj) {
      if (flattenedObj.hasOwnProperty(key)) {
        const existingValue = expandedObj[key];
        if (existingValue) {
          expandedObj[key] = [...existingValue, flattenedObj[key]];
        }
        expandedObj[`${key}`] = flattenedObj[key];
      }
    }

    for (const key in expandedObj) {
      const existingValue = expandedObjects[key] ?? null;
      if (existingValue) {
        expandedObjects[key] = [...existingValue, expandedObj[key]];
      } else {
        expandedObjects[key] = [expandedObj[key]];
      }
    }
  }
  return expandedObjects;
}

export function serializePaymentType(paymentType: CartPaymentType | null) {
  switch (paymentType) {
    case CartPaymentType.APPLE_PAY:
      return 'APPLE_PAY';
    case CartPaymentType.GOOGLE_PAY:
      return 'GOOGLE_PAY';
    case CartPaymentType.CREDIT_ANONYMOUS:
      return 'CREDIT_ANONYMOUS';
    default:
      return 'VAULTED_ACCOUNT';
  }
}

export function serializeServiceMode(serviceMode: ServiceMode | null) {
  switch (serviceMode) {
    case ServiceMode.DELIVERY:
      return 'Delivery';
    case ServiceMode.DRIVE_THRU:
    case ServiceMode.MOBILE_ORDER_DRIVE_THRU:
    case ServiceMode.EAT_IN:
    case ServiceMode.TAKEOUT:
    case ServiceMode.CURBSIDE:
      return 'Pickup';
    default:
      return 'None';
  }
}

export function serializePickupMode(serviceMode: ServiceMode | null) {
  switch (serviceMode) {
    case ServiceMode.DELIVERY:
      return 'Delivery';
    case ServiceMode.DRIVE_THRU:
      return 'Drive Thru';
    case ServiceMode.MOBILE_ORDER_DRIVE_THRU:
      return 'Mobile Order Drive Thru';
    case ServiceMode.EAT_IN:
      return 'Eat In';
    case ServiceMode.TAKEOUT:
      return 'Take Out';
    case ServiceMode.CURBSIDE:
      return 'Curbside';
    default:
      return 'None';
  }
}

export function serializeNumberOfDriveThruWindows(driveThruLaneType: string | null) {
  switch (driveThruLaneType) {
    case 'single':
      return 1;
    case 'dual':
      return 2;
    default:
      return 0;
  }
}

export const getCartDataItems = (cartEntries: ICartEntry[]): string => {
  const cartData = JSON.stringify({
    items: cartEntries.map(entry => ({
      items: createProduct(entry),
    })),
  });
  const cartDataItemsRegex = /item_\d{3,}/gi;
  return cartData?.match(cartDataItemsRegex)?.join() || '';
};

export function isCommerceEvent(eventName: string) {
  return eventName.includes('eCommerce');
}

export function isExpandableEvent(eventName: CustomEventNames) {
  const eventsWeWantToExpand = [
    CustomEventNames.E_COMMERCE_PURCHASE,
    CustomEventNames.E_COMMERCE_CHECKOUT,
    CustomEventNames.E_COMMERCE_VIEW_DETAIL,
  ];
  return eventsWeWantToExpand.includes(eventName);
}

export function convertToSingleCommerceProduct(product: Partial<IAmplitudeProduct>) {
  const { id, quantity, name, price, total_product_amount, ...rest } = product;

  const newProduct: Record<string, any> = { ...rest };
  newProduct.Quantity = String(product.quantity);
  newProduct.Id = String(product.id);
  newProduct.Name = String(product.name);
  newProduct['Total Product Amount'] = String(product.total_product_amount);
  newProduct.Price = String(product.price);

  return newProduct;
}
export function getProductsDetails(
  products: IBaseItem[] | ICartEntry[] | ICartEntryLoggable[],
  price = 0
): IAmplitudeProductDetails[] {
  let productsDetails: IAmplitudeProductDetails[] = [];
  if (isBaseItemArray(products)) {
    productsDetails = getBaseItemsDetails(products, price);
  } else if (isCartEntryArray(products)) {
    productsDetails = getICartEntryDetails(products);
  }
  return productsDetails;
}

function isBaseItemArray(
  items: IBaseItem[] | ICartEntry[] | ICartEntryLoggable[]
): items is IBaseItem[] {
  return items.length > 0 && '_type' in items[0];
}

function isCartEntryArray(
  items: IBaseItem[] | ICartEntry[] | ICartEntryLoggable[]
): items is ICartEntry[] | ICartEntryLoggable[] {
  return items.length > 0 && 'type' in items[0];
}

function getICartEntryDetails(
  cartEntries: ICartEntry[] | ICartEntryLoggable[]
): IAmplitudeProductDetails[] {
  const productDetails = flattenCartEntries(cartEntries);
  return productDetails;
}
const validEntriesTypes = [CartEntryType.combo, CartEntryType.item, ''];

function flattenCartEntries(
  cartEntries: ICartEntry[] | ICartEntryLoggable[],
  parent?: ICartEntry | ICartEntryLoggable
): IAmplitudeProductDetails[] {
  return cartEntries.reduce(
    (acc: IAmplitudeProductDetails[], entry: ICartEntry | ICartEntryLoggable) => {
      if (validEntriesTypes.includes(entry.type)) {
        const entryArray: ICartEntry[] | ICartEntryLoggable[] = [entry] as
          | ICartEntry[]
          | ICartEntryLoggable[];
        buildProductDetails(entryArray, parent).forEach(product => acc.push(product));
      }
      // Recursively flatten the children and add them to the flattened array
      if (entry.children && entry.children.length > 0) {
        acc.push(...flattenCartEntries(entry.children, entry));
      }
      return acc;
    },
    []
  );
}
function convertPriceToNumber(price: string | number): number {
  if (typeof price === 'number') {
    return price; // If already a number, return as is
  }
  // In fragments (ICartEntryLoggables), price comes as a string representing the cents
  const priceInCents = parseInt(price, 10);
  return priceInCents / 100; // Convert cents to dollars
}

function buildProductDetails(
  cartEntries: ICartEntry[] | ICartEntryLoggable[],
  parent?: ICartEntry | ICartEntryLoggable
): IAmplitudeProductDetails[] {
  const productDetails = cartEntries.map(product => {
    return {
      name: product.name || '',
      sanityId: product.sanityId || product._id,
      quantity: parent ? product.quantity * parent.quantity : product.quantity,
      price: product.price ? convertPriceToNumber(product.price) : 0,
      productType: product.type,
      parentName: parent?.name || '',
      parentSanityId: parent?.sanityId || parent?._id,
      bkpn: product.productNumber,
      foodType: product.productHierarchy?.L1,
      category: product.productHierarchy?.L2,
      segment: product.productHierarchy?.L3,
      platform: product.productHierarchy?.L4,
      menuItemName: product.productHierarchy?.L5,
      isExtra: product.isExtra || '',
    };
  });
  return productDetails;
}

function getBaseItemsDetails(
  items: IBaseItem[],
  price: number | undefined
): IAmplitudeProductDetails[] {
  return items.map(item => ({
    name: item.name?.locale,
    sanityId: item._id,
    price,
    bkpn: item.operationalItem?.productNumber,
    productType: item._type,
    foodType: item.productHierarchy?.L1,
    category: item.productHierarchy?.L2,
    segment: item.productHierarchy?.L3,
    platform: item.productHierarchy?.L4,
    menuItemName: item.productHierarchy?.L5,
  }));
}

export function transformLoyaltyDocument(
  incentive: LoyaltyReward | LoyaltyOffer
): IAmplitudeLoyaltyDocument {
  const dayParts = (getIncentiveDayParts(incentive) || []).filter(
    (dayPart): dayPart is string => typeof dayPart === 'string'
  );
  const type = incentive.__typename || '';
  const isRewardIncentive = isReward(incentive);
  const name = isRewardIncentive
    ? maybeLocale(incentive.name) || ''
    : blockContentToPlainText(incentive.name?.localeRaw);
  const loyaltyEngineId = incentive.loyaltyEngineId ?? '';
  const sanityId = incentive._id;
  const incentiveType = isRewardIncentive
    ? LoyaltyPromotionType.REWARD
    : LoyaltyPromotionType.OFFER;

  return {
    dayParts,
    engineId: loyaltyEngineId,
    incentiveSanityId: sanityId,
    incentiveType,
    loyaltyEngineId,
    name,
    sanityId,
    type,
  };
}

export function getLoyaltyDocumentForQuest(
  quest: IQuest,
  cmsQuestCard: ICmsQuestCard
): IAmplitudeLoyaltyDocument {
  return {
    name: cmsQuestCard.name?.locale || '',
    sanityId: quest.cmsId,
    loyaltyEngineId: cmsQuestCard.loyaltyEngineId || '',
    type: quest.cmsType,
    incentiveType: quest.incentiveType || '',
    questStartDate: quest.startDate,
    questEndDate: quest.endDate,
  };
}
