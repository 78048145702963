import { useCallback, useMemo } from 'react';

import { isNil } from 'lodash-es';

import { IRestaurant } from '@rbi-ctg/store';
import { useConfigValue } from 'hooks/configs/use-config-value';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useServiceModeContext } from 'state/service-mode';
import { ServiceMode, ServiceModeStatus } from 'state/service-mode/types';
import {
  isMobileOrderingAvailable,
  useGetRestaurantAvailabilityFn,
  useGetRestaurantFn,
} from 'utils/restaurant';
import { PICKUP_SERVICE_MODES } from 'utils/service-mode';

type UseServiceModeStatus = {
  /**
   * An array containing pickup service modes that are currently open
   * (based on hours of operation) and enabled.
   */
  availablePickupServiceModes: ServiceMode[];
  /**
   * Will be `true` if None of the service modes offered by the store
   * is currently open (based on hours of operation) AND enabled.
   */
  allServiceModesUnavailable: boolean;
  /**
   * Determines a store's availability.
   * A store will be deemed available when all of the below conditions are met:
   * 1. No service mode is selected OR the current service mode is delivery OR
   * the current pickup service mode is available (open based on hours of operation
   * and offered by the store) and not disabled.
   * 2. Store is available.
   * 3. Store has mobile ordering enabled.
   * @param store - The store whose availability needs to be determined.
   * @returns A `boolean` indicaint
   */
  isRestaurantAvailable: (store: IRestaurant) => Promise<boolean>;
  /**
   * Will be `true` when all of the below conditions are met:
   * 1. Store is available.
   * 2. Store has mobile ordering enabled.
   * 3. Store is open for pickup, which means that it has at least
   * one pickup service mode available (offered by the store AND
   * open based on hours of operations) and not disabled.
   */
  restaurantCanBeSelected: boolean;
  /**
   * @see {@link ServiceModeStatus}
   */
  serviceModeStatus: ServiceModeStatus;
};

export const useServiceModeStatusGenerator = () => {
  const isCurbsideEnabled = useFlag(LaunchDarklyFlag.ENABLE_CURBSIDE);
  const isDeliveryEnabled = useFlag(LaunchDarklyFlag.ENABLE_DELIVERY);
  const isMobileOrderDriveThruEnabled = useFlag(LaunchDarklyFlag.ENABLE_MOBILE_ORDER_DRIVE_THRU);
  const mobileOrderDriveThruStores = useFlag(LaunchDarklyFlag.MOBILE_ORDER_DRIVE_THRU_STORES) || '';

  const driveThruDisabled = useFlag(LaunchDarklyFlag.DISABLE_DRIVE_THRU);
  const dineInDisabled = useFlag(LaunchDarklyFlag.DISABLE_DINE_IN);
  const takeOutDisabled = useFlag(LaunchDarklyFlag.DISABLE_TAKE_OUT);

  const enableDeliveryOutsideOpeningHours = useFlag(
    LaunchDarklyFlag.ENABLE_DELIVERY_CHECKOUT_OUTSIDE_OPENING_HOURS
  );

  const generateServiceModeStatusForStore = useCallback(
    (store: IRestaurant) => {
      const hasDelivery = !!store.hasDelivery;
      const isDeliveryOpen =
        store.isOpenByServiceMode?.delivery || enableDeliveryOutsideOpeningHours;

      const hasCurbside = !!store.hasCurbside;
      const isCurbsideOpen = !!store.isOpenByServiceMode?.curbside;

      const hasDriveThru = !!store.hasDriveThru;
      const isDriveThruOpen = !!store.isOpenByServiceMode?.driveThru;

      const hasMobileOrderDriveThru = !!store.hasMobileOrderDriveThru;
      const isMobileOrderDriveThruOpen = !!store.isOpenByServiceMode?.mobileOrderDriveThru;

      const isMobileOrderDriveThruEnabledForStore =
        isMobileOrderDriveThruEnabled &&
        (mobileOrderDriveThruStores
          ? mobileOrderDriveThruStores.split(',').includes(store.number || '')
          : false);

      const isMobileOrderDriveThruAvailable =
        hasMobileOrderDriveThru &&
        isMobileOrderDriveThruEnabledForStore &&
        isMobileOrderDriveThruOpen;

      const isDiningRoomOpen = !!store.isOpenByServiceMode?.diningRoom;
      const hasEatIn = !!store.hasDineIn;
      const hasTakeout = !!store.hasTakeOut;

      const serviceModeStatus: ServiceModeStatus = {
        CURBSIDE: {
          capable: hasCurbside,
          available: hasCurbside && isCurbsideOpen,
          disabled: !isCurbsideEnabled,
          open: isCurbsideOpen,
        },
        DELIVERY: {
          capable: hasDelivery,
          available: hasDelivery && isDeliveryOpen,
          disabled: !isDeliveryEnabled,
          open: isDeliveryOpen,
        },
        DRIVE_THRU: {
          capable: hasDriveThru,
          available: !isMobileOrderDriveThruAvailable && hasDriveThru && isDriveThruOpen,
          disabled: isNil(driveThruDisabled) || driveThruDisabled,
          open: isDriveThruOpen,
        },
        MOBILE_ORDER_DRIVE_THRU: {
          capable: hasMobileOrderDriveThru,
          available: isMobileOrderDriveThruAvailable,
          disabled: !isMobileOrderDriveThruEnabledForStore,
          open: isMobileOrderDriveThruOpen,
        },
        EAT_IN: {
          capable: hasEatIn,
          available: hasEatIn && isDiningRoomOpen,
          disabled: isNil(dineInDisabled) || dineInDisabled,
          open: isDiningRoomOpen,
        },
        TAKEOUT: {
          capable: hasTakeout,
          available: hasTakeout && isDiningRoomOpen,
          disabled: isNil(takeOutDisabled) || takeOutDisabled,
          open: isDiningRoomOpen,
        },
      };

      return serviceModeStatus;
    },
    [
      isCurbsideEnabled,
      isDeliveryEnabled,
      driveThruDisabled,
      isMobileOrderDriveThruEnabled,
      mobileOrderDriveThruStores,
      dineInDisabled,
      takeOutDisabled,
      enableDeliveryOutsideOpeningHours,
    ]
  );

  return {
    generateServiceModeStatusForStore,
  };
};

export const isAvailableAndNotDisabled = ({
  available,
  disabled,
}: ServiceModeStatus[keyof ServiceModeStatus]) => available && !disabled;

export const useServiceModeStatus = (store: IRestaurant): UseServiceModeStatus => {
  const restaurantsConfig = useConfigValue({ key: 'restaurants', defaultValue: {} });
  const validMobileOrderingEnvs = useMemo(() => restaurantsConfig.validMobileOrderingEnvs ?? [], [
    restaurantsConfig.validMobileOrderingEnvs,
  ]);
  const { serviceMode } = useServiceModeContext();

  const enableOrdering = useFlag(LaunchDarklyFlag.ENABLE_ORDERING);

  const hasAvailableProperty = useGetRestaurantAvailabilityFn();
  const getRestaurant = useGetRestaurantFn();
  const { generateServiceModeStatusForStore } = useServiceModeStatusGenerator();

  const serviceModeStatus = useMemo(() => generateServiceModeStatusForStore(store), [
    generateServiceModeStatusForStore,
    store,
  ]);

  const allServiceModesUnavailable = Object.values(serviceModeStatus).every(
    ({ available, disabled }) => !available || disabled
  );

  const availablePickupServiceModes = useMemo(
    () => PICKUP_SERVICE_MODES.filter(value => isAvailableAndNotDisabled(serviceModeStatus[value])),
    [serviceModeStatus]
  );

  // TODO: Check if it can be replaced with restaurantCanBeSelected
  const isRestaurantAvailable = useCallback(
    async (restaurantData: IRestaurant) => {
      const mobileOrderingEnabled = isMobileOrderingAvailable(
        restaurantData,
        validMobileOrderingEnvs
      );

      const { number: storeId } = restaurantData;

      if (!storeId || !mobileOrderingEnabled) {
        return false;
      }

      const rbiRestaurant = await getRestaurant(storeId);

      const isRestaurantPosAvailable = Boolean(rbiRestaurant?.available); // TODO: should we use hasAvailableProperty() instead?
      const storeServiceModeStatus = generateServiceModeStatusForStore(restaurantData);

      const open =
        !serviceMode || // TODO: Why is it open if there isn't a service mode?
        serviceMode === ServiceMode.DELIVERY || // TODO: Why is it open if service mode is delivery?
        isAvailableAndNotDisabled(storeServiceModeStatus[serviceMode]);

      return open && isRestaurantPosAvailable;
    },
    [generateServiceModeStatusForStore, serviceMode] // eslint-disable-line react-hooks/exhaustive-deps
  );

  /** Determines whether a restaurant can be selected */
  const restaurantCanBeSelected = useMemo(() => {
    if (!enableOrdering) {
      return false;
    }

    // Store selection 1.0: store can only be selected if there is an available service mode
    return (
      hasAvailableProperty(store) &&
      isMobileOrderingAvailable(store, validMobileOrderingEnvs) &&
      Boolean(availablePickupServiceModes.length)
    );
  }, [
    store,
    enableOrdering,
    hasAvailableProperty,
    validMobileOrderingEnvs,
    availablePickupServiceModes.length,
  ]);

  return {
    allServiceModesUnavailable,
    availablePickupServiceModes,
    isRestaurantAvailable,
    restaurantCanBeSelected,
    serviceModeStatus,
  };
};
