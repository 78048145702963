import imageUrlBuilder from '@sanity/image-url';
import {
  ImageUrlBuilderOptionsWithAliases,
  SanityImageSource,
} from '@sanity/image-url/lib/types/types';

import { region, sanityDataset, sanityProjectId } from 'utils/environment';

export const buildImageUrl = (
  source: SanityImageSource,
  options: Partial<ImageUrlBuilderOptionsWithAliases> = {}
) => {
  const dataset = region
    ? `${sanityDataset()}_${region.toLowerCase()}`
    : sanityDataset().toLowerCase();

  const builder = imageUrlBuilder({ dataset, projectId: sanityProjectId });

  return builder.image(source).withOptions(options).url() || '';
};

export const parseAssetID = (assetID: string = '') => {
  const [, id, dimensions, format] = assetID.split('-');
  if (!(id && dimensions && format)) {
    return {
      id: '',
      format: '',
      dimensions: {
        aspectRatio: 0,
        width: 0,
        height: 0,
      },
    };
  }
  const [width = 0, height = 0] = dimensions?.split('x').map(str => parseInt(str, 10));
  return {
    id,
    format,
    dimensions: {
      aspectRatio: width / height,
      width,
      height,
    },
  };
};
