import { useIntl } from 'react-intl';

import { ISanityImage } from '@rbi-ctg/menu';
import Picture from 'components/picture';
import { IImageFragment } from 'generated/sanity-graphql';

import { LogoContainer } from './styled';

export const WalmartLogo = ({
  image,
}: {
  image: ISanityImage | IImageFragment | ISanityImage | null | undefined;
}) => {
  const { formatMessage } = useIntl();

  return (
    <LogoContainer>
      <Picture width="$24" image={image} alt={formatMessage({ id: 'deliveryLogoAlt' })} />
    </LogoContainer>
  );
};
