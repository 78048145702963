import { useEffect } from 'react';

import { useLoyaltyUserLazyQuery } from 'generated/graphql-gateway';
import { actions, selectors, useAppDispatch, useAppSelector } from 'state/global-state';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';

import type { IUseLoyaltyUserState } from './types';
import { useIsLoyaltyEnabled } from './use-is-loyalty-enabled';
import { useLoyaltyUserId } from './use-loyalty-user';

/**
  Get loyalty user state (Rewards Points etc.) fresh or from redux. will always populate redux with latest.
  ⚠️ Just need loyaltyId? use useLoyaltyUserId hook ⚠️ 
 */
export const useLoyaltyUserState = ({
  refreshOnMount,
}: {
  /** ensure loyalty info isn't stale */
  refreshOnMount: boolean;
}): IUseLoyaltyUserState => {
  const dispatch = useAppDispatch();
  const { loyaltyUser: cacheLoyaltyUser, loading: cacheIsLoading } = useAppSelector(
    selectors.loyalty.selectLoyaltyUser
  );
  const { loyaltyUserId: loyaltyId } = useLoyaltyUserId();
  const loyaltyEnabled = useIsLoyaltyEnabled();

  // TODO: move this to offer's context
  const offersCooldownEnabled = Boolean(useFlag(LaunchDarklyFlag.ENABLE_GLOBAL_OFFERS_COOLDOWN));

  const [
    fetchLoyaltyUser,
    { data, loading: localLoading, error, called, refetch },
  ] = useLoyaltyUserLazyQuery({
    variables: { loyaltyId },
    fetchPolicy: 'network-only',
  });

  // load if we don't have a cache or refresh on mount
  const shouldLoad =
    loyaltyEnabled && loyaltyId && !called && (refreshOnMount || !cacheLoyaltyUser);

  useEffect(() => {
    if (shouldLoad) {
      dispatch(actions.loyalty.setLoyaltyLoading(true));
      fetchLoyaltyUser()
        .then(resp => {
          const loyaltyUser = resp.data?.loyaltyUserV2;
          dispatch(actions.loyalty.setUser(loyaltyUser));

          const offerRedemptionAvailableAfter =
            loyaltyUser?.offerRedemptionAvailability?.availableAfter;
          if (offerRedemptionAvailableAfter && offersCooldownEnabled) {
            dispatch(
              actions.loyalty.setOfferRedemptionAvailableAfter(offerRedemptionAvailableAfter)
            );
          }
        })
        .finally(() => {
          dispatch(actions.loyalty.setLoyaltyLoading(false));
        });
    }
  }, [shouldLoad, fetchLoyaltyUser, dispatch, offersCooldownEnabled]);

  const loyaltyUser = data?.loyaltyUserV2 ?? cacheLoyaltyUser;

  const loading = localLoading || cacheIsLoading;

  return {
    loyaltyUser,
    error,
    refetch,
    loading,
  };
};
