import React from 'react';

import { Box, HStack, Pressable, Text } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import { A11yWrapper } from 'components/a11y-wrapper';
import { StoreSanityOrderEta } from 'components/sanity-store-order-eta/store-sanity-order-eta';
import { Halal } from 'components/ucl/svgs/Halal';
import { useIsMobileBreakpoint } from 'hooks/use-media-query';

import {
  Address,
  ServiceModes,
  Status,
  StoreCardViewContainer,
  disableColorProp,
} from '../store-card.styled';
import { StoreCardDetailsProps } from '../types';

import { StoreCardMessages } from './store-card-messages';

export const StoreCardDetails: React.FC<StoreCardDetailsProps> = ({
  errorMessage,
  isSelected,
  storeDetails,
  onInfoPress,
  isInMapView,
  elementFocusAfterExpandRef,
}) => {
  const { formatMessage } = useIntl();
  const isMobileBreakpoint = useIsMobileBreakpoint();
  const {
    restaurantStatus,
    serviceModesText,
    address,
    city,
    isHalal,
    accessInstructions,
    isNonTraditionalStore,
  } = storeDetails;
  const showRestaurantStatus =
    (isSelected && restaurantStatus.isOpen) || !restaurantStatus.isOpen || isInMapView;

  return (
    <StoreCardViewContainer>
      {address && (
        <Box focusable={false}>
          <Pressable
            onPress={onInfoPress}
            focusable={isSelected}
            accessibilityDisabled={!isSelected}
            accessible={isSelected}
            accessibilityRole="link"
            accessibilityLabel={`${address}, ${city}`}
            ref={elementFocusAfterExpandRef}
          >
            <Box flexWrap={'nowrap'} flexDirection={'row'}>
              <Address
                {...(!restaurantStatus.canBeSelected && disableColorProp)}
                flex={isMobileBreakpoint ? 1 : undefined}
              >
                {address}
              </Address>
              <StoreSanityOrderEta store={storeDetails.restaurant} />
            </Box>
            <Text variant="copyTwo" {...(!restaurantStatus.canBeSelected && disableColorProp)}>
              {city}
            </Text>
          </Pressable>
        </Box>
      )}
      {isHalal && <Halal title={formatMessage({ id: 'isHalalTitle' })} />}
      {serviceModesText && (
        <HStack alignItems="center" focusable>
          <ServiceModes {...(!restaurantStatus.canBeSelected && disableColorProp)}>
            {serviceModesText}
          </ServiceModes>
        </HStack>
      )}
      {showRestaurantStatus && (
        <A11yWrapper>
          <Status
            isWithinOneHourOfClose={restaurantStatus.isWithinOneHourOfClose}
            isOpen={restaurantStatus.canBeSelected}
          >
            {restaurantStatus.text}
          </Status>
        </A11yWrapper>
      )}
      <StoreCardMessages
        errorMessage={errorMessage}
        accessInstructions={isNonTraditionalStore ? accessInstructions : ''}
      />
    </StoreCardViewContainer>
  );
};
