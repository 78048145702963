import React, { ReactNode } from 'react';

import { Pressable, Skeleton, Text } from '@rbilabs/universal-components';
import { AccessibilityProps } from 'react-native';

import { useReversedUIContext } from 'state/reversed-ui';
import { theme } from 'styles/configure-theme';

import { ZoomInView } from '../zoom-in-view';

export const StyledCtaButton = Pressable.withConfig({
  display: 'flex',
  flexDirection: 'row',
  _web: {
    cursor: 'pointer',
  },
  fontSize: {
    base: 'sm',
    md: 'md',
  },
});

interface CtaButtonProps extends AccessibilityProps {
  text?: string;
  cta: () => void;
  icon?: ReactNode;
  showDistanceText?: boolean;
  bold?: boolean;
  isLoading?: boolean;
}

const AnimatedDistanceText = ({
  isLoading,
  textContent,
}: {
  isLoading: boolean;
  textContent: React.ReactNode;
}) => {
  return !isLoading && !!textContent ? (
    <ZoomInView animate>{textContent}</ZoomInView>
  ) : (
    <Skeleton rounded="full" h="$5" width="90px" />
  );
};

const CtaButton = ({
  text,
  cta,
  icon,
  showDistanceText = false,
  bold = false,
  isLoading = false,
  ...rest
}: CtaButtonProps) => {
  const { reversedUI } = useReversedUIContext();

  const textColor = reversedUI ? theme.token('text-reversed') : theme.token('text-default');

  const textContent = (
    <Text color={textColor} fontSize="sm" bold={bold}>
      {text}
    </Text>
  );

  const component = showDistanceText ? (
    <AnimatedDistanceText isLoading={isLoading} textContent={textContent} />
  ) : (
    textContent
  );

  return (
    <StyledCtaButton
      testID="top-service-mode-cta-button"
      onPress={cta}
      accessibilityRole="button"
      alignItems="center"
      {...rest}
    >
      {component}
      {icon}
    </StyledCtaButton>
  );
};

export default CtaButton;
