import { INutritionDto } from 'generated/graphql-gateway';

import { MultipliersSelections } from '../../product-wizard/types';

export enum ModifierType {
  NON_NUMERIC = 'NON_NUMERIC',
  NUMERIC = 'NUMERIC',
  CHECKBOX = 'CHECKBOX',
  SINGLE_CHOICE = 'SINGLE_CHOICE',
  MULTIPLE_CHOICE = 'MULTIPLE_CHOICE',
}

export interface IModifier {
  id: string;
  name: string;
  quantity: { min: number; max: number };
  multipliers: IModifierMultiplier[];
  defaultMultipliers: ReadonlyArray<string>;
  singleChoiceOnly: boolean;
  type: ModifierType;
  displayModifierMultiplierName: boolean;
  image?: string;
}

export interface IModifierMultiplier {
  id: string;
  name: string;
  prefix?: string;
  multiplier: number;
  nutrition?: INutritionDto;
  price?: number;
  image?: string;
}

export interface IModifierData {
  modifier: IModifier;
  selections: MultipliersSelections;
}

export interface IModifierTypeUIProps {
  modifier: IModifier;
  selections: MultipliersSelections;
  onSelectionsChange: (selections: MultipliersSelections) => void;
}
