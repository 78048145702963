import { useCallback, useEffect, useRef, useState } from 'react';

import { QueryHookOptions, useApolloClient } from '@apollo/client';

import { ICartEntry } from '@rbi-ctg/menu';
import { useLoyaltyOfferGroupsExperiment } from 'experiments/use-loyalty-offer-groups-experiment';
import {
  ILoyaltyOffersQuery,
  ILoyaltyUserOffersQuery,
  IOffersFragment,
  LoyaltyOfferRedemptionType,
  LoyaltyOfferType,
  LoyaltyPaymentMethod,
  LoyaltyServiceMode,
  LoyaltyUserOffersDocument,
  useLoyaltyOffersLazyQuery,
  useLoyaltyUserOffersQuery,
} from 'generated/graphql-gateway';
import {
  useFeatureSortedLoyaltyOffersLazyQuery,
  useLoyaltySystemwideOffersByIdsQuery,
} from 'generated/sanity-graphql';
import { useLocaleSmartBlockContent } from 'hooks/use-locale-smart-block-content/use-locale-smart-block-content';
import { actions, selectors, useAppDispatch, useAppSelector } from 'state/global-state';
import { IEntriesIdsMap } from 'state/global-state/models/loyalty/offers/offers.types';
import {
  flattenEntriesToMap,
  parseEntry,
  parseOffers,
} from 'state/global-state/models/loyalty/offers/offers.utils';
import { withLazySimpleCache } from 'state/graphql/with-simple-cache';
import { useLocale } from 'state/intl';
import { LANGUAGES } from 'state/intl/types';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useServiceModeContext } from 'state/service-mode';
import { useStoreContext } from 'state/store';
import logger from 'utils/logger';

import { LoyaltyAppliedOffer, LoyaltyOffer } from '../types';
import { mergeLoyaltyOffers } from '../utils';

import { IAppliedRewards, IQueryLoyaltyUserOffersOptions } from './types';
import { useLoyaltyOffersEvaluation } from './use-loyalty-offers-evaluation';
import { useLoyaltyUserId } from './use-loyalty-user';
import { usePersonalizedOffers } from './use-personalized-offers';
import { IPersonalizedData, mergePersonalizedData } from './utils/personalized-offers';

const useCachedFeatureSortedLoyaltyOffersQuery = withLazySimpleCache(
  useFeatureSortedLoyaltyOffersLazyQuery
);

export const useLoyaltyOffers = () => {
  // This flag is parent to the next ones
  const loyaltyOffersEnabled = Boolean(useFlag(LaunchDarklyFlag.ENABLE_LOYALTY_OFFERS));
  // This flag replaces Offers 3.0
  const loyaltyStandardOffersEnabled = Boolean(
    useFlag(LaunchDarklyFlag.ENABLE_LOYALTY_STANDARD_OFFERS)
  );

  const enableLoyaltyStandardOffers = useFlag(LaunchDarklyFlag.ENABLE_LOYALTY_STANDARD_OFFERS);
  const offersCooldownEnabled = Boolean(useFlag(LaunchDarklyFlag.ENABLE_GLOBAL_OFFERS_COOLDOWN));
  const client = useApolloClient();
  const [evaluating, setEvaluating] = useState(false);
  const dispatch = useAppDispatch();
  const { loyaltyUserId } = useLoyaltyUserId();
  const appliedOffers = useAppSelector(selectors.loyalty.selectAppliedOffers);
  const userOffers = useAppSelector(selectors.loyalty.selectUserOffers);
  const { serviceMode } = useServiceModeContext();
  const { store } = useStoreContext();
  const sortedOffersRef = useRef<LoyaltyOffer[]>([]);
  const cartEntriesIdsMap = useRef<IEntriesIdsMap>({});
  const { language } = useLocale();
  const prevLanguageRef = useRef<LANGUAGES>(language);

  const [
    fetchPersonalizedOfferData,
    { loading: personalizedDataLoading },
  ] = usePersonalizedOffers();
  const storeId = store?.number;

  const offerGroupExperimentEnabled = useLoyaltyOfferGroupsExperiment(true);

  const { transformSmartBlockContent } = useLocaleSmartBlockContent();
  const [
    queryFeatureSortedLoyaltyOffers,
    { loading: cmsOffersLoading, refetch: refetchCmsOffers },
  ] = useCachedFeatureSortedLoyaltyOffersQuery({
    variables: {
      id: offerGroupExperimentEnabled
        ? 'feature-loyalty-offers-ui-singleton-experiment'
        : 'feature-loyalty-offers-ui-singleton',
    },
    onCompleted(data) {
      if (data?.LoyaltyOffersUI?.sortedSystemwideOffers) {
        const newOffers = data.LoyaltyOffersUI.sortedSystemwideOffers as LoyaltyOffer[];

        sortedOffersRef.current =
          sortedOffersRef.current.length > 0
            ? mergeLoyaltyOffers(sortedOffersRef.current, newOffers)
            : newOffers;
      }
    },
  });

  useEffect(() => {
    if (enableLoyaltyStandardOffers) {
      const languageChanged = prevLanguageRef.current !== language;

      queryFeatureSortedLoyaltyOffers({ allowFromCache: !languageChanged });
      prevLanguageRef.current = language;
    }
  }, [language, enableLoyaltyStandardOffers, queryFeatureSortedLoyaltyOffers]);

  const getPersonalizedOffer = useCallback(
    (
      offer: Partial<IOffersFragment>,
      personalizedOfferData: IPersonalizedData
    ): LoyaltyOffer | undefined => {
      let personalizedOffer;

      try {
        personalizedOffer = mergePersonalizedData({
          engineOffer: offer,
          transformSmartBlockContent,
          ...personalizedOfferData,
        });
      } catch (error) {
        // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
        const message = error?.message ? `Error: ${error.message}` : '';
        logger.error(`Failed processing personalized offer: ${offer.id} - ${message}`);
      }

      return personalizedOffer;
    },
    [transformSmartBlockContent]
  );

  const onUserOffersQueryComplete = useCallback(
    async (data: ILoyaltyUserOffersQuery) => {
      const loyaltyUser = data?.loyaltyUserV2;
      const offerRedemptionAvailableAfter =
        loyaltyUser?.offerRedemptionAvailability?.availableAfter;

      if (offerRedemptionAvailableAfter && offersCooldownEnabled) {
        dispatch(actions.loyalty.setOfferRedemptionAvailableAfter(offerRedemptionAvailableAfter));
      }

      const loyaltyUserOffers = loyaltyUser?.offers;
      if (!loyaltyUserOffers?.length) {
        return;
      }

      // Fetch data needed to build personalized offer
      const personalizedOfferData = await fetchPersonalizedOfferData(loyaltyUserOffers);

      const personalizedUserOffers: LoyaltyOffer[] = [];
      const systemWideOffers: IOffersFragment[] = [];

      loyaltyUserOffers.forEach(offer => {
        if (offer.type === LoyaltyOfferType.PERSONALIZED && personalizedOfferData) {
          const personalizedOffer = getPersonalizedOffer(offer, personalizedOfferData);
          if (personalizedOffer) {
            personalizedUserOffers.push(personalizedOffer);
          }
        }

        if (offer.type === LoyaltyOfferType.GLOBAL) {
          systemWideOffers.push(offer);
        }
      });

      sortedOffersRef.current = sortedOffersRef.current.concat(personalizedUserOffers);

      dispatch(actions.loyalty.setCmsOffers(sortedOffersRef.current));
      dispatch(actions.loyalty.setUserOffers(systemWideOffers));
      dispatch(actions.loyalty.setPersonalizedOffers(personalizedUserOffers));
    },
    [dispatch, fetchPersonalizedOfferData, getPersonalizedOffer, offersCooldownEnabled]
  );

  const [queryEngineOffers, { loading: engineOffersLoading }] = useLoyaltyOffersLazyQuery({
    fetchPolicy: 'network-only',
    variables: {
      serviceMode: LoyaltyServiceMode[serviceMode || ''] || undefined,
      storeId,
      omitInvalids: false,
    },
    onCompleted: (data: ILoyaltyOffersQuery) => {
      if (data?.loyaltyOffersV2?.length) {
        dispatch(actions.loyalty.setCmsOffers(sortedOffersRef.current));
        dispatch(actions.loyalty.setOffers(data.loyaltyOffersV2 as IOffersFragment[]));
      }
    },
  });

  // Attach applied offers ids to get the full collection of offers from CMS
  const appliedLoyaltyOffersIds = appliedOffers.map(({ cmsId }) => cmsId || '');

  const { loading: queryUserAppliedOffersLoading } = useLoyaltyUserOffersQuery({
    fetchPolicy: 'no-cache',
    skip: !loyaltyUserId || !appliedLoyaltyOffersIds.length || userOffers.length > 0,
    variables: {
      loyaltyId: loyaltyUserId,
      where: {
        ids: appliedOffers.map(({ id }) => id || ''),
      },
    },
    onCompleted: onUserOffersQueryComplete,
  });

  const { loading: swoffersLoading } = useLoyaltySystemwideOffersByIdsQuery({
    variables: {
      ids: appliedLoyaltyOffersIds,
    },
    skip: !appliedLoyaltyOffersIds.length,
    onCompleted(data) {
      if (data?.allSystemwideOffers) {
        sortedOffersRef.current = sortedOffersRef.current.concat(
          data.allSystemwideOffers as LoyaltyOffer[]
        );

        dispatch(actions.loyalty.setCmsOffers(sortedOffersRef.current));

        const upsizeAndDiscountIds = appliedOffers
          .filter(({ cartId }) => cartId === 'discount-offer')
          .map(({ id }) => id || '');

        if (upsizeAndDiscountIds.length) {
          queryUserOffers({
            variables: {
              loyaltyId: loyaltyUserId,
              where: {
                omitInvalids: false,
                ids: upsizeAndDiscountIds,
              },
            },
          });
        }
      }
    },
  });

  const [queryUserOffersLoading, setQueryUserOffersLoading] = useState(false);
  const queryUserOffers = useCallback(
    async (options: QueryHookOptions) => {
      setQueryUserOffersLoading(true);
      dispatch(actions.loyalty.setOffersLoading(true));

      try {
        const { data } = await client.query<ILoyaltyUserOffersQuery>({
          ...options,
          fetchPolicy: 'no-cache',
          query: LoyaltyUserOffersDocument,
        });

        if (data) {
          onUserOffersQueryComplete(data);
        }
      } catch (e) {
        logger.error(`Error fetching user offers: ${e}`);
      } finally {
        setQueryUserOffersLoading(false);
        dispatch(actions.loyalty.setOffersLoading(false));
      }
    },
    [client, dispatch, onUserOffersQueryComplete]
  );

  const queryLoyaltyUserOffers = useCallback(
    ({
      redemptionTypes,
      omitInvalids = false,
    }: {
      redemptionTypes: LoyaltyOfferRedemptionType[];
      omitInvalids?: boolean;
    }) => ({
      loyaltyId,
      cartEntries,
      appliedRewards,
      subtotalAmount,
      appliedLoyaltyOffers = [],
    }: IQueryLoyaltyUserOffersOptions) => {
      const parsedEntries = cartEntries?.reduce(parseEntry(appliedRewards), []);

      cartEntriesIdsMap.current = (parsedEntries || []).reduce(flattenEntriesToMap, {});

      queryUserOffers({
        variables: {
          loyaltyId,
          where: {
            appliedIncentives: parseOffers(appliedLoyaltyOffers),
            cartEntries: parsedEntries,
            serviceMode: serviceMode || undefined,
            redemptionTypes,
            storeId,
            subtotalAmount,
            omitInvalids,
          },
        },
      });
    },
    [queryUserOffers, serviceMode, storeId]
  );

  // Offers without loyaltyId should always be of redemption type STANDARD
  const queryLoyaltyOffers = useCallback(
    ({ subtotalAmount = 0 }: any) => {
      queryEngineOffers({
        variables: {
          serviceMode: LoyaltyServiceMode[serviceMode || ''],
          storeId,
          subtotalAmount,
          redemptionTypes: [LoyaltyOfferRedemptionType.STANDARD],
        },
      });
    },
    [queryEngineOffers, serviceMode, storeId]
  );

  const queryLoyaltyUserStandardOffers = useCallback(
    queryLoyaltyUserOffers({ redemptionTypes: [LoyaltyOfferRedemptionType.STANDARD] }),
    [queryLoyaltyUserOffers]
  );

  const { evaluateLoyaltyOffers } = useLoyaltyOffersEvaluation();

  const evaluateLoyaltyUserIncentives = useCallback(
    async (
      loyaltyId: string,
      appliedLoyaltyOffers: LoyaltyAppliedOffer[],
      cartEntries?: ICartEntry[],
      appliedLoyaltyRewards?: IAppliedRewards | null,
      subtotalAmount?: number,
      paymentMethod?: LoyaltyPaymentMethod | null
    ) => {
      const parsedEntries = cartEntries?.reduce(parseEntry(appliedLoyaltyRewards), []);

      cartEntriesIdsMap.current = (parsedEntries || []).reduce(flattenEntriesToMap, {});

      setEvaluating(true);

      let evaluationResult = null;
      try {
        evaluationResult = await evaluateLoyaltyOffers({
          loyaltyId,
          appliedOffers: appliedLoyaltyOffers,
          cartEntries: parsedEntries,
          subtotalAmount,
          paymentMethod,
          serviceMode,
          storeId,
        });

        if (evaluationResult) {
          dispatch(actions.loyalty.setOffersFeedbackMap(evaluationResult));
        }
      } finally {
        setEvaluating(false);
      }

      return evaluationResult;
    },
    [dispatch, evaluateLoyaltyOffers, serviceMode, storeId]
  );

  useEffect(() => {
    dispatch(
      actions.loyalty.setOffersLoading(
        queryUserOffersLoading ||
          engineOffersLoading ||
          personalizedDataLoading ||
          cmsOffersLoading ||
          swoffersLoading ||
          evaluating ||
          queryUserAppliedOffersLoading
      )
    );
  }, [
    dispatch,
    queryUserOffersLoading,
    engineOffersLoading,
    personalizedDataLoading,
    cmsOffersLoading,
    swoffersLoading,
    evaluating,
    queryUserAppliedOffersLoading,
  ]);

  return {
    evaluateLoyaltyUserIncentives,
    loyaltyOffersEnabled,
    loyaltyStandardOffersEnabled,
    queryLoyaltyOffers,
    queryLoyaltyUserStandardOffers,
    refetchCmsOffers,
    cmsOffersLoading,
  };
};
