import { makeUclComponent } from '@rbilabs/universal-components';

import ActionButton from 'components/action-button';

export const StyledActionButton = makeUclComponent(ActionButton).withConfig({
  width: {
    base: 'full',
    md: '300px',
  },
  alignSelf: 'center',
  marginTop: '$4',
});
