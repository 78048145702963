import React, { FC, useCallback, useContext, useMemo, useState } from 'react';

import { isEqual } from 'lodash-es';

import { IBaseProps } from '@rbi-ctg/frontend';
import { MenuObject } from '@rbi-ctg/menu';
import { MenuObjectTypes } from 'enums/menu';
import { useMenuVariant } from 'experiments/menu-variant/use-menu-variant';
import {
  Channel,
  IEntity,
  PosDataServiceMode,
  ServiceMode,
  useStoreMenuLazyQuery,
} from 'generated/graphql-gateway';
import { useGetMenuSectionsLazyQuery } from 'generated/sanity-graphql';
import { useFeatureMenu } from 'hooks/use-feature-menu';
import { withLazySimpleCache } from 'state/graphql/with-simple-cache';
import { useLocale } from 'state/intl';
import { LANGUAGES } from 'state/intl/types';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useServiceModeContext } from 'state/service-mode';
import { useStoreContext } from 'state/store';
import { GraphQLErrorCodes, parseGraphQLErrorCodes } from 'utils/errors';
import logger from 'utils/logger';
import noop from 'utils/noop';
import { useMemoAll } from 'utils/use-memo-all';

import useActiveDayParts, { isValidDayPart } from './hooks/use-active-day-parts';
import { IValidDayPart } from './types';
import { IMainMenuContext } from './types';
import { remapDayParts } from './utils';

export const MainMenuContext = React.createContext<IMainMenuContext>({
  data: undefined,
  loading: false,
  getStoreMenu: () => Promise.resolve(),
  getMainMenu: () => false,
  getPricingAndAvailability: () => null,
  getCalories: () => null,
  getStoreMenuEntity: () => null,
  hasError: false,
  getSection: () => null,
  activeDayParts: [],
  forceRefreshActiveDayParts: noop,
  dayParts: [],
  storeMenuLoading: false,
  storeMenuCalled: false,
  storeMenuData: undefined,
  setIsOffer: isOffer => isOffer,
  isOffer: false,
  isMenuLoading: false,
});

const useCachedGetMenuSectionsLazyQuery = withLazySimpleCache(useGetMenuSectionsLazyQuery);
const useCachedStoreMenuLazyQuery = withLazySimpleCache(useStoreMenuLazyQuery);

export const MainMenuProvider: FC<React.PropsWithChildren<IBaseProps>> = ({ children }) => {
  const storeDayPartsEnabled = useFlag(LaunchDarklyFlag.ENABLE_DAY_PARTS_PER_STORE);

  const { serviceMode } = useServiceModeContext();
  const { refetchPosData } = useStoreContext();

  const { featureMenu } = useFeatureMenu();
  const { variantName } = useMenuVariant(featureMenu) || {};

  const { language } = useLocale();
  const prevLanguageRef = React.useRef<LANGUAGES>(language);

  const [menuCompletePending, setMenuCompletePending] = useState<boolean>(false);
  const [storeMenuCompletePending, setStoreMenuCompletePending] = useState<boolean>(false);

  const [menuSectionMap, setMenuSectionMap] = useState<Map<string, any>>(new Map());
  const [getMenuSectionsLazy, { loading, error, data }] = useCachedGetMenuSectionsLazyQuery({
    onCompleted(data) {
      setMenuSectionMap(new Map(data.Menu?.options?.map(x => [(x as any)._id, x])));
      setMenuCompletePending(false);
    },
    onError() {
      setMenuCompletePending(false);
    },
  });

  const [storeMenuDataMap, setStoreMenuDataMap] = useState<Map<string, IEntity>>(new Map());
  const [storeDayParts, setStoreDayParts] = useState<IValidDayPart[]>([]);

  const [
    getStoreMenuLazy,
    {
      loading: storeMenuLoading,
      error: storeMenuError,
      called: storeMenuCalled,
      data: storeMenuData,
    },
  ] = useCachedStoreMenuLazyQuery({
    onCompleted(data) {
      setStoreDayParts(remapDayParts(Array.from(data?.storeMenuWithDayParts?.dayParts ?? [])));
      setStoreMenuDataMap(oldValue => {
        const newValue = new Map(data.storeMenuWithDayParts.entities?.map(menu => [menu.id, menu]));
        return isEqual(oldValue, newValue) ? oldValue : newValue;
      });
      setStoreMenuCompletePending(false);
    },
    onError() {
      setStoreMenuCompletePending(false);
    },
  });

  const isMenuLoading =
    loading || storeMenuLoading || menuCompletePending || storeMenuCompletePending;

  const posDataServiceMode =
    ServiceMode.DELIVERY === serviceMode ? PosDataServiceMode.DELIVERY : PosDataServiceMode.PICKUP;

  const getStoreMenu: IMainMenuContext['getStoreMenu'] = useCallback(
    async ({ region, storeId, forceRefresh }) => {
      const variables = {
        channel: Channel.WHITELABEL,
        region,
        storeId,
        serviceMode: posDataServiceMode,
      };

      const { error } = await getStoreMenuLazy({
        allowFromCache: !forceRefresh,
        variables: { ...variables, variantName },
      });

      // If fetching a variantName returned 404 we'll do a second attempt without variantName
      // Otherwise the user might have a blank menu due to a possible misconfiguration.
      const storeMenuNotFound = Boolean(
        error &&
          parseGraphQLErrorCodes(error).some(
            parsedError => parsedError.errorCode === GraphQLErrorCodes.STORE_MENU_NOT_FOUND
          )
      );
      if (variantName && storeMenuNotFound) {
        logger.warn(`Store Menu not found for variant menu: ${{ ...variables, variantName }}`);
        getStoreMenuLazy({
          allowFromCache: !forceRefresh,
          variables,
        });
      }
    },
    [getStoreMenuLazy, variantName, posDataServiceMode]
  );
  const [isOffer, setIsOffer] = useState(false);

  const getMainMenu: IMainMenuContext['getMainMenu'] = useCallback(
    ({ menuId, region, storeId, forceRefresh }): boolean => {
      let hasExecutedFetch = false;
      if (!menuId || !region || !storeId) {
        return hasExecutedFetch;
      }

      // Prevent getting menu sections from the cache when the language has changed.
      const languageChanged = prevLanguageRef.current !== language;
      const allowFromCache = !forceRefresh && !languageChanged;

      // These loading flags are set to false on each query callback
      setMenuCompletePending(true);
      setStoreMenuCompletePending(true);

      getMenuSectionsLazy({ allowFromCache, variables: { id: menuId } });
      getStoreMenu({ region, storeId, forceRefresh });
      if (forceRefresh) {
        refetchPosData({ storeNumber: storeId });
      }
      hasExecutedFetch = true;
      prevLanguageRef.current = language;

      return hasExecutedFetch;
    },
    [getMenuSectionsLazy, refetchPosData, getStoreMenu, language]
  );

  const getSection = useCallback(
    (sanityId: string) => {
      const [type, id] = sanityId.split(/-(.*)/);
      return type === MenuObjectTypes.SECTION ? menuSectionMap.get(id) : null;
    },
    [menuSectionMap]
  );

  const getPricingAndAvailability = useCallback(
    (id: string) => {
      if (storeMenuLoading || storeMenuDataMap.size === 0) {
        return null;
      }

      return storeMenuDataMap.get(id);
    },
    [storeMenuDataMap, storeMenuLoading]
  );

  const getCalories = useCallback(
    (item: MenuObject) => {
      if (!item || !item._id) {
        return null;
      }

      return storeMenuDataMap.get(item._id)?.calories || null;
    },
    [storeMenuDataMap]
  );

  const getStoreMenuEntity = useCallback(
    (menuEntityId: string) => {
      if (storeMenuLoading || storeMenuDataMap.size === 0) {
        return null;
      }

      return storeMenuDataMap.get(menuEntityId);
    },
    [storeMenuDataMap, storeMenuLoading]
  );

  const hasError = Boolean(error || storeMenuError);

  // @ts-expect-error TS(2769) FIXME: No overload matches this call.
  const featureMenuDayParts = useMemo(() => featureMenu?.dayParts?.filter(isValidDayPart) || [], [
    featureMenu,
  ]);

  const dayParts =
    storeDayPartsEnabled && storeDayParts.length > 0 ? storeDayParts : featureMenuDayParts;

  const [activeDayParts, forceRefreshActiveDayParts] = useActiveDayParts({ dayParts });

  const value = useMemoAll({
    data,
    getStoreMenu,
    getMainMenu,
    getSection,
    hasError,
    loading,
    isMenuLoading,
    storeMenuCalled,
    storeMenuLoading,
    activeDayParts,
    forceRefreshActiveDayParts,
    dayParts,
    storeMenuData,
    getPricingAndAvailability,
    getCalories,
    getStoreMenuEntity,
    isOffer,
    setIsOffer,
  });

  return <MainMenuContext.Provider value={value}>{children}</MainMenuContext.Provider>;
};

// MainMenuContext.Provider.whyDidYouRender = true;

export const useMainMenuContext = () => useContext(MainMenuContext);
export default MainMenuContext.Consumer;
