/**
 * The following enum and utility types give us strongly-typed
 * LaunchDarkly flag values. When adding a new flag, two steps
 * must be taken to ensure the correct type is associated with the flag
 */
/**
 * First update this enum to include the flag name
 * Note: the flag name should appear exactly as is it is in the
 * Launch Darkly dashboard
 */

export enum LaunchDarklyFlag {
  AUTO_FILL_TEST_CARD = 'autoFillTestCard',
  CURBSIDE_VEHICLE_DESCRIPTION_MAX_LENGTH = 'curbsideVehicleDescriptionMaxLength',
  DEFAULT_SERVICE_MODE_IN_STORE = 'defaultServiceModeInStore',
  DELIVERY_RADIUS_IN_MILES = 'deliveryRadiusInMiles',
  DISABLE_DINE_IN = 'disableDineIn',
  DISABLE_DRIVE_THRU = 'disableDriveThru',
  DISABLE_TAKE_OUT = 'disableTakeOut',
  ENABLE_ACCOUNT_DELETE_ONE_TRUST = 'enableAccountDeleteOneTrust',
  ENABLE_ACCOUNT_IN_FOOTER = 'enable-account-in-footer',
  ENABLE_ADD_EXTRAS = 'enable-add-extras',
  ENABLE_ADS_PERSONALIZATION_OPT_OUT = 'enable-ads-personalization-opt-out',
  ENABLE_ALLERGENS_DISCLAIMER = 'enableAllergensDisclaimer',
  ENABLE_APPLE_PAY = 'enableApplePay',
  ENABLE_APPLE_PAY_WEB = 'enable-apple-pay-web',
  ENABLE_AUTO_LOCALE = 'enableAutoLocale',
  ENABLE_AUTO_SELECT_RESTAURANT = 'enableAutoSelectRestaurant',
  ENABLE_AUTO_SIGN_IN_ON_SIGN_UP = 'enableAutoSignInOnSignUp',
  ENABLE_CLOSING_TIME_DIALOG = 'enable-closing-time-dialog',
  ENABLE_CASH_APP_PAYMENTS = 'enable-cash-app-payments',
  ENABLE_CASH_PAYMENT = 'enable-cash-payment',
  ENABLE_CLAIM_LOYALTY_POINTS = 'enable-claim-loyalty-points',
  ENABLE_COGNITO_SIGNUP_IN_BE = 'enableCognitoSignupInBe',
  ENABLE_CURBSIDE = 'enableCurbside',
  ENABLE_CUSTOM_BIRTHDAY_INPUT = 'enableCustomBirthdayInput',
  ENABLE_DAY_PARTS_PER_STORE = 'enable-dayparts-per-store',
  ENABLE_DELIVERY = 'delivery',
  ENABLE_DELIVERY_CANCELLATION_POLICY = 'enableDeliveryCancellationPolicy',
  ENABLE_DELIVERY_ONLY = 'enable-delivery-only',
  ENABLE_DELIVERY_WATCH_DRIVER_LOCATION = 'enableDeliveryWatchDriverLocation',
  ENABLE_EDIT_OFFERS_CART_BUTTON = 'enable-edit-offers-cart-button',
  ENABLE_FEATURE_ONBOARDING = 'enable-feature-onboarding',
  ENABLE_SMS_MARKETING_SUBSCRIPTIONS = 'enable-sms-marketing-subscriptions',
  ENABLE_UNLINK_WALMART_FLOW = 'enable-unlink-walmart-flow',
  /**
   * Enable checkout outside opening hours, only for delivery
   */
  ENABLE_DELIVERY_CHECKOUT_OUTSIDE_OPENING_HOURS = 'enable-delivery-checkout-outside-opening-hours',
  /**
   * Whether or not to require address line 2 in the delivery details form
   * - Some markets, like BK DE, require this to identify the customer
   */
  ENABLE_DELIVERY_DETAILS_FORM_REQUIRE_ADDRESS_2 = 'enable-delivery-details-form-require-address-2',
  ENABLE_DISCOUNTS_ON_ORDER_RECEIPT = 'enable-discounts-on-order-receipt',
  ENABLE_FAVORITE_STORES = 'enableFavoriteStores',
  ENABLE_FEATURE_HOME_PAGE = 'enableFeatureHomePage',
  ENABLE_FEES_ON_ORDER_RECEIPT = 'enable-fees-on-order-receipt',
  ENABLE_FIND_RESTAURANT_LINK = 'enable-find-restaurant-link',
  ENABLE_FORCE_PICKER_IMAGE = 'product-details-force-picker-image',
  ENABLE_FORCED_NATIVE_APP_UPDATE = 'enable-forced-native-app-update',
  FORCE_OTA_APP_UPDATE = 'force-ota-app-update',
  ENABLE_FOODWARE_ITEMS = 'enable-foodware-items',
  ENABLE_FORTER_FRAUD = 'enable-forter-fraud',
  ENABLE_FREE_DELIVERY_CHECKOUT_PROGRESS_BAR = 'enableFreeDeliveryCheckoutProgressBar',
  ENABLE_GIFT_CARD = 'gift-card-payment-method',
  ENABLE_GLOBAL_OFFERS_COOLDOWN = 'enable-global-offers-cooldown',
  ENABLE_GOOGLE_PAY = 'enableGooglePay',
  ENABLE_GOOGLE_PAY_WEB = 'enable-google-pay-web',
  ENABLE_IN_RESTAURANT_VARIATIONS = 'enable-in-restaurant-variations',
  ENABLE_STATIC_IN_STORE_IDENTIFICATION = 'enable-static-in-store-identification',
  ENABLE_INTERNAL_DIAGNOSTIC_TOOLS = 'enableInternalDiagnosticTools',
  ENABLE_LOCATOR_CLEAR_STORE_AND_SM_BUTTON = 'enable-locator-clear-store-and-sm-button',
  ENABLE_LOYALTY = 'enable-loyalty-fe',
  ENABLE_LOYALTY_OFFERS = 'enable-loyalty-offers',
  ENABLE_LOYALTY_OFFERS_FILTERS = 'enable-loyalty-offers-filters',
  ENABLE_LOYALTY_POINTS_IN_HEADER = 'enable-loyalty-points-in-header',
  ENABLE_LOYALTY_PROMO_CODES = 'enable-loyalty-promo-codes',
  ENABLE_LOYALTY_QR_CODE_LOYALTY_ID = 'enable-qr-code-loyalty-id',
  ENABLE_LOYALTY_OFFER_EXPIRATION_DISPLAY = 'enable-offer-expiration-display',
  ENABLE_LOYALTY_STANDARD_OFFERS = 'enable-loyalty-standard-offers',
  ENABLE_MOVE_SIGNUP_AFTER_CART = 'enable-move-signup-after-cart',
  ENABLE_MY_CODE_PAYMENT_METHOD = 'enable-my-code-payment-method',
  ENABLE_MY_CODE_REDESIGN = 'enable-my-code-redesign',
  ENABLE_NUTRITION_EXPLORER_ALLERGENS = 'enable-nutrition-explorer-allergens',
  ENABLE_OFFER_REDEMPTION_METHODS = 'enable-offer-redemption-methods',
  ENABLE_OFFERS = 'enableOffers',
  ENABLE_OFFERS_EDIT_CART = 'enable-offers-edit-cart',
  ENABLE_ORDER_NUMBER_GENERATION = 'enable-order-number-generation',
  ENABLE_ORDERING = 'enableOrdering',
  ENABLE_MENU_REORDERING_AVAILABILITY_OVERRIDE = 'enable-menu-reordering-availability-override',
  ENABLE_PAYMENT_ERROR_CONTINUE = 'enable-payment-error-continue',
  ENABLE_PAYPAL_PAYMENTS = 'enable-paypal-payments',
  ENABLE_PERSONALIZED_OFFERS_TEMPLATE = 'fe-personalized-offers-template',
  ENABLE_PYPESTREAM = 'enable-pypestream',
  ENABLE_PRICE_MISMATCH_INTERNAL_ONLY = 'enable-price-mismatch-internal-only',
  ENABLE_QUEST_TILES = 'enable-quest-tiles',
  ENABLE_REDEEM_IN_RESTAURANT_PRIMARY = 'enable-redeem-in-restaurant-primary',
  ENABLE_REORDER = 'enable-reorder',
  ENABLE_REWARD_PAGE_STAND_ALONE = 'enable-reward-page-stand-alone',
  ENABLE_SEND_SAME_OTP_CODE_ONCE = 'send-same-otp-code-once',
  ENABLE_SIGN_UP = 'enableSignUp',
  ENABLE_SIGNUP_CART_BUTTON_TEXT = 'enable-signup-cart-button-text',
  ENABLE_SORT_OFFERS_FOR_SERVICE_MODE = 'enable-sort-offers-for-service-mode',
  ENABLE_STORE_CONFIRMATION_MODAL = 'enableStoreConfirmationModal',
  ENABLE_STREET_NUMBER_AFTER_STREET_NAME = 'enable-street-number-after-street-name',
  ENABLE_TOP_SERVICE_MODE = 'enable-top-service-mode',
  /**
   * when set to true, execute the standalone addPaymentMethod mutation
   * that occurs as part of the checkout process when the 'save payment method' checkbox is selected
   */
  ENABLE_VAULT_AFTER_MAKING_ONE_TIME_PAYMENT = 'enable-vault-after-making-one-time-payment',
  ENABLE_VENMO_PAYMENTS = 'enable-venmo-payments',
  ENABLE_WALMART_PLUS_INTEGRATION = 'enable-walmart-plus-integration',
  ENABLE_WALMART_PLUS_TRIAL_OFFER = 'enable-walmart-plus-trial-offer',
  ENABLE_WALMART_PLUS_WEEK_OFFER = 'enable-walmart-plus-week-offer',
  ENABLE_ZERO_BOUNCE_ERROR_MODAL = 'enable-zero-bounce-error-modal',
  FIRE_ORDER_AHEAD = 'fireOrderAhead',
  FORCE_RESTAURANT_SELECTION_FOR_REWARDS = 'force-restaurant-selection-for-rewards',
  HIDE_ITEMS_FROM_NUTRITION_INFO = 'hide-items-from-nutrition-info',
  HIDE_TIP_AMOUNT_CHECKOUT = 'hide-tip-amount-checkout',
  LOYALTY_IN_RESTAURANT_STATUS_POLL_INTERVAL_MS = 'loyalty-in-restaurant-status-poll-interval-ms',
  ORDER_LOCATOR_TABS = 'order-locator-tabs',
  OVERRIDE_CHECKOUT_DELIVERY_MINIMUM = 'checkoutDeliveryMinimumOverrideCents',
  PAYMENT_FIELD_VARIATIONS = 'payment-fields-variations',
  SUPPORTED_CARD_BRANDS_VARIATIONS = 'supported-card-brands-variations',
  RADIUS_FOR_STORE_LOCATOR = 'radius-for-store-locator',
  RESET_CART_RESET_STORE_TIMEOUT = 'resetStoreResetCartTimeout',
  RESET_CART_TIMEOUT_HRS = 'reset-cart-timeout-hrs',
  SEND_POSTAL_CODE_ONLY_FOR_FIRST_DATA_PAYMENT = 'send-postal-code-only-for-first-data-payment',
  SHOW_INCREASED_DELIVERY_FEES_MODAL = 'show-increased-delivery-fees-modal',
  SHOW_LOYALTY_BANNER_V1 = 'show-loyalty-banner-v1',
  SHOW_ORDERING_ISSUE = 'show-ordering-issue-info',
  SIGN_UP_FIELDS_VARIATIONS = 'sign-up-fields-variations',
  SKIP_LOYALTY_STORE_VALIDATION = 'skip-loyalty-store-validation',
  STORE_CARD_BUTTON_OPTIONS = 'store-card-button-options',
  TIERED_DELIVERY_FEES = 'tiered-delivery-fees',
  TIMED_FIRE_OPTIONS = 'timed-fire-options',
  TIP_PERCENT_THRESHOLD_CENTS = 'tipPercentThresholdCents',
  USE_HIGH_VOLUME_SUPPORT_MSG = 'use-high-volume-support-msg',
  ENABLE_STORE_MENU_PRICING = 'enable-store-menu-pricing',
  ENABLE_PREPAID_PAYMENT_METHODS = 'enable-prepaid-payment-methods',
  // ****** TEST FLAGS ******
  // These flags may not exist in LD but allows us to enable their functionality
  // by using ?rbiFeature.$FLAG=true on the url such as rbiFeature.enable-test-google-pay-web=true
  ENABLE_COOLDOWN_TOAST_INFO = 'enable-cooldown-toast-info',
  ENABLE_MAIN_FAB_BUTTON = 'enable-main-fab-button',
  ENABLE_TEST_APPLE_PAY_WEB = 'enable-test-apple-pay-web',
  ENABLE_TEST_GOOGLE_PAY_WEB = 'enable-test-google-pay-web',
  /*
   Load static Sanity page instead of default loyalty component
   */
  ENABLE_REWARDS_STATIC_PAGE = 'enable-rewards-static-page',
  ENABLE_OFFERS_STATIC_PAGE = 'enable-offers-static-page',
  /**
   * Controls with components and algorithm to fetch offers data.
   * This is part of a loyalty offers refactor initiative.
   * Once the refactor has finished remove this flag.
   */
  ENABLE_LOYALTY_OFFERS_V2_FE = 'enable-loyalty-offers-v2-fe',

  MAX_RBI_API_RETRIES = 'max-rbi-api-retries',
  MAX_SANITY_API_RETRIES = 'max-sanity-api-retries',

  ENABLE_FAST_LANE_DRIVE_THRU = 'enable-fast-lane-drive-thru',
  ENABLE_OPTIONAL_AMPLITUDE_TRACKING = 'enable-optional-amplitude-tracking',
  ENABLE_DEBUG_TOOL = 'enable-debug-menu',

  /**
   * Replace "Drive-Thru" text with "Mobile Order Drive-Thru Lane"
   */
  ENABLE_MOBILE_ORDER_DRIVE_THRU_LANE = 'enable-mobile-order-drive-thru-lane',
  /**
   * Loyalty Id and Pay Feature Flag
   */
  ENABLE_ID_AND_PAY_FE = 'enable-id-and-pay-fe',

  /**
   * Enable Social Login Google Feature Flag
   */
  ENABLE_SOCIAL_LOGIN_GOOGLE = 'enable-social-login-google',

  /**
   * Enable Social Login Apple Feature Flag
   */
  ENABLE_SOCIAL_LOGIN_APPLE = 'enable-social-login-apple',

  /**
   * Enable Social Login Facebook Feature Flag
   */
  ENABLE_SOCIAL_LOGIN_FACEBOOK = 'enable-social-login-facebook',

  /**
   * Enable new checkout animation
   */
  ENABLE_CHECKOUT_ANIMATION = 'enable-checkout-animation',

  /**
   * Enable Order Injection Flow
   */
  ORDER_INJECTION_FLOW = 'order-injection-flow',

  /**
   * Enable top order status bar
   */
  ENABLE_TOP_ORDER_STATUS_BAR = 'enable-top-order-status-bar',
  /**
   * FlyBuy Variations
   */
  FLYBUY_VARIATIONS = 'flybuy-variations',

  /**
   * Enable Foundation Donations
   */
  ENABLE_FOUNDATION_DONATIONS = 'enable-foundation-donations',

  /**
   * Enable OTP Login
   */
  ENABLE_OTP_LOGIN = 'enabled-otp-login',

  /**
   * Enable Delivery Fees Stripe
   */
  DELIVERY_FEES_STRIPE = 'delivery-fees-stripe',

  /**
   * Enable User Away From Store
   */
  USER_AWAY_FROM_STORE = 'user-away-from-store',

  /**
   * Enable Non Traditional Store UI
   */
  ENABLE_NON_TRADITIONAL_STORE_UI = 'enable-non-traditional-store-ui',

  /**
   * Enable Order Bay Service Mode
   */
  ENABLE_ORDER_BAY = 'enable-order-bay',

  /**
   * Enable IOS Live Activity
   */
  ENABLE_IOS_LIVE_ACTIVITY = 'enable-ios-live-activity',

  /**
   * Enable cart cancellation policy
   */
  ENABLE_CART_CANCELLATION_POLICY = 'enable-cart-cancellation-policy',

  /**
   * Controls whether or not to display a prompt to the user asking them
   * if they want to change the selected store to another that is closer.
   */
  ENABLE_AUTO_SELECT_CLOSEST_STORE_PROMPT = 'enable-auto-select-closest-store-prompt',

  /**
   * Controls on which items should we show the "NEW" badge on the first section of the account page.
   */
  ENABLE_ACCOUNT_NEW_BADGE = 'enable-account-new-badge',

  /**
   * Controls the time that Order Status Bar will be shown
   */
  TOP_ORDER_STATUS_BAR_TIME_OUT = 'top-order-status-bar-time-out',

  /**
   * Controls the time that Auto Select Store Prompt will be shown
   */
  AUTO_SELECT_STORE_PROMPT_TIMEOUT = 'auto-select-store-prompt-timeout',

  /**
   * Enable web view debugging
   */
  ENABLE_WEBVIEW_DEBUGGING = 'enable-webview-debugging',

  /**
   * Enable unified menu
   */
  ENABLE_UNIFIED_MENU = 'enable-one-menu',
  /**
   * Enable Mobile Order Drive Thru Service Mode
   */
  ENABLE_MOBILE_ORDER_DRIVE_THRU = 'enable-mobile-drive-thru-service-mode',

  /**
   * Enable Smart Alerts
   */
  ENABLE_SMART_ALERTS = 'enable-smart-alerts',

  /**
   * Stores that have Mobile Order Drive Thru Service Mode enabled
   */
  MOBILE_ORDER_DRIVE_THRU_STORES = 'mobile-order-drive-thru-stores',

  /**
   * Enables or disables the NYC regulatory link in the user interface. This feature is only available for users in NYC.
   */
  NYC_REGULATORY_LINK = 'nyc-regulatory-link',
}

export type AllLaunchDarklyFlags = {
  [key in LaunchDarklyFlag]: any;
};

/**
 * Secondly, update the appropriate union based on the type
 * your new flag should return
 */
export type NumericFlags =
  | LaunchDarklyFlag.CURBSIDE_VEHICLE_DESCRIPTION_MAX_LENGTH
  | LaunchDarklyFlag.FIRE_ORDER_AHEAD
  | LaunchDarklyFlag.OVERRIDE_CHECKOUT_DELIVERY_MINIMUM
  | LaunchDarklyFlag.DELIVERY_RADIUS_IN_MILES
  | LaunchDarklyFlag.RESET_CART_RESET_STORE_TIMEOUT
  | LaunchDarklyFlag.TIP_PERCENT_THRESHOLD_CENTS
  | LaunchDarklyFlag.RADIUS_FOR_STORE_LOCATOR
  | LaunchDarklyFlag.LOYALTY_IN_RESTAURANT_STATUS_POLL_INTERVAL_MS
  | LaunchDarklyFlag.RESET_CART_TIMEOUT_HRS
  | LaunchDarklyFlag.MAX_RBI_API_RETRIES
  | LaunchDarklyFlag.MAX_SANITY_API_RETRIES
  | LaunchDarklyFlag.USER_AWAY_FROM_STORE
  | LaunchDarklyFlag.TOP_ORDER_STATUS_BAR_TIME_OUT
  | LaunchDarklyFlag.AUTO_SELECT_STORE_PROMPT_TIMEOUT;

export type StringFlags =
  | LaunchDarklyFlag.DEFAULT_SERVICE_MODE_IN_STORE
  | LaunchDarklyFlag.ENABLE_OFFER_REDEMPTION_METHODS
  | LaunchDarklyFlag.ENABLE_FORCED_NATIVE_APP_UPDATE
  | LaunchDarklyFlag.FORCE_OTA_APP_UPDATE
  | LaunchDarklyFlag.ORDER_INJECTION_FLOW
  | LaunchDarklyFlag.DELIVERY_FEES_STRIPE
  | LaunchDarklyFlag.ENABLE_ACCOUNT_NEW_BADGE
  | LaunchDarklyFlag.MOBILE_ORDER_DRIVE_THRU_STORES
  | LaunchDarklyFlag.NYC_REGULATORY_LINK;

export type VariationFlags =
  | LaunchDarklyFlag.ENABLE_IN_RESTAURANT_VARIATIONS
  | LaunchDarklyFlag.ENABLE_PREPAID_PAYMENT_METHODS
  | LaunchDarklyFlag.HIDE_ITEMS_FROM_NUTRITION_INFO
  | LaunchDarklyFlag.ORDER_LOCATOR_TABS
  | LaunchDarklyFlag.PAYMENT_FIELD_VARIATIONS
  | LaunchDarklyFlag.SUPPORTED_CARD_BRANDS_VARIATIONS
  | LaunchDarklyFlag.SIGN_UP_FIELDS_VARIATIONS
  | LaunchDarklyFlag.STORE_CARD_BUTTON_OPTIONS
  | LaunchDarklyFlag.TIMED_FIRE_OPTIONS
  | LaunchDarklyFlag.FLYBUY_VARIATIONS
  | LaunchDarklyFlag.ENABLE_MENU_REORDERING_AVAILABILITY_OVERRIDE;

export type BooleanFlags = Exclude<LaunchDarklyFlag, NumericFlags | StringFlags | VariationFlags>;

export type BoolFlags =
  | LaunchDarklyFlag.ENABLE_ADS_PERSONALIZATION_OPT_OUT
  | LaunchDarklyFlag.ENABLE_GLOBAL_OFFERS_COOLDOWN
  | LaunchDarklyFlag.ENABLE_PERSONALIZED_OFFERS_TEMPLATE
  | LaunchDarklyFlag.ENABLE_ORDER_NUMBER_GENERATION
  | LaunchDarklyFlag.ENABLE_DELIVERY_DETAILS_FORM_REQUIRE_ADDRESS_2
  | LaunchDarklyFlag.ENABLE_DELIVERY_CHECKOUT_OUTSIDE_OPENING_HOURS
  | LaunchDarklyFlag.ENABLE_REWARDS_STATIC_PAGE
  | LaunchDarklyFlag.ENABLE_FAVORITE_STORES
  | LaunchDarklyFlag.ENABLE_OFFERS_STATIC_PAGE
  | LaunchDarklyFlag.ENABLE_DISCOUNTS_ON_ORDER_RECEIPT
  | LaunchDarklyFlag.ENABLE_FEES_ON_ORDER_RECEIPT
  | LaunchDarklyFlag.ENABLE_QUEST_TILES
  | LaunchDarklyFlag.ENABLE_SEND_SAME_OTP_CODE_ONCE
  | LaunchDarklyFlag.ENABLE_FEATURE_ONBOARDING
  | LaunchDarklyFlag.ENABLE_FAST_LANE_DRIVE_THRU
  | LaunchDarklyFlag.ENABLE_DEBUG_TOOL
  | LaunchDarklyFlag.ENABLE_MOBILE_ORDER_DRIVE_THRU
  | LaunchDarklyFlag.ENABLE_MOBILE_ORDER_DRIVE_THRU_LANE
  | LaunchDarklyFlag.ENABLE_SMS_MARKETING_SUBSCRIPTIONS
  | LaunchDarklyFlag.ENABLE_UNLINK_WALMART_FLOW
  | LaunchDarklyFlag.ENABLE_TOP_ORDER_STATUS_BAR
  | LaunchDarklyFlag.ENABLE_WALMART_PLUS_INTEGRATION
  | LaunchDarklyFlag.ENABLE_WALMART_PLUS_TRIAL_OFFER
  | LaunchDarklyFlag.ENABLE_WALMART_PLUS_WEEK_OFFER
  | LaunchDarklyFlag.ENABLE_ORDER_BAY
  | LaunchDarklyFlag.ENABLE_AUTO_SELECT_CLOSEST_STORE_PROMPT
  | LaunchDarklyFlag.ENABLE_EDIT_OFFERS_CART_BUTTON
  | LaunchDarklyFlag.ENABLE_UNIFIED_MENU
  | LaunchDarklyFlag.ENABLE_SMART_ALERTS;

export type FlagType<F extends LaunchDarklyFlag> = F extends NumericFlags
  ? number
  : F extends StringFlags
  ? string
  : F extends BooleanFlags
  ? boolean
  : F extends VariationFlags
  ? unknown
  : never;

export type LaunchDarklyFlagsObject = { [F in LaunchDarklyFlag]?: FlagType<F> };
