import { useMemo } from 'react';

import { useIntl } from 'react-intl';

import { IRestaurant } from '@rbi-ctg/store';
import { useOpenClosedText } from 'pages/store-locator/components/store-card/hooks/use-open-closed-text';
import { useGetRestaurantAvailabilityFn, useIsMobileOrderingAvailable } from 'utils/restaurant';

interface UseErrorMessageOptions {
  restaurant: IRestaurant;
}

export const useErrorMessage = ({ restaurant }: UseErrorMessageOptions) => {
  const { formatMessage } = useIntl();
  const { isOpen } = useOpenClosedText(restaurant);

  const getRestaurantAvailability = useGetRestaurantAvailabilityFn();
  const isMobileOrderingAvailable = useIsMobileOrderingAvailable(restaurant);

  const isRestaurantAvailable = getRestaurantAvailability(restaurant); // TODO: Should we use restaurantCanBeSelected() instead?

  return useMemo(() => {
    if (!restaurant.hasMobileOrdering) {
      return formatMessage({ id: 'mobileOrderingUnavailableAtStore' });
    } else if (!isMobileOrderingAvailable || !isRestaurantAvailable || !isOpen) {
      return formatMessage({ id: 'mobileOrderingUnavailable' });
    }

    return;
  }, [
    formatMessage,
    isMobileOrderingAvailable,
    isRestaurantAvailable,
    restaurant.hasMobileOrdering,
    isOpen,
  ]);
};
