import React from 'react';

import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useLoyaltyUser } from 'state/loyalty/hooks/use-loyalty-user';

import { QuestCardError, QuestCardSkeleton } from './quest-card';
import { QuestCardWrapper } from './quest-card-wrapper';
import { QuestLibraryHeader } from './quests-library/quest-library-header';
import { useUserQuest } from './use-user-quest';

export const QuestSection = ({ showHeader = true }: { showHeader?: boolean }) => {
  const { loyaltyUser } = useLoyaltyUser();
  const isQuestTilesEnable = useFlag(LaunchDarklyFlag.ENABLE_QUEST_TILES);
  const loyaltyId = loyaltyUser?.id;
  const { isLoading, error, refetch, userQuest, cmsQuestCardUI } = useUserQuest();

  if (!isQuestTilesEnable || !loyaltyId) {
    return null;
  }

  if (isLoading) {
    return <QuestCardSkeleton />;
  }

  if (error) {
    return <QuestCardError refetch={refetch} />;
  }

  if (!userQuest || !cmsQuestCardUI) {
    return null;
  }

  return (
    <>
      {showHeader && <QuestLibraryHeader />}
      <QuestCardWrapper
        quest={userQuest.loyaltyQuest}
        cmsQuestCard={userQuest.cmsQuestCard}
        cmsQuestCardUI={cmsQuestCardUI}
      />
    </>
  );
};
