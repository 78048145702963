import { ReactNode } from 'react';

import { BoxProps } from '@rbilabs/universal-components';
import { ImageURISource, LayoutChangeEvent } from 'react-native';

import { ISanityImage } from '@rbi-ctg/menu';
import { ISanityImage as GWISanityImage } from 'generated/graphql-gateway';
import { IFeatureStaticImagesQuery, IImageFragment } from 'generated/sanity-graphql';

export interface IPictureProps extends BoxProps {
  alt: string;
  children?: ReactNode;
  className?: string;
  image?: ISanityImage | IImageFragment | GWISanityImage | null;
  lottie?: Lottie | null;
  lazy?: boolean;
  lockedAspectRatio?: boolean;
  lqOff?: boolean;
  //aka normal <Image/> resize mode cover (default) or contain if true
  objectFitContain?: boolean;
  placeholderAspectRatio?: number;
  priority?: 'low' | 'normal' | 'high' | null;
  quality?: number;
  //draggable?: boolean; // TODO: RN

  // A number multiplier to be used when requesting the image.
  // Useful when you will need to zoom in on the image or the image will be artificial cropped.
  //
  // A value of 1 will keep the resolution the same.
  // Values less then 1 will decrease the resolution.
  // And values greater then one will increase resolution
  resolutionMultiplier?: number;

  // Flag to validate if we need to use height/width props
  sizedByChildren?: boolean;
  cache?: NonNullable<ImageURISource['cache']>;

  onImageLoaded?: () => void;
  isBlurhashEnabled?: boolean;
  onLayout?: (event: LayoutChangeEvent) => void;
}

export enum ResizeMode {
  contain = 'contain',
  cover = 'cover',
}

export type IntrinsicBoundaryDimensions = {
  width: number;
  height: number;
  aspectRatio: number;
};

export type ExtrinsicBoundaryDimensions = {
  width?: string | number;
  height?: string | number;
};

export type MeasuredDimensions = {
  width: number;
  height: number;
};

export enum LoadState {
  'preload',
  'loading',
  'loaded',
}

export type Lottie = NonNullable<
  NonNullable<
    NonNullable<IFeatureStaticImagesQuery['FeatureStaticImages']>['staticImagesList']
  >[number]
>['lottie'];
