import {
  CountryCode,
  MetadataJson,
  ParseError,
  PhoneNumber,
  parsePhoneNumberWithError,
} from 'libphonenumber-js/core';

// https://gitlab.com/catamphetamine/libphonenumber-js#customizing-metadata
import metadata from './metadata.custom.json';

export async function parsePhoneNumber(text: string, country: CountryCode): Promise<PhoneNumber> {
  return parsePhoneNumberWithError(text, country, metadata as MetadataJson);
}

export { ParseError };
