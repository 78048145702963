import { Svg, SvgPath, SvgProps, registerSVG } from '../../../components/svg';

export function MobileOrderDriveThru(props: SvgProps) {
  return (
    <Svg {...props} data-legacy-icon viewBox="0 0 24 24">
      <SvgPath
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.7792 8.31125H20.2424C19.974 8.31125 19.7056 8.44545 19.7056 8.84807V9.9217C19.7056 10.1901 19.974 10.4585 20.2424 10.4585H21.1818V19.4502C21.1818 19.7186 21.4502 19.987 21.7186 19.987H22.2554C22.5239 19.987 22.7923 19.7186 22.7923 19.4502V10.4585H23.4633C23.7317 10.4585 24.0001 10.1901 24.0001 9.9217V9.38488C24.0001 9.11647 23.7317 8.84807 23.4633 8.84807L20.7792 8.31125Z"
      />
      <SvgPath d="M15.92 5.01C15.72 4.42 15.16 4 14.5 4H3.5C2.84 4 2.29 4.42 2.08 5.01L0 11V19C0 19.55 0.45 20 1 20H2C2.55 20 3 19.55 3 19V18H15V19C15 19.55 15.45 20 16 20H17C17.55 20 18 19.55 18 19V11L15.92 5.01ZM3.5 15C2.67 15 2 14.33 2 13.5C2 12.67 2.67 12 3.5 12C4.33 12 5 12.67 5 13.5C5 14.33 4.33 15 3.5 15ZM14.5 15C13.67 15 13 14.33 13 13.5C13 12.67 13.67 12 14.5 12C15.33 12 16 12.67 16 13.5C16 14.33 15.33 15 14.5 15ZM2 10L3.5 5.5H14.5L16 10H2Z" />
    </Svg>
  );
}

registerSVG('MobileOrderDriveThru', MobileOrderDriveThru);
