import { Box, Icon } from '@rbilabs/universal-components';

import { theme } from 'styles/configure-theme';
import { hiddenAccessibilityPlatformProps } from 'utils/accessibility';

export const HeaderContainer = Box.withConfig({
  position: 'relative',
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  height: '$14',
});

export const BackButtonContainer = Box.withConfig({
  position: 'absolute',
  left: 0,
});

export const StyledBackIcon = Icon.withConfig({
  color: theme.token('icon-header-contrast'),
  size: '5',
  ...hiddenAccessibilityPlatformProps,
});

export const StyledLogo = Box.withConfig({
  marginX: 'auto',
  padding: '$1.5',
});
