import React from 'react';

import { isIOS } from 'utils/environment';

import DestinationMarker from './icons/destination-marker';
import DriverMarker from './icons/driver-marker';
import StoreMarker from './icons/store-marker';
import StoreMarkerActive from './icons/store-marker-active';
import StoreMarkerDisabled from './icons/store-marker-disabled';
import StoreMarkerFavorite from './icons/store-marker-fav';
import StoreMarkerFavClosed from './icons/store-marker-fav-closed';
import StoreMarkerInActive from './icons/store-marker-inactive';
import UserMarker from './icons/user-marker';
import { IMarker, IMarkerInput, MarkerTypes } from './types';

export const deriveMarkerIcon = (type: MarkerTypes): React.ReactNode => {
  const alignPressedProps = isIOS() && {
    bottom: 8,
  };

  switch (type) {
    case MarkerTypes.User:
    case MarkerTypes.UserManual:
      return <UserMarker />;
    case MarkerTypes.StoreOpen:
    case MarkerTypes.StoreOpenSelected:
      return <StoreMarker />;
    case MarkerTypes.StoreFocused:
    case MarkerTypes.StoreFocusedSelected:
      return <StoreMarkerActive {...alignPressedProps} />;
    case MarkerTypes.StoreFocusedNotAvailable:
      return <StoreMarkerInActive {...alignPressedProps} />;
    case MarkerTypes.StoreNotAvailable:
      return <StoreMarkerDisabled />;
    case MarkerTypes.StoreFavNotAvailable:
      return <StoreMarkerFavClosed />;
    case MarkerTypes.StoreFavOpen:
    case MarkerTypes.StoreFavSelected:
      return <StoreMarkerFavorite />;
    case MarkerTypes.Driver:
      return <DriverMarker />;
    case MarkerTypes.Destination:
      return <DestinationMarker />;

    default:
      throw new Error(`Unsupported type: ${type}`);
  }
};

export const checkSingleUseMarker = (type: MarkerTypes): boolean =>
  [MarkerTypes.User, MarkerTypes.UserManual, MarkerTypes.Driver, MarkerTypes.Destination].includes(
    type
  );

// this is just for mobile apps as after some research most of the performance issues was caused by SVG images
export const getKeyForMarkerInput = (
  { type, location }: IMarkerInput,
  isSingleUseMarker: boolean
): string => (isSingleUseMarker ? type : `${location.lat}${location.lng}`);

export const generateMarker = ({ id, type, location, onPress }: IMarkerInput): IMarker => {
  const isSingleUseMarker = checkSingleUseMarker(type);
  return {
    id,
    location,
    icon: deriveMarkerIcon(type),
    onPress,
    type,
    preventMarkerPress: isSingleUseMarker,
    visible: type !== MarkerTypes.UserManual,
  };
};
