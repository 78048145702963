import { isEmpty } from 'lodash-es';

export function appendObjectToQueryString(path: string, object: object): string {
  if (!object || isEmpty(object)) {
    return path;
  }

  const appendedParams = Object.keys(object)
    .map(key => {
      const encodedKey = encodeURIComponent(key);
      const value = object[key];
      const processedValue = typeof value === 'object' ? JSON.stringify(value) : value;
      const encodedValue = encodeURIComponent(processedValue);
      return `${encodedKey}=${encodedValue}`;
    })
    .join('&');
  const concatChar = path.includes('?') ? '&' : '?';
  return `${path}${concatChar}${appendedParams}`;
}

function parseStringValue(value: string) {
  let result: number | object | string | boolean;
  try {
    // Numbers with more than 16 chars, possibly are codes (like in loyalty) so we don't parse them
    result = value.length >= 16 && !isNaN(value as any) ? value : JSON.parse(value);
  } catch (error) {
    result = value;
  }
  return result;
}

export function convertQueryStringToObject<T extends object>(queryString: String): T {
  const params = new URLSearchParams(queryString.replace('?', ''));

  const result = {} as T;
  params.forEach((value: string, key: string) => {
    const normalizedKey = key.replace('[]', '');
    const parsedValue = parseStringValue(value);

    const previousValue = result[normalizedKey];
    if (previousValue) {
      // Handle array like parameters like ?someParam[]=123&someParam[]=text
      result[normalizedKey] = [
        Array.isArray(previousValue) ? [...previousValue] : previousValue,
        parsedValue,
      ];
    } else {
      result[normalizedKey] = parsedValue;
    }
  });
  return result;
}
