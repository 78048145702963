import { IntlFormatters } from 'react-intl';

import { ServiceMode } from 'state/service-mode/types';

export const SERVICE_MODES = [
  ServiceMode.DELIVERY,
  ServiceMode.DRIVE_THRU,
  ServiceMode.EAT_IN,
  ServiceMode.MOBILE_ORDER_DRIVE_THRU,
  ServiceMode.TAKEOUT,
  ServiceMode.CURBSIDE,
];

export const PICKUP_SERVICE_MODES = [
  ServiceMode.DRIVE_THRU,
  ServiceMode.EAT_IN,
  ServiceMode.MOBILE_ORDER_DRIVE_THRU,
  ServiceMode.TAKEOUT,
  ServiceMode.CURBSIDE,
];

export const DELIVERY_SERVICE_MODES = [ServiceMode.DELIVERY];

export const isDelivery = (serviceMode: ServiceMode | null): serviceMode is ServiceMode.DELIVERY =>
  serviceMode === ServiceMode.DELIVERY;

export const isDriveThru = (
  serviceMode: ServiceMode | null
): serviceMode is ServiceMode.DRIVE_THRU => serviceMode === ServiceMode.DRIVE_THRU;

export const isMobileOrderDriveThru = (
  serviceMode: ServiceMode | null
): serviceMode is ServiceMode.MOBILE_ORDER_DRIVE_THRU =>
  serviceMode === ServiceMode.MOBILE_ORDER_DRIVE_THRU;

export const isPickup = (serviceMode: ServiceMode | null): serviceMode is ServiceMode.DELIVERY =>
  serviceMode ? PICKUP_SERVICE_MODES.includes(serviceMode) : false;

export const getLocalizedServiceMode = (
  serviceMode: ServiceMode,
  formatMessage: IntlFormatters['formatMessage'],
  isOrderBayEnabled: boolean = false
): string => {
  switch (serviceMode) {
    case ServiceMode.DRIVE_THRU:
      return formatMessage({ id: 'driveThru' });
    case ServiceMode.EAT_IN:
      return formatMessage({ id: 'dineIn' });
    case ServiceMode.MOBILE_ORDER_DRIVE_THRU:
      return formatMessage({ id: 'mobileOrderDriveThru' });
    case ServiceMode.TAKEOUT:
      return formatMessage({ id: 'takeOut' });
    case ServiceMode.CURBSIDE:
      // TODO: BKPE-6812 ORDER_BAY_PILOT. Remove or consolidate this logic once the pilot is done
      return formatMessage({ id: isOrderBayEnabled ? 'orderBay' : 'curbside' });
    case ServiceMode.DELIVERY:
      return formatMessage({ id: 'delivery' });
    default:
      return '';
  }
};

interface IHeading {
  icon: string;
  heading: string;
}
export const getHeadingForServiceMode = (
  formatMessage: IntlFormatters['formatMessage'],
  isFastLaneEnabled: boolean,
  isMobileOrderForDriveThruLaneEnabled: boolean,
  serviceMode?: ServiceMode | null,
  isOrderBayEnabled?: boolean
): IHeading | null => {
  let heading: IHeading | null;

  switch (serviceMode) {
    case ServiceMode.DELIVERY:
      heading = {
        icon: getServiceModeIcon(serviceMode),
        heading: formatMessage({ id: 'delivery' }),
      };
      break;
    case ServiceMode.CURBSIDE:
      heading = {
        icon: getServiceModeIcon(serviceMode),
        // TODO: BKPE-6812 ORDER_BAY_PILOT. Remove or consolidate this logic once the pilot is done
        heading: formatMessage({ id: isOrderBayEnabled ? 'orderBay' : 'curbside' }),
      };
      break;
    case ServiceMode.TAKEOUT:
      heading = {
        icon: getServiceModeIcon(serviceMode),
        heading: formatMessage({ id: isFastLaneEnabled ? 'fastLaneDriveThru' : 'pickUp' }),
      };
      break;
    case ServiceMode.DRIVE_THRU:
      heading = {
        icon: getServiceModeIcon(serviceMode),
        heading: formatMessage({
          id: isMobileOrderForDriveThruLaneEnabled ? 'mobileOrderLane' : 'driveThru',
        }),
      };
      break;
    case ServiceMode.MOBILE_ORDER_DRIVE_THRU:
      heading = {
        icon: getServiceModeIcon(serviceMode),
        heading: formatMessage({
          id: 'mobileOrderDriveThru',
        }),
      };
      break;
    case ServiceMode.EAT_IN:
      heading = {
        icon: getServiceModeIcon(serviceMode),
        heading: formatMessage({ id: 'dineIn' }),
      };
      break;
    default:
      heading = null;
  }

  return heading;
};

export const getServiceModeIcon = (serviceMode?: ServiceMode | null): string => {
  const iconMap: { [key in ServiceMode]?: string } = {
    [ServiceMode.DELIVERY]: 'delivery',
    [ServiceMode.CURBSIDE]: 'driveThru',
    [ServiceMode.TAKEOUT]: 'store',
    [ServiceMode.DRIVE_THRU]: 'driveThru',
    [ServiceMode.MOBILE_ORDER_DRIVE_THRU]: 'mobileOrderDriveThru',
    [ServiceMode.EAT_IN]: 'restaurant',
  };

  return serviceMode ? iconMap[serviceMode] || '' : '';
};
